import { createContext, useContext } from 'react';
import * as cdiService from '../services/cdiService';

export const CdiContext = createContext({});

const CdiProvider = ({ children }) => {
  const loadCdiPercentage = async () => {
    try {
      const data = await cdiService.getCdi();
      return data.data;
    } catch (error) {
      console.error(error);
    }
  };

  return <CdiContext.Provider value={{ loadCdiPercentage }}>{children}</CdiContext.Provider>;
};

const useCdi = () => {
  return useContext(CdiContext);
};

export { CdiProvider, useCdi };
