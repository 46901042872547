import React from 'react';
import { Translator } from '@components';

const StatusCard = ({ status }) => {
  return (
    <div
      className="col-12"
      style={{
        borderRadius: '200px',
        backgroundColor: `${
          status === 'INCAPTURE'
            ? '#FF9900'
            : status === 'ALLSOLD'
            ? '#475A9D'
            : status === 'LIQUIDATING'
            ? '#2E291E'
            : status === 'COMPLETE'
            ? '#009847'
            : status === 'CANCELLED'
            ? '#A30914'
            : ''
        }`,
        textAlign: 'center',
      }}
    >
      <p className="statement-status-card" style={{ color: 'var(--white)' }}>
        {status === 'INCAPTURE' ? (
          <Translator path="pages.tokensList.inCapture" />
        ) : status === 'ALLSOLD' ? (
          <Translator path="pages.tokensList.allSold" />
        ) : status === 'LIQUIDATING' ? (
          <Translator path="pages.tokensList.liquidating" />
        ) : status === 'COMPLETE' ? (
          <Translator path="pages.tokensList.complete" />
        ) : status === 'CANCELLED' ? (
          <Translator path="pages.tokensList.cancelled" />
        ) : (
          ''
        )}
      </p>
    </div>
  );
};

export default StatusCard;
