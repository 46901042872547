import React, { useState } from 'react';
import { useIssuer, useRegister } from '@contexts-issuer';
import { Translator, CodeValidator } from '@components';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
const Validator = ({ props: { setCode, code, step, setStep } }) => {
  const { issuerInfo } = useIssuer();
  const { resetToken, validateToken } = useRegister();
  const successObj = { msg: '' };
  const [retry, setRetry] = useState(successObj);
  const { t } = useTranslation();

  useEffect(() => {
    const sendToken = () => {
      resetToken(issuerInfo.issuerLoginId);
    };

    if (step === 1 && issuerInfo) sendToken();
  }, [step]);

  const verifyToken = async () => {
    if (code && code.length === 4 && issuerInfo) {
      const params = {
        token: code,
        issuerLoginId: issuerInfo.issuerLoginId,
      };
      const success = await validateToken(params);
      if (success === true) setStep(2);
    }
  };

  const resend = () => {
    if (step === 1 && issuerInfo) {
      resetToken(issuerInfo.issuerLoginId);
    }
    const success = { ...successObj };
    success.msg = t('pages.withdrawList.resend').toString();
    setRetry(success);
  };

  return (
    <div className="row col-6 d-flex justify-content-between validator">
      <p>
        <Translator path="pages.withdrawList.request.body" />
      </p>
      <p className="profile-email">{issuerInfo && issuerInfo.email}</p>
      <div className="col-12 d-flex justify-content-start" style={{ gap: '0 11px', margin: '2.188rem 0 0 0' }}>
        <CodeValidator props={{ setCode }} />
      </div>

      {retry.msg && (
        <div className="text-success fs-12" style={{ marginTop: '0.313rem' }}>
          {retry.msg}
        </div>
      )}
      <div className="row col-12 d-flex justify-content-start align-items-center" style={{ margin: 0 }}>
        <span className="form-check d-inline-block col-7 mt-2" style={{ padding: 0, whiteSpace: 'nowrap' }}>
          <label className="resend" htmlFor="check1" style={{ alignSelf: 'start' }}>
            <Translator path="pages.register.secondStep.send" />
          </label>
          <label className="resend" style={{ alignSelf: 'end', marginLeft: '0.5rem' }}>
            <Translator path="pages.register.secondStep.code" />
          </label>
          <label className="click cursor-pointer" onClick={() => resend()}>
            <Translator path="pages.profile.click" />
          </label>
        </span>
      </div>
      <button className="col-3 btn btn-outlined" style={{ marginTop: '2.5rem ' }} onClick={() => setStep(0)}>
        <Translator path="pages.profile.goBack" />
      </button>
      <button className="col-6 btn btn-orange" style={{ marginTop: '2.5rem' }} onClick={() => verifyToken()}>
        <Translator path="pages.profile.continue" />
      </button>
    </div>
  );
};

export default Validator;
