import React from 'react';
import { Link } from 'react-router-dom';

import { logoAmarela, logoText, logoEmissor, logoIssuer } from '@assets';
import { useTranslation } from 'react-i18next';

/// React router dom
//images
export function NavMenuToggle() {
  setTimeout(() => {
    let mainwrapper = document.querySelector('#main-wrapper');
    if (mainwrapper.classList.contains('menu-toggle')) {
      mainwrapper.classList.remove('menu-toggle');
    } else {
      mainwrapper.classList.add('menu-toggle');
    }
  }, 200);
}

const NavHeader = ({ props: { toggle, setToggle } }) => {
  const { i18n } = useTranslation();

  return (
    <div className="nav-header">
      <Link to="/dashboard" className="brand-logo">
        {process.env.REACT_APP_BUILD_TARGET === 'investor' ? (
          <>
            <img src={logoAmarela} className="logo-abbr" alt="" />
            <img src={logoText} className="brand-title" alt="" />
            <img src={logoAmarela} className="logo-color" alt="" />
          </>
        ) : process.env.REACT_APP_BUILD_TARGET === 'issuer' ? (
          <img src={i18n.language === 'pt-BR' ? logoEmissor : logoIssuer} className="brand-title" alt="" />
        ) : (
          ''
        )}
      </Link>

      <div
        className="nav-control "
        onClick={() => {
          setToggle(!toggle);
          //openMenuToggle();
          NavMenuToggle();
        }}
      >
        <div className={`hamburger ${toggle ? 'is-active' : ''}`}>
          <span className="line" style={{ backgroundColor: '#FF9900' }}></span>
          <span className="line" style={{ backgroundColor: '#FF9900' }}></span>
          <span className="line" style={{ backgroundColor: '#FF9900' }}></span>
          <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="22" y="11" width="4" height="4" rx="2" fill="#FF9900" />
            <rect x="11" width="4" height="4" rx="2" fill="#FF9900" />
            <rect x="22" width="4" height="4" rx="2" fill="#FF9900" />
            <rect x="11" y="11" width="4" height="4" rx="2" fill="#FF9900" />
            <rect x="11" y="22" width="4" height="4" rx="2" fill="#FF9900" />
            <rect width="4" height="4" rx="2" fill="#FF9900" />
            <rect y="11" width="4" height="4" rx="2" fill="#FF9900" />
            <rect x="22" y="22" width="4" height="4" rx="2" fill="#FF9900" />
            <rect y="22" width="4" height="4" rx="2" fill="#FF9900" />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default NavHeader;
