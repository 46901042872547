import * as api from '../api';

export function buyToken(body) {
  return api.post('v2/purchase', body);
}

export function getPurchases(params) {
  return api.get('purchase/', params);
}

export function getPurchasesByUser(userId) {
  return api.get(`purchase/${userId}`);
}
