import React, { useState } from 'react';
import { InputText, Translator } from '@components';
import { useIssuer } from '@contexts-issuer';
import Validator from './Validator';
import ResetPassword from './ResetPassword';

const AlterPassword = () => {
  const { issuerInfo } = useIssuer();
  const [step, setStep] = useState(0);
  const [code, setCode] = useState();

  return (
    <div className="d-flex justify-content-center">
      <div className="col-10 profile-edit-wrapper">
        <div className="col-12 d-flex align-items-center profile-header">
          <p>
            <Translator path="pages.profile.title" />
          </p>
        </div>
        <div className="row col-12 profile-body">
          {step === 0 ? (
            <div className="col-xl-7 col-lg-12 col-md-10 col-sm-12 send-email">
              <InputText
                text={'pages.profile.email'}
                className={'issuer-input-disabled'}
                defaultValue={issuerInfo && issuerInfo.email}
              />
              <button className="col-xl-6 col-lg-6 col-md-6 col-sm-12 action btn btn-orange" onClick={() => setStep(1)}>
                <Translator path="pages.profile.title" />
              </button>
            </div>
          ) : step === 1 ? (
            <div className="d-flex justify-content-left">
              <Validator props={{ setCode, code, step, setStep }} />
            </div>
          ) : step === 2 ? (
            <div className="col-6">
              <ResetPassword props={{ code, setStep }} />
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

export default AlterPassword;
