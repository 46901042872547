import { cardLogo } from '@assets';
import { Translator } from '@components';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Content = ({ props }) => {
  const { t } = useTranslation();

  return (
    <>
      <div
        className="row col-12 d-flex flex-row align-items-center justify-content-between mb-3"
        style={{ justifyContent: 'flex-start', padding: 0, margin: 0 }}
      >
        <div
          className={`col-12 col-sm-6 col-md-12 col-lg-12 col-xl-6 d-flex align-items-center token-flex`}
          style={{ padding: 0, margin: 0 }}
        >
          <div
            className="d-flex  justify-content-center align-items-center"
            style={{
              padding: 0,
              height: '3.75rem',
              width: '3.75rem',
            }}
          >

            <img
              src={props?.image ? props.image : cardLogo}
              className='img-responsive img-fluid'
              style={{
                width: '100%',
                height: '100%',
                backgroundSize: 'cover',
                backgroundImage: `url(${props?.image ? props.image : cardLogo})`,
                background: 'rgba(247, 219, 179, 0.4)',
                borderRadius: '10px',
              }}
            />

          </div>
          <div className="d-flex  flex-column " style={{ padding: 0 }}>
            <h4 className="mb-0" style={{ marginLeft: '0.625rem', whiteSpace: 'pre-wrap' }}>
              {props?.name}
            </h4>
          </div>
        </div>
        {/* <div
          className="col-12 col-sm-6 col-md-12 col-lg-12 col-xl-6 d-flex justify-content-center flex-column"
          style={{ padding: 0, margin: 0, textAlign: 'center' }}
        >
          <p className="card-label-text mb-0">
            <Translator path="components.tokenCard.value" />
          </p>
          <p className="card-val-text mb-2">
            {props.unityPrice
              ? props?.unityPrice?.toLocaleString('pt-BT', { style: 'currency', currency: 'BRL' })
              : 'R$ 00,00'}
          </p>
        </div>*/}
      </div>
    </>
  );
};

export default Content;
