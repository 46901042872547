import { Loading, Translator, StepsCard } from '@components';
import { useIssuer } from '@contexts-issuer';
import { Col, Row } from 'react-bootstrap';
import { useEffect } from 'react';

const Dashboard = () => {
  const { loading: loadingIssuer, getCompleteIssuerProfile, issuerCompleteProfile } = useIssuer();

  useEffect(() => {
    const issuerId = localStorage.getItem('issuerId');
    getCompleteIssuerProfile(issuerId);
  }, []);

  if (loadingIssuer) {
    return <Loading />;
  }

  if (issuerCompleteProfile) {
    return (
      <Col className="page-wrapper">
        <Row className="first-steps card m-0 ">
          <Row className="card-title m-0 p-0">
            <p className="p-0">
              <Translator path={'pages.dashboard.firstSteps'} />
            </p>
          </Row>
          <Row className="py-5 d-flex step-cards">
            <Col xxl={4} lg={4}>
              <StepsCard step={1} issuer={issuerCompleteProfile} />
            </Col>
            <Col xxl={4} lg={4}>
              <StepsCard step={2} issuer={issuerCompleteProfile} />
            </Col>
            <Col xxl={4} lg={4}>
              <StepsCard step={3} issuer={issuerCompleteProfile} />
            </Col>
          </Row>
        </Row>
        <Row></Row>
      </Col>
    );
  }
};

export default Dashboard;
