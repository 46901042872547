import { createContext, useCallback, useContext, useState } from 'react';
import swal from 'sweetalert';

import * as ProfileService from '../../services/Investor/profileService';
import * as registerService from '../../services/Investor/registerService';
import { ErrorHandler } from '../../services/Investor/errorHandler';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

export const ProfileContext = createContext({});

const ProfileProvider = ({ children }) => {
  const { t } = useTranslation();
  const [profile, setProfile] = useState();
  const [loadingProfile, setLoadingProfile] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [showBalance, setShowBalance] = useState('password');
  const [showTotalAssets, setShowTotalAssets] = useState('password');
  const [totalAssets, setTotalAssets] = useState(0);
  const [myId, setMyId] = useState();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const editProfile = useCallback(async (userId, body, type) => {
    setLoadingButton(true);
    await ProfileService.editUserProfile(userId, body)
      .then((response) => {
        const successHeader = t('success');
        const successMessage = t('sucessMessage');
        swal(successHeader, successMessage, 'success');
        if (type === 'pj') {
          navigate('/profilepj');
        } else {
          navigate('/profile');
        }
      })
      .catch((error) => {
        const errorTitle = t('apiErrors.error').toString();
        const errorCode = ErrorHandler(error.response.data.message);
        const errorMsg = t(errorCode).toString();
        swal(errorTitle, errorMsg, 'error');
      });
    setLoadingButton(false);
  });

  const loadProfile = useCallback(
    async (userId) => {
      setLoadingProfile(true);
      try {
        const data = await ProfileService.get(userId);
        setProfile(data.data);
      } catch (error) {
        const errorTitle = t('apiErrors.error').toString();
        const errorMsg = t('apiErrors.profile').toString();
        if (error.response.status !== 401) {
          swal(errorTitle, errorMsg, 'error');
        }
      } finally {
        setLoadingProfile(false);
      }
    },
    [setProfile, profile, loadingProfile],
  );

  const uploadImage = useCallback(async (file) => {
    setImageLoading(true);
    try {
      const { data } = await ProfileService.uploadImage(file);
      return data;
    } catch (error) {
      console.error(error);
    } finally {
      setImageLoading(false);
    }
  }, []);

  const downloadImageInvestor = useCallback(async (id) => {
    setImageLoading(true);
    try {
      const { data } = await ProfileService.downloadImage(id);
      return data;
    } catch (error) {
      console.error(error);
    } finally {
      setImageLoading(false);
    }
  }, []);

  const balanceControl = useCallback(
    async (type) => {
      setShowBalance(type);
    },
    [setShowBalance],
  );

  const totalAssetsControl = useCallback(
    async (type) => {
      setShowTotalAssets(type);
    },
    [setShowTotalAssets],
  );

  const getTotalAssets = (profile, tokens) => {
    let value = 0;
    tokens?.forEach((token) => {
      let valueToken = 0;

      token.purchases?.forEach((purchase) => {
        (purchase.status === 'INCAPTURE' || purchase.status === 'CAPTATED') &&
          (valueToken += +purchase.boughtValue + purchase.boughtQuantity * +token.yieldValue);
      });

      value += valueToken;
    });

    if (profile?.wallet) value += profile?.wallet?.balance;
    setTotalAssets(value);
  };

  const sendResetToken = useCallback(
    async (email) => {
      setLoadingButton(true);
      try {
        const response = await ProfileService.sendToken(email);
        setMyId(response.data.userId);
      } catch (error) {
        const errorTitle = t('resetPasswordPage.errors.err').toString();
        const errorMsg = t('resetPasswordPage.errors.email').toString();
        swal(errorTitle, errorMsg, 'error');
      } finally {
        setLoadingButton(false);
      }
    },
    [setLoadingButton, setMyId, myId],
  );

  const validateToken = useCallback(async (code, setStep, userId) => {
    setLoadingButton(true);
    try {
      const params = { userId, token: code };
      await registerService.confirmCode(params);
      if (pathname == '/reset') {
        setStep(3);
      } else setStep(2);
    } catch (error) {
      const errorTitle = t('resetPasswordPage.errors.err').toString();
      const errorMsg = t('resetPasswordPage.errors.token').toString();
      swal(errorTitle, errorMsg, 'error');
    } finally {
      setLoadingButton(false);
    }
  }, []);

  const resetPassword = useCallback(
    async (body) => {
      setLoadingButton(true);
      try {
        await ProfileService.resetPasswrod(body);
        const successHeader = t('success');
        const successMessage = t('sucessMessage');
        swal(successHeader, successMessage, 'success');
        if (pathname === '/reset') {
          navigate('/login');
        } else {
          navigate('/security');
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingButton(false);
      }
    },
    [setLoadingButton],
  );

  const resetMyId = useCallback(() => {
    setMyId(null);
  }, [setMyId]);

  const listProfiles = async (userId) => {
    try {
      const { data } = await ProfileService.listProfileByUserId(userId);
      return data;
    } catch (error) {
      const errorTitle = t('Profile').toString();
      const errorMsg = t('apiErrors.entityNotFound').toString();
      swal(errorTitle, errorMsg, 'error');
    }
  };

  const listLegalProfiles = async (userId) => {
    try {
      const { data } = await ProfileService.listLegalProfiles();
      return data;
    } catch (error) {
      const errorTitle = t('Profile').toString();
      const errorMsg = t('apiErrors.entityNotFound').toString();
      swal(errorTitle, errorMsg, 'error');
    }
  };

  return (
    <ProfileContext.Provider
      value={{
        profile,
        loadingProfile,
        loadProfile,
        editProfile,
        loadingButton,
        uploadImage,
        downloadImageInvestor,
        imageLoading,
        balanceControl,
        showBalance,
        totalAssetsControl,
        showTotalAssets,
        getTotalAssets,
        totalAssets,
        resetPassword,
        sendResetToken,
        validateToken,
        myId,
        resetMyId,
        listProfiles,
        listLegalProfiles,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

const useProfile = () => {
  return useContext(ProfileContext);
};

export { ProfileProvider, useProfile };
