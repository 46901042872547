import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useProfile } from '@contexts-investor';
import { specialToCode } from '@utils';
import { CodeValidator, Countdown, Translator } from '@components';

const SecondStep = ({ props: { email, setCode, code, handleStep, setStep } }) => {
  const { t } = useTranslation();
  const { sendResetToken, loadingButton } = useProfile();

  const successObj = { msg: '' };
  const [resend, setResend] = useState(successObj);

  const reSend = () => {
    sendResetToken(specialToCode(email));
    const success = { ...successObj };
    success.msg = t('withdrawPage.firstStep.resend').toString();
    setResend(success);
  };

  return (
    <>
      <h3 className="reset-header">
        <Translator path="resetPasswordPage.secondStep.title" />
      </h3>
      <p className="reset-body" style={{ marginBottom: 0 }}>
        <Translator path="withdrawPage.firstStep.body" />
      </p>
      <p className="email-reset">{email}</p>
      <div className="col-12 d-flex justify-content-start" style={{ padding: 0, gap: '0 11px' }}>
        <CodeValidator props={{ setCode }} />
      </div>
      {resend.msg && (
        <div className="text-success fs-12" style={{ marginTop: '0.313rem', padding: 0 }}>
          {resend.msg}
        </div>
      )}
      <div
        className="col-7 d-flex justify-content-start align-items-start"
        style={{ padding: 0, margin: '0 0 1.5rem 0' }}
      >
        <div className="mt-2" style={{ margin: 0, padding: 0 }}>
          <Countdown />
        </div>
        <span
          className="form-check d-inline-block mt-2"
          style={{ whiteSpace: 'nowrap', padding: '0 0 0 2.5rem', margin: 0 }}
        >
          <label className="label-forgot-password" htmlFor="check1" style={{ margin: 0 }}>
            <Translator path="register.emailPage.send" />
          </label>
          <label className="a-forgot-password" onClick={() => reSend()}>
            <Translator path="register.emailPage.click" />
          </label>
        </span>
      </div>
      <div className="row col-12 justify-content-between" style={{ marginTop: '1.25rem' }}>
        <button
          className="btn btn-outlined col-3 text-center"
          style={{ padding: 0, whiteSpace: 'nowrap' }}
          onClick={() => setStep(1)}
        >
          <Translator path="resetPasswordPage.goback" />
        </button>
        <button
          className={`btn btn-orange col-6 ${
            loadingButton && code.length > 3 ? 'd-flex justify-content-center disabled' : ''
          }`}
          onClick={handleStep}
        >
          {loadingButton && code.length > 3 ? (
            <div className="loading-spinner" style={{ width: '30px', height: '30px' }} />
          ) : (
            <Translator path="resetPasswordPage.continue" />
          )}
        </button>
      </div>
    </>
  );
};

export default SecondStep;
