import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { I18n, Translator } from '@components';
import { useTranslation } from 'react-i18next';
import { loginBg, logoCriptech } from '@assets';
import { loadingToggleAction } from '../../../../store/actions/AuthActions';
import { Wizard } from 'react-use-wizard';
import { signupActionPJ } from '../../../../store/actions/RegisterActions';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';

function RegisterLegalPerson(props) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [cpf, setCpf] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [image, setImage] = useState('');

  const [cnpj, setCnpj] = useState('');
  const [corporateName, setCorporateName] = useState('');
  const [data, setData] = useState('');
  const [phoneCorp, setPhoneCorp] = useState('');
  const [addressCorp, setAddressCorp] = useState('');
  const [documentFiles, setDocumentFiles] = useState([]);
  const [documentName, setDocumentName] = useState([]);
  const [socialContract, setSocialContract] = useState('');

  const [term1, setTerm1] = useState(false);
  const [term2, setTerm2] = useState(false);
  const [term3, setTerm3] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const startLoading = () => {
    setLoading(!loading);
  };

  function onSignUpPJ(e) {
    e.preventDefault();
    startLoading();

    const body = {
      corporateName: corporateName,
      cnpj: cnpj,
      foundationDate: data,
      phone: phoneCorp,
      socialContract: socialContract,
      additionalDocumentsFile: documentFiles,
      address: addressCorp,
      user: {
        name: name,
        cpf: cpf,
        email: email,
        phone: phone,
        address: address,
      }
    }
    const formData = new FormData();

    Object.keys(body).forEach((key) => {
      if (key === 'additionalDocumentsFile') {
        documentFiles.forEach((doc) => {
          formData.append(key, doc);
        });
      } else if (key === 'user') {
        formData.append(key, JSON.stringify(body[key]));
      } else {
        formData.append(key, body[key]);
      }
    });
    dispatch(loadingToggleAction(true));
    dispatch(signupActionPJ(formData, navigate, t, setLoading, body.user.email));

  }

  // Enviar todos os useState para as proximas telas sem perder o estado dela.

  const getters = {
    name: name,
    email: email,
    phone: phone,
    cpf: cpf.replaceAll('.', '').replace('-', ''),
    address: address,
    cnpj: cnpj,
    corporateName: corporateName,
    data: data,
    phoneCorp: phoneCorp,
    addressCorp: addressCorp,
    documentFiles: documentFiles,
    documentName: documentName,
    socialContract: socialContract,
    term1: term1,
    term2: term2,
    term3: term3,
  };

  const setters = {
    setName,
    setEmail,
    setCpf,
    setPhone,
    setAddress,
    setCnpj,
    setCorporateName,
    setData,
    setPhoneCorp,
    setAddressCorp,
    setDocumentFiles,
    setDocumentName,
    setSocialContract,
    setTerm1,
    setTerm2,
    setTerm3,
  };

  return (
    <>
      <div className="page-wraper">
        <div className="browse-job login-style3">
          <div
            className="bg-img-fix"
            style={{
              background: '#fff url(' + loginBg + ')',
              height: '100vh',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'right',
              backgroundSize: 'contain',
            }}
          >
            <div className="row gx-0">
              <div className="register-form-container bg-white d-flex justify-content-center">
                <div className="register-form d-flex justify-content-center ft">
                  {props.errorMessage && <div className="">{props.errorMessage}</div>}
                  <form className="dz-form py-2 row">
                    <Wizard>
                      <Step1 get={getters} set={setters} />
                      <Step2 get={getters} set={setters} />
                      <Step3 get={getters} set={setters} onSignUpPJ={onSignUpPJ} loading={loading} />
                    </Wizard>
                  </form>
                </div>
                <I18n />
              </div>
            </div>
          </div >
        </div >
      </div >
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(RegisterLegalPerson);
