import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import Translator from '../I18n/Translator';
import Content from './Content/Content';
import Details from './Details/Details';
import Graph from './Graph/Graph';
import Status from './Status';

const TokenCard = ({ props, title = '', showStatus = true, isButton = true}) => {
  const porcentage = props?.soldQuantity ? ((props?.soldQuantity / props?.offeredQuantity) * 100).toFixed(2) : 0;
  return (
    <Swiper className="swiper overflow-hiden " 
      style={{ width: '100%', cursor: props.status === 'INCAPTURE' ? 'pointer' : ''  }}>
      {props !== undefined && (
        <SwiperSlide>
          <div
            className="card card-box card-hover"
            style={{
              marginBottom: 0,
              height: '100%',
              boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15)',
            }}    
          >
            {title !== '' && (
              <h4 style={{ position: 'absolute', margin: '20px' }}>
                <Translator path={title} />
              </h4>
            )}
            {showStatus && <Status props={{ status: props.status }} />}
            <div
              className="row card-body align-items-center justify-content-center flex-wrap"
              style={{ margin: '2.5rem 0', padding: '0 2.5rem' }}
            >
              <Graph props={{ showGraph: true, porcentage }} />
              <Content props={props} />
              <Details props={{ ...props, showVal: true }} />
              {isButton && 
                <button
                    className={`btn btn-orange col-11`}
                    style={{ marginTop: '1.25rem', backgroundColor: props?.status === "ALLSOLD" || props?.status === 'COMPLETE' ? '#525252' : null }}
                    >
                    <Translator path={props?.status !== "ALLSOLD" && props?.status !== "COMPLETE" ? "investment.buyTokenPage.submit" : "components.statusCard.ranOut"} />
                </button>
              }  
            </div>
          </div>
        </SwiperSlide>
      )}
    </Swiper>
  );
};

export default TokenCard;
