import { Logo } from '@assets';
import React from 'react';
import './styles.css';
const Loading = ({ noContainer = false }) => {
  if (noContainer === true)
    return (
      <div className="d-flex align-items-center justify-content-center">
        <div className="loading-spinner" style={{ width: '6.75rem', height: '6.75rem', position: 'absolute' }}></div>
        <img
          className="animation-none"
          src={Logo}
          alt=""
          style={{ height: '6.25rem', width: '6.25rem', padding: '0.313rem' }}
        />
      </div>
    );

  return (
    <div
      className="spinner-container d-flex"
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 'calc(100vh - 140px)',
      }}
    >
      <div className="loading-spinner" style={{ width: '6.75rem', height: '6.75rem', position: 'absolute' }}></div>
      <img
        className="animation-none"
        src={Logo}
        alt=""
        style={{ height: '6.25rem', width: '6.25rem', padding: '0.313rem' }}
      />
    </div>
  );
};

export default Loading;
