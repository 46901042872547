export const validateCharacteres = (value) => {
  if (value.length >= 8) return true;
  return false;
};

export const validateNumber = (value) => {
  if (/\d/.test(value)) return true;
  return false;
};

export const validateString = (value) => {
  if (/[A-Z]/.test(value)) return true;
  return false;
};

export const validateSpecial = (value) => {
  if (/[`!@#$%^&*()_{};:"|,.<>/?~]/.test(value)) return true;
  return false;
};

export const validateEquals = (first, second) => {
  if (first === second && first.length >= 8) return true;
  return false;
};

export const isPhone = (phone) =>
  /^\(?(\d{2})\)?[- ]?(\d{5})[- ]?(\d{4})$/.test(phone) ||
  /^\(?(\d{2})\)?[- ]?(\d{4})[- ]?(\d{4})$/.test(phone) ||
  /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(phone);

export const isEmail = (email) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
