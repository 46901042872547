import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { QrCodePix } from 'qrcode-pix';

import { Translator } from '@components';
import { useWallet } from '@contexts-issuer';
import { useTranslation } from 'react-i18next';

import 'react-toastify/dist/ReactToastify.css';

const PixBody = ({ props: { info } }) => {
  const { t } = useTranslation();
  const { depositDetails } = useWallet();
  const [pixQrCode, setPixQrCode] = useState();
  const [pixString, setPixString] = useState();

  const copy = async () => {
    const text = pixString;
    await navigator.clipboard.writeText(text).then(
      (success) => {
        notifyBottomCenter();
      },
      (error) => {
        console.error(error);
      },
    );
  };

  const buildQRCode = {
    version: '01',
    key: depositDetails ? depositDetails.adminBankData.pixKey : process.env.REACT_APP_PIX_KEY,
    name: process.env.REACT_APP_MERCHANT_NAME,
    city: process.env.REACT_APP_MERCHANT_CITY,
    transactionId: undefined,
    message: t('pages.depositList.pix.msg').toString(),
    cep: process.env.REACT_APP_QRCODE_CEP,
    value: info,
  };
  const qrCode = QrCodePix(buildQRCode);

  useEffect(() => {
    const buildImage = async () => {
      const pix = await qrCode.base64();
      setPixString(qrCode.payload());
      setPixQrCode(pix);
    };

    if (qrCode) buildImage();
  }, [qrCode]);

  const notifyBottomCenter = () => {
    toast.success(t('pages.depositList.pix.copied').toString(), {
      position: 'bottom-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <div
      className="row second-step d-flex flex-column justify-content-center align-items-center "
      style={{ margin: 0, padding: 0 }}
    >
      <div className="row col-6 align-itens-start justify-content-evenly" style={{ margin: '0 0 1.25rem 0 ' }}>
        <img
          className="col-6"
          src={pixQrCode ? pixQrCode : ''}
          style={{ padding: 0, width: '156px', height: '156px' }}
        />
        <div className="row col-12 d-flex align-items-center justify-content-center">
          <div className="col-5 " style={{ borderTop: '1px solid #525252', padding: 0 }} />
          <p
            className="col-2"
            style={{
              padding: 0,
              margin: 0,
              textAlign: 'center',
              color: '#525252',
              fontWeight: '400',
              fontSize: '1rem',
            }}
          >
            <Translator path="pages.depositList.pix.or" />
          </p>
          <div className="col-5" style={{ borderTop: '1px solid #525252', padding: 0 }} />
        </div>
      </div>
      <span
        className="col-6 d-flex align-items-center justify-content-center"
        style={{ color: '#ff9900', textAlign: 'center', cursor: 'pointer' }}
        onClick={() => copy()}
      >
        <i className="material-icons">content_copy</i>
        <p style={{ margin: 0 }}>
          <Translator path="pages.depositList.pix.copy" />
        </p>
      </span>
      <ToastContainer />
    </div>
  );
};

export default PixBody;
