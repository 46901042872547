import React from 'react';
import { PropolsalCard } from '@components';
const PropolsalList = ({ props: { propolsals } }) => {
  return (
    <div className="row" style={{ margin: 0, padding: 0 }}>
      {propolsals.map((item, index) => {
        return (
          <div
            key={index}
            className="col-sm-12 col-md-6 col-xxl-4 "
            style={{ margin: '0 0 2.5rem 0', padding: '0 2.344rem' }}
          >
            <PropolsalCard props={{ propolsal: item }} />
          </div>
        );
      })}
    </div>
  );
};

export default PropolsalList;
