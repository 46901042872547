import React from 'react';
import { bankAccFinish } from '@assets';
import { Translator } from '@components';

const FinishForm = ({ props: { email, setLayout } }) => {
  return (
    <>
      <div className="row col-12">
        <div
          className="col-12 d-flex flex-column align-items-center justify-content-center"
          style={{ margin: '11.5rem 0' }}
        >
          <img src={bankAccFinish} />
          <div className="col-12 pending-status" style={{ padding: '2.5rem 2.5rem 0 2.5rem ' }}>
            <p style={{ margin: 0, textAlign: 'center' }}>
              <Translator path="pages.myBank.finish.body" />
            </p>
            <p style={{ margin: 0, textAlign: 'center' }}>
              <Translator path="pages.myBank.finish.end" />
            </p>
          </div>
          <button className="col-3 btn btn-orange" style={{ margin: '1.25rem 0 0 0' }} onClick={(e) => setLayout(1)}>
            <Translator path="pages.myBank.finish.goBack" />
          </button>
        </div>
      </div>
    </>
  );
};

export default FinishForm;
