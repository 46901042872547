import React, { useState } from 'react';
import swal from 'sweetalert';
import { useTranslation } from 'react-i18next';

import { Translator } from '@components';
import { useRegister, useIssuer } from '@contexts-issuer';
import { stage3 } from '@assets';

import { validateCharacteres, validateNumber, validateString, validateSpecial, validateEquals } from '@utils';
import { useEffect } from 'react';

const FinalStep = ({ props: { code } }) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');
  const { resetIssuerPassword, loadingButton, resetId } = useRegister();
  const { issuerInfo } = useIssuer();
  const { t } = useTranslation();

  let errorsObj = { password: '', confirm: '', val: '' };
  const [errors, setErrors] = useState(errorsObj);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prevState) => !prevState);
  };

  async function onSetPassword(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };

    if (password === '') {
      errorObj.password = t('pages.register.finalStep.validationErr.password').toString();
      error = true;
    }

    if (confirm === '') {
      errorObj.confirm = t('pages.register.finalStep.validationErr.confirm').toString();
      error = true;
    }

    setErrors(errorObj);

    if (
      validateCharacteres(password) &&
      validateNumber(password) &&
      validateString(password) &&
      validateSpecial(password) &&
      validateEquals(password, confirm)
    ) {
      const body = { password: password, accessToken: code, issuerLoginId: resetId };
      resetIssuerPassword(body);
    } else {
      if (error) {
        return;
      }
      swal('Error', t('pages.register.finalStep.errors.attend').toString(), 'error');
    }
  }

  useEffect(() => {
    const errorObj = { ...errorsObj };
    errorsObj = {
      password: '',
      confirm: '',
    };
    setErrors(errorObj);
  }, [password, confirm]);

  return (
    <>
      <div className="row col-12 reset-header">
        <img src={stage3} />
        <p className="title">
          <Translator path="pages.reset.finalStep.title" />
        </p>
        <p className="body">
          <Translator path="pages.reset.finalStep.body" />
        </p>
      </div>
      <div className="form-group mb-3" style={{ paddingLeft: 0, marginTop: '1.25rem' }}>
        <div className="d-flex flex-row set-password">
          <p style={{ margin: 0 }}>
            <Translator path="pages.register.finalStep.password" />
          </p>
          <p>*</p>
        </div>
        <div className="input-container-security">
          <input
            className="security form-control"
            type={isPasswordVisible ? 'text' : 'password'}
            onChange={(e) => setPassword(e.target.value)}
          />
          <i
            className={`eye-icon-security fa  ${isPasswordVisible ? 'fa-eye visible' : 'fa-eye-slash visible'}`}
            onClick={togglePasswordVisibility}
            style={{
              width: '2rem',
              height: '1.5rem',
            }}
          />
        </div>

        {errors.password && <div className="text-danger fs-12">{errors.password}</div>}
      </div>
      <div className="form-group" style={{ paddingLeft: 0, marginBottom: '0.625rem' }}>
        <div className="d-flex flex-row set-password">
          <p>
            <Translator path="pages.register.finalStep.confirm" />
          </p>
          <p>*</p>
        </div>
        <div className="input-container-security">
          <input
            className="security form-control"
            type={isPasswordVisible ? 'text' : 'password'}
            onChange={(e) => setConfirm(e.target.value)}
          />
          <i
            className={`eye-icon-security fa  ${isPasswordVisible ? 'fa-eye visible' : 'fa-eye-slash visible'}`}
            onClick={togglePasswordVisibility}
            style={{
              width: '2rem',
              height: '1.5rem',
            }}
          />
        </div>

        {errors.confirm && <div className="text-danger fs-12">{errors.confirm}</div>}
        {errors.val && <div className="text-danger fs-12">{errors.val}</div>}
      </div>

      <form className="dz-form row justify-content-center " style={{ padding: 0, margin: 0 }} onSubmit={onSetPassword}>
        <div
          className="form-group row d-flex flex-column justify-content-center align-items-start"
          style={{ paddingLeft: 0, margin: 0 }}
        >
          <div className="col-12 d-flex justify-content-start reset-padding-margin">
            <p className={`${validateCharacteres(password) ? 'label-validation' : 'label-noval'}`}>
              <i className="fa-solid fa-check" style={{ marginRight: '0.625rem' }} />
              <Translator path="pages.register.finalStep.validators.lenght" />
            </p>
          </div>
          <div className="col-12 d-flex justify-content-start reset-padding-margin">
            <p className={`${validateNumber(password) ? 'label-validation' : 'label-noval'}`}>
              <i className="fa-solid fa-check" style={{ marginRight: '0.625rem' }} />
              <Translator path="pages.register.finalStep.validators.num" />
            </p>
          </div>
          <div className="col-12 d-flex justify-content-start reset-padding-margin">
            <p className={`${validateString(password) ? 'label-validation' : 'label-noval'}`}>
              <i className="fa-solid fa-check" style={{ marginRight: '0.625rem' }} />
              <Translator path="pages.register.finalStep.validators.upper" />
            </p>
          </div>
          <div className="col-12 d-flex justify-content-start reset-padding-margin">
            <p className={`${validateSpecial(password) ? 'label-validation' : 'label-noval'}`}>
              <i className="fa-solid fa-check" style={{ marginRight: '0.625rem' }} />
              <Translator path="pages.register.finalStep.validators.special" />
            </p>
          </div>
          <div className="col-12 d-flex justify-content-start reset-padding-margin">
            <p
              className={`${validateEquals(password, confirm) ? 'label-validation' : 'label-noval'}`}
              style={{ marginBottom: '2.5rem' }}
            >
              <i className="fa-solid fa-check" style={{ marginRight: '0.625rem' }} />
              <Translator path="pages.register.finalStep.validators.equal" />
            </p>
          </div>
        </div>
        <div className="row col-12 justify-content-start reset-padding-margin">
          <button
            type="submit"
            className={`btn btn-orange col-7 ${loadingButton ? 'd-flex justify-content-center disabled' : ''}`}
          >
            {loadingButton ? (
              <div className="loading-spinner" style={{ width: '30px', height: '30px' }} />
            ) : (
              <Translator path="pages.reset.finish" />
            )}
          </button>
        </div>
      </form>
    </>
  );
};

export default FinalStep;
