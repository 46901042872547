import { Loading, Translator } from '@components';
import { useDeposit } from '@contexts-investor';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

import ErrorModal from './ErrorModal';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';

const DepositModal = ({ props: { show, setShow, profile } }) => {
  const { loading, deposit, success, closeModal } = useDeposit();
  const [currentStep, setCurrentStep] = useState(1);
  const [showError, setShowError] = useState();
  const [sizeControl, setSizeControl] = useState('md');

  const [userStatus, setUserStatus] = useState('');

  useEffect(() => {
    setUserStatus(profile?.status);
  }, []);

  const handleFirstStep = (body) => {
    setCurrentStep(2);
    deposit(body);
  };

  const closeAll = () => {
    setShowError(false);
    setShow(false);
    setCurrentStep(1);
    setSizeControl('md');
    closeModal();
  };

  useEffect(() => {
    if (success === false) {
      setShowError(true);
      setSizeControl('lg');
    }
  }, [success]);

  if (loading) {
    return (
      <div className="d-flex align-items-center justify-content-center spinner-box">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <Modal show={show} size={sizeControl} centered>
        <Modal.Body
          style={{ padding: 20 }}
          className={`row d-flex flex-column justify-content-center align-items-center ${showError ? 'control-deposit-error justify-content-between' : 'justify-content-center '
            }`}
        >
          {showError === true || userStatus !== 'ACTIVE' ? (
            <ErrorModal props={{ closeAll, errorType: userStatus }} />
          ) : (
            <div className="deposit-modal-body row flex-column justify-content-center align-items-center">
              <div
                className="col-12 d-flex justify-content-between align-items-center flex-row "
                style={{ marginBottom: '1.25rem' }}
              >
                <div className="row col-11 d-flex align-items-center justify-content-center">
                  <i className="col-1 material-icons" style={{ color: '#ff9900' }}>
                    paid
                  </i>
                  <h4 className="col-6 modal-title-deposit mb-0" style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                    <Translator path="depositPage.firstStep.title" />
                  </h4>
                </div>
                <i
                  className="material-icons moda-close-icon cursor-pointer"
                  onClick={() => {
                    setShow(false);
                    setCurrentStep(1);
                  }}
                >
                  close
                </i>
              </div>
              {currentStep == 1 ? <FirstStep props={{ handleStep: handleFirstStep }} /> : <SecondStep />}
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DepositModal;
