import { depositRequestSuccess, emptyDepositIssuer, logoEmissor, logoIssuer } from '@assets';
import { Loading, Table, TokenDetails, Translator } from '@components';
import { useIssuer, useWallet } from '@contexts-issuer';
import { formatDate } from 'date-utils-2020';
import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import FilterModal from './FilterModal';
import HowtoDepositModal from './HowtoDepositModal';
import RequestDeposit from './RequestDeposit';
import StatusCard from './StatusCard';
import { COLUMNS } from './TableColumns';

const Deposit = () => {
  const { getDeposits, loading, getDepositDetails } = useWallet();
  const { issuerProfile } = useIssuer();
  const { i18n } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [deposits, setDeposits] = useState();
  const [filters, setFilters] = useState();
  const [isReview, setIsReview] = useState();
  const [isDone, setIsDone] = useState();
  const [depositSuccess, setDepositSuccess] = useState(false);
  const [issuerDepositId, setIssuerDepositId] = useState();
  const [requestModal, setRequestModal] = useState(false);
  const [howItWorksModal, sethowItWorksModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [tokenToDetail, setTokenToDetail] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const getDeposit = async () => {
      const rawData = await getDepositDetails(issuerDepositId);
    };

    if (issuerDepositId) getDeposit();
  }, [issuerDepositId]);

  useEffect(() => {
    const getMyDeposits = async () => {
      const rawData = await getDeposits(1, 20, filters, issuerProfile.issuerId);
      const data = Array.from(rawData).map((item) => {
        const action = (
          <Dropdown style={{ color: 'var(--orange)' }}>
            <Dropdown.Toggle as="div" className="i-false sidebar-select">
              <i className="material-icons statement-action cursor-pointer">more_horiz</i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {item.status === 'INCAPTURE' ? (
                <Dropdown.Item
                  onClick={() => {
                    handleShowDetailsModal(item.token);
                  }}
                >
                  <Translator path="pages.withdrawList.tableColumns.actionItems.details" />
                </Dropdown.Item>
              ) : item.status === 'WAITING' ? (
                <Dropdown.Item
                  onClick={() => {
                    setIssuerDepositId(item.issuerDepositId);
                    setRequestModal(true);
                    setIsReview(false);
                    setIsDone(false);
                  }}
                >
                  <Translator path="pages.depositList.actionItems.ready" />
                </Dropdown.Item>
              ) : item.status === 'ANALYSING' ? (
                <Dropdown.Item
                  onClick={() => {
                    setIsReview(true);
                    setIsDone(false);
                    setIssuerDepositId(item.issuerDepositId);
                    setRequestModal(true);
                  }}
                >
                  <Translator path="pages.depositList.actionItems.detailsPrev" />
                </Dropdown.Item>
              ) : item.status === 'COMPLETE' ? (
                <Dropdown.Item
                  onClick={() => {
                    setIsDone(true);
                    setIsReview(false);
                    setIssuerDepositId(item.issuerDepositId);
                    setRequestModal(true);
                  }}
                >
                  <Translator path="pages.depositList.actionItems.details" />
                </Dropdown.Item>
              ) : (
                ''
              )}
            </Dropdown.Menu>
          </Dropdown>
        );

        return {
          ...item,
          value: 'R$ ' + new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(parseFloat(item.value)),
          date: formatDate(item.expectedDepositDate, 'dd/MM/yyyy'),
          tokenName: item.token.name,
          depositCode: item.depositCode,
          depositDate: item.depositDate ? new Date(item.depositDate).toLocaleDateString('pt-BR') : '-',

          action: item.status !== 'CANCELLED' ? action : '',
          status: <StatusCard status={item.status} />,
        };
      });
      setDeposits(data);
    };
    if (issuerProfile && issuerProfile.issuerId) getMyDeposits();
  }, [issuerProfile && issuerProfile.issuerId, filters]);

  const handleShowDetailsModal = async (token) => {
    setTokenToDetail(token);
    setShowDetailsModal(true);
  };

  if (loading) {
    return <Loading />;
  }

  if (depositSuccess)
    return (
      <div className="row d-flex flex-column align-items-center withdraw-requested deposit-success ">
        <img className="header-logo" src={i18n.language === 'pt-BR' ? logoEmissor : logoIssuer} />
        <p>
          <Translator path="pages.depositList.body" />
        </p>
        <img className="body-image" src={depositRequestSuccess} />
        <button className="col-4 btn btn-orange" onClick={() => setDepositSuccess(false)}>
          <Translator path="pages.withdrawList.request.goBack" />
        </button>
      </div>
    );

  if (deposits)
    return (
      <div className="withdrawList-wrapper">
        <div className="row justify-content-between">
          <h3 className="col-6 statement-header" style={{ whiteSpace: 'nowrap', margin: 0, padding: 0 }}>
            <Translator path="pages.depositList.title" />
          </h3>
          <button
            className={`btn btn-orange col-2 ${deposits?.length === 0 && !filters ? 'disabled' : ''}`}
            style={{ marginBottom: '1.25rem' }}
            onClick={() => setShowModal(true)}
          >
            <Translator path="pages.depositList.filter" />
          </button>
          <div className="col-12" style={{ margin: '0 0 0.625rem 0', padding: 0 }}>
            <p className="how-it-works-button" onClick={() => sethowItWorksModal(true)}>
              <Translator path="pages.depositList.works" />
            </p>
          </div>
          <Table
            props={{
              tableData: deposits,
              tableColumns: COLUMNS,
              emptyImage: emptyDepositIssuer,
            }}
          />
          <FilterModal props={{ show: showModal, setShow: setShowModal, setFilters, filters }} />
          <RequestDeposit
            props={{ show: requestModal, setShow: setRequestModal, setSuccess: setDepositSuccess, isReview, isDone }}
          />
          <HowtoDepositModal props={{ show: howItWorksModal, setShow: sethowItWorksModal }} />
        </div>

        <TokenDetails
          show={showDetailsModal}
          tokenOrProposal={tokenToDetail}
          setState={setShowDetailsModal}
          issuer={issuerProfile}
        />
      </div>
    );
};

export default Deposit;
