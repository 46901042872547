import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const PopOver = ({ placement, text, id, children }) => {
  return (
    <OverlayTrigger
      key={placement}
      placement={placement}
      overlay={
        <Tooltip className={id} id={`tooltip-${placement}`}>
          {text}
        </Tooltip>
      }
    >
      {children}
    </OverlayTrigger>
  );
};

export default PopOver;
