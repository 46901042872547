import { Loading, Translator } from '@components';
import { useIssuer } from '@contexts-issuer';

import { issuerNotApproved, missApprove, proposalChanges } from '@assets';

import { Button } from 'react-bootstrap';

import ProposalForm from './ProposalForm';
import { useNavigate } from 'react-router-dom';

const NewProposal = () => {
  const { loadingIssuerProfile, issuerProfile } = useIssuer();
  const navigate = useNavigate();
  if (loadingIssuerProfile) return <Loading />;

  if (issuerProfile)
    return (
      <div className="page-wrapper">
        <div className="card card-proposal">
          <div className="card-title">
            <Translator path={'pages.tokenProposal.title'} />
          </div>
          <div className="card-stroke"></div>
          <div className="card-content">
            {issuerProfile.status === 'APPROVED' ? (
              <ProposalForm />
            ) : issuerProfile.status === 'IN_ANALYSIS' ? (
              <div className="miss-approve d-flex flex-column justify-content-center align-items-center">
                <div>
                  <img src={missApprove} alt="" />
                </div>
                <div className="miss-approve-text">
                  <Translator path={'pages.tokenProposal.missApprove.text'} />
                </div>
              </div>
            ) : issuerProfile.status === 'INCOMPLETE' || issuerProfile.status === 'REJECTED' ? (
              <div className="not-approved d-flex flex-column justify-content-center align-items-center">
                <div>
                  <img src={issuerNotApproved} alt="" />
                </div>
                <div className="not-approved-text">
                  {issuerProfile.status === 'INCOMPLETE' ? (
                    <Translator path={'pages.tokenProposal.notApproved.text'} />
                  ) : issuerProfile.status === 'REJECTED' ? (
                    <Translator path={'pages.tokenProposal.rejected.body'} />
                  ) : (
                    ''
                  )}
                </div>
                <div>
                  <Button
                    variant="primary"
                    size="lg"
                    className="text-center"
                    id="btn"
                    onClick={() => navigate('/mycorporation')}
                  >
                    <Translator path={'pages.tokenProposal.notApproved.complete'} />
                  </Button>
                </div>
              </div>
            ) : issuerProfile.status === 'CHANGES_REQUEST' ? (
              <>
                <div className="row changes-requested">
                  <div className="col-12 d-flex flex-column align-items-center">
                    <img src={proposalChanges} />
                    <p>
                      <Translator path="pages.tokenProposal.changesRequested.body" />
                    </p>
                    <button className="btn btn-orange" onClick={() => navigate('/mycorporation')}>
                      <Translator path="pages.tokenProposal.changesRequested.button" />
                    </button>
                  </div>
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    );
};

export default NewProposal;
