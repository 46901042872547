import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useProfile } from '@contexts-investor';
import { Translator } from '@components';

const FirstStep = ({ props: { email, setEmail, handleStep } }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { resetMyId, loadingButton } = useProfile();

  return (
    <>
      <div className="row col-12">
        <p className="reset-header">
          <Translator path="resetPasswordPage.firstStep.title" />
        </p>
        <p className="reset-body">
          <Translator path="resetPasswordPage.firstStep.body" />
        </p>
        <input
          type="email"
          value={email}
          placeholder={t('login.email').toString()}
          className="form-control show-pass"
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="row col-12 justify-content-between" style={{ marginTop: '1.25rem' }}>
        <button
          className="btn btn-outlined col-3 text-center"
          style={{ padding: 0, whiteSpace: 'nowrap' }}
          onClick={() => {
            resetMyId();
            navigate(-1);
          }}
        >
          <Translator path="resetPasswordPage.goback" />
        </button>
        <button
          className={`btn btn-orange col-6 ${loadingButton ? 'd-flex justify-content-center disabled' : ''}`}
          onClick={handleStep}
        >
          {loadingButton ? (
            <div className="loading-spinner" style={{ width: '30px', height: '30px' }} />
          ) : (
            <Translator path="resetPasswordPage.continue" />
          )}
        </button>
      </div>
    </>
  );
};

export default FirstStep;
