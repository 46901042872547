import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useProfile } from '@contexts-investor';
import { Buffer } from 'buffer';

import { Translator, Loading } from '@components';
import { noPreview } from '@assets';

import { cpfMask, phoneMask } from '@utils';
import { formatDate } from 'date-utils-2020';
import { Wizard, useWizard } from 'react-use-wizard';

const MyData = ({ userInfo }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { profile, loadingProfile, loadProfile, downloadImageInvestor, imageLoading } = useProfile();
  const { previousStep, nextStep } = useWizard()


  if (loadingProfile) return <Loading />;

  if (profile && userInfo)
    return (
      <div
        className="row col-xxl-7 col-xl-6 col-md-12 col-sm-12 col-xs-12 col-12 profilepj-box"
      >
        <div
          className="row col-xl-12 col-lg-12 col-sm-12 col-xs-12 d-flex align-items-center justify-content-start cursor-pointer profile-header-border"
          style={{ height: '10vh' }}
        >
          <div
            className="col-xl-3 col-lg-3 col-sm-3 d-flex col-6 align-items-center justify-content-center profile-highlight"
            style={{ height: '100%' }}
            onClick={() => previousStep()}
          >
            <p className="profile-menu-headers">
              <Translator path="profilePage.data" />
            </p>
          </div>
          <div
            className="col-xl-3 col-lg-3 col-sm-3 d-flex col-6 align-items-center justify-content-center"
            style={{ height: '100%' }}
            onClick={() => nextStep()}
          >
            <p className="profile-menu-headers">
              <Translator path="investment.buyTokenPage.issuer.company" />
            </p>
          </div>
          <div className="row col-12 profilepj-row-modifier">
            <div className="row col-sm-6" style={{ margin: 0, padding: '0 0.625rem 0 0' }}>
              <label className="col-12 profile-data-labels">
                <Translator path="profilePage.name" />
              </label>
              <div className="row col-12" style={{ padding: 0, margin: 0 }}>
                <input className="col-12 profile-data-input-disabled" readOnly value={userInfo.loginLegalUser.name} />
              </div>
              <label className="col-12 profile-data-labels">
                <Translator path="profilePage.email" />
              </label>
              <div className="row col-12" style={{ padding: 0, margin: 0 }}>
                <input className="col-12 profile-data-input-disabled" readOnly value={userInfo.loginLegalUser.email} />
              </div>
            </div>
            <div className="row col-sm-6" style={{ margin: 0, padding: '0 0 0 0' }}>
              <label className="col-12 profile-data-labels">
                <Translator path="profilePage.cpf" />
              </label>
              <div className="row col-12" style={{ padding: 0, margin: 0 }}>
                <input className="col-12 profile-data-input-disabled" readOnly value={userInfo.cpf} />
              </div>
              <label className="col-12 profile-data-labels">
                <Translator path="profilePage.phone" />
              </label>
              <div className="row col-12" style={{ padding: 0, margin: 0 }}>
                <input className="col-12 profile-data-input-disabled" readOnly value={userInfo.phone} />
              </div>
            </div>
            <label className="col-12 profile-data-labels">
              <Translator path="profilePage.address" />
            </label>
            <div className="row col-sm-12" style={{ margin: 0, padding: 0 }} >
              <div className="row col-12" style={{ padding: 0, margin: 0 }}>
                <textarea
                  required=""
                  className="text-area profile-data-textarea-disabled"
                  readOnly value={userInfo.loginLegalUser.address}
                />
              </div>
              <button
                className="col-md-4 col-lg-3 col-sm-4 btn btn-orange"
                style={{ marginTop: '2.5rem' }}
                onClick={() => {
                  navigate('/profile/editpj');
                }}
              >
                <Translator path="profilePage.editSubmit" />
              </button>
            </div>
          </div>
        </div>
      </div >
    );
};

export default MyData;
