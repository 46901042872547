import ErrorGif from '@assets/gifs/error.gif';
import { Translator } from '@components';
import React from 'react';
import { Modal } from 'react-bootstrap';

const ErrorModal = ({ props: { show, setShow, status } }) => {
  return (
    <Modal show={show} centered>
      <Modal.Body
        style={{ padding: 20 }}
        className={`row d-flex flex-column justify-content-center align-items-center`}
      >
        <div className="col-12">
          <h3 className="error-modal-withdraw text-center">
            <Translator path="walletPage.errorModal.title" />
          </h3>
          <p className="error-modal-withdraw text-center">
            <Translator path={`walletPage.errorModal.${status === 'INACTIVE' ? 'inactiveBody' : 'inAnalysisBody'}`} />
          </p>
        </div>
        <div className="col-12 d-flex justify-content-center" style={{ marginBottom: '1.5rem' }}>
          <img src={ErrorGif} className="col-3" />
        </div>
        <button className="btn btn-orange col-4" onClick={() => setShow(false)}>
          <Translator path="walletPage.errorModal.button" />
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default ErrorModal;
