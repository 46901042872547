import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useWithdraw } from '@contexts-investor';
import { Translator } from '@components';
import ErrorGif from '@assets/gifs/error.gif';

const ErrorModal = ({ props: { show, error } }) => {
  const navigate = useNavigate();
  const { closeModal } = useWithdraw();

  return (
    <Modal show={show} aria-labelledby="contained-modal-title-vcenter" size="lg" centered>
      <Modal.Body
        style={{ padding: 20, height: '480px', textAlign: 'center' }}
        className="row d-flex flex-column justify-content-between align-items-center"
      >
        <div className="col-12">
          <h3 className="error-modal-withdraw">
            <Translator path="withdrawPage.firstStep.errorTitle" />
          </h3>
          <p className="error-modal-withdraw">{error}</p>
        </div>
        <div className="col-12 ">
          <img src={ErrorGif} alt="" className="col-3" />
        </div>
        <button
          className="btn btn-orange col-4"
          onClick={() => {
            closeModal();
            navigate('/wallet');
          }}
        >
          <Translator path="withdrawPage.finalStep.goback" />
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default ErrorModal;
