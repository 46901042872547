import { stepOneInvestor, stepThreeInvestor, stepTwoInvestor } from '@assets';
import { Translator } from '@components';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const StepsCard = ({ step = 1, deposits, purchases, withdrawals }) => {
  const [isStep1Complete, setIsStep1Complete] = useState(false);
  const [isStep2Complete, setIsStep2Complete] = useState(false);
  const [isStep3Complete, setIsStep3Complete] = useState(false);

  const { t } = useTranslation();

  const navigate = useNavigate();

  useEffect(() => {
    if (deposits && deposits?.length) {
      setIsStep1Complete(true);
    }

    if (purchases && purchases.length) {
      setIsStep1Complete(true);
      setIsStep2Complete(true);
    }

    if (withdrawals && withdrawals.length) {
      setIsStep1Complete(true);
      setIsStep2Complete(true);
      setIsStep3Complete(true);
    }
  }, []);

  return (
    <div className="row col-12 gx-0 gy-0 d-flex investor-steps-card">
      <div className="col-12">
        <img
          src={step === 1 ? stepOneInvestor : step === 2 ? stepTwoInvestor : stepThreeInvestor}
          style={{ height: step === 2 ? '-webkit-fill-available' : 'auto' }}
        />
      </div>
      <div className="d-flex col-12 flex-column main-content">
        <div className="steps-content-investor">
          <div className="d-flex align-items-center text-content">
            <div className="d-flex justify-content-center align-items-center icon-container">
              <i className="material-icons">{step === 1 ? 'pix' : step === 2 ? 'token' : 'account_balance'}</i>
            </div>
            <span className="d-flex flex-column step-controller" style={{ marginLeft: '0.625rem' }}>
              <p>
                {step === 1
                  ? t('dashboard.stepsCard.deposit')
                  : step === 2
                    ? t('dashboard.stepsCard.investment')
                    : t('dashboard.stepsCard.withdraw')}
              </p>
              <p>
                {step === 1
                  ? t('dashboard.stepsCard.step1')
                  : step === 2
                    ? t('dashboard.stepsCard.step2')
                    : t('dashboard.stepsCard.step3')}
              </p>
            </span>
          </div>
          <p className="steps-description">
            {step === 1
              ? t('dashboard.stepsCard.depositMsg')
              : step === 2
                ? isStep1Complete
                  ? t('dashboard.stepsCard.investmentMsg')
                  : t('dashboard.stepsCard.waiting1')
                : step === 3
                  ? isStep1Complete && isStep2Complete
                    ? t('dashboard.stepsCard.withdrawMsg')
                    : t('dashboard.stepsCard.waiting2')
                  : ''}
          </p>
        </div>
        <div className="action">
          {step === 1 ? (
            !isStep1Complete ? (
              <Button className={`col-xl-6 col-lg-12 col-md-4 col-sm-6 action btn btn-orange`} onClick={() => navigate('/wallet')}>
                <Translator path={'dashboard.stepsCard.depositBtn'} />
              </Button>
            ) : (
              <div className="d-flex flex-row align-items-center complete">
                <i className="material-icons">check</i>

                <Translator path={'dashboard.stepsCard.complete'} />
              </div>
            )
          ) : step === 2 ? (
            isStep1Complete && !isStep2Complete ? (
              <Button className={`col-xl-6 col-lg-12 col-md-4 col-sm-6 action btn btn-orange`} onClick={() => navigate('/investment')}>
                <Translator path={'dashboard.stepsCard.investmentBtn'} />
              </Button>
            ) : isStep2Complete ? (
              <div className="d-flex flex-row align-items-center complete">
                <i className="material-icons">check</i>

                <Translator path={'dashboard.stepsCard.complete'} />
              </div>
            ) : (
              <Button variant="primary" size="lg" className="col-xl-7 col-lg-12 col-md-5 col-sm-6 disable" id="btn" disabled>
                <label className="waiting-text"><Translator path={'dashboard.stepsCard.waitingBtn'} /></label>
              </Button>
            )
          ) : step === 3 ? (
            isStep3Complete ? (
              <div className="d-flex flex-row align-items-center complete">
                <i className="material-icons">check</i>

                <Translator path={'dashboard.stepsCard.complete'} />
              </div>
            ) : isStep1Complete && isStep2Complete ? (
              <Button className={`col-xl-6 col-lg-10 col-md-4 col-sm-6 action btn btn-orange`} onClick={() => navigate('/wallet')}>
                <Translator path={'dashboard.stepsCard.withdrawBtn'} />
              </Button>
            ) : (
              <Button
                variant="primary"
                size="lg"
                className="col-xl-7 col-lg-12 col-md-5 col-sm-6 text-center disable"
                id="btn"
                disabled
                style={{ fontSize: '1rem !important' }}
              >
                <label className="waiting-text"><Translator path={'dashboard.stepsCard.waitingBtn'} /></label>
              </Button>
            )
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

export default StepsCard;
