export const ErrorHandler = (message) => {
  if (message === 'QUERY_ERROR') {
    return 'apiErrors.query';
  }
  if (message === 'USER_NOT_FOUND') {
    return 'apiErrors.userNotFound';
  }
  if (message === 'TOKEN_NOT_FOUND') {
    return 'apiErrors.tokenNotFound';
  }
  if (message === 'ENTITY_NOT_FOUND') {
    return 'apiErrors.entityNotFound';
  }
  if (message === 'UPLOAD_ERROR') {
    return 'apiErrors.upload';
  }
  if (message === 'TOKEN_GENERATION_ERROR') {
    return 'apiErrors.tokenGen';
  }
  if (message === 'EXPIRED_TOKEN_ERROR') {
    return 'apiErrors.tokenExpire';
  }
  if (message === 'EMAIL_SENDING_ERROR') {
    return 'apiErrors.emailSending';
  }
  if (message === 'KEYCLOAK_CONNECTION_ERROR') {
    return 'apiErrors.keycloakCon';
  }
  if (message === 'QUANTITY_ERROR') {
    return 'apiErrors.quantity';
  }
  if (message === 'BLOCK_CHAIN_ERROR_WALLET') {
    return 'apiErrors.blockChainWallet';
  }
  if (message === 'ERR_BAD_REQUEST') {
    return 'apiErrors.badRequest';
  }
};
