import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { bgIssuer, logoEmissor, logoIssuer } from '@assets';
import { I18n, Translator } from '@components';
import { useRegister } from '@contexts-issuer';
import { specialToCode, useZoom } from '@utils';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { validateRecaptchaToken } from '../../../../services/AuthService';
import swal from 'sweetalert';

function Login(props) {
  const { i18n, t } = useTranslation();
  const { login, loading } = useRegister();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const navigate = useNavigate();
  const zoom = useZoom();
  const { executeRecaptcha } = useGoogleReCaptcha();

  let errorsObj = { email: '', password: '' };
  const [errors, setErrors] = useState(errorsObj);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prevState) => !prevState);
  };

  function onLogin() {
    executeRecaptcha("enquiryFormSubmit").then((gReCaptchaToken) => {
      validateRecaptchaToken(gReCaptchaToken).then((response) => {
        if (response?.data?.success) {
          handleLogin()
        } else {
          throw t('pages.login.validationErr.recaptcha').toString();
        }
      }).catch((e) => {
        swal(e, '', 'error');
      })
    })
  }

  const handleLogin = () => {
    let error = false;
    const errorObj = { ...errorsObj };
    if (email === '') {
      errorObj.email = t('pages.login.validationErr.email').toString();
      error = true;
    }
    if (password === '') {
      errorObj.password = t('pages.login.validationErr.password').toString();
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }

    const username = specialToCode(email);
    const pass = specialToCode(password);
    const body = `grant_type=password&client_id=${process.env.REACT_APP_CLIENT_ID}&client_secret=${process.env.REACT_APP_CLIENT_SECRET}&username=${username}&password=${pass}`;
    login(body);
  };

  useEffect(() => {
    const errorObj = { ...errorsObj };
    errorObj.email = '';
    errorObj.password = '';
    setErrors(errorObj);
  }, [email]);

  return (
    <>
      <div className="page-wraper">
        <div className="browse-job login-style3">
          <div
            className="bg-img-fix overflow-hidden"
            style={{
              background: '#fff url(' + bgIssuer + ')',
              height: '100vh',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'right',
              backgroundSize: 'contain',
            }}
          >
            <div className="row gx-0">
              <div
                style={{ minWidth: '45%', overflow: 'auto' }}
                className="col-xxl-4 col-xl-5 col-lg-6 col-md-8 col-sm-12 vh-100 bg-white d-flex justify-content-center align-items-center"
              >
                <div className="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside col-12">
                  <div
                    id="mCSB_1_container"
                    className="mCSB_container"
                    style={{ position: 'relative', top: '0', left: '0', dir: 'ltr' }}
                  >
                    <div className={`${zoom > 1 ? 'scroll-bar-wrapper' : ''}`}>
                      <div className="login-form style-2">
                        <div className="card-body form-login-issuer" style={{ marginTop: '13rem !important' }}>
                          <div className=" row gx-0">
                            <Link to="/login" className="logo ">
                              <img
                                src={i18n.language === 'pt-BR' ? logoEmissor : logoIssuer}
                                alt=""
                                className="width-230 mCS_img_loaded hide-logo "
                              />
                            </Link>

                            <div className="row col-12 pd-mg-0">
                              <p className="col-12">
                                <Translator path="pages.login.title" />
                              </p>
                              <p className="col-12">
                                <Translator path="pages.login.body" />
                              </p>
                            </div>
                            <div className="row col-12 form-content">
                              <div className="col-12 d-flex flex-column justify-content-start" style={{ padding: 0 }}>
                                <div className="d-flex flex-row">
                                  <p>
                                    <Translator path="pages.login.email" />
                                  </p>
                                  <p>*</p>
                                </div>
                                <input
                                  type="email"
                                  className="col-12 form-control"
                                  onChange={(e) => setEmail(e.target.value)}
                                />
                                {errors.email && <div className="text-danger fs-12">{errors.email}</div>}
                              </div>
                              <div className="col-12 d-flex flex-column justify-content-start" style={{ padding: 0 }}>
                                <div className="d-flex flex-row">
                                  <p>
                                    <Translator path="pages.login.password" />
                                  </p>
                                  <p>*</p>
                                </div>
                                <div className="input-container-security">
                                  <input
                                    className="security form-control show-pass"
                                    type={isPasswordVisible ? 'text' : 'password'}
                                    onChange={(e) => setPassword(e.target.value)}
                                  />
                                  <i
                                    className={`eye-icon-security fa  ${
                                      isPasswordVisible ? 'fa-eye visible' : 'fa-eye-slash visible'
                                    }`}
                                    onClick={togglePasswordVisibility}
                                    style={{
                                      width: '2rem',
                                      height: '1.5rem',
                                    }}
                                  />
                                </div>

                                {errors.password && <div className="text-danger fs-12">{errors.password}</div>}
                              </div>
                            </div>
                            <div className="row col-12 justify-content-between align-items-center reset-padding-margin form-actions-issuer">
                              <button
                                className={`btn btn-orange col-12 col-sm-4 d-flex justify-content-center align-items-center text-center ${
                                  loading ? 'disabled' : ''
                                }`}
                                style={{ whiteSpace: 'nowrap' }}
                                onClick={() => onLogin()}
                              >
                                {loading ? (
                                  <Translator path="pages.login.loading" />
                                ) : (
                                  <Translator path="pages.login.submit" />
                                )}
                              </button>
                              <p
                                className="col-12 col-sm-4 d-flex justify-content-center align-items-center text-center cursor-pointer"
                                onClick={() => navigate('/reset')}
                              >
                                <Translator path="pages.login.alter" />
                              </p>
                            </div>
                            <div className="row col-12 form-no-account">
                              <p className="col-12 ">
                                <Translator path="pages.login.noAccount" />
                              </p>
                              <button className="btn btn-orange col-12" onClick={() => navigate('/register')}>
                                <Translator path="pages.login.register" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <I18n />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(Login);
