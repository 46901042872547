import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Translator, Loading } from '@components';
import { useRegister } from '@contexts-issuer';

import { cpfMask, validadeCpf, cnpjMask, phoneMask, validateCnpj, isPhone, isEmail } from '@utils';

const FirstStep = ({ props: { setStep, setSendEmail, setError } }) => {
  const { t } = useTranslation();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [cpf, setCpf] = useState();
  const [phone, setPhone] = useState();
  const [cnpj, setCnpj] = useState();

  const { register, loading } = useRegister();
  const navigate = useNavigate();

  let errorsObj = { name: '', email: '', cpf: '', cnpj: '', phone: '' };
  const [errors, setErrors] = useState(errorsObj);

  function onSignUp(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (!name) {
      errorObj.name = t('pages.register.firstStep.validationErr.noName');
      error = true;
    }
    if (!email) {
      errorObj.email = t('pages.register.firstStep.validationErr.noEmail');
      error = true;
    }

    if (!cpf) {
      errorObj.cpf = t('pages.register.firstStep.validationErr.noCpf');
      error = true;
    }
    if (!phone) {
      errorObj.phone = t('pages.register.firstStep.validationErr.noPhone');
      error = true;
    }

    if (!cnpj) {
      errorObj.cnpj = t('pages.register.firstStep.validationErr.noCnpj');
      error = true;
    }

    if (cpf && !validadeCpf(cpf)) {
      errorObj.cpf = t('pages.register.firstStep.validationErr.invalidCpf');
      error = true;
    }
    if (cnpj && !validateCnpj(cnpj)) {
      errorObj.cnpj = t('pages.register.firstStep.validationErr.invalidCnpj');
      error = true;
    }

    if (phone && !isPhone(phone)) {
      errorObj.phone = t('pages.register.firstStep.validationErr.invalidPhone');
      error = true;
    }

    if (email && !isEmail(email)) {
      errorObj.email = t('pages.register.firstStep.validationErr.invalidEmail');
      error = true;
    }

    if (error) {
      setErrors(errorObj);
      setError(true);
      return;
    }

    const body = {
      name: name,
      email: email,
      phone: phone,
      cpf: cpf.replaceAll('.', '').replace('-', ''),
      cnpj: cnpj,
    };

    setSendEmail(email);
    register(body, setStep);
  }

  const handleCpf = (e) => {
    const cpfFormated = cpfMask(e.target.value);
    setCpf(cpfFormated);
  };

  useEffect(() => {
    const errorObj = { ...errorsObj };
    errorsObj = { name: '', email: '', cpf: '', cnpj: '', phone: '' };
    setErrors(errorObj);
    setError(false);
  }, [name, email, cpf, cnpj, phone]);

  if (loading) return <Loading />;

  return (
    <>
      <div className="row col-12 pd-mg-0">
        <p className="col-12">
          <Translator path="pages.register.firstStep.header" />
        </p>
        <p className="col-12" style={{ whiteSpace: 'pre-wrap' }}>
          <Translator path="pages.register.firstStep.body" />
        </p>
      </div>
      <div className="row col-12 form-content">
        <div className="col-12 d-flex flex-column justify-content-start" style={{ padding: 0 }}>
          <div className="d-flex flex-row">
            <p>
              <Translator path="pages.register.firstStep.cnpj" />
            </p>
            <p>*</p>
          </div>
          <input
            className="col-12 form-control"
            value={cnpj}
            maxLength={18}
            onChange={(e) => setCnpj(cnpjMask(e.target.value))}
          />
          {errors.cnpj && <div className="text-danger fs-12">{errors.cnpj}</div>}
        </div>
        <div className="col-12 d-flex flex-column justify-content-start" style={{ padding: 0 }}>
          <div className="d-flex flex-row">
            <p>
              <Translator path="pages.register.firstStep.name" />
            </p>
            <p>*</p>
          </div>
          <input className="col-12 form-control" value={name} onChange={(e) => setName(e.target.value)} />
          {errors.name && <div className="text-danger fs-12">{errors.name}</div>}
        </div>
        <div className="col-12 d-flex flex-column justify-content-start" style={{ padding: 0 }}>
          <div className="d-flex flex-row">
            <p>
              <Translator path="pages.register.firstStep.cpf" />
            </p>
            <p>*</p>
          </div>
          <input className="col-12 form-control" value={cpf} onChange={(e) => handleCpf(e)} />
          {errors.cpf && <div className="text-danger fs-12">{errors.cpf}</div>}
        </div>
        <div className="col-12 d-flex flex-column justify-content-start" style={{ padding: 0 }}>
          <div className="d-flex flex-row">
            <p>
              <Translator path="pages.register.firstStep.email" />
            </p>
            <p>*</p>
          </div>
          <input
            className="col-12 form-control"
            value={email}
            type="email"
            onChange={(e) => setEmail(e.target.value)}
          />
          {errors.email && <div className="text-danger fs-12">{errors.email}</div>}
        </div>
        <div className="col-12 d-flex flex-column justify-content-start" style={{ padding: 0 }}>
          <div className="d-flex flex-row">
            <p>
              <Translator path="pages.register.firstStep.phone" />
            </p>
            <p>*</p>
          </div>
          <input
            className="col-12 form-control"
            value={phone}
            maxLength={15}
            onChange={(e) => setPhone(phoneMask(e.target.value))}
          />
          {errors.phone && <div className="text-danger fs-12">{errors.phone}</div>}
        </div>
      </div>
      <div
        className="row col-12 justify-content-between align-items-center"
        style={{ padding: 0, margin: '2.5rem 0 0 0' }}
      >
        <button className="btn btn-outlined col-4" style={{ whiteSpace: 'nowrap' }} onClick={(e) => navigate(-1)}>
          <Translator path="pages.register.firstStep.goBack" />
        </button>
        <button className={`btn btn-orange col-6`} onClick={(e) => onSignUp(e)}>
          <Translator path="pages.register.firstStep.continue" />
        </button>
      </div>
    </>
  );
};

export default FirstStep;
