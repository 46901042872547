import React from 'react';
import { Translator } from '@components';

const InputText = ({
  text,
  setState,
  onChange,
  type,
  value,
  name,
  setWithMask = null,
  maxLength = null,
  readOnly = null,
  defaultValue,
  className,
  disabled = false,
  obrigatory = true,
}) => {
  return (
    <div className="col-12 d-flex flex-column justify-content-start" style={{ padding: 0 }}>
      <div className={`d-flex flex-row ${obrigatory === true ? 'text-input-container' : 'text-input-no-star'}`}>
        <p className="text-input-label">
          <Translator path={text} />
        </p>
        {obrigatory ? <p>*</p> : ''}
      </div>
      {defaultValue ? (
        <input
          className={`col-12 d-flex align-items-start ${className ? className : 'form-control'}`}
          name={name ? name : ''}
          type={type ? type : ''}
          readOnly={readOnly ? readOnly : false}
          defaultValue={defaultValue ? defaultValue : ''}
          maxLength={maxLength ? maxLength : ''}
          disabled={disabled}
          onChange={(e) => {
            if (setWithMask && setState) {
              setWithMask(e.target.value, setState);
            } else if (setState) {
              setState(e.target.value);
            } else if (onChange && type === 'file') {
              onChange(e.target.files[0]);
            } else if (onChange) {
              onChange(e.target.value);
            }
          }}
        />
      ) : (
        <input
          className={`col-12 d-flex align-items-start ${className ? className : 'form-control'}`}
          name={name ? name : ''}
          type={type ? type : ''}
          readOnly={readOnly ? readOnly : false}
          value={value ? value : ''}
          maxLength={maxLength ? maxLength : ''}
          disabled={disabled}
          onChange={(e) => {
            if (setWithMask && setState) {
              setWithMask(e.target.value, setState);
            } else if (setState) {
              setState(e.target.value);
            } else if (onChange && type === 'file') {
              onChange(e.target.files[0]);
            } else if (onChange) {
              onChange(e.target.value);
            }
          }}
        />
      )}
    </div>
  );
};

export default InputText;
