import { Translator } from '@components';
import React, { useEffect, useState } from 'react';
import LogoutPage from './Logout';

export const MenuList =
  process.env.REACT_APP_BUILD_TARGET === 'investor'
    ? [
      {
        title: <Translator path={'titles.dashboard'} />,
        menuLabel: <Translator path={'menuLabel.dashboard'} />,
        // classsChange: 'mm-collapse',
        iconStyle: (
          <i className="material-icons" style={{ backgroundColors: '#ff9900' }}>
            dashboard
          </i>
        ),
        to: 'dashboard',
        content: null,
      },
      {
        title: <Translator path={'titles.investment'} />,
        menuLabel: <Translator path={'menuLabel.investment'} />,
        // classsChange: 'mm-collapse',
        iconStyle: <i className="material-icons">token</i>,
        to: 'investment',
      },
      {
        title: <Translator path={'titles.wallet'} />,
        menuLabel: <Translator path={'menuLabel.wallet'} />,
        // classsChange: 'mm-collapse',
        iconStyle: <i className="material-icons">wallet</i>,
        to: '/wallet',
        content: [
          {
            title: <Translator path={'titles.wallet'} />,
            to: '/wallet',
          },
          {
            title: <Translator path={'titles.walletItems.deposit'} />,
            to: '/wallet/deposit',
          },
          {
            title: <Translator path={'titles.walletItems.withdraw'} />,
            to: '/wallet/withdrawList',
          },
          {
            title: <Translator path={'titles.walletItems.invest'} />,
            to: '/wallet/statement',
          },
        ],
      },
      {
        title: <Translator path={'titles.profile'} />,
        id: 'profile',
        menuLabel: <Translator path={'menuLabel.profile'} />,
        // classsChange: 'mm-collapse',
        iconStyle: <i className="material-icons">account_circle</i>,
        to: '/profile'
      },
      {
        menuLabel: <Translator path={'menuLabel.account'} />,
        // classsChange: 'mm-collapse',
        iconStyle: <i className="material-icons">swap_horiz</i>,
        to: 'login/account',
      },
      // {
      //   title: <Translator path={'titles.rewards'} />,
      //   menuLabel: <Translator path={'menuLabel.rewards'} />,
      //   // classsChange: 'mm-collapse',
      //   iconStyle: <i className="material-icons">redeem</i>,
      //   to: 'dashboard',
      // },
      {
        title: <Translator path={'titles.security'} />,
        menuLabel: <Translator path={'menuLabel.security'} />,
        // classsChange: 'mm-collapse',
        iconStyle: <i className="material-icons">lock</i>,
        to: 'security',
      },
      {
        title: <Translator path={'titles.useTerms'} />,
        menuLabel: <Translator path={'menuLabel.useTerms'} />,
        // classsChange: 'mm-collapse',
        iconStyle: <i className="material-icons">policy</i>,
        to: '',
        onClick: true,
      },
      {
        title: <Translator path={'titles.logout'} />,
        menuLabel: <Translator path={'titles.logout'} />,
        iconStyle: <i className="material-icons">logout</i>,
        classsChange: 'logout',
        to: 'logout',
      },
      // {
      //   title: <Translator path={'titles.inviteAFriend'} />,
      //   menuLabel: <Translator path={'menuLabel.inviteAFriend'} />,
      //   // classsChange: 'mm-collapse',
      //   iconStyle: <i className="material-icons">person_add</i>,
      //   to: 'dashboard',
      // },
    ]
    : [
      {
        title: <Translator path={'titles.dashboard'} />,
        menuLabel: <Translator path={'menuLabel.dashboard'} />,
        iconStyle: (
          <i className="material-icons" style={{ backgroundColors: '#ff9900' }}>
            dashboard
          </i>
        ),
        to: 'dashboard',
        content: null,
      },

      {
        title: <Translator path={'titles.tokens'} />,
        menuLabel: <Translator path={'menuLabel.tokens'} />,
        iconStyle: (
          <i className="material-icons" style={{ width: '22px' }}>
            tokens
          </i>
        ),
        to: 'tokens',
        content: [
          {
            title: <Translator path={'menuLabel.tokensItems.newProposal'} />,
            to: 'tokenProposal',
          },
          {
            title: <Translator path={'menuLabel.tokensItems.myProposal'} />,
            to: 'tokenProposal/list',
          },
          {
            title: <Translator path={'menuLabel.tokensItems.myTokens'} />,
            to: 'tokenProposal/tokens/list',
          },
        ],
      },
      {
        title: <Translator path={'titles.wallet'} />,
        menuLabel: <Translator path={'menuLabel.wallet'} />,
        iconStyle: <i className="material-icons">wallet</i>,
        to: 'wallet',
        content: [
          {
            title: <Translator path={'menuLabel.wallet'} />,
            to: 'wallet',
          },
          {
            title: <Translator path={'menuLabel.walletItems.depositList'} />,
            to: 'wallet/depositList',
          },
          {
            title: <Translator path={'menuLabel.walletItems.whithdrawList'} />,
            to: 'wallet/withdrawList',
          },
        ],
      },

      {
        title: <Translator path={'titles.issuer'} />,
        menuLabel: <Translator path={'menuLabel.issuer'} />,
        iconStyle: <i className="material-icons">business</i>,
        to: 'issuer',
        content: [
          {
            title: <Translator path={'menuLabel.issuerItems.myData'} />,
            to: '/mycorporation',
          },
          {
            title: <Translator path={'menuLabel.issuerItems.myBank'} />,
            to: 'mybank',
          },
        ],
      },
      {
        title: <Translator path={'titles.profile'} />,
        menuLabel: <Translator path={'titles.profile'} />,
        iconStyle: <i className="material-icons">account_circle</i>,
        to: 'profile',
        content: [
          {
            title: <Translator path={'menuLabel.profileItems.registry'} />,
            to: '/profile',
          },
          {
            title: <Translator path={'menuLabel.profileItems.alter'} />,
            to: '/profile/password',
          },
        ],
      },

      {
        title: <Translator path={'titles.logout'} />,
        menuLabel: <Translator path={'titles.logout'} />,
        iconStyle: <i className="material-icons">logout</i>,
        classsChange: 'logout',
        to: 'logout',
      },
    ];
