import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRegister } from '@contexts-issuer';
import { CodeValidator, Countdown, Translator } from '@components';

const SecondStep = ({ props: { email, setCode, code, setStep } }) => {
  const { t } = useTranslation();
  const { validateToken, loading, resetToken } = useRegister();

  const successObj = { msg: '' };
  const [resend, setResend] = useState(successObj);

  const reSend = () => {
    const success = { ...successObj };
    success.msg = t('pages.register.firstStep.resend').toString();
    setResend(success);
    resetToken(localStorage.getItem('issuerLoginId'));
  };

  const handleStep = async () => {
    try {
      const params = {
        token: code,
        issuerLoginId: localStorage.getItem('issuerLoginId'),
      };
      const response = await validateToken(params);
      if (response === true) setStep(3);
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    setResend('');
  }, [code]);

  return (
    <>
      <h3 className="reset-header" style={{ margin: '2.5rem 0 0.313rem 0' }}>
        <Translator path="pages.register.secondStep.header" />
      </h3>
      <p className="reset-body" style={{ marginBottom: 0 }}>
        <Translator path="pages.register.secondStep.body" />
      </p>
      <p className="email-reset" style={{ marginBottom: '2.5rem' }}>
        {email ? email : localStorage.getItem('issuer_email')}
      </p>
      <div className="col-12 d-flex justify-content-start" style={{ gap: '0 11px' }}>
        <CodeValidator props={{ setCode }} />
      </div>

      {resend.msg && (
        <div className="text-success fs-12" style={{ marginTop: '0.313rem' }}>
          {resend.msg}
        </div>
      )}
      <div className="row col-12 d-flex justify-content-between align-items-center" style={{ margin: 0 }}>
        <div className="col-5" style={{ margin: 0, padding: 0 }}>
          <Countdown />
        </div>
        <span className="form-check d-inline-block step-one col-7 mt-2" style={{ whiteSpace: 'nowrap' }}>
          <label className="a-forgot-password" htmlFor="check1" style={{ alignSelf: 'start' }}>
            <Translator path="pages.register.secondStep.send" />
          </label>
          <label
            className="a-forgot-password"
            style={{ marginLeft: '0.313rem', alignSelf: 'end' }}
            onClick={() => reSend()}
          >
            <Translator path="pages.register.secondStep.code" />
          </label>
        </span>
      </div>
      <div className="row col-12 justify-content-end" style={{ margin: '1.25rem 0 0 0' }}>
        <button
          className={`btn btn-orange col-6 ${loading ? 'd-flex justify-content-center disabled' : ''}`}
          onClick={() => handleStep()}
        >
          {loading ? (
            <div className="loading-spinner" style={{ width: '30px', height: '30px' }} />
          ) : (
            <Translator path="pages.register.secondStep.continue" />
          )}
        </button>
      </div>
    </>
  );
};

export default SecondStep;
