import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Translator, Loading, Table } from '@components';
import { useWallet } from '@contexts-issuer';

import { Dropdown } from 'react-bootstrap';

import { COLUMNS } from './TableColumns';
import FilterModal from './FilterModal';
import StatusCard from './StatusCard';
import RequestWithdraw from './RequestWithdraw';
import HowtoWithdrawModal from './HowtoWithdrawModal';

import { noData, noWithdraws, logoEmissor, logoIssuer, requestWithdraw } from '@assets';

const WithdrawList = () => {
  const { getWithdraws, getWithdrawDetails, withdrawDetails, loading } = useWallet();
  const { i18n } = useTranslation();
  const [isReview, setIsReview] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [withdraws, setWithdraws] = useState();
  const [issuerWithdrawId, setIssuerWithdrawId] = useState();
  const [withdrawSuccess, setWithdrawSuccess] = useState(false);
  const [filters, setFilters] = useState();
  const [howItWorksModal, sethowItWorksModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [requestModal, setRequestModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const getMyWithdraws = async () => {
      const rawData = await getWithdraws(1, 20, filters);
      const data = Array.from(rawData.data).map((item) => {
        const action = (
          <Dropdown style={{ color: 'var(--orange)' }}>
            <Dropdown.Toggle as="div" className="i-false sidebar-select">
              <i className="material-icons statement-action cursor-pointer">more_horiz</i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {item.status === 'INCAPTURE' ? (
                <Dropdown.Item onClick={() => navigate(`/token/${item.token.tokenId}`)}>
                  <Translator path="pages.withdrawList.tableColumns.actionItems.details" />
                </Dropdown.Item>
              ) : item.status === 'AVAILABLE' ? (
                <>
                  <Dropdown.Item onClick={() => navigate(`/token/${item.token.tokenId}`)}>
                    <Translator path="pages.withdrawList.tableColumns.actionItems.details" />
                  </Dropdown.Item>

                  <Dropdown.Item
                    onClick={() => {
                      setIssuerWithdrawId(item.issuerWithdrawId);
                      setRequestModal(true);
                    }}
                  >
                    <Translator path="pages.withdrawList.tableColumns.actionItems.withdraw" />
                  </Dropdown.Item>
                </>
              ) : item.status === 'ACCOUNT_IN_ANALYSIS' ? (
                <Dropdown.Item onClick={() => navigate('/mybank')}>
                  <Translator path="pages.withdrawList.tableColumns.actionItems.account" />
                </Dropdown.Item>
              ) : item.status === 'REQUESTED' ? (
                <Dropdown.Item
                  onClick={() => {
                    setIsReview(true);
                    setIssuerWithdrawId(item.issuerWithdrawId);
                    setRequestModal(true);
                  }}
                >
                  <Translator path="pages.withdrawList.tableColumns.actionItems.requested" />
                </Dropdown.Item>
              ) : item.status === 'DONE' ? (
                <Dropdown.Item
                  onClick={() => {
                    setIsDone(true);
                    setIssuerWithdrawId(item.issuerWithdrawId);
                    setRequestModal(true);
                  }}
                >
                  <Translator path="pages.withdrawList.tableColumns.actionItems.requested" />
                </Dropdown.Item>
              ) : (
                ''
              )}
            </Dropdown.Menu>
          </Dropdown>
        );

        return {
          ...item,
          withdrawCode: item.issuerWithdrawCode,
          tokenName: item.token.name,
          tokenCode: '',
          value: 'R$ ' + new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(parseFloat(item.value)),
          date: new Date(item.expectedWithdrawDate).toLocaleDateString('pt-BR'),
          withdrawDate: item.withdrawDate ? new Date(item.withdrawDate).toLocaleDateString('pt-BR') : '-',
          action: item.status !== 'CANCELLED' ? action : '',
          status: <StatusCard status={item.status} />,
        };
      });
      setWithdraws(data);
    };
    getMyWithdraws();
  }, [filters]);

  useEffect(() => {
    if (issuerWithdrawId) getWithdrawDetails(issuerWithdrawId);
  }, [issuerWithdrawId]);

  if (loading) {
    return <Loading />;
  }

  if (withdrawSuccess)
    return (
      <div className="row d-flex flex-column align-items-center withdraw-requested">
        <img className="header-logo" src={i18n.language === 'pt-BR' ? logoEmissor : logoIssuer} />
        <p className="span-selectors">
          <span>
            <Translator path="pages.withdrawList.body" />{' '}
          </span>
          <span>{withdrawDetails && withdrawDetails.token.tokenProposal.createdBy.email}</span>
        </p>
        <img className="body-image" src={requestWithdraw} />
        <button className="col-4 btn btn-orange" onClick={() => setWithdrawSuccess(false)}>
          <Translator path="pages.withdrawList.request.goBack" />
        </button>
      </div>
    );

  if (withdraws)
    return (
      <div className="withdrawList-wrapper">
        <div className="row justify-content-between">
          <h3 className="col-6 statement-header" style={{ whiteSpace: 'nowrap', marginBottom: '1.25rem', padding: 0 }}>
            <Translator path="pages.withdrawList.title" />
          </h3>
          <button
            className={`btn btn-orange col-2 ${withdraws?.length === 0 && !filters ? 'disabled' : ''}`}
            style={{ marginBottom: '1.25rem' }}
            onClick={() => setShowModal(true)}
          >
            <Translator path="pages.withdrawList.filter" />
          </button>
          <div className="col-12" style={{ margin: '0 0 0.625rem 0', padding: 0 }}>
            <p className="how-it-works-button" onClick={() => sethowItWorksModal(true)}>
              <Translator path="pages.withdrawList.works" />
            </p>
          </div>
          <Table
            props={{
              tableData: withdraws,
              tableColumns: COLUMNS,
              emptyImage: filters ? noData : noWithdraws,
            }}
          />
          <FilterModal props={{ show: showModal, setShow: setShowModal, setFilters: setFilters }} />
          <RequestWithdraw
            props={{ show: requestModal, setShow: setRequestModal, setSucess: setWithdrawSuccess, isReview, isDone }}
          />
          <HowtoWithdrawModal props={{ show: howItWorksModal, setShow: sethowItWorksModal }} />
        </div>
      </div>
    );
};

export default WithdrawList;
