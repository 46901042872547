import React from 'react';
import { Translator } from '@components';
import { formatDate } from 'date-utils-2020';

const Body = ({ props: { info } }) => {
  return (
    <>
      <div className="col-12 col-sm-8 col-md-8 col-lg-6">
        <p className="propolsal-card-label">
          <Translator path="components.tokenPropolsalCard.captureDate" />
        </p>
        <p className="propolsal-card-info">{formatDate(info.fundingLimitDate, 'dd/MM/yyyy')}</p>
        <p className="propolsal-card-label">
          <Translator path="components.tokenPropolsalCard.note" />
        </p>
        <p className="propolsal-card-info">{`R$ ${new Intl.NumberFormat('pt-BR', {
          minimumFractionDigits: 2,
        }).format(parseFloat(info.invoiceValue))}`}</p>
        <p className="propolsal-card-label">
          <Translator path="components.tokenPropolsalCard.toReceive" />
        </p>
        <p className="propolsal-card-info">
          {`R$ ${new Intl.NumberFormat('pt-BR', {
            minimumFractionDigits: 2,
          }).format(parseFloat(info.valueToReceive))}`}
        </p>
      </div>
      <div className="col-12 col-sm-8 col-md-8 col-lg-6">
        <p className="propolsal-card-label">
          <Translator path="components.tokenPropolsalCard.liquidDate" />
        </p>
        <p className="propolsal-card-info">{formatDate(info.liquidationDate, 'dd/MM/yyyy')}</p>
      </div>
    </>
  );
};

export default Body;
