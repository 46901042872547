import { Translator } from '@components';

const Footer = () => {
  return (
    <>
      <div className="row col-12 footer-wrapper d-flex justify-content-center align-items-center">
        <div className="row col-12 d-flex footer-content justify-content-center align-items-center">
          <p className="col-12 col-sm-12 col-md-6 col-lg-6 ">
            <Translator path="components.footer.title" />
          </p>
          <p className="col-12 col-sm-12 col-md-5 col-lg-5">
            <Translator path="components.footer.corporate" />
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
