import React from 'react';
import { Translator } from '@components';

const StatusCard = ({ status }) => {
  return (
    <div
      className="col-12"
      style={{
        borderRadius: '200px',
        backgroundColor: `${
          status === 'INCAPTURE'
            ? '#FF9900'
            : status === 'AVAILABLE'
            ? '#005312'
            : status === 'ACCOUNT_IN_ANALYSIS'
            ? '#E61D2B'
            : status === 'REQUESTED'
            ? '#E18700'
            : status === 'DONE'
            ? '#009847'
            : '#E61D2B'
        }`,
        textAlign: 'center',
      }}
    >
      <p className="statement-status-card" style={{ color: 'var(--white)' }}>
        {status === 'INCAPTURE' ? (
          <Translator path="pages.withdrawList.status.incapture" />
        ) : status === 'AVAILABLE' ? (
          <Translator path="pages.withdrawList.status.available" />
        ) : status === 'ACCOUNT_IN_ANALYSIS' ? (
          <Translator path="pages.withdrawList.status.analysing" />
        ) : status === 'REQUESTED' ? (
          <Translator path="pages.withdrawList.status.requested" />
        ) : status === 'DONE' ? (
          <Translator path="pages.withdrawList.status.done" />
        ) : (
          <Translator path="pages.withdrawList.status.cancel" />
        )}
      </p>
    </div>
  );
};

export default StatusCard;
