import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { I18n, Translator } from '@components';
import { useTranslation } from 'react-i18next';
import { cnpjMask, phoneMask, validateCnpj } from '@utils';
import { useWizard } from 'react-use-wizard';
import { Link, useNavigate } from 'react-router-dom';
import { logoCriptech } from '@assets';

function Step2({ get, set, ...props }) {
  const { t } = useTranslation();

  let errorsObj = {
    cnpj: '',
    corporateName: '',
    data: '',
    phone: '',
    address: '',
    socialContract: '',
  };

  const [errors, setErrors] = useState(errorsObj);
  const [registerError, setRegisterError] = useState(false);
  const [dateType, setDateType] = useState('text');
  const { previousStep, nextStep } = useWizard();


  const handleCnpj = (e) => {
    const cnpjFormated = cnpjMask(e.target.value);
    set.setCnpj(cnpjFormated);
  };

  const isPhone = (phone) => {
    /^\(?(\d{2})\)?[- ]?(\d{5})[- ]?(\d{4})$/.test(phone) ||
      /^\(?(\d{2})\)?[- ]?(\d{4})[- ]?(\d{4})$/.test(phone) ||
      /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(phone);
  }


  const handleAddDocLink = (e) => {
    set.setDocumentFiles((prevState) => [...prevState, e.target.files[0]]);
    set.setDocumentName((prevState) => [...prevState, e.target.files[0].name]);
  };

  const handleRemoveDocLink = (name) => {

    console.log(get.documentName)
    set.setDocumentFiles(get.documentFiles.filter(item => item.name !== name))
    set.setDocumentName(get.documentName.filter(item => item !== name))

  };

  const handleAddContract = (e) => {
    set.setSocialContract(e.target.files[0]);
  };

  function verifyFields(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };

    if (get.corporateName === '') {
      errorObj.name = t('register.errors.validName').toString();
      error = true;
    }
    if (get.cnpj === '') {
      errorObj.cnpj = t('register.errors.validCnpj').toString();
      error = true;
    }
    if (get.phoneCorp === '') {
      errorObj.phone = t('register.errors.phone').toString();
      error = true;
    }
    if (!validateCnpj(get.cnpj)) {
      errorObj.cnpj = t('register.errors.validCnpj').toString();
      error = true;
    }
    if (get.addressCorp === '') {
      errorObj.addressCorp = t('register.errors.address').toString();
      error = true;
    }
    if (get.socialContract === '') {
      errorObj.socialContract = t('register.errors.social').toString();
      error = true;
    }
    if (get.data === '') {
      errorObj.data = t('register.errors.foundationdate').toString();
      error = true;
    }

    setErrors(errorObj);

    if (error) {
      setRegisterError(true);
      return;
    }
    nextStep();
  }

  useEffect(() => {
    const errorObj = { ...errorsObj };
    errorsObj = {
      cnpj: '',
      corporateName: '',
      data: '',
      phoneCorp: '',
      addressCorp: '',
      socialContract: '',
    };
    setErrors(errorObj);
    setRegisterError(false);
  }, [get.cnpj, get.corporateName, get.data, get.phoneCorp, get.addressCorp, get.socialContract]);

  return (
    <>
      <div className="page-wraper">
        <nav className="nav nav-tabs border-bottom-0">
          <div className="tab-content w-100" id="nav-tabContent">
            <div className="tab-pane active show fade">
              <div className="logo-header">
                <Link to="/login" className="logo ">
                  <img src={logoCriptech} alt="" className="width-230 mCS_img_loaded " />
                </Link>
              </div>
              <h3 className="form-title">
                <Translator path={'register.title'} />
              </h3>
              <p style={{ color: '#525252', fontWight: '400', fontSize: '1.25rem' }}>
                <Translator path={'register.bodypj'} />
              </p>
              <div className="form-group mt-3">
                <input
                  className="form-control"
                  placeholder={t('CNPJ').toString()}
                  onChange={(e) => handleCnpj(e)}
                  value={cnpjMask(get.cnpj)}
                />
                {errors.cnpj && <div className="text-danger fs-12">{errors.cnpj}</div>}
              </div>
              <div className="form-group mt-3">
                <input
                  required=""
                  className="form-control"
                  placeholder={t('register.corporatename').toString()}
                  type="text"
                  onChange={(e) => set.setCorporateName(e.target.value)}
                  value={get.corporateName}
                />
                {errors.name && <div className="text-danger fs-12">{errors.name}</div>}
              </div>
              <div className="form-group mt-3">
                <input
                  className="form-control"
                  type={`${dateType}`}
                  placeholder={t('register.foundationdate').toString()}
                  onFocus={() => setDateType('date')}
                  onBlur={() => setDateType('text')}
                  onChange={(e) => set.setData(e.target.value)}
                  value={get.data}
                />
                {errors.data && <div className="text-danger fs-12">{errors.data}</div>}
              </div>

              <div className="form-group mt-3">
                <input
                  className="form-control"
                  placeholder={t('register.phone').toString()}
                  maxLength={15}
                  onChange={(e) => set.setPhoneCorp(phoneMask(e.target.value))}
                  value={get.phoneCorp}
                />
                {errors.phone && <div className="text-danger fs-12">{errors.phone}</div>}
              </div>

              <div className="col-12 additional-docs-register document-register">

                <div className='document-register'>

                  <label htmlFor="filePicker" className="document-input-file-text">
                    {get.socialContract === '' ? (
                      <Translator path={'register.socialcontractandbylaws'} />
                    ) : (
                      <label htmlFor="filePicker" style={{ color: '#242423' }}>
                        {get.socialContract.name}
                      </label>
                    )}
                  </label>
                  <input
                    name="link"
                    id="filePicker"
                    accept=".pdf, .jpeg, .png, .jpg"
                    type="file"
                    style={{ visibility: "hidden" }}
                    onChange={(e) => handleAddContract(e)}
                  />
                  <i className="material-icons icon-file-orange">attach_file</i>


                  {errors.fiscalDoc !== '' ? (
                    <div className="text-danger fs-12" style={{ bottom: '-1rem' }}>
                      {errors.fiscalDoc}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                {get.documentName.length > 0 &&
                  get.documentName.map((doc) => {
                    return (
                      <small key={doc}>
                        <label style={{ display: 'flex', alignItems: 'center', width: '100%', marginTop: '0.5rem', color: '#242423' }}>
                          <i className="fa fa-paperclip" style={{ marginLeft: '0.5rem', paddingRight: '0.5rem' }} ></i>
                          {doc}
                          <div
                            style={{ marginLeft: '1rem' }}
                            onClick={(e) => handleRemoveDocLink(doc)}
                          >
                            <i className="material-icons">delete</i>
                          </div>
                        </label>
                      </small>
                    );
                  })}
                {get.socialContract !== '' ? (
                  <div
                    className="add-doc"
                    style={{ marginTop: `${errors.fiscalDoc ? '1.25rem' : '0.75rem'}`, width: 'fit-content' }}
                  >
                    <label htmlFor="imagem">
                      <p>
                        <i className="material-icons">add</i>
                        <Translator path={'register.document'} />
                      </p>
                    </label>
                    <input name="link" id="imagem" accept=".pdf, .jpeg, .png, .jpg" type="file" disabled={get.documentFiles?.length === 5}
                      style={{ visibility: "hidden" }}
                      onChange={(e) => handleAddDocLink(e)}
                    />
                  </div>
                ) : (
                  ''
                )}
                <div className="row col-12" style={{ padding: 0 }}>
                  {errors.socialContract && <div className="text-danger fs-12">{errors.socialContract}</div>}
                </div>
              </div>

              <div className="form-group mt-3">
                <textarea
                  required=""
                  className="form-control text-area"
                  placeholder={t('register.completeaddress').toString()}
                  onChange={(e) => set.setAddressCorp(e.target.value)}
                  value={get.addressCorp}
                />
                {errors.addressCorp && <div className="text-danger fs-12">{errors.addressCorp}</div>}
              </div>

              <div className="form-group clearfix text-left mt-4">
                <button
                  className="btn btn-outlined outline gray"
                  style={{ width: '8rem' }}
                  onClick={() => previousStep()}
                >
                  <Translator path={'register.goback'} />
                </button>

                <button
                  className="col-6 btn btn-orange float-end"
                  onClick={(e) => verifyFields(e)}>
                  <Translator path={'register.submit'} />
                </button>

              </div>
            </div>
          </div>
        </nav >
      </div >
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(Step2);
