import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import swal from 'sweetalert';
import { useProfile } from '@contexts-investor';
import { Translator, Loading } from '@components';

const FinalStep = ({ props: { code } }) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');
  const { t } = useTranslation();
  const { profile, resetPassword, loadingButton } = useProfile();
  let errorsObj = { password: '', confirm: '', val: '' };
  const [errors, setErrors] = useState(errorsObj);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prevState) => !prevState);
  };

  const validateCharacteres = (value) => {
    if (value.length >= 8) return true;
    return false;
  };
  const validateNumber = (value) => {
    if (/\d/.test(value)) return true;
    return false;
  };
  const validateString = (value) => {
    if (/[A-Z]/.test(value)) return true;
    return false;
  };
  const validateSpecial = (value) => {
    if (/[`!@#$%^&*()_{};:"|,.<>/?~]/.test(value)) return true;
    return false;
  };
  const validateEquals = (first, second) => {
    if (first === second && first.length >= 8) return true;
    return false;
  };

  async function onSetPassword(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };

    if (password === '') {
      errorObj.password = t('register.passwordPage.errors.pass').toString();
      error = true;
    }

    if (confirm === '') {
      errorObj.confirm = t('register.passwordPage.errors.confirm').toString();
      error = true;
    }

    if (confirm !== password) {
      errorObj.val = t('register.passwordPage.errors.val').toString();
      error = true;
    }

    setErrors(errorObj);

    if (
      validateCharacteres(password) &&
      validateNumber(password) &&
      validateString(password) &&
      validateSpecial(password) &&
      validateEquals(password, confirm)
    ) {
      let email = '';
      if (profile?.physicalUser) {
        email = profile.physicalUser.email;
      }
      if (profile?.legalUser) {
        email = profile.legalUser.loginLegalUser.email;
      }
      if (profile) {
        const body = { password: password, accessToken: code, email: email };
        resetPassword(body);
      }
    } else {
      if (error) {
        return;
      }
      swal('Error', t('register.passwordPage.errors.attend').toString(), 'error');
    }
  }

  if (loadingButton) return <Loading />;

  return (
    <>
      <div className="row col-12 d-flex flex-column align-items-start justify-content-center">
        <p className="security-edit-header">
          <Translator path="securityPage.edit.secondStep.title" />
        </p>
        <p className="security-edit-body">
          <Translator path="securityPage.edit.secondStep.body" />
        </p>
        <div className="form-group mb-3" style={{ paddingLeft: 0 }}>
          <div className="input-container-security">
            <input
              className="security form-control show-pass"
              type={isPasswordVisible ? 'text' : 'password'}
              placeholder={t('register.passwordPage.pass').toString()}
              onChange={(e) => setPassword(e.target.value)}
            />
            <i
              className={`eye-icon-security fa  ${isPasswordVisible ? 'fa-eye visible' : 'fa-eye-slash visible'}`}
              onClick={togglePasswordVisibility}
              style={{
                width: '2rem',
                height: '1.5rem',
              }}
            />
          </div>

          {errors.password && <div className="text-danger fs-12">{errors.password}</div>}
        </div>
        <div className="form-group mb-3" style={{ paddingLeft: 0 }}>
          <div className="input-container-security">
            <input
              className="security form-control show-pass"
              type={isPasswordVisible ? 'text' : 'password'}
              placeholder={t('register.passwordPage.rePass').toString()}
              onChange={(e) => setConfirm(e.target.value)}
            />
            <i
              className={`eye-icon-security fa  ${isPasswordVisible ? 'fa-eye visible' : 'fa-eye-slash visible'}`}
              onClick={togglePasswordVisibility}
              style={{
                width: '2rem',
                height: '1.5rem',
              }}
            />
          </div>

          {errors.confirm && <div className="text-danger fs-12">{errors.confirm}</div>}
          {errors.val && <div className="text-danger fs-12">{errors.val}</div>}
        </div>

        <form className="dz-form py-2 row justify-content-center " style={{ paddingLeft: 0 }} onSubmit={onSetPassword}>
          <div
            className="form-group row d-flex flex-column justify-content-center align-items-start"
            style={{ paddingLeft: 0 }}
          >
            <div className="col-12 d-flex justify-content-start">
              <p className={`${validateCharacteres(password) ? 'label-validation' : 'label-noval'}`}>
                <i className="fa-solid fa-check" style={{ marginRight: '0.625rem' }} />
                <Translator path="register.passwordPage.validationOne" />
              </p>
            </div>
            <div className="col-12 d-flex justify-content-start">
              <p className={`${validateNumber(password) ? 'label-validation' : 'label-noval'}`}>
                <i className="fa-solid fa-check" style={{ marginRight: '0.625rem' }} />
                <Translator path="register.passwordPage.validationTwo" />
              </p>
            </div>
            <div className="col-12 d-flex justify-content-start">
              <p className={`${validateString(password) ? 'label-validation' : 'label-noval'}`}>
                <i className="fa-solid fa-check" style={{ marginRight: '0.625rem' }} />
                <Translator path="register.passwordPage.validationThree" />
              </p>
            </div>
            <div className="col-12 d-flex justify-content-start">
              <p className={`${validateSpecial(password) ? 'label-validation' : 'label-noval'}`}>
                <i className="fa-solid fa-check" style={{ marginRight: '0.625rem' }} />
                <Translator path="register.passwordPage.validationFour" />
              </p>
            </div>
            <div className="col-12 d-flex justify-content-start">
              <p className={`${validateEquals(password, confirm) ? 'label-validation' : 'label-noval'}`}>
                <i className="fa-solid fa-check" style={{ marginRight: '0.625rem' }} />
                <Translator path="register.passwordPage.validationEquals" />
              </p>
            </div>
          </div>
          <button type="submit" className="btn btn-orange col-6">
            <Translator path="register.passwordPage.submit" />
          </button>
        </form>
      </div>
    </>
  );
};

export default FinalStep;
