import { Translator } from '@components';

const textData1 = (
  <ul>
    <li>
      <Translator path="securityPage.accordionData.hint1.li1" />
    </li>
    <li>
      <Translator path="securityPage.accordionData.hint1.li2" />
    </li>
    <li>
      <Translator path="securityPage.accordionData.hint1.li3" />
    </li>
    <li>
      <Translator path="securityPage.accordionData.hint1.li4" />
    </li>
  </ul>
);

const textData2 = (
  <ul>
    <li>
      <Translator path="securityPage.accordionData.hint2.li1" />
    </li>
    <li>
      <Translator path="securityPage.accordionData.hint2.li2" />
    </li>
    <li>
      <Translator path="securityPage.accordionData.hint2.li3" />
    </li>
    <li>
      <Translator path="securityPage.accordionData.hint2.li4" />
    </li>
  </ul>
);

const textData3 = (
  <ul>
    <li>
      <Translator path="securityPage.accordionData.hint3.li1" />
    </li>
    <li>
      <Translator path="securityPage.accordionData.hint3.li2" />
    </li>
  </ul>
);

const textData4 = (
  <ul>
    <li>
      <Translator path="securityPage.accordionData.hint4.li1" />
    </li>
    <li>
      <Translator path="securityPage.accordionData.hint4.li2" />
    </li>
  </ul>
);

export const accordionData = [
  {
    title: <Translator path="securityPage.accordionData.hint1.title" />,
    text: textData1,
  },
  {
    title: <Translator path="securityPage.accordionData.hint2.title" />,
    text: textData2,
  },
  {
    title: <Translator path="securityPage.accordionData.hint3.title" />,
    text: textData3,
  },
  {
    title: <Translator path="securityPage.accordionData.hint4.title" />,
    text: textData4,
  },
];
