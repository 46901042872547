import { connect } from 'react-redux';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { I18n, Translator } from '@components';
import { loginBg, logoCriptech } from '@assets';

function RegisterType(props) {
  const navigate = useNavigate();

  return (
    <div className="page-wraper">
      <div className="browse-job login-style3">
        <div
          className="bg-img-fix overflow-hidden"
          style={{
            background: '#fff url(' + loginBg + ')',
            height: '100vh',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right',
            backgroundSize: 'contain',
          }}
        >
          <div className="row gx-0">
            <div className="register-form-container bg-white d-flex justify-content-center align-items-center">
              <div className="register-form-type d-flex justify-content-center align-items-left" style={{}}>
                <div className="return-login" style={{ marginBottom: '3rem' }} onClick={() => navigate('/login')}>
                  <i className="bi bi-arrow-left select-arrow" />
                  <p style={{ marginTop: '0.3rem', marginLeft: '-0.5rem' }}><Translator path={'register.goback'} /> </p>
                </div>
                <div className="logo-header">
                  <Link to={'/login'} className="logo">
                    <img src={logoCriptech} alt="" className="width-230 mCS_img_loaded" />
                  </Link>
                </div>
                <div className="login-form-box nav nav-tabs border-bottom-0">
                  <div className="tab-content w-100" id="nav-tabContent">
                    <h3>
                      <Translator path="register.singup" />
                    </h3>
                    <label className="select-option-text">
                      <Translator path="register.select" />
                    </label>
                    <div className="select-option-pf-pj">
                      <NavLink to="/register" className="col-12 select-option-button">
                        <label className="select-option-person">
                          <Translator path="register.userpf" />
                        </label> <i className="bi bi-arrow-right select-arrow" />
                      </NavLink>

                      <NavLink to="/registerpj" className="col-12 select-option-button">
                        <label className="select-option-person">
                          <Translator path="register.userpj" />
                        </label> <i className="bi bi-arrow-right select-arrow" />
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
              <I18n />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(RegisterType);
