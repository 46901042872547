import React from 'react';
import { useTranslation } from 'react-i18next';
import { Translator } from '@components';

const StatusCard = ({ status }) => {
  const { t } = useTranslation();

  return (
    <div
      className="col-12"
      style={{
        borderRadius: '200px',
        backgroundColor: `${
          status === 'INCAPTURE'
            ? '#FF9900'
            : status === 'LIQUIDATED'
            ? '#009847'
            : status === 'CANCELED'
            ? '#E61D2B'
            : status === 'CAPTATED'
            ? '#005312'
            : ''
        }`,
        textAlign: 'center',
      }}
    >
      <p className="statement-status-card">
        {status === 'INCAPTURE' ? (
          <Translator path="components.statusCard.captation" />
        ) : status === 'CAPTATED' ? (
          <Translator path="components.statusCard.captated" />
        ) : status === 'CANCELED' ? (
          <Translator path="components.statusCard.cancel" />
        ) : status === 'LIQUIDATED' ? (
          <Translator path="components.statusCard.complete" />
        ) : (
          ''
        )}
      </p>
    </div>
  );
};

export default StatusCard;
