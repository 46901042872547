import Translator from '../../../../components/I18n/Translator';

export const COLUMNS = [
  {
    Header: <Translator path={'components.depositTable.order'} />,
    Footer: <Translator path={'components.depositTable.order'} />,
    accessor: 'depositCode',
    isSortable: false,
  },
  {
    Header: <Translator path={'components.depositTable.val'} />,
    Footer: <Translator path={'components.depositTable.val'} />,
    accessor: 'formatedValue',
    isSortable: false,
  },
  {
    Header: <Translator path={'components.depositTable.date'} />,
    Footer: <Translator path={'components.depositTable.date'} />,
    accessor: 'day',
    isSortable: false,
  },
  {
    Header: <Translator path={'components.depositTable.time'} />,
    Footer: <Translator path={'components.depositTable.time'} />,
    accessor: 'time',
    isSortable: false,
  },
  {
    Header: <Translator path={'components.depositTable.form'} />,
    Footer: <Translator path={'components.depositTable.form'} />,
    accessor: 'type',
    isSortable: false,
  },
];
