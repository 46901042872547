import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PurchaseCard from '../PurchaseCard';

const PurchaseList = ({ props }) => {
  const [data, setData] = useState();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    setData(props);
  }, [props]);

  return (
    <div className="row">
      {data &&
        data.map((item, index) => {
          return (
            <div
              key={index}
              className={`col-sm-12 col-md-6 col-xxl-4 ${item.status === 'INCAPTURE' ? 'c-pointer' : ''} `}
              style={{ marginBottom: '2.5rem' }}
              onClick={() => item.status === 'LIQUIDATED' ? navigate(`/investment/${item.tokenId}/details`) : ''}
            >
              {/* onClick={() => {
                handleRedirect(item);
              }} */}
              <PurchaseCard props={item} />
            </div>
          );
        })}
    </div>
  );
};

export default PurchaseList;
