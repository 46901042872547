import 'react-datepicker/dist/react-datepicker.css';

import { noPreview } from '@assets';
import { InputText, Loading, PopOver, Translator } from '@components';
import { useIssuer, useTokenProposal } from '@contexts-issuer';
import { moneyMask } from '@utils';
import { Buffer } from 'buffer';
import { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import swal from 'sweetalert';

import { ProposalOverviewModal } from '../ProposalOverviewModal';
import { SuccessModal } from '../SuccessModal';

// import { ProposalPreview } from '../ProposalPreview';
const ProposalForm = () => {
  const [choosenImage, setChoosenImage] = useState();
  const [preview, setPreview] = useState('');
  const [loadingPreview, setloadingPreview] = useState(false);

  const [tokenName, setTokenName] = useState('');
  const [invoiceValue, setInvoiceValue] = useState(0);
  const [valueToReceive, setValueToReceive] = useState(0);
  const [fundingLimitDate, setFundingLimitDate] = useState();
  const [liquidationDate, setLiquidationDate] = useState();
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [description, setDescription] = useState('');
  const [additionalDocs, setAdditionalDocs] = useState([{ link: '', description: '' }]);
  const [showOverviewModal, setShowOverviewModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  // const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [proposalSubmitted, setProposalSubmitted] = useState(false);
  const [body, setBody] = useState({});
  // const [temporaryBody, setTemporaryBody] = useState({});

  const { uploadTokenImage, downloadTokenImage, imageLink, loading, loadingImage, uploadAdditionalDoc, loadingDocs } =
    useTokenProposal();

  const { getIssuerProfile, issuerProfile } = useIssuer();

  let errorsObj = {
    name: '',
    image: '',
    invoiceValue: '',
    valueToReceive: '',
    fundingLimitDate: '',
    liquidationDate: '',
    discountPercentage: '',
    description: '',
    link: '',
    docDescription: '',
    fiscalDoc: '',
    fiscalDocDesc: '',
  };
  const [errors, setErrors] = useState(errorsObj);

  const { t } = useTranslation();

  useEffect(() => {
    const issuerId = localStorage.getItem('issuerId');
    getIssuerProfile(issuerId);

    clearForm();
  }, []);

  useEffect(() => {
    if (proposalSubmitted && tokenName !== '') {
      setShowSuccessModal(true);
    }
  }, [proposalSubmitted]);

  useEffect(() => {
    if (!choosenImage) {
      setPreview('');
      return;
    }

    setloadingPreview(true);
    const upload = async () => {
      await uploadTokenImage(choosenImage);
    };

    upload();
  }, [choosenImage]);

  useEffect(() => {
    const download = async () => {
      const imageBuffer = await downloadTokenImage(imageLink);
      const newSrc = Buffer.from(imageBuffer).toString('base64');
      setPreview(newSrc);
      setloadingPreview(false);
    };

    if (imageLink) download();
  }, [imageLink]);

  useEffect(() => {
    const errorObj = { ...errorsObj };
    errorsObj = {
      name: '',
      image: '',
      invoiceValue: '',
      valueToReceive: '',
      fundingLimitDate: '',
      liquidationDate: '',
      discountPercentage: '',
      description: '',
      invoiceTooLow: '',
      link: '',
      docDescription: '',
      fiscalDoc: '',
      fiscalDocDesc: '',
    };
    setErrors(errorObj);

    // const tempBody = {
    //   issuerId: localStorage.getItem('issuerId'),
    //   name: tokenName,
    //   image: `data:image/jpeg;base64,${preview}`,
    //   invoiceValue: +removeMasks(invoiceValue),
    //   valueToReceive: +removeMasks(valueToReceive),
    //   fundingLimitDate: fundingLimitDate && new Date(fundingLimitDate).toISOString().split(/[A-Z]/)[0],
    //   liquidationDate: liquidationDate && new Date(liquidationDate).toISOString().split(/[A-Z]/)[0],
    //   discountPercentage,
    //   description,
    //   additionalDocs,
    // };

    // setTemporaryBody(tempBody);
  }, [
    tokenName,
    invoiceValue,
    valueToReceive,
    fundingLimitDate,
    liquidationDate,
    discountPercentage,
    description,
    additionalDocs,
    imageLink,
  ]);

  useEffect(() => {
    if (invoiceValue && discountPercentage) {
      setValueToReceiveWithMask(`${+removeMasks(invoiceValue) - +discountPercentage * +removeMasks(invoiceValue)}`);
    }
  }, [invoiceValue, discountPercentage]);

  const submitForm = (e) => {
    e.preventDefault();

    let error = false;
    const errorObj = { ...errorsObj };

    if (tokenName === '') {
      errorObj.name = t('pages.tokenProposal.errors.noName');
      error = true;
    }

    if (preview == '') {
      errorObj.image = t('pages.tokenProposal.errors.noImage');
      error = true;
    }

    if (invoiceValue === 0) {
      errorObj.invoiceValue = t('pages.tokenProposal.errors.noInvoice');
      error = true;
    }

    if (valueToReceive === 0) {
      errorObj.valueToReceive = t('pages.tokenProposal.errors.noValueToReceive');
      error = true;
    }

    if (fundingLimitDate === undefined) {
      errorObj.fundingLimitDate = t('pages.tokenProposal.errors.noFundingLimitDate');
      error = true;
    }

    if (liquidationDate === undefined) {
      errorObj.liquidationDate = t('pages.tokenProposal.errors.noLiquidationDate');
      error = true;
    }

    if (discountPercentage === 0) {
      errorObj.discountPercentage = t('pages.tokenProposal.errors.noDiscountPercentage');
      error = true;
    }

    if (description === '') {
      errorObj.description = t('pages.tokenProposal.errors.noDescription');
      error = true;
    }

    if (additionalDocs[0].link === '') {
      errorObj.fiscalDoc = t('pages.tokenProposal.errors.noFiscalDoc');
      error = true;
    }

    if (additionalDocs[0].description === '') {
      errorObj.fiscalDocDesc = t('pages.tokenProposal.errors.noFiscalDesc');
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }

    const reqBody = {
      issuerId: localStorage.getItem('issuerId'),
      name: tokenName,
      image: imageLink,
      invoiceValue: +removeMasks(invoiceValue),
      discountPercentage: +discountPercentage,
      valueToReceive: +removeMasks(valueToReceive),
      fundingLimitDate: new Date(fundingLimitDate).toISOString().split(/[A-Z]/)[0],
      liquidationDate: new Date(liquidationDate).toISOString().split(/[A-Z]/)[0],
      description: description,
      tokenProposalAdditionalDocs: additionalDocs,
    };

    setBody(reqBody);

    toggleShowOverviewModal(e);
  };

  const setInvoiceValueWithMask = (value) => {
    setInvoiceValue(moneyMask(value));
  };

  const setValueToReceiveWithMask = (value) => {
    if (+removeMasks(invoiceValue) >= 1)
      setValueToReceive(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value));
    else setValueToReceive('R$ 0,00');
  };

  const addAdditionalDocument = (e) => {
    if (additionalDocs.length !== 0 && additionalDocs[additionalDocs.length - 1].link === '') {
      const errorObj = { ...errorsObj };
      errorObj.link = t('pages.tokenProposal.errors.noAdditionalDoc').toString();
      setErrors(errorObj);
      return;
    } else if (additionalDocs.length !== 0 && additionalDocs[additionalDocs.length - 1].description === '') {
      const errorObj = { ...errorsObj };
      errorObj.docDescription = t('pages.tokenProposal.errors.noAdditionalDocDesc').toString();
      setErrors(errorObj);
      return;
    }

    setAdditionalDocs([...additionalDocs, { link: '', description: '' }]);
  };

  const handleAddDocLink = async (index, e) => {
    console.log('tamanho: ', e.target.files[0].size);
    if (e.target.files[0].size > 209715200) {
      const title = t('pages.tokenProposal.errors.fileTooLargeTitle');
      const body = t('pages.tokenProposal.errors.fileTooLargeBody');
      swal(title, body, 'error');
      e.target.value = null;
      return;
    }
    const docLink = await uploadAdditionalDoc(e.target.files[0]);

    if (docLink === undefined) {
      e.target.value = null;
      return
    }

    if (docLink !== '') {
      additionalDocs[index].link = docLink;
      setAdditionalDocs([...additionalDocs]);
    } else {
      return;
    }
  };

  const handleAddDocDescription = (index, e) => {
    additionalDocs[index].description = e.target.value;
    setAdditionalDocs([...additionalDocs]);
  };

  const handleRemoveDoc = (index) => {
    if (index !== 0) setAdditionalDocs([...additionalDocs.filter((_, i) => index !== i)]);
  };

  const removeMasks = (value) => {
    if (value) {
      const newValue = value
        ?.replace('R', '')
        ?.replace('$', '')
        ?.replaceAll('.', '')
        ?.replaceAll(',', '.')
        ?.replaceAll(' ', '');

      return newValue;
    }
  };

  const toggleShowOverviewModal = (e) => {
    e.preventDefault();
    setShowOverviewModal(!showOverviewModal);
  };

  const clearForm = () => {
    setTokenName('');
    setPreview('');
    setAdditionalDocs([{ link: '', description: '' }]);
    setInvoiceValue(0);
    setValueToReceive(0);
    setFundingLimitDate();
    setLiquidationDate();
  };

  if (loading) return <Loading />;

  return (
    <div className="form col-12">
      <form onSubmit={submitForm}>
        <div className="token-media image">
          {loadingImage ? (
            <>
              <div
                className="loading-spinner"
                style={{
                  width: '5.625rem',
                  height: '5.625rem',
                  position: 'absolute',
                }}
              />
              <img src={noPreview} alt="" style={{ width: '5.625rem', height: '5.625rem', margin: '0 !important' }} />
            </>
          ) : (
            <div className="d-inline">
              <img
                src={preview ? `data:image/jpeg;base64,${preview}` : noPreview}
                style={{ width: '5.625rem', height: '5.625rem' }}
              />
              <div
                className="upload-button"
                title=""
                data-toggle="tooltip"
                data-placement="right"
                data-original-title="update"
                style={{ backgroundColor: '#FF9900' }}
              >
                <input
                  type="file"
                  className="update-file"
                  style={{ backgroundColor: '#FF9900', left: 0 }}
                  onChange={(e) => setChoosenImage(e.target.files[0])}
                />
                <i className="fa fa-camera"></i>
              </div>
            </div>
          )}
          <span
            className="mx-4"
            style={{
              fontWeight: '500',
              fontSize: '1rem',
              color: '#525252',
              margin: '0 0 5px 0',
              padding: 0,
              whiteSpace: 'nowrap',
            }}
          >
            <Translator path={'pages.tokenProposal.image'} />
            <span className="obrigatory">*</span>
          </span>
          {errors.image && <div className="text-danger fs-12">{errors.image}</div>}
        </div>
        <div className="row col-12">
          <div className="col-lg-6 col-md-12">
            <InputText text={'pages.tokenProposal.name'} setState={setTokenName} value={tokenName} maxLength={'16'} />
            {errors.name && <div className="text-danger fs-12">{errors.name}</div>}
          </div>
          <div className="col-lg-6 col-md-12">
            <InputText
              text={'pages.tokenProposal.invoiceValue'}
              setState={setInvoiceValueWithMask}
              value={invoiceValue}
              maxLength={23}
            />
            {errors.invoiceValue && <div className="text-danger fs-12">{errors.invoiceValue}</div>}
          </div>
        </div>
        <div className="row col-12">
          <div className="col-lg-6 col-md-12">
            <div className="d-flex flex-row text-input-container">
              <p className="text-input-label">
                <Translator path={'pages.tokenProposal.fundingDateLimit'} />
              </p>
              <p>*</p>
            </div>
            <DatePicker
              selected={fundingLimitDate}
              onChange={(date) => setFundingLimitDate(date)}
              dateFormat="dd/MM/yyyy"
              className="date-picker col-12 d-flex align-items-start form-control"
              startDate={new Date()}
              minDate={new Date()}
            />
            {errors.fundingLimitDate && <div className="text-danger fs-12">{errors.fundingLimitDate}</div>}
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="d-flex flex-row text-input-container">
              <p className="text-input-label">
                <Translator path={'pages.tokenProposal.liquidationDate'} />
              </p>
              <p>*</p>
            </div>
            <DatePicker
              selected={liquidationDate}
              onChange={(date) => setLiquidationDate(date)}
              dateFormat="dd/MM/yyyy"
              className="date-picker col-12 d-flex align-items-start form-control"
              startDate={fundingLimitDate}
              minDate={fundingLimitDate}
            />

            {errors.liquidationDate && <div className="text-danger fs-12">{errors.liquidationDate}</div>}
          </div>
        </div>
        <div className="row col-12">
          <div className="col-lg-6 col-md-12">
            <div className="col-12 d-flex flex-column justify-content-start">
              <div className="d-flex flex-row text-input-container">
                <p className="text-input-label">
                  <Translator path={'pages.tokenProposal.discountPercentage'} />
                </p>
                <p>*</p>
              </div>
              <div className="position-relative">
                <Form.Select
                  className="form-control"
                  onChange={(e) => setDiscountPercentage(e.target.value)}
                  value={discountPercentage}
                >
                  <option value=""></option>
                  <option value="0.03">3%</option>
                  <option value="0.035">3.5%</option>
                  <option value="0.04">4%</option>
                </Form.Select>
                <i className="material-icons position-absolute select-discount">expand_more</i>
              </div>
              {errors.discountPercentage && <div className="text-danger fs-12">{errors.discountPercentage}</div>}
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <InputText
              className="token-input-disabled"
              text={'pages.tokenProposal.valueToReceive'}
              readOnly={true}
              value={valueToReceive && !valueToReceive.includes('NaN') ? valueToReceive : 0}
            />
          </div>
        </div>
        <div className="row col-12 p-0">
          <div className="row col-12 p-0">
            <div className="col-12 d-flex flex-column justify-content-start p-0 padding-left">
              <div className="d-flex flex-row text-input-container">
                <p className="text-input-label">
                  <Translator path={'pages.tokenProposal.description'} />
                </p>
                <p>*</p>
              </div>
              <textarea
                type="textarea"
                className="form-control col-12 d-flex align-items-start"
                onChange={(e) => setDescription(e.target.value)}
                style={{ height: '9.5vh' }}
              />
              {errors.description && <div className="text-danger fs-12">{errors.description}</div>}
            </div>
          </div>
        </div>
        <div className="col-12 additional-docs">
          {additionalDocs.map((doc, index) => (
            <div key={index} className="row col-lg-12 justify-content-center">
              <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                <div className="position-relative d-flex flex-column justify-content-start">
                  <div className="d-flex flex-row text-input-container position-relative">
                    <p className="text-input-label" style={{ wordWrap: 'normal' }}>
                      <Translator path="pages.tokenProposal.additionalDoc" />
                      {<span> ({index + 1})</span>}
                      {index === 0 ? <span className="obrigatory">*</span> : ''}
                    </p>
                    <p></p>
                  </div>
                  <input
                    key={index}
                    name="link"
                    accept=".pdf"
                    type="file"
                    className={'col-10 d-flex align-items-start form-control'}
                    onChange={(e) => handleAddDocLink(index, e)}
                  />
                  {loadingDocs === 'additionalDocs' ? (
                    <PopOver
                      children={
                        <div
                          className="loading-spinner icon-float-right hide-icon-issuer"
                          style={{ width: '30px', height: '30px' }}
                        />
                      }
                      placement="top"
                      id="orangePopOver"
                      text={t('pages.issuer.downloadDoc').toString()}
                    ></PopOver>
                  ) : (
                    <i className="material-icons icon-float-right hide-icon-issuer">attach_file</i>
                  )}
                  {errors.fiscalDoc !== '' ? (
                    <div className="text-danger fs-12 position-absolute" style={{ bottom: '-1rem' }}>
                      {errors.fiscalDoc}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="col-xl-7 col-lg-11 col-md-11 col-sm-11 col-xs-9">
                <div className="d-flex flex-column justify-content-start position-relative" style={{ padding: 0 }}>
                  <div className="d-flex flex-row text-input-container">
                    <p className="text-input-label">
                      <Translator path="pages.tokenProposal.additionalDocDescription" />
                      {<span> ({index + 1})</span>}
                      {index === 0 ? <span className="obrigatory">*</span> : ''}
                    </p>
                    <p></p>
                  </div>
                  <input
                    key={index}
                    name="description"
                    value={additionalDocs[index].description}
                    className={'col-10 d-flex align-items-start form-control '}
                    onChange={(e) => handleAddDocDescription(index, e)}
                    maxLength={50}
                  />
                  {errors.fiscalDocDesc !== '' ? (
                    <div className="text-danger fs-12 position-absolute" style={{ bottom: '-1rem' }}>
                      {errors.fiscalDocDesc}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-xs-1 d-flex">
                <div className="d-flex align-items-end">
                  <div
                    className="d-flex justify-content-center align-items-center redPopOver"
                    style={{
                      borderRadius: '8px',
                      border: '1px solid #E61D2B',
                      padding: '8px 10px 10px 7px',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleRemoveDoc(index)}
                  >
                    <PopOver
                      children={
                        <i
                          className="material-icons"
                          width="20px"
                          height="20px"
                          style={{
                            color: '#E61D2B',
                            width: '20px',
                            height: '20px',
                          }}
                        >
                          delete
                        </i>
                      }
                      placement="top"
                      id="redPopOver"
                      text={t('pages.tokenProposal.remove').toString()}
                    ></PopOver>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div
            className="add-doc"
            style={{ marginTop: `${errors.fiscalDoc ? '1.25rem' : '0.75rem'}`, width: 'fit-content' }}
          >
            <p onClick={(e) => addAdditionalDocument(e)} style={{ width: 'fit-content' }}>
              <i className="material-icons">add</i>
              <Translator path={'pages.tokenProposal.addDoc'} />
            </p>
          </div>
          <div className="row col-12" style={{ padding: 0, margin: '0 0 0.5rem 0' }}>
            <div className="col-sm-6 paddingLeft-control-issuer">
              {errors.link && <div className="text-danger fs-12">{errors.link}</div>}
            </div>
            <div className="col-sm-6 paddingRight-control-issuer">
              {errors.docDescription && <div className="text-danger fs-12">{errors.docDescription}</div>}
            </div>
          </div>
        </div>

        <div className="actions col-lg-12" style={{ marginBottom: '2.5rem' }}>
          <Button variant="primary" size="lg" className="text-center col-xl-6 col-md-2" id="btn" type="submit">
            <Translator path={'pages.tokenProposal.sendProposal'} />
          </Button>
          {/* <div
            className="preview col-xl-6 col-md-4"
            onClick={() => {
              setShowPreviewModal(true);
            }}
          >
            <i className="material-icons">visibility</i>
            <Translator path="pages.tokenProposal.preview" />
          </div> */}
        </div>
      </form>
      <ProposalOverviewModal
        show={showOverviewModal}
        body={body}
        issuer={issuerProfile}
        imageLink={preview}
        setShow={setShowOverviewModal}
        setSubmitted={setShowSuccessModal}
      />

      <SuccessModal show={showSuccessModal} setShow={setShowSuccessModal} />
      {/* <ProposalPreview
        show={showPreviewModal}
        token={temporaryBody}
        issuer={issuerProfile}
        setState={setShowPreviewModal}
      /> */}
    </div>
  );
};

export default ProposalForm;
