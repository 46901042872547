import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { loadingToggleAction } from '../../../../store/actions/AuthActions';
import { signupAction } from '../../../../store/actions/RegisterActions';

import { I18n, Translator } from '@components';
import { useTranslation } from 'react-i18next';

// image
import { loginBg, logoCriptech } from '@assets';
import { cpfMask, moneyMask, phoneMask } from '@utils';
import { validadeCpf } from '@utils/masks';

function Register(props) {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [cpf, setCpf] = useState('');
  const [profissao, setProfissao] = useState('');
  const [renda, setRenda] = useState('');
  const [income, setIncome] = useState('');
  const [data, setData] = useState('');
  const [phone, setPhone] = useState('');
  //const [refeer, setRefeer] = useState('');
  const [documentImageFiles, setDocumentImageFiles] = useState([]);
  const [documentImageName, setDocumentImageName] = useState([]);
  const [selfieImageFile, setSelfieImageFile] = useState();
  const [selfieImageName, setSelfieImageName] = useState([]);
  const [check, setCheck] = useState(false);
  const [loading, setLoading] = useState(false);
  const maxLength = 15;

  let errorsObj = {
    name: '',
    email: '',
    cpf: '',
    profissao: '',
    renda: '',
    data: '',
    check: '',
    phone: '',
    document: '',
    selfie: '',
  };
  const [errors, setErrors] = useState(errorsObj);
  const [registerError, setRegisterError] = useState(false);
  const [dateType, setDateType] = useState('text');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const startLoading = () => {
    setLoading(!loading);
  };

  function onSignUp(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };

    if (name === '') {
      errorObj.name = t('register.errors.name').toString();
      error = true;
    }
    if (email === '') {
      errorObj.email = t('register.errors.email').toString();
      error = true;
    }

    if (profissao === '') {
      errorObj.profissao = t('register.errors.occupation').toString();
      error = true;
    }

    if (renda === '') {
      errorObj.renda = t('register.errors.wage').toString();
      error = true;
    }

    if (cpf === '') {
      errorObj.cpf = t('register.errors.cpf').toString();
      error = true;
    }
    if (phone === '') {
      errorObj.phone = t('register.errors.phone').toString();
      error = true;
    }
    if (data === '') {
      errorObj.data = t('register.errors.birth').toString();
      error = true;
    }
    if (documentImageFiles.length === 0) {
      errorObj.document = t('register.errors.document').toString();
      error = true;
    }
    if (!selfieImageFile) {
      errorObj.selfie = t('register.errors.selfie').toString();
      error = true;
    }
    const valDate = new Date('jan 1 2005');
    const myDate = new Date(data);
    if (myDate.getFullYear() >= valDate.getFullYear()) {
      errorObj.data = t('register.errors.minor').toString();
      error = true;
    }

    if (check === false) {
      errorObj.check = t('register.errors.check').toString();
      error = true;
    }

    if (!validadeCpf(cpf)) {
      errorObj.cpf = t('register.errors.validId').toString();
      error = true;
    }

    if (!isPhone(phone)) {
      errorObj.phone = t('register.errors.validPhone').toString();
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      setLoading(false);
      setRegisterError(true);
      return;
    }
    startLoading();

    const body = {
      name: name,
      email: email,
      occupation: profissao,
      monthlyIncome: income,
      phone: phone,
      cpf: cpf.replaceAll('.', '').replace('-', ''),
      birthDate: data,
      //recommendationUserId: refeer,
      documentImageFile: documentImageFiles,
      selfieWithDocumentFile: selfieImageFile,
    };

    const formData = new FormData();
    Object.keys(body).forEach((key) => {
      if (key === 'documentImageFile') {
        documentImageFiles.forEach((doc) => {
          formData.append(key, doc);
        });
      } else {
        formData.append(key, body[key]);
      }
    });

    localStorage.setItem('email', body.email);
    dispatch(loadingToggleAction(true));
    dispatch(signupAction(formData, navigate, t, setLoading, body.email));
  }

  const handleCpf = (e) => {
    const cpfFormated = cpfMask(e.target.value);
    setCpf(cpfFormated);
  };

  const handleProfession = (e) => {
    if (/^[A-Za-zÀ-ÿ\s^´~`]*$/.test(e.target.value) || e.target.value === '') {
      setProfissao(e.target.value);
    }
  };

  const isPhone = (phone) =>
    /^\(?(\d{2})\)?[- ]?(\d{5})[- ]?(\d{4})$/.test(phone) ||
    /^\(?(\d{2})\)?[- ]?(\d{4})[- ]?(\d{4})$/.test(phone) ||
    /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(phone);

  const handleWage = (e) => {
    const wage = moneyMask(e.target.value);
    setRenda(wage);
    const value = wage.replace('R', '').replace('$', '').replaceAll('.', '').replaceAll(',', '.').replaceAll(' ', '');
    setIncome(value);
  };

  const handleDocumentFiles = (event) => {
    setDocumentImageFiles((prevState) => [...prevState, event.target.files[0]]);
    setDocumentImageName((prevState) => [...prevState, { name: event.target.files[0].name }]);
  };

  const handleSelfieFiles = (event) => {
    setSelfieImageFile(event.target.files[0]);
    setSelfieImageName([{ name: event.target.files[0].name }]);
  };

  useEffect(() => {
    const errorObj = { ...errorsObj };
    errorsObj = { name: '', email: '', cpf: '', profissao: '', renda: '', data: '', check: '', phone: '' };
    setErrors(errorObj);
    setRegisterError(false);
  }, [name, email, cpf, profissao, renda, data, check, phone]);

  return (
    <>
      <div className="page-wraper">
        <div className="browse-job login-style3">
          <div
            className="bg-img-fix"
            style={{
              background: '#fff url(' + loginBg + ')',
              height: '100vh',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'right',
              backgroundSize: 'contain',
            }}
          >
            <div className="row gx-0">
              <div className="register-form-container bg-white d-flex justify-content-center">
                <div className="register-form d-flex justify-content-center align-items-center">
                  <div className="logo-header">
                    <Link to="/login" className="logo ">
                      <img src={logoCriptech} alt="" className="width-230 mCS_img_loaded " />
                    </Link>
                  </div>
                  <nav className="nav nav-tabs border-bottom-0">
                    <div className="tab-content w-100" id="nav-tabContent">
                      <div className="tab-pane active show fade">
                        {props.errorMessage && <div className="">{props.errorMessage}</div>}
                        <form className="dz-form py-2 row" onSubmit={onSignUp}>
                          <h3 className="form-title">
                            <Translator path={'register.title'} />
                          </h3>
                          <div className="dz-separator-outer m-b5">
                            <div className="dz-separator bg-primary style-liner"></div>
                          </div>
                          <p style={{ color: '#525252', fontWight: '400', fontSize: '1.25rem' }}>
                            <Translator path={'register.body'} />
                          </p>
                          <div className="form-group mt-3">
                            <input
                              required=""
                              className="form-control"
                              placeholder={t('register.name').toString()}
                              type="text"
                              onChange={(e) => setName(e.target.value)}
                            />
                            {errors.name && <div className="text-danger fs-12">{errors.name}</div>}
                          </div>
                          <div className="form-group mt-3">
                            <input
                              required=""
                              className="form-control"
                              placeholder={t('register.email').toString()}
                              onChange={(e) => setEmail(e.target.value)}
                              type="email"
                            />
                            {errors.email && <div className="text-danger fs-12">{errors.email}</div>}
                          </div>
                          <div className="form-group mt-3">
                            <input
                              className="form-control"
                              placeholder={t('register.occupation').toString()}
                              value={profissao}
                              onChange={(e) => handleProfession(e)}
                            />
                            {errors.profissao && <div className="text-danger fs-12">{errors.profissao}</div>}
                          </div>
                          <div className="form-group mt-3">
                            <input
                              className="form-control"
                              placeholder={t('register.phone').toString()}
                              maxLength={maxLength}
                              value={phone}
                              onChange={(e) => setPhone(phoneMask(e.target.value))}
                            />
                            {errors.phone && <div className="text-danger fs-12">{errors.phone}</div>}
                          </div>

                          <div className="form-group mt-3">
                            <input
                              className="form-control"
                              placeholder={t('register.wage').toString()}
                              type="text"
                              value={renda != 'R$ 0,00' ? renda : ''}
                              onChange={(e) => handleWage(e)}
                            />
                            {errors.renda && <div className="text-danger fs-12">{errors.renda}</div>}
                          </div>
                          <div className="form-group mt-3">
                            <input
                              className="form-control"
                              onChange={(e) => handleCpf(e)}
                              value={cpf}
                              placeholder={t('register.id').toString()}
                            />
                            {errors.cpf && <div className="text-danger fs-12">{errors.cpf}</div>}
                          </div>
                          <div className="form-group mt-3">
                            <input
                              className="form-control"
                              type={`${dateType}`}
                              placeholder={t('register.birth').toString()}
                              max={'2005-02-20'}
                              onChange={(e) => setData(e.target.value)}
                              onFocus={() => setDateType('date')}
                              onBlur={() => setDateType('text')}
                            />
                            {errors.data && <div className="text-danger fs-12">{errors.data}</div>}
                          </div>
                          {/*
                          Removendo código de amigo de forma temporária.
                          <div className="form-group mt-3">
                            <input
                              className="form-control"
                              onChange={(e) => setRefeer(e.target.value)}
                              placeholder={t('register.code').toString()}
                            />
                          </div>*/}

                          <div className="form-group mt-3">
                            <p style={{ fontSize: '1rem', marginBottom: '0.5rem' }}>
                              Foto do seu documento de identidade (frente e verso):{' '}
                            </p>
                            <div className="upload-button">
                              <label htmlFor="document">Escolher arquivo</label>
                              <input
                                id="document"
                                type="file"
                                className="update-file"
                                onChange={handleDocumentFiles}
                                disabled={documentImageFiles?.length === 2}
                              />
                              {errors.document && <div className="text-danger fs-12">{errors.document}</div>}
                              {documentImageName.length > 0 &&
                                documentImageName.map((doc) => {
                                  return (
                                    <small key={doc.name}>
                                      <i className="fa fa-paperclip" style={{ paddingRight: '5px' }}></i>
                                      {doc.name}
                                    </small>
                                  );
                                })}
                            </div>
                          </div>

                          <div className="form-group mt-3">
                            <p style={{ fontSize: '1rem', marginBottom: '0.5rem' }}>
                              Selfie com o seu documento de identidade:{' '}
                            </p>
                            <div className="upload-button">
                              <label htmlFor="selfie">Escolher arquivo</label>
                              <input id="selfie" type="file" className="update-file" onChange={handleSelfieFiles} />
                              {errors.selfie && <div className="text-danger fs-12">{errors.selfie}</div>}
                              {selfieImageName.length > 0 &&
                                selfieImageName.map((doc) => {
                                  return (
                                    <small key={doc.name}>
                                      <i className="fa fa-paperclip" style={{ paddingRight: '5px' }}></i>
                                      {doc.name}
                                    </small>
                                  );
                                })}
                            </div>
                          </div>
                          <div className="mb-3 mt-3 col-12">
                            <span className="form-check float-start me-2">
                              <input
                                type="checkbox"
                                className="form-check-input mt-0 required"
                                id="check2"
                                name="example1"
                                onChange={() => setCheck(!check)}
                              />
                              <label className="label-forgot-password" htmlFor="check1">
                                <Translator path={'register.check'} />
                              </label>
                              <a href={process.env.REACT_APP_USE_TERMS} target={'_blank'} className="a-forgot-password" rel="noreferrer">
                                <Translator path={'register.terms'} />
                              </a>
                              {errors.check && <div className="text-danger fs-12">{errors.check}</div>}
                            </span>
                          </div>
                          <div className="form-group clearfix text-left">
                            <NavLink to="/registertype" className="btn btn-outlined outline gray" type="button">
                              <Translator path={'register.goback'} />
                            </NavLink>
                            {loading ? (
                              <button type="submit" className="btn btn-orange float-end disabled">
                                <Translator path={'loading'} />
                              </button>
                            ) : (
                              <button type="submit" className="btn btn-orange float-end">
                                <Translator path={'register.submit'} />
                              </button>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </nav>
                </div>
                <I18n />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(Register);
