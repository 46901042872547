export const validadeCpf = (cpf) => {
  cpf = cpf.replace(/[^\d]+/g, '');

  if (cpf === '') return false;

  if (
    cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  )
    return false;

  let add = 0;
  for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(9))) return false;

  add = 0;
  for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(10))) return false;
  return true;
};

export const validateCnpj = (value) => {
  return value.match(
    /([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})/,
  );
};

export const cpfMask = (value) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
};

export const moneyMask = (value) => {
  value = value.replace('.', '').replace(',', '').replace(/\D/g, '');

  const options = { minimumFractionDigits: 2 };
  const result = new Intl.NumberFormat('pt-BR', options).format(parseFloat(value) / 100);

  return 'R$ ' + result;
};

export const cnpjMask = (v) => {
  return v
    .replace(/\D/g, '')
    .replace(/^(\d{2})(\d)/, '$1.$2')
    .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
    .replace(/\.(\d{3})(\d)/, '.$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2');
};

export const phoneMask = (value) => {
  if (!value) return '';
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d)(\d{4})$/, '$1-$2');
};

export const cepMask = (value) => {
  return value.replace(/\D/g, '').replace(/^(\d{5})(\d)/, '$1-$2');
};

export const dateMask = (inputDate) => {
  const dateParts = inputDate.split('-');
  if (dateParts.length !== 3) {
    return inputDate;
  }
  const [year, month, day] = dateParts;
  return `${day}/${month}/${year}`;
}

export const socialContractMask = (full) => {

  const lastIndex = full.lastIndexOf('/');
  const name = full.substring(lastIndex + 1);
  return name.slice(36);

}

export const setNumberOnly = (value, setState) => {
  if (/^\d*$/.test(value)) {
    setState(value);
  }
};

export const handleShowMoneyLabels = (setState, setLabel, value) => {
  const wage = moneyMask(value);
  setLabel(wage);
  const formatedValue = wage
    .replace('R', '')
    .replace('$', '')
    .replaceAll('.', '')
    .replaceAll(',', '.')
    .replaceAll(' ', '');
  setState(formatedValue);
};
