import { Translator } from '@components';

export const COLUMNS = [
  {
    Header: <Translator path={'pages.depositList.tableColumns.order'} />,
    Footer: <Translator path={'pages.depositList.tableColumns.order'} />,
    accessor: 'depositCode',
    isSortable: true,
  },
  {
    Header: <Translator path={'pages.depositList.tableColumns.name'} />,
    Footer: <Translator path={'pages.depositList.tableColumns.name '} />,
    accessor: 'tokenName',
    isSortable: true,
  },
  // { TODO: DESCOMENTAR QUANDO FOR ADICIONADO O CODIGO DO TOKEN NO ENDPOINT DE LISTAGEM DE DEPOSITOS
  //   Header: <Translator path={'pages.depositList.tableColumns.tCode'} />,
  //   Footer: <Translator path={'pages.depositList.tableColumns.tCode'} />,
  //   accessor: 'tokenCode',
  //   isSortable: true,
  // },
  {
    Header: <Translator path={'pages.depositList.tableColumns.amount'} />,
    Footer: <Translator path={'pages.depositList.tableColumns.amount '} />,
    accessor: 'value',
    isSortable: true,
  },
  {
    Header: <Translator path={'pages.depositList.tableColumns.pDate'} />,
    Footer: <Translator path={'pages.depositList.tableColumns.pDate'} />,
    accessor: 'date',
    isSortable: false,
  },
  {
    Header: <Translator path={'pages.depositList.tableColumns.date'} />,
    Footer: <Translator path={'pages.depositList.tableColumns.date'} />,
    accessor: 'depositDate',
    isSortable: false,
  },
  {
    Header: <Translator path={'pages.depositList.tableColumns.status'} />,
    Footer: <Translator path={'pages.depositList.tableColumns.status'} />,
    accessor: 'status',
    isSortable: false,
  },
  {
    Header: <Translator path={'pages.depositList.tableColumns.action'} />,
    Footer: <Translator path={'pages.depositList.tableColumns.action'} />,
    accessor: 'action',
    isSortable: false,
  },
];
