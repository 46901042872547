import { formatDate } from 'date-utils-2020';

const SimpleCard = ({ icon, info, infoType, title }) => {
  return (
    <div className="row col-xxl-3 col-lg-6 col-md-6 col-sm-6 col-12 " style={{ margin: 0, padding: '0.3125rem' }}>
      <div className="simple-card card align-items-center justify-content-center">
        <div className="icon">
          <i className="material-icons">{icon}</i>
        </div>
        <div className="info" style={{ overflow: 'hidden' }}>
          {infoType === 'date' ? (
            <p>{formatDate(info, 'dd/MM/yyyy')}</p>
          ) : infoType === 'currency' ? (
            info > 0 ? (
              <p style={{ whiteSpace: 'normal' }}>
                {info?.toLocaleString('pt-BT', { style: 'currency', currency: 'BRL' })}
              </p>
            ) : (
              <p style={{ whiteSpace: 'nowrap' }}>{'R$ 0,00'}</p>
            )
          ) : info > 0 ? (
            <p>{info}</p>
          ) : (
            <p>{'0'}</p>
          )}
        </div>
        <div className="title">
          <p>{title}</p>
        </div>
      </div>
    </div>
  );
};

export default SimpleCard;
