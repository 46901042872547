import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Translator } from '@components';
import { moneyMask } from '@utils';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const FilterModal = ({ props: { show, setShow, setFilters, filters } }) => {
  const [order, setOrder] = useState();
  const [amount, setAmount] = useState();
  const [date, setDate] = useState();
  const [finalDate, setFinalDate] = useState();
  const { t } = useTranslation();
  const [renda, setRenda] = useState('');

  const onHideModal = () => {
    setShow(false);
  };

  const handleWage = (e) => {
    const wage = moneyMask(e.target.value);
    setRenda(wage);
    const value = wage.replace('R', '').replace('$', '').replaceAll('.', '').replaceAll(',', '.').replaceAll(' ', '');
    setAmount(value);
  };

  const clearFilters = () => {
    setFilters({});
    setShow(false);
  };

  const handleFilters = () => {
    const filters = {
      initialDate: date ? new Date(date).toISOString().split(/[A-Z]/)[0] : undefined,
      finalDate: finalDate ? new Date(finalDate).toISOString().split(/[A-Z]/)[0] : undefined,
      depositCode: order ? order : undefined,
      depositValue: amount ? amount : undefined,
    };
    setFilters(filters);
    setShow(false);
  };

  return (
    <Modal show={show} aria-labelledby="contained-modal-title-vcenter" size="lg" centered>
      <Modal.Body style={{ padding: 20 }} className="row d-flex flex-column justify-content-center align-items-center">
        <div className="row">
          <div
            className="col-12 d-flex justify-content-between align-items-center flex-row "
            style={{ marginBottom: '1.25rem' }}
          >
            <h4 className="col-3 modal-title-deposit"> Filtros</h4>
            <i className="material-icons moda-close-icon cursor-pointer" onClick={onHideModal}>
              close
            </i>
          </div>
          <div className="col-12" style={{ borderBottom: '1px solid #EDEDED' }} />
          <div
            className="col-12 d-flex justify-content-center align-items-center"
            style={{ gap: '1.25rem', margin: '2.5rem 0' }}
          >
            <div className="col-6">
              <input
                type="text"
                className="form-control"
                style={{ marginBottom: '1.25rem' }}
                defaultValue={filters ? filters && filters.depositCode : ''}
                placeholder={t('depositPage.numberHolder').toString()}
                onChange={(e) => setOrder(e.target.value)}
              />
              <div className="d-flex align-items-center justify-content-end ">
                <i className="material-icons icon-float-no-margin" style={{ margin: '0 15px 10px 0', zIndex: 1 }}>
                  today
                </i>
                <DatePicker
                  className="form-control"
                  selected={date ? new Date(date) : filters && filters.initialDate ? new Date(filters.initialDate) : ''}
                  dateFormat="dd/MM/yyyy"
                  placeholderText={date ? '' : t('depositPage.dateHolder').toString()}
                  onChange={(date) => setDate(date)}
                />
              </div>
            </div>
            <div className="col-6">
              <input
                type="text"
                className="form-control placeholder-grey"
                style={{ marginBottom: '1.25rem' }}
                placeholder={t('depositPage.valHolder').toString()}
                value={renda ? renda : filters && filters.depositValue ? moneyMask(filters.depositValue) : ''}
                onChange={(e) => handleWage(e)}
              />
              <div className="d-flex align-items-center justify-content-end ">
                <i className="material-icons icon-float-no-margin" style={{ margin: '0 15px 10px 0', zIndex: 1 }}>
                  today
                </i>
                <DatePicker
                  className="form-control"
                  selected={
                    finalDate ? new Date(finalDate) : filters && filters.finalDate ? new Date(filters.finalDate) : ''
                  }
                  dateFormat="dd/MM/yyyy"
                  placeholderText={finalDate ? '' : t('depositPage.fDateHolder').toString()}
                  onChange={(date) => setFinalDate(date)}
                />
              </div>
            </div>
          </div>
          <div className="col-12" style={{ borderBottom: '1px solid #EDEDED' }} />
          <div className="col-12 d-flex justify-content-end" style={{ gap: '1.25rem', marginTop: '1.25rem' }}>
            <button className="btn btn-outlined col-4" onClick={() => clearFilters()}>
              <Translator path="depositPage.cleanBtn" />
            </button>
            <button className="btn btn-orange col-3" onClick={() => handleFilters()}>
              <Translator path="depositPage.filter" />
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default FilterModal;
