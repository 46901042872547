import React, { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useProfile, useWithdraw } from '@contexts-investor';
import { Loading } from '@components';

import ErrorModal from './ErrorModal';
import FinalStep from './FinalStep';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';

const Withdraw = () => {
  const { profile } = useProfile();
  const { validateWithdraw, loading, success, withdrawValue, sendEmailToken } = useWithdraw();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [code, setCode] = useState();

  const [currentStep, setCurrentStep] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [modalError, setModalError] = useState();

  const handleFirstStep = async (code) => {
    setCode(code);
    const user = localStorage.getItem('userId');
    await validateWithdraw(code, user);
    setCurrentStep(2);
  };

  const handleSecondStep = async (info) => {
    const body = {
      ...info,
      withdrawToken: code,
    };
    await withdrawValue(body);
    setCurrentStep(3);
  };

  useEffect(() => {
    if (profile) sendEmailToken();
  }, [pathname]);

  useEffect(() => {
    if (success === false) {
      setShowModal(true);
      const error =
        currentStep === 1
          ? t('withdrawPage.firstStep.error').toString()
          : t('withdrawPage.secondStep.error').toString();

      setModalError(error);
    }
  }, [success]);

  if (loading || !profile) {
    return <Loading />;
  }

  if (profile)
    return (
      <>
        <div className="page-wrapper d-flex">
          <div className="row col-12 justify-content-center align-items-center modifier-withdraw ">
            <div className="row d-flex flex-column align-items-center content-formater-withdraw">
              {currentStep === 1 ? (
                <FirstStep props={{ email: profile?.legalUser ? profile.legalUser.loginLegalUser.email : profile.physicalUser.email, handleStep: handleFirstStep }} />
              ) : currentStep === 2 ? (
                <SecondStep props={{ handleStep: handleSecondStep }} />
              ) : currentStep === 3 ? (
                <FinalStep />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <ErrorModal props={{ show: showModal, error: modalError }} />
      </>
    );
};

export default Withdraw;
