import React, { useContext } from 'react';
import { connect } from 'react-redux';

/// React router dom
import { Routes, Route, Outlet, useNavigate, Navigate } from 'react-router-dom';
import { isAuthenticated } from '../../../store/selectors/AuthSelectors';

/// Css
import '../../css/index.css';
import '../../css/chart.css';
import '../../css/step.css';

/// Layout
import Nav from '../../layouts/nav';
import ScrollToTop from '../../layouts/ScrollToTop';
import { ThemeContext } from '@contexts/ThemeContext';

import { Footer, ProposalFormEdit } from '@components';
import {
  Dashboard,
  NewProposal,
  MyCorporation,
  ProposalList,
  TokensList,
  MyBank,
  WithdrawList,
  Deposit,
  Wallet,
  AlterPassword,
  Profile,
} from '@pages-issuer';

import LogoutPage from '../../layouts/nav/Logout';

const Markup = (props) => {
  const navigate = useNavigate();
  const allroutes = [
    { url: '/dashboard', Component: Dashboard },
    { url: '/tokenProposal', Component: NewProposal },
    { url: '/tokenProposal/list', Component: ProposalList },
    { url: '/tokenProposal/edit/:proposalId', Component: ProposalFormEdit },
    { url: '/tokenProposal/tokens/list', Component: TokensList },
    { url: '/tokenProposal/counterProposal/:proposalId', Component: ProposalFormEdit },
    { url: '/wallet', Component: Wallet },
    { url: '/wallet/depositList', Component: Deposit },
    { url: '/wallet/withdrawList', Component: WithdrawList },
    { url: '/mycorporation', Component: MyCorporation },
    { url: '/mybank', Component: MyBank },
    { url: '/profile', Component: Profile },
    { url: '/profile/password', Component: AlterPassword },
    { url: '/logout', Component: LogoutPage },
  ];

  //let path = window.location.pathname;
  //path = path.split("/");
  //path = path[path.length - 1];

  //let pagePath = path.split("-").includes("page");
  //const { menuToggle } = useContext(ThemeContext);
  const token = localStorage.getItem('token');
  const path = window.location.pathname;
  const noAuthPaths = ['/publictokens', '/publictokenslite', '/register', '/register/email', '/register/password', '/reset', '/login'];

  return (
    <>
      <Routes>
        <Route element={<MainLayout />}>
          {allroutes.map(({ Component, url }, i) => {
            return <Route key={i} exact path={url} element={<Component />} />;
          })}
          <Route
            path="*"
            element={
              token ? (
                path.includes('/dashboard') || path.includes('/investment') ? (
                  ''
                ) : (
                  <Navigate to="/dashboard" />
                )
              ) : !noAuthPaths.includes(path) ? (
                <Navigate to="/login" />
              ) : (
                ''
              )
            }
          />
        </Route>
      </Routes>
      <ScrollToTop />
    </>
  );
};

function MainLayout() {
  const { menuToggle } = useContext(ThemeContext);
  return (
    <div style={{ width: '100%', height: '100%', position: 'absolute', }}>
      <div id="main-wrapper" className={`show ${menuToggle ? 'menu-toggle' : ''}`}>
        <Nav />
        <div className="content-body" style={{ minHeight: window.screen.height - 45 }}>
          <div className="container-fluid">
            <>
              <Outlet />
            </>
          </div>
          <Footer />
        </div>
      </div>
    </div >
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default connect(mapStateToProps)(Markup);
