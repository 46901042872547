import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useProfile } from '@contexts-investor';
import { noPreview } from '@assets';
import { Buffer } from 'buffer';
import { Translator, AccordionCard, Loading } from '@components';

import { accordionData } from './accordionData';
import { useNavigate } from 'react-router-dom';
const Security = () => {
  const [imageURL, setImageURL] = useState();
  const [preview, setPreview] = useState();
  const { t } = useTranslation();
  const { profile, loadingProfile, loadProfile, imageLoading, downloadImageInvestor } = useProfile();
  const navigate = useNavigate();

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    if (userId !== (null && undefined)) loadProfile(userId);
  }, [localStorage.getItem('userId')]);

  useEffect(() => {
    const downloadData = async (imageURL) => {
      const base64Image = await downloadImageInvestor(imageURL.replaceAll('/', '%2F'));
      const downloadedImage = Buffer.from(base64Image.data, 'binary').toString('base64');
      setPreview(downloadedImage);
    };

    if (profile && profile?.physicalUser?.image) {
      downloadData(profile?.physicalUser?.image)
    }
    if (profile && profile?.legalUser?.image) {
      downloadData(profile?.legalUser?.image)
    }
  }, [profile]);

  if (loadingProfile) return <Loading />;

  if (profile)
    return (
      <div className="page-wrapper">
        <div className="row row justify-content-between">
          <div
            className="row col-xl-4 col-md-12 col-sm-12 d-flex align-items-center justify-content-center profile-box"
            style={{ height: '21rem', marginBottom: '2.5rem' }}
          >
            <div className="author-profile">
              <div className="author-media">
                {imageLoading ? (
                  <>
                    <div
                      className="loading-spinner"
                      style={{
                        width: '140px',
                        height: '140px',
                        position: 'absolute',
                      }}
                    />
                    <img src={noPreview} alt="" style={{ width: '140px', height: '140px', margin: '0 !important' }} />
                  </>
                ) : (
                  <img
                    src={preview ? `data:image/jpeg;base64,${preview}` : noPreview}
                    alt=""
                    style={{ width: '140px', height: '140px' }}
                  />
                )}
              </div>
              <div className="author-info">
                <h6 className="title">{profile.physicalUser ? profile.physicalUser.name : profile.legalUser.corporateName}</h6>
              </div>
            </div>
          </div>
          <div className="row col-xl-7 col-md-12 col-sm-12 col-xs-12 col-12 security-box">
            <div
              className="row col-xl-12 col-lg-12 col-sm-12 col-xs-12 d-flex align-items-center justify-content-start security-header-border"
              style={{ height: '10vh' }}
            >
              <div
                className={`col-xl-3 col-lg-3 col-sm-3 d-flex col-6 align-items-center justify-content-center security-highlight`}
                style={{ height: '100%' }}
              >
                <p className="security-menu-headers">
                  <Translator path="profilePage.data" />
                </p>
              </div>
              <div className="row col-12 security-row-modifier" style={{ gap: '1.25rem' }}>
                <div className="row col-sm-6">
                  <label className="col-12 security-data-labels">
                    <Translator path="securityPage.email" />
                  </label>
                  <div className="row col-12" style={{ padding: 0, margin: 0 }}>
                    <input className="col-12 security-data-input-disabled" readOnly value={profile.physicalUser ? profile.physicalUser.email : profile.legalUser.loginLegalUser.email} />
                  </div>
                </div>
                <div className="row col-sm-6">
                  <label className="col-12 security-data-labels">
                    <Translator path="securityPage.pass" />
                  </label>
                  <div className="row col-12" style={{ padding: 0, margin: 0 }}>
                    <input className="col-12 security-data-input-disabled" readOnly value="********" />
                  </div>
                </div>
                <button
                  className="btn btn-orange col-4"
                  style={{ whiteSpace: 'nowrap', marginTop: '1.25rem', textAlign: 'center' }}
                  onClick={() => {
                    navigate('/security/edit');
                  }}
                >
                  <Translator path="securityPage.title" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <h3 className="security-hint-header">
          <Translator path="securityPage.hint" />
        </h3>
        <div className="row security-hints-body">
          {Array.from(accordionData).map((item, index) => {
            return (
              <div className="row col-xl-6 col-lg-6 col-md12 col-sm-12 col-xs-12">
                <AccordionCard props={{ info: item, index: index }} />
              </div>
            );
          })}
        </div>
      </div>
    );
};

export default Security;
