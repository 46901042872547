import { Buffer } from 'buffer';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';

import 'react-datepicker/dist/react-datepicker.css';

import { noPreview } from '@assets';
import { InputText, PopOver, Translator } from '@components';
import { useIssuer } from '@contexts-issuer';
import { cepMask, moneyMask, phoneMask } from '@utils';

const RegisterForm = ({ props: { handleRegister, isEdit = false } }) => {
  const {
    issuerProfile,
    uploadImage,
    imageURL,
    downloadImage,
    imageBuffer,
    getIssuerProfile,
    uploadAdditionalDocs,
    additionalDocsId,
    uploadSocialContract,
    socialContractId,
    downloadSocialContract,
    mySocialContract,
    downloadAdditionalDocs,
    imageLoading,
    loadingButton,
    loadingDocs,
  } = useIssuer();

  const [socialReason, setSocialReason] = useState();
  const [corporateName, setCorporateName] = useState();
  const [cep, setCep] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [foundationDate, setFoundationDate] = useState();
  const [simpleDescription, setSimpleDescription] = useState();
  const [detailedDescription, setDetailedDescription] = useState();
  const [shareCapital, setShareCapital] = useState();
  const [showShareCapital, setShowShareCapital] = useState();
  const [website, setWebsite] = useState();
  const [businessLine, setBusinessLine] = useState();
  const [image, setImage] = useState();
  const [preview, setPreview] = useState();
  const [documentArray, setDocumentArray] = useState([{ link: '', description: '' }]);
  const [tempLink, setTempLink] = useState([{ link: '', index: '', name: '' }]);
  const [isEditing, setIsEditing] = useState(false);
  const [fileName, setFileName] = useState();
  const [showLabelControl, setShowLabelControl] = useState(false);
  const [showAdditionalDocs, setShowAddtionalDocs] = useState([{ show: '' }]);

  let errorsObj = {
    link: '',
    desc: '',
    image: '',
    socialReason: '',
    corporateName: '',
    cep: '',
    email: '',
    phone: '',
    foundationDate: '',
    simpleDescription: '',
    detailedDescription: '',
    socialContractId: '',
    shareCapital: '',
    website: '',
    documentArray: '',
    shareCapital: '',
    businessLine: '',
  };
  const [errors, setErrors] = useState(errorsObj);
  const { t } = useTranslation();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    uploadSocialContract(file);
    setFileName(file.name);
  };

  const setCapitalwithMask = (value) => {
    const capital = moneyMask(value);
    setShowShareCapital(capital);
    const formatedValue = capital
      .replace('R', '')
      .replace('$', '')
      .replaceAll('.', '')
      .replaceAll(',', '.')
      .replaceAll(' ', '');
    setShareCapital(formatedValue);
  };

  function setCepwithMask(value) {
    setCep(value.replace(/\D/g, '').replace(/^(\d{5})(\d)/, '$1-$2'));
  }

  const setPhonewithMask = (value) => {
    setPhone(phoneMask(value));
  };

  const finishRegister = () => {
    if (!isEdit) {
      let error = false;
      const errorObj = { ...errorsObj };
      if (!imageURL) {
        errorObj.image = t('pages.issuer.validators.logo').toString();
        error = true;
      }
      if (!corporateName) {
        errorObj.corporateName = t('pages.issuer.validators.corporateName').toString();
        error = true;
      }
      if (!cep) {
        errorObj.cep = t('pages.issuer.validators.cep').toString();
        error = true;
      }
      if (!socialReason) {
        errorObj.socialReason = t('pages.issuer.validators.social').toString();
        error = true;
      }
      if (!email) {
        errorObj.email = t('pages.issuer.validators.corporateEmail').toString();
        error = true;
      }
      if (!phone) {
        errorObj.phone = t('pages.issuer.validators.phone').toString();
        error = true;
      }
      if (!foundationDate) {
        errorObj.foundationDate = t('pages.issuer.validators.foundingDate').toString();
        error = true;
      }
      if (!simpleDescription) {
        errorObj.simpleDescription = t('pages.issuer.validators.simpleDescription').toString();
        error = true;
      }
      if (!detailedDescription) {
        errorObj.detailedDescription = t('pages.issuer.validators.description').toString();
        error = true;
      }
      if (!socialContractId) {
        errorObj.socialContractId = t('pages.issuer.validators.contract').toString();
        error = true;
      }
      if (!shareCapital) {
        errorObj.shareCapital = t('pages.issuer.validators.capital').toString();
        error = true;
      }
      if (!businessLine) {
        errorObj.businessLine = t('pages.issuer.validators.area').toString();
        error = true;
      }
      if (error) {
        setErrors(errorObj);
        return;
      }
    }
    if (issuerProfile) {
      const body = {
        image: imageURL ? imageURL : issuerProfile.image,
        corporateName: corporateName ? corporateName : issuerProfile.corporateName,
        cep: cep ? cep : issuerProfile.cep,
        name: socialReason ? socialReason : issuerProfile.socialReason,
        email: issuerProfile.email ? issuerProfile.email : email,
        phone: phone ? phone : issuerProfile.phone,
        foundationDate: foundationDate
          ? new Date(foundationDate).toISOString().split(/[A-Z]/)[0]
          : issuerProfile.foundationDate,
        simpleDescription: simpleDescription ? simpleDescription : issuerProfile.simpleDescription,
        detailedDescription: detailedDescription ? detailedDescription : issuerProfile.detailedDescription,
        socialContract: {
          link: socialContractId ? socialContractId : issuerProfile.socialContract.link,
          socialContractId: issuerProfile.socialContractId ? issuerProfile.socialContractId : undefined,
        },
        shareCapital: shareCapital ? +shareCapital : issuerProfile.shareCapital,
        website: website ? website : issuerProfile.website,
        businessLine: businessLine ? businessLine : issuerProfile.businessLine,
        additionalDocs: [...documentArray],
      };
      handleRegister(body);
    }
  };

  const removeElement = (index) => {
    if (isEdit && !isEditing) {
      return;
    }

    if (tempLink.length === 1 || documentArray.length === 1) {
      const errorObj = { ...errorsObj };
      errorObj.desc = t('pages.issuer.err.noRemove').toString();
      setErrors(errorObj);
    } else if (
      index > issuerProfile.additionalDocs.length ||
      Array.isArray(issuerProfile.additionalDocs).length === undefined
    ) {
      let data = [...documentArray];
      data.splice(index, 1);
      setDocumentArray(data);
      data = [...tempLink];
      data.splice(index, 1);
      setTempLink(data);
    } else {
      const errorObj = { ...errorsObj };
      errorObj.desc = t('pages.issuer.err.original').toString();
      setErrors(errorObj);
    }
  };

  const addElement = () => {
    if (documentArray[documentArray.length - 1].link === '') {
      const errorObj = { ...errorsObj };
      errorObj.link = t('pages.issuer.err.noLink').toString();
      setErrors(errorObj);
    } else if (documentArray[documentArray.length - 1].description === '') {
      const errorObj = { ...errorsObj };
      errorObj.desc = t('pages.issuer.err.noLink').toString();
      setErrors(errorObj);
    } else {
      setDocumentArray((documentArray) => [...documentArray, { link: '', description: '' }]);
      setTempLink((tempLink) => [...tempLink, { link: '', index: '' }]);
      showAdditionalDocs((showAdd) => [...showAdd, { show: false }]);
    }
  };

  const showDocuments = (index) => {
    let showDocs = [...showAdditionalDocs];
    showDocs[index].show = true;
    setShowAddtionalDocs(showDocs);
  };

  const handleLinkAddition = (index, e) => {
    let data = [...tempLink];
    data[index][e.target.name] = e.target.files[0];
    data[index].index = index;
    data[index].name = e.target.files[0].name;
    setTempLink(data);

    const errorObj = { ...errorsObj };
    errorsObj = { link: '' };
    setErrors(errorObj);
  };

  const handleDescAddition = (index, e) => {
    let data = [...documentArray];
    data[index][e.target.name] = e.target.value;
    setDocumentArray(data);

    const errorObj = { ...errorsObj };
    errorsObj = { desc: '' };
    setErrors(errorObj);
  };

  const handleSocialDownload = () => {
    const buffered = Buffer.from(mySocialContract.data, 'binary');
    const blob = new Blob([buffered], { type: 'application/pdf' });
    const file = new File([blob], 'socialContract.pdf');
    const url = URL.createObjectURL(file);

    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'socialContract.pdf');

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    URL.revokeObjectURL(url);
  };

  const handleAdditionalDownload = async (index) => {
    if (isEdit) {
      const additionalDoc = await downloadAdditionalDocs(documentArray[index].link);
      const buffered = Buffer.from(additionalDoc.data, 'binary');
      const blob = new Blob([buffered], { type: 'application/pdf' });
      const file = new File([blob], `additional document ${index}.pdf`);
      const url = URL.createObjectURL(file);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', `additional document ${index}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }
  };

  useEffect(() => {
    if (image) uploadImage(image);
  }, [image]);

  useEffect(() => {
    if (issuerProfile.image) downloadImage(issuerProfile.image.replaceAll('/', '%2F'));
    else if (imageURL) downloadImage(imageURL.replaceAll('/', '%2F'));
  }, [issuerProfile.image, imageURL]);

  useEffect(() => {
    if (imageURL) downloadImage(imageURL.replaceAll('/', '%2F'));
  }, [imageURL]);

  useEffect(() => {
    if (imageBuffer) {
      const image = Buffer.from(imageBuffer.data, 'binary').toString('base64');
      setPreview(image);
    }
  }, [imageBuffer]);

  useEffect(() => {
    if (issuerProfile && issuerProfile.socialContract && issuerProfile.socialContract.link) {
      downloadSocialContract(issuerProfile.socialContract.link.replaceAll('/', '%2F'));
    }
  }, [issuerProfile, socialContractId]);

  useEffect(() => {
    getIssuerProfile(issuerProfile.issuerId);
    if (isEdit) {
      if (issuerProfile.additionalDocs.length === 0) setDocumentArray([{ link: '', description: '', name: '' }]);
      else {
        setDocumentArray(issuerProfile.additionalDocs);
        const mappedShow = issuerProfile.additionalDocs.map((doc) => {
          return {
            ...doc,
            show: true,
          };
        });
        setShowAddtionalDocs(mappedShow);
      }
    }
  }, []);

  useEffect(() => {
    if (tempLink[tempLink.length - 1].link) {
      uploadAdditionalDocs(tempLink[tempLink.length - 1].link);
    }
  }, [tempLink]);

  useEffect(() => {
    if (additionalDocsId) {
      let data = [...documentArray];
      data[data.length - 1].link = additionalDocsId;
      setDocumentArray(data);
    }
  }, [additionalDocsId]);

  useEffect(() => {
    const errorObj = { ...errorsObj };
    errorsObj = {
      image: '',
      socialReason: '',
      corporateName: '',
      cep: '',
      email: '',
      phone: '',
      foundationDate: '',
      simpleDescription: '',
      detailedDescription: '',
      socialContractId: '',
      shareCapital: '',
      website: '',
      documentArray: '',
      businessLine: '',
    };
    setErrors(errorObj);
  }, [
    image,
    socialReason,
    corporateName,
    cep,
    email,
    phone,
    foundationDate,
    simpleDescription,
    detailedDescription,
    socialContractId,
    shareCapital,
    website,
    documentArray,
    shareCapital,
    businessLine,
  ]);

  return (
    <>
      <div className={'col-12 d-flex align-items-center justify-content-start'}>
        <div className={`author-profile d-flex align-items-center flex-control-issuer`}>
          <div className="author-media" style={{ margin: 0 }}>
            {imageLoading ? (
              <>
                <div
                  className="loading-spinner"
                  style={{ width: '90px', height: '90px', position: 'absolute', margin: '2.5rem 0 1.25rem 2.5rem' }}
                />
                <img src={noPreview} alt="" style={{ width: '90px', height: '90px', margin: '0 !important' }} />
              </>
            ) : (
              <>
                <img
                  src={preview ? `data:image/jpeg;base64,${preview}` : noPreview}
                  alt=""
                  style={{ width: '90px', height: '90px' }}
                />

                {!isEdit || isEditing ? (
                  <div
                    className="upload-link"
                    title=""
                    data-toggle="tooltip"
                    data-placement="right"
                    data-original-title="update"
                    style={{ backgroundColor: '#FF9900' }}
                  >
                    <input
                      type="file"
                      className="update-file"
                      style={{ backgroundColor: '#FF9900' }}
                      onChange={(e) => setImage(e.target.files[0])}
                      readOnly={isEdit ? (isEditing ? false : true) : false}
                    />
                    <i className="fa fa-camera"></i>
                  </div>
                ) : (
                  ''
                )}
              </>
            )}
          </div>
          <div className="d-flex flex-row text-input-container">
            <p className="corporate-logo-text">
              <Translator path="pages.issuer.logo" />
            </p>
            <p>*</p>
          </div>
        </div>
      </div>
      <div className="row col-12 " style={{ padding: 0, margin: '0 0 1.25rem 0' }}>
        <div className="col-md-6 paddingLeft-control-issuer">
          {errors.image && <div className="text-danger fs-12">{errors.image}</div>}
        </div>
      </div>
      <div className="row col-12 " style={{ padding: 0, margin: '0 0 1.25rem 0' }}>
        <div className="col-md-6 paddingLeft-control-issuer">
          <InputText
            className="issuer-input-disabled"
            text={'pages.issuer.cnpj'}
            readOnly={true}
            defaultValue={issuerProfile ? issuerProfile?.cnpj : ''}
          />
        </div>
        <div className="col-md-6 paddingRight-control-issuer">
          <InputText
            className={`${isEdit ? (isEditing ? 'form-control' : 'issuer-input-disabled') : 'form-control'}`}
            text={'pages.issuer.social'}
            setState={setSocialReason}
            value={socialReason}
            readOnly={isEdit ? (isEditing ? false : true) : false}
            defaultValue={isEdit ? issuerProfile.name : ' '}
          />
          {errors.socialReason && <div className="text-danger fs-12">{errors.socialReason}</div>}
        </div>
      </div>
      <div className="row col-12 " style={{ padding: 0, margin: '0 0 1.25rem 0' }}>
        <div className="col-md-6 paddingLeft-control-issuer">
          <InputText
            className={`${isEdit ? (isEditing ? 'form-control' : 'issuer-input-disabled') : 'form-control'}`}
            text={'pages.issuer.corporateName'}
            setState={setCorporateName}
            readOnly={isEdit ? (isEditing ? false : true) : false}
            defaultValue={isEdit ? issuerProfile.corporateName : ''}
            value={corporateName}
          />
          {errors.corporateName && <div className="text-danger fs-12">{errors.corporateName}</div>}
        </div>
        <div className="col-md-6 paddingRight-control-issuer">
          <InputText
            className={`${isEdit ? (isEditing ? 'form-control' : 'issuer-input-disabled') : 'form-control'}`}
            text={'pages.issuer.cep'}
            setState={setCepwithMask}
            maxLength={9}
            readOnly={isEdit ? (isEditing ? false : true) : false}
            defaultValue={isEdit ? cepMask(issuerProfile.cep) : ''}
            value={cep}
          />
          {errors.cep && <div className="text-danger fs-12">{errors.cep}</div>}
        </div>
      </div>
      <div className="row col-12 " style={{ padding: 0, margin: '0 0 1.25rem 0' }}>
        <div className="col-md-6 col-xl-4 paddingLeft-control-issuer">
          <InputText
            className={`${isEdit ? (isEditing ? 'form-control' : 'issuer-input-disabled') : 'form-control'}`}
            text={'pages.issuer.corporateEmail'}
            type="email"
            setState={setEmail}
            readOnly={isEdit ? (isEditing ? false : true) : false}
            defaultValue={isEdit ? issuerProfile.email : ''}
            value={email}
          />
          {errors.email && <div className="text-danger fs-12">{errors.email}</div>}
        </div>
        <div className="col-md-6 col-xl-4 paddingControl-issuer phone-padding">
          <InputText
            className={`${isEdit ? (isEditing ? 'form-control' : 'issuer-input-disabled') : 'form-control'}`}
            text={'pages.issuer.phone'}
            setState={setPhonewithMask}
            maxLength={15}
            readOnly={isEdit ? (isEditing ? false : true) : false}
            defaultValue={isEdit ? phoneMask(issuerProfile.phone) : ''}
            value={phone}
          />
          {errors.phone && <div className="text-danger fs-12">{errors.phone}</div>}
        </div>
        <div className="col-md-12 col-xl-4 paddingRight-control-issuer date-padding">
          <div className="col-12 d-flex flex-column justify-content-start no-picker" style={{ padding: 0 }}>
            <div className="d-flex flex-row text-input-container">
              <p className="text-input-label">
                <Translator path="pages.issuer.foundingDate" />
              </p>
              <p>*</p>
            </div>

            <i className="material-icons icon-float-right">today</i>
            <DatePicker
              className={`${isEdit ? (isEditing ? 'form-control' : 'issuer-input-disabled') : 'form-control'}`}
              selected={isEdit ? new Date(issuerProfile.foundationDate) : foundationDate}
              readOnly={isEdit}
              dateFormat="dd/MM/yyyy"
              onChange={(date) => setFoundationDate(date)}
              endDate={new Date()}
              maxDate={new Date()}
              fixedHeight
              showMonthDropdown
              showYearDropdown
              scrollableYearDropdown
              showPreviousMonths
            />
            {errors.foundationDate && <div className="text-danger fs-12">{errors.foundationDate}</div>}
          </div>
        </div>
      </div>
      <div className="row col-12" style={{ padding: '0 2.5rem', margin: '0 0 1.25rem 0' }}>
        <InputText
          className={`${isEdit ? (isEditing ? 'form-control' : 'issuer-input-disabled') : 'form-control'}`}
          text={'pages.issuer.simpleDescription'}
          setState={setSimpleDescription}
          readOnly={isEdit ? (isEditing ? false : true) : false}
          defaultValue={isEdit ? issuerProfile.simpleDescription : ''}
          value={simpleDescription}
        />
        {errors.simpleDescription && <div className="text-danger fs-12">{errors.simpleDescription}</div>}
      </div>
      <div className="row col-12" style={{ padding: 0, margin: '0 0 1.25rem 0' }}>
        <div className="col-12" style={{ padding: '0 2.5rem' }}>
          <div className="d-flex flex-row text-input-container">
            <p className="text-input-label">
              <Translator path={'pages.issuer.description'} />
            </p>
            <p>*</p>
          </div>
          <textarea
            type="textarea"
            className={`${isEdit ? (isEditing ? 'form-control' : 'issuer-input-disabled') : 'form-control'}`}
            defaultValue={isEdit ? issuerProfile.detailedDescription : ''}
            readOnly={isEdit ? (isEditing ? false : true) : false}
            onChange={(e) => setDetailedDescription(e.target.value)}
            style={{ height: '9.5vh' }}
          />
          {errors.detailedDescription && <div className="text-danger fs-12">{errors.detailedDescription}</div>}
        </div>
      </div>
      <div className="row col-12" style={{ padding: 0, margin: '0 0 1.25rem 0' }}>
        <div className="col-md-6  paddingLeft-control-issuer">
          <div className="col-12 d-flex flex-column justify-content-start" style={{ padding: 0 }}>
            <div className="d-flex flex-row text-input-container">
              <p className="text-input-label">
                <Translator path="pages.issuer.contract" />
              </p>
              <p>*</p>
            </div>
            {loadingDocs === 'socialContract' ? (
              <PopOver
                children={
                  <div
                    className="loading-spinner icon-float-right hide-icon-issuer"
                    style={{ width: '30px', height: '30px' }}
                  />
                }
                placement="top"
                id="orangePopOver"
                text={t('pages.issuer.downloadDoc').toString()}
              ></PopOver>
            ) : (
              <PopOver
                children={
                  <i
                    className="material-icons icon-float-right hide-icon-issuer"
                    onClick={(e) => {
                      handleSocialDownload();
                    }}
                  >
                    attach_file
                  </i>
                }
                placement="top"
                id="orangePopOver"
                text={t('pages.issuer.downloadDoc').toString()}
              ></PopOver>
            )}
            {issuerProfile && issuerProfile.socialContract && issuerProfile.socialContract.link && !showLabelControl ? (
              <input
                type="text"
                className={`col-12 d-flex align-items-start ${isEdit ? (isEditing ? 'form-control' : 'issuer-input-disabled') : 'form-control'
                  } hide-label`}
                readOnly={true}
                defaultValue={
                  issuerProfile && issuerProfile.socialContract && issuerProfile.socialContract.link
                    ? t('pages.issuer.selectedSocialContract')
                    : ''
                }
                onClick={() => setShowLabelControl(true)}
                disabled={isEdit ? (isEditing ? false : true) : false}
              />
            ) : (
              <input
                type="file"
                name="description"
                accept=".pdf"
                id="file-upload"
                className={`col-12 d-flex align-items-start ${isEdit ? (isEditing ? 'form-control' : 'issuer-input-disabled') : 'form-control'
                  } hide-label`}
                style={{ paddingRight: '35px', overflow: 'auto', display: 'none' }}
                readOnly={isEdit ? (isEditing ? false : true) : false}
                onChange={(e) => {
                  handleFileChange(e);
                }}
                filename={fileName}
                disabled={isEdit ? (isEditing ? false : true) : false}
              />
            )}

            {errors.socialContractId && <div className="text-danger fs-12">{errors.socialContractId}</div>}
          </div>
        </div>
        <div className="col-md-6 paddingRight-control-issuer">
          <InputText
            className={`${isEdit ? (isEditing ? 'form-control' : 'issuer-input-disabled') : 'form-control'}`}
            text={'pages.issuer.capital'}
            setState={setCapitalwithMask}
            defaultValue={
              isEdit
                ? `R$ ${new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(
                  parseFloat(issuerProfile.shareCapital),
                )}`
                : ''
            }
            readOnly={isEdit ? (isEditing ? false : true) : false}
            value={showShareCapital}
            maxLength={47}
          />
          {errors.shareCapital && <div className="text-danger fs-12">{errors.shareCapital}</div>}
        </div>
      </div>
      <div className="row col-12" style={{ padding: 0, margin: '0 0 1.25rem 0' }}>
        <div className="col-md-6 paddingLeft-control-issuer">
          <InputText
            className={`${isEdit ? (isEditing ? 'form-control' : 'issuer-input-disabled') : 'form-control'}`}
            text={'pages.issuer.url'}
            setState={setWebsite}
            readOnly={isEdit ? (isEditing ? false : true) : false}
            defaultValue={isEdit ? issuerProfile.website : ''}
            value={website}
            obrigatory={false}
          />
        </div>
        <div className="col-md-6 paddingRight-control-issuer">
          <InputText
            className={`${isEdit ? (isEditing ? 'form-control' : 'issuer-input-disabled') : 'form-control'}`}
            text={'pages.issuer.area'}
            setState={setBusinessLine}
            readOnly={isEdit ? (isEditing ? false : true) : false}
            defaultValue={isEdit ? issuerProfile.businessLine : ''}
            value={businessLine}
          />
          {errors.businessLine && <div className="text-danger fs-12">{errors.businessLine}</div>}
        </div>
      </div>
      {documentArray.map((item, index) => {
        return (
          <div key={index} className="row col-12" style={{ padding: 0, margin: '0 0 1.25rem 0' }}>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4 additional-input-padding">
              <div className="col-12 d-flex flex-column justify-content-start" style={{ padding: 0 }}>
                <div className="d-flex flex-row text-input-container">
                  <p className="text-input-label">
                    <Translator path="pages.issuer.docs" />
                  </p>
                  <p></p>
                </div>

                {loadingDocs === 'additionalDocs' ? (
                  <PopOver
                    children={
                      <div
                        className="loading-spinner icon-float-right hide-icon-issuer"
                        style={{ width: '30px', height: '30px' }}
                      />
                    }
                    placement="top"
                    id="orangePopOver"
                    text={t('pages.issuer.downloadDoc').toString()}
                  ></PopOver>
                ) : (
                  <PopOver
                    children={
                      <i
                        className="material-icons icon-float-right hide-icon-issuer"
                        onClick={() => handleAdditionalDownload(index)}
                      >
                        attach_file
                      </i>
                    }
                    placement="top"
                    id="orangePopOver"
                    text={t('pages.issuer.downloadDoc').toString()}
                  ></PopOver>
                )}
                {showAdditionalDocs[index] && showAdditionalDocs[index].show == true ? (
                  <input
                    type="text"
                    className={`col-12 d-flex align-items-start ${isEdit ? (isEditing ? 'form-control' : 'issuer-input-disabled') : 'form-control'
                      } hide-label`}
                    readOnly={true}
                    defaultValue={
                      issuerProfile && issuerProfile.additionalDocs && issuerProfile.additionalDocs[index].link
                        ? `${t('pages.issuer.selectedAddtionalDoc')} ${index}`
                        : ''
                    }
                    onClick={() => {
                      if (isEdit && isEditing) showDocuments(index);
                    }}
                    disabled={isEdit ? (isEditing ? false : true) : false}
                  />
                ) : (
                  <>
                    <input
                      name="link"
                      accept=".pdf"
                      type="file"
                      placeholder={`${item.link ? item.link.split('%2F')[3] : t('pages.issuer.isEdit.link').toString()
                        }`}
                      className={`col-12 d-flex align-items-start hide-label ${isEdit ? (isEditing ? 'form-control' : 'issuer-input-disabled') : 'form-control'
                        }`}
                      style={{
                        overflow: 'hidden',
                        paddingRight: '25px',
                      }}
                      readOnly={isEdit ? (isEditing ? false : true) : false}
                      onChange={(e) => handleLinkAddition(index, e)}
                      filename={tempLink[index] && tempLink[index].name ? tempLink[index].name : 'Arquivo selecionado'}
                      disabled={isEdit ? (isEditing ? false : true) : false}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="col-9 col-sm-10 col-md-10 col-lg-10 col-xl-7  additional-desc-padding">
              <div className="col-12 d-flex flex-column justify-content-start">
                <div className="d-flex flex-row text-input-container">
                  <p className="text-input-label">
                    <Translator path="pages.issuer.docDescription" />
                  </p>
                  <p></p>
                </div>
                <input
                  name="description"
                  type="text"
                  value={documentArray[index].description}
                  className={`col-12 d-flex align-items-start ${isEdit ? (isEditing ? 'form-control' : 'issuer-input-disabled') : 'form-control'
                    }`}
                  readOnly={isEdit ? (isEditing ? false : true) : false}
                  onChange={(e) => {
                    handleDescAddition(index, e);
                  }}
                />
              </div>
            </div>
            <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-1  remove-item-padding d-flex align-items-end justify-content-end">
              <div className="col-12 d-flex align-items-center justify-content-end">
                <div
                  className="d-flex justify-content-center align-items-center redPopOver"
                  style={{
                    borderRadius: '8px',
                    border: '1px solid #E61D2B',
                    padding: '8px 10px 10px 7px',
                    cursor: `${isEditing || !isEdit ? 'pointer' : 'not-allowed'}`,
                  }}
                  onClick={() => removeElement(index)}
                >
                  <PopOver
                    children={
                      <i
                        className="material-icons"
                        width="20px"
                        height="20px"
                        style={{
                          color: '#E61D2B',
                          width: '20px',
                          height: '20px',
                        }}
                      >
                        delete
                      </i>
                    }
                    placement="top"
                    id="redPopOver"
                    text={t('pages.issuer.removeDoc').toString()}
                  ></PopOver>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <div className="row col-12" style={{ padding: 0, margin: '0 0 0.5rem 0' }}>
        <div className="col-md-12" style={{ padding: '0 2.5rem' }}>
          {errors.link && <div className="text-danger fs-12">{errors.link}</div>}
          {errors.desc && <div className="text-danger fs-12">{errors.desc}</div>}
        </div>
      </div>

      {!isEdit || isEditing ? (
        <div
          className="row col-12 d-flex flex-row justify-content-start align-items-center"
          style={{ padding: '0 2.5rem', margin: 0 }}
        >
          <div
            className="col-6 col-sm-4 d-flex flex-row justify-content-start align-items-center "
            style={{ margin: 0, padding: 0 }}
            onClick={() => addElement()}
          >
            <i className="material-icons" style={{ color: '#ff9900', cursor: 'pointer' }}>
              add
            </i>
            <p className="issuer-text-align">
              <Translator path="pages.issuer.addDoc" />
            </p>
          </div>
        </div>
      ) : (
        ''
      )}

      {isEdit && isEditing ? (
        <div
          className="row col-8 paddingButton-issuer"
          style={{ padding: '0 0 0 2.5rem', margin: '1.25rem 0 2.5rem 0' }}
        >
          <div className="col-md-6" style={{ padding: '0 10px 0 0' }}>
            <button
              className={`col-12 btn btn-orange ${loadingButton ? 'd-flex justify-content-center disabled' : ''}`}
              style={{ whiteSpace: 'nowrap' }}
              onClick={() => finishRegister()}
            >
              {loadingButton ? (
                <div className="loading-spinner" style={{ width: '30px', height: '30px' }} />
              ) : issuerProfile.status === 'APPROVED' ? (
                <Translator path="pages.issuer.save" />
              ) : (
                <Translator path="pages.issuer.alter" />
              )}
            </button>
          </div>
          <div className="col-md-6" style={{ padding: '0 10px 0 0' }}>
            <button
              className="col-12 btn btn-outlined"
              style={{ whiteSpace: 'normal' }}
              onClick={() => setIsEditing(false)}
            >
              <Translator path="pages.issuer.cancel" />
            </button>
          </div>
        </div>
      ) : isEdit && !isEditing ? (
        <div
          className="row col-12 paddingButton-issuer align-items-center justify-content-between"
          style={{ padding: '0 2.5rem 0 2.5rem', margin: '1.25rem 0 2.5rem 0' }}
        >
          <div className="col-md-3" style={{ padding: 0 }}>
            <button
              className="col-12 btn btn-orange"
              style={{ whiteSpace: 'nowrap' }}
              onClick={() => setIsEditing(true)}
            >
              <Translator path="pages.issuer.edit" />
            </button>
          </div>

          {issuerProfile.status === 'APPROVED' ? (

            <div className="col-md-9 d-flex approved-alert">
              <div className="col-12 d-flex align-items-center justify-content-evenly">
                <i className="material-icons d-flex justify-content-start align-items-center">info_outline</i>
                <p>
                  <Translator path="pages.issuer.alterApproved" />
                </p>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      ) : (
        <div
          className="row col-8 paddingButton-issuer"
          style={{ padding: '0 0 0 2.5rem', margin: '1.25rem 0 2.5rem 0' }}
        >
          <div className="col-md-6" style={{ padding: '0 10px 0 0' }}>
            <button
              className={`col-12 btn btn-orange ${loadingButton ? 'd-flex justify-content-center disabled' : ''}`}
              style={{ whiteSpace: 'nowrap' }}
              onClick={() => finishRegister()}
            >
              {loadingButton ? (
                <div className="loading-spinner" style={{ width: '30px', height: '30px' }} />
              ) : (
                <Translator path="pages.issuer.register" />
              )}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default RegisterForm;
