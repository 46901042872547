import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Translator, SelectInput } from '@components';
import { moneyMask, handleShowMoneyLabels } from '@utils';

const FilterModal = ({ props: { show, setShow, setFilters, filters } }) => {
  const [date, setDate] = useState();
  const [finalDate, setFinalDate] = useState();
  const [order, setOrder] = useState();
  const [tokenName, setTokenName] = useState();
  const [quantity, setQuantity] = useState();
  const [investedValue, setInvestedValue] = useState();
  const [toReceiveValue, setToReceiveValue] = useState();
  const [receivedValue, setReceivedValue] = useState();
  const [reversedValue, setReversedValue] = useState();
  const [investedLabel, setInvestedLabel] = useState();
  const [toReceiveLabel, setToReceiveLabel] = useState();
  const [receivedLabel, setReceivedLabel] = useState();
  const [reversedLabel, setReversedLabel] = useState();
  const [selectedOption, setSelectedOption] = useState(null);
  const { t } = useTranslation();

  const onHideModal = () => {
    setShow(false);
  };

  const clearFilters = () => {
    setFilters({});
    setShow(false);
  };

  const handleFilters = () => {
    const currentfilters = {
      ...filters,
      initialDate: date ? new Date(date).toISOString().split(/[A-Z]/)[0] : undefined,
      finalDate: finalDate ? new Date(finalDate).toISOString().split(/[A-Z]/)[0] : undefined,
      purchaseCode: order ? order : undefined,
      tokenName: tokenName ? tokenName : undefined,
      quantity: quantity ? quantity : undefined,
      investedValue: investedValue ? investedValue : undefined,
      toReceiveValue: toReceiveValue ? toReceiveValue : undefined,
      receivedValue: receivedValue ? receivedValue : undefined,
      reversedValue: reversedValue ? reversedValue : undefined,
      status: selectedOption && selectedOption.value ? selectedOption.value : undefined,
    };
    setFilters(currentfilters);
    setShow(false);
  };

  const selectOption = [
    { value: 'CANCELED', label: t('components.statementTable.filter.selectOptions.cancel') },
    { value: 'CAPTATED', label: t('components.statementTable.filter.selectOptions.captated') },
    { value: 'INCAPTURE', label: t('components.statementTable.filter.selectOptions.incapture') },
    { value: 'LIQUIDATED', label: t('components.statementTable.filter.selectOptions.liquidated') },
  ];

  const defaultSelectOption = {
    label:
      filters && filters.status === 'CANCELED'
        ? t('components.statementTable.filter.selectOptions.cancel')
        : filters && filters.status === 'CAPTATED'
          ? t('components.statementTable.filter.selectOptions.captated')
          : filters && filters.status === 'INCAPTURE'
            ? t('components.statementTable.filter.selectOptions.incapture')
            : filters && filters.status === 'LIQUIDATED'
              ? t('components.statementTable.filter.selectOptions.liquidated')
              : t('components.statementTable.filter.selectPH').toString(),
  };
  const selectStyles = {
    valueContainer: (provided) => ({
      ...provided,
      maxHeight: '3rem',
      padding: 0,
    }),
    singleValue: (provided) => ({
      ...provided,
      padding: '0 0 0 1.2rem',
      margin: 0,
      color: '#757575 !important',
    }),
    input: (provided) => ({
      ...provided,
      maxHeight: '3rem',
      margin: 0,
      padding: '0 0 0 1.2rem',
      color: '#757575 !important',
    }),

    control: (baseStyles, state) => ({
      ...baseStyles,
      padding: 0,
      background: '#FFFFFF',
      fontSize: '1.25rem',
      height: '3rem',
      color: '#757575 !important',
      borderColor: '#c0c0c0',
      borderRadius: '0.5rem',
      border: '1px solid #C0C0C0',
      boxShadow: '#ff9900',

      '&:hover': {
        borderColor: '#ff9900',
      },
      '&:hover,&:active': {
        boxShadow: 'none',
        background: '#FFFFFF',
        color: '#757575',
      },
    }),

    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#FF9900' : 'white',
      color: 'var(--light-gray) !important',

      '&:hover': {
        borderColor: '#FF9900',
        backgroundColor: '#FF9900',
      },
    }),
    placeholder: (baseStyles, state) => ({
      ...baseStyles,
      color: '#757575 !important',
      marginLeft: '1.25rem',
    }),
  };

  const selectData = {
    options: selectOption,
    styles: selectStyles,
    select: defaultSelectOption,
    setSelect: setSelectedOption,
    placeholder: t('components.statementTable.filter.selectPH').toString(),
  };

  return (
    <Modal show={show} aria-labelledby="contained-modal-title-vcenter" size="lg" centered>
      <Modal.Body style={{ padding: 20 }} className="row d-flex flex-column justify-content-center align-items-center">
        <div className="row">
          <div
            className="col-12 d-flex justify-content-between align-items-center flex-row "
            style={{ marginBottom: '1.25rem' }}
          >
            <h4 className="col-3 modal-title-deposit">
              <Translator path="components.statementTable.filter.filter" />
            </h4>
            <i className="material-icons moda-close-icon cursor-pointer" onClick={onHideModal}>
              close
            </i>
          </div>
          <div className="col-12" style={{ borderBottom: '1px solid #EDEDED' }} />
          <div
            className="col-12 d-flex justify-content-center align-items-center"
            style={{ gap: '1.25rem', margin: '2.5rem 0' }}
          >
            <div className="col-6 no-picker">
              <input
                type="text"
                className="form-control"
                style={{ marginBottom: '2.5rem' }}
                placeholder={t('components.statementTable.filter.order')}
                defaultValue={filters && filters.purchaseCode ? filters.purchaseCode : ''}
                onChange={(e) => setOrder(e.target.value)}
              />
              <i className="material-icons icon-float-middle">today</i>
              <div className="d-flex align-items-center justify-content-end w-100">
                <i className="material-icons icon-float-no-margin date-icon" style={{ margin: '0 15px 10px 0', zIndex: 1 }}>
                  today
                </i>
                <DatePicker
                  className="form-control"
                  selected={date ? new Date(date) : filters && filters.initialDate ? new Date(filters.initialDate) : ''}
                  dateFormat="dd/MM/yyyy"
                  placeholderText={date ? '' : t('components.statementTable.date').toString()}
                  onChange={(date) => setDate(date)}
                />
              </div>
            </div>
            <div className="col-6 ">
              <input
                type="text"
                style={{ marginBottom: '2.5rem' }}
                className="form-control"
                defaultValue={filters && filters.tokenName ? filters.tokenName : ''}
                placeholder={t('components.statementTable.filter.name')}
                onChange={(e) => setTokenName(e.target.value)}
              />
              <div className="d-flex align-items-center justify-content-end w-100">
                <i className="material-icons icon-float-no-margin date-icon" style={{ margin: '0 15px 10px 0', zIndex: 1 }}>
                  today
                </i>
                <DatePicker
                  className="form-control"
                  selected={
                    finalDate ? new Date(finalDate) : filters && filters.finalDate ? new Date(filters.finalDate) : ''
                  }
                  dateFormat="dd/MM/yyyy"
                  placeholderText={finalDate ? '' : t('components.statementTable.filter.fdate').toString()}
                  onChange={(date) => setFinalDate(date)}
                />
              </div>
            </div>
          </div>
          <div
            className="col-12 d-flex justify-content-center align-items-center"
            style={{ gap: '1.25rem', margin: 0 }}
          >
            <div className="col-6 no-picker ">
              <input
                type="text"
                className="form-control"
                placeholder={t('components.statementTable.filter.quant')}
                style={{ marginBottom: '2.5rem' }}
                defaultValue={filters && filters.quantity ? filters.quantity : ''}
                onChange={(e) => setQuantity(e.target.value)}
              />
              <input
                type="text"
                className="form-control"
                value={
                  investedLabel
                    ? investedLabel
                    : filters && filters.investedValue
                      ? moneyMask(filters.investedValue)
                      : ''
                }
                placeholder={t('components.statementTable.filter.invest')}
                onChange={(e) => handleShowMoneyLabels(setInvestedValue, setInvestedLabel, e.target.value)}
              />
            </div>
            <div className="col-6 ">
              <input
                type="text"
                className="form-control"
                value={
                  receivedLabel
                    ? receivedLabel
                    : filters && filters.receivedValue
                      ? moneyMask(filters.receivedValue)
                      : ''
                }
                placeholder={t('components.statementTable.filter.received')}
                style={{ marginBottom: '2.5rem' }}
                onChange={(e) => handleShowMoneyLabels(setReceivedValue, setReceivedLabel, e.target.value)}
              />
              <input
                type="text"
                value={
                  toReceiveLabel
                    ? toReceiveLabel
                    : filters && filters.toReceiveValue
                      ? moneyMask(filters.toReceiveValue)
                      : ''
                }
                className="form-control"
                placeholder={t('components.statementTable.filter.toReceive')}
                onChange={(e) => handleShowMoneyLabels(setToReceiveValue, setToReceiveLabel, e.target.value)}
              />
            </div>
          </div>
          <div
            className="col-12 d-flex justify-content-center align-items-center"
            style={{ gap: '1.25rem', margin: '2.5rem 0' }}
          >
            <div className="col-6">
              <input
                type="text"
                value={
                  reversedLabel
                    ? reversedLabel
                    : filters && filters.reversedValue
                      ? moneyMask(filters.reversedValue)
                      : ''
                }
                className="form-control"
                placeholder={t('components.statementTable.filter.return')}
                onChange={(e) => handleShowMoneyLabels(setReversedValue, setReversedLabel, e.target.value)}
              />
            </div>
            <div className="col-6">
              <SelectInput props={selectData} />
            </div>
          </div>
          <div className="col-12" style={{ borderBottom: '1px solid #EDEDED' }} />
          <div className="col-12 d-flex justify-content-end" style={{ gap: '1.25rem', marginTop: '1.25rem' }}>
            <button className="btn btn-outlined col-4" onClick={() => clearFilters()}>
              <Translator path="depositPage.cleanBtn" />
            </button>
            <button className="btn btn-orange col-3" onClick={() => handleFilters()}>
              <Translator path="depositPage.filter" />
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default FilterModal;