import { Translator } from '@components';

export const COLUMNS = [
  {
    Header: <Translator path={'pages.withdrawList.tableColumns.withdrawCode'} />,
    Footer: <Translator path={'pages.withdrawList.tableColumns.withdrawCode'} />,
    accessor: 'withdrawCode',
    isSortable: true,
  },
  {
    Header: <Translator path={'pages.withdrawList.tableColumns.name'} />,
    Footer: <Translator path={'pages.withdrawList.tableColumns.name '} />,
    accessor: 'tokenName',
    isSortable: true,
  },
  // {
  //   Header: <Translator path={'pages.withdrawList.tableColumns.tokenCode'} />,
  //   Footer: <Translator path={'pages.withdrawList.tableColumns.tokenCode'} />,
  //   accessor: 'tokenCode',
  //   isSortable: true,
  // },
  {
    Header: <Translator path={'pages.withdrawList.tableColumns.val'} />,
    Footer: <Translator path={'pages.withdrawList.tableColumns.val '} />,
    accessor: 'value',
    isSortable: true,
  },
  {
    Header: <Translator path={'pages.withdrawList.tableColumns.date'} />,
    Footer: <Translator path={'pages.withdrawList.tableColumns.date'} />,
    accessor: 'date',
    isSortable: false,
  },
  {
    Header: <Translator path={'pages.withdrawList.tableColumns.withdrawDate'} />,
    Footer: <Translator path={'pages.withdrawList.tableColumns.withdrawDate'} />,
    accessor: 'withdrawDate',
    isSortable: false,
  },
  {
    Header: <Translator path={'pages.withdrawList.tableColumns.status'} />,
    Footer: <Translator path={'pages.withdrawList.tableColumns.status'} />,
    accessor: 'status',
    isSortable: false,
  },
  {
    Header: <Translator path={'pages.withdrawList.tableColumns.action'} />,
    Footer: <Translator path={'pages.withdrawList.tableColumns.action'} />,
    accessor: 'action',
    isSortable: false,
  },
];
