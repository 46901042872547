import { stepIssuerData, stepTokenProposal, stepBankData } from '@assets';
import { Translator } from '@components';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

export const StepsCard = ({ step = 1, issuer }) => {
  const [isStep1Complete, setIsStep1Complete] = useState(false);
  const [isStep2Complete, setIsStep2Complete] = useState(false);
  const [isStep3Complete, setIsStep3Complete] = useState(false);
  const [firstProposal, setFirstProposal] = useState({});
  const [issuerBankData, setIssuerBankData] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    if (issuer?.status === 'APPROVED') {
      setIsStep1Complete(true);
    }

    if (issuer?.tokenProposals?.length) {
      const codes = issuer.tokenProposals.map((proposal) => proposal.tokenProposalCode);

      const oldest = codes.reduce((a, b) => {
        return a < b ? a : b;
      });

      const oldestProposal = issuer.tokenProposals.filter((proposal) => {
        return proposal.tokenProposalCode === oldest ? proposal : '';
      });

      setFirstProposal(oldestProposal[0]);
    }

    if (issuer?.issuerBankData?.length) {
      setIssuerBankData(issuer.issuerBankData[0]);
    }
  }, [issuer]);

  useEffect(() => {
    if (firstProposal.status === 'ACCEPTED' && isStep1Complete) {
      setIsStep2Complete(true);
    }
  }, [firstProposal]);

  if (issuer && firstProposal) {
    return (
      <div className="steps-card card m-0">
        <div className="card-image position-relative">
          <img src={step === 1 ? stepIssuerData : step === 2 ? stepTokenProposal : stepBankData} className="w-100" />
          {step === 1 ? (
            issuer.status === 'IN_ANALYSIS' ? (
              <div className="position-absolute step-tooltip" style={{ backgroundColor: '#FF7A00' }}>
                <Translator path={'pages.dashboard.step1.status.analysis'} />
              </div>
            ) : issuer.status === 'REJECTED' ? (
              <div className="position-absolute step-tooltip" style={{ backgroundColor: '#E61D2B' }}>
                <Translator path={'pages.dashboard.step1.status.rejected'} />
              </div>
            ) : issuer.status === 'APPROVED' ? (
              <div className="position-absolute step-tooltip" style={{ backgroundColor: '#009847' }}>
                <Translator path={'pages.dashboard.step1.status.approved'} />
              </div>
            ) : issuer.status === 'CHANGES_REQUEST' ? (
              <div className="position-absolute step-tooltip" style={{ backgroundColor: '#E18700' }}>
                <Translator path={'pages.dashboard.step1.status.change'} />
              </div>
            ) : (
              ''
            )
          ) : step === 2 && isStep1Complete ? (
            firstProposal.status === 'IN_ANALYSIS' ? (
              <div className="position-absolute step-tooltip" style={{ backgroundColor: '#FF7A00' }}>
                <Translator path={'pages.dashboard.step2.status.analysis'} />
              </div>
            ) : firstProposal.status === 'REJECTED' ? (
              <div className="position-absolute step-tooltip" style={{ backgroundColor: '#E61D2B' }}>
                <Translator path={'pages.dashboard.step2.status.rejected'} />
              </div>
            ) : firstProposal.status === 'NEGOTIATING' ? (
              <div className="position-absolute step-tooltip" style={{ backgroundColor: '#E18700' }}>
                <Translator path={'pages.dashboard.step2.status.change'} />
              </div>
            ) : firstProposal.status === 'ACCEPTED' ? (
              <div className="position-absolute step-tooltip" style={{ backgroundColor: '#009847' }}>
                <Translator path={'pages.dashboard.step2.status.approved'} />
              </div>
            ) : firstProposal.status === 'PRE_ACCEPTED' ? (
              <div className="position-absolute step-tooltip" style={{ backgroundColor: '#005829' }}>
                <Translator path={'pages.dashboard.step2.status.preAccepted'} />
              </div>
            ) : firstProposal.status === 'ANNULLED' ? (
              <div className="position-absolute step-tooltip" style={{ backgroundColor: '#A30914' }}>
                <Translator path={'pages.dashboard.step2.status.annulled'} />
              </div>
            ) : (
              ''
            )
          ) : step === 3 && isStep2Complete && isStep1Complete ? (
            issuerBankData.status === 'IN_ANALYSIS' ? (
              <div className="position-absolute step-tooltip" style={{ backgroundColor: '#FF7A00' }}>
                <Translator path={'pages.dashboard.step3.status.analysis'} />
              </div>
            ) : issuerBankData.status === 'REJECTED' ? (
              <div className="position-absolute step-tooltip" style={{ backgroundColor: '#E61D2B' }}>
                <Translator path={'pages.dashboard.step3.status.rejected'} />
              </div>
            ) : issuerBankData.status === 'APPROVED' ? (
              <div className="position-absolute step-tooltip" style={{ backgroundColor: '#009847' }}>
                <Translator path={'pages.dashboard.step3.status.approved'} />
              </div>
            ) : (
              ''
            )
          ) : (
            ''
          )}
        </div>
        <div className="step-card-content">
          <div className="d-flex flex-row step-card-title">
            <div className="step-icon">
              <i className="material-icons">{step === 1 ? 'start' : step === 2 ? 'token' : 'account_balance'}</i>
            </div>
            <div>
              <p className="m-0">
                <Translator path={`pages.dashboard.${step === 1 ? 'step1' : step === 2 ? 'step2' : 'step3'}.title`} />
              </p>
              <p className="m-0">
                <Translator path={`pages.dashboard.${step === 1 ? 'step1' : step === 2 ? 'step2' : 'step3'}.actual`} />
              </p>
            </div>
          </div>
          <div className="step-text">
            <p className="m-0">
              {step === 1 ? (
                <Translator
                  path={`pages.dashboard.step1.${
                    issuer.status === 'IN_ANALYSIS'
                      ? 'analysis'
                      : issuer.status === 'REJECTED'
                      ? 'rejected'
                      : issuer.status === 'CHANGES_REQUEST'
                      ? 'change'
                      : issuer.status === 'APPROVED'
                      ? 'approved'
                      : 'start'
                  }`}
                />
              ) : step === 2 ? (
                <Translator
                  path={`pages.dashboard.step2.${
                    !isStep1Complete
                      ? 'waiting'
                      : firstProposal.status === 'IN_ANALYSIS'
                      ? 'analysis'
                      : firstProposal.status === 'REJECTED'
                      ? 'rejected'
                      : firstProposal.status === 'NEGOTIATING'
                      ? 'change'
                      : firstProposal.status === 'ACCEPTED'
                      ? 'approved'
                      : firstProposal.status === 'PRE_ACCEPTED'
                      ? 'preAccepted'
                      : firstProposal.status === 'ANNULLED'
                      ? 'annulled'
                      : 'start'
                  }`}
                />
              ) : step === 3 ? (
                <Translator
                  path={`pages.dashboard.step3.${
                    !isStep1Complete || !isStep2Complete
                      ? 'waiting'
                      : issuer.issuerBankData.length === 0
                      ? 'start'
                      : issuerBankData.status === 'IN_ANALYSIS'
                      ? 'analysis'
                      : issuerBankData.status === 'REJECTED'
                      ? 'rejected'
                      : issuerBankData.status === 'CHANGES_REQUEST'
                      ? 'change'
                      : issuerBankData.status === 'APPROVED'
                      ? 'approved'
                      : ''
                  }`}
                />
              ) : (
                ''
              )}
            </p>
          </div>
          <div className="step-actions">
            {step === 1 ? (
              issuer.status === 'INCOMPLETE' ? (
                <Button
                  variant="primary"
                  size="lg"
                  className="text-center"
                  id="btn"
                  onClick={() => navigate('/mycorporation')}
                >
                  <Translator path={'pages.dashboard.step1.startBtn'} />
                </Button>
              ) : issuer.status === 'IN_ANALYSIS' ? (
                <Button variant="primary" size="lg" className="text-center disable" id="btn" disabled>
                  <Translator path={'pages.dashboard.step1.analysisBtn'} />
                </Button>
              ) : issuer.status === 'REJECTED' ? (
                <Button
                  variant="primary"
                  size="lg"
                  className="text-center"
                  id="btn"
                  onClick={() => navigate('/mycorporation')}
                >
                  <Translator path={'pages.dashboard.step1.rejectedBtn'} />
                </Button>
              ) : issuer.status === 'CHANGES_REQUEST' ? (
                <Button
                  variant="primary"
                  size="lg"
                  className="text-center"
                  id="btn"
                  onClick={() => navigate('/mycorporation')}
                >
                  <Translator path={'pages.dashboard.step1.changeBtn'} />
                </Button>
              ) : issuer.status === 'APPROVED' ? (
                <div className="d-flex flex-row align-items-center complete">
                  <i className="material-icons">check</i>

                  <Translator path={'pages.dashboard.step1.approvedBtn'} />
                </div>
              ) : (
                ''
              )
            ) : step === 2 ? (
              !isStep1Complete ? (
                <Button variant="primary" size="lg" className="text-center disable" id="btn" disabled>
                  <Translator path={'pages.dashboard.step2.waitingBtn'} />
                </Button>
              ) : isStep1Complete && Object.keys(firstProposal).length === 0 ? (
                <Button
                  variant="primary"
                  size="lg"
                  className="text-center"
                  id="btn"
                  onClick={() => navigate('/tokenProposal')}
                >
                  <Translator path={'pages.dashboard.step2.startBtn'} />
                </Button>
              ) : firstProposal.status === 'IN_ANALYSIS' ? (
                <Button variant="primary" size="lg" className="text-center disable" id="btn" disabled>
                  <Translator path={'pages.dashboard.step2.analysisBtn'} />
                </Button>
              ) : firstProposal.status === 'REJECTED' ? (
                <Button
                  variant="primary"
                  size="lg"
                  className="text-center"
                  id="btn"
                  onClick={() => navigate('/tokenProposal')}
                >
                  <Translator path={'pages.dashboard.step2.rejectedBtn'} />
                </Button>
              ) : firstProposal.status === 'NEGOTIATING' ? (
                <Button
                  variant="primary"
                  size="lg"
                  className="text-center"
                  id="btn"
                  onClick={() => navigate('/tokenProposal/list')}
                >
                  <Translator path={'pages.dashboard.step2.changeBtn'} />
                </Button>
              ) : firstProposal.status === 'ACCEPTED' ? (
                <div className="d-flex flex-row align-items-center complete">
                  <i className="material-icons">check</i>

                  <Translator path={'pages.dashboard.step2.approvedBtn'} />
                </div>
              ) : firstProposal.status === 'PRE_ACCEPTED' ? (
                <Button
                  variant="primary"
                  size="lg"
                  className="text-center"
                  id="btn"
                  onClick={() => navigate('/tokenProposal/list')}
                >
                  <Translator path={'pages.dashboard.step2.preAcceptedBtn'} />
                </Button>
              ) : firstProposal.status === 'ANNULLED' ? (
                <Button
                  variant="primary"
                  size="lg"
                  className="text-center"
                  id="btn"
                  onClick={() => navigate('/tokenProposal')}
                >
                  <Translator path={'pages.dashboard.step2.annulledBtn'} />
                </Button>
              ) : (
                ''
              )
            ) : step === 3 ? (
              !isStep2Complete ? (
                <Button variant="primary" size="lg" className="text-center disable" id="btn" disabled>
                  <Translator path={'pages.dashboard.step2.waitingBtn'} />
                </Button>
              ) : isStep2Complete && issuer.issuerBankData.length === 0 ? (
                <Button
                  variant="primary"
                  size="lg"
                  className="text-center"
                  id="btn"
                  onClick={() => navigate('/myBank')}
                >
                  <Translator path={'pages.dashboard.step3.startBtn'} />
                </Button>
              ) : issuerBankData.status === 'IN_ANALYSIS' ? (
                <Button variant="primary" size="lg" className="text-center disable" id="btn" disabled>
                  <Translator path={'pages.dashboard.step3.analysisBtn'} />
                </Button>
              ) : issuerBankData.status === 'REJECTED' ? (
                <Button
                  variant="primary"
                  size="lg"
                  className="text-center"
                  id="btn"
                  onClick={() => navigate('/myBank')}
                >
                  <Translator path={'pages.dashboard.step3.rejectedBtn'} />
                </Button>
              ) : issuerBankData.status === 'APPROVED' ? (
                <div className="d-flex flex-row align-items-center complete">
                  <i className="material-icons">check</i>

                  <Translator path={'pages.dashboard.step3.approvedBtn'} />
                </div>
              ) : (
                ''
              )
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    );
  }
};
