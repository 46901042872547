export default {
  investor: {
    apiErrors: {
      error: 'Error',
      query: 'Failed to search the database',
      userNotFound: 'Failed find the user',
      tokenNotFound: 'Failed to find token',
      entityNotFound: 'User or Token not found',
      upload: 'Failed to upload',
      tokenGen: 'Failed to generate token',
      tokenExpire: 'Authentication token expired please login again',
      emailSending: 'Failed to send email',
      keycloakCon: 'Failed while connection to keycloak',
      quantity: 'Not enough tokens available',
      blockChainWallet: 'Failed to create an user wallet',
      badRequest: 'Failed to send request',
      tokenList: 'Failed to get tokens',
      tokenDetails: 'Failed to get token details',
      profile: 'Failed to load user data',
      highlightedTokens: 'Failed to get highlighted tokens',
      deposit: 'Failed to find user deposits',
      recaptcha: 'Invalid recaptcha'
    },
    loading: 'Loading...',
    success: 'Success',
    sucessMessage: 'The operation was processed successfuly',
    dashboard: {
      firstSteps: 'First Steps',
      stepsCard: {
        deposit: 'Deposit',
        depositMsg: 'Make your first deposit in our platform.',
        waiting1: 'Please, finish "Step 1" to release "Step 2".',
        investment: 'Investiment',
        investmentMsg: 'Make your first investment and start raising your income.',
        waiting2: 'Please, finish the previous steps.',
        withdraw: 'Withdraw',
        withdrawMsg: 'Make your first withdraw in our platform.',
        depositBtn: 'Deposit',
        complete: 'Complete',
        waitingBtn: 'Waiting...',
        investmentBtn: 'Invest',
        withdrawBtn: 'Withdraw',
        step1: 'Step 1 of 3',
        step2: 'Step 2 of 3',
        step3: 'Step 3 of 3',
      },
      currentBalance: 'Current balance',
      totalAssets: 'Your assets',
      totalAssetsInfo: 'Patrimony is your current balance + your investments',
      highlightedTokens: 'Highlighted tokens',
    },
    login: {
      header: 'Login',
      title: 'Personal information',
      body: 'Please inform your email and password',
      email: 'Email',
      password: 'Password',
      submit: 'Sign in',
      forgot: 'Forgot your password? ',
      keep: 'Keep connected',
      link: ' Click here!',
      signup: "Don't have and account?",
      create: 'Create account',
      login: 'Log In',
      selectaccount: 'Select an account!',
      selectaccounttext: 'Please, select one of the options bellow to continue.',
      continueas: 'Continue as: ',
      concluded: "Registration completed!",
      concludedtext: "You can now log in using the same information from your physical account. After that, you can choose the desired login type: physical or legal account.",
      errors: {
        email: 'Please inform your email!',
        password: 'Please inform your password!',
        title: 'Invalid credentials',
        credentials: 'Please verify your email and password',
      },
    },
    register: {
      header: 'Register',
      title: 'Create account',
      body: 'Inform your personal information',
      bodypj: 'Inform your company details below',
      corporatename: 'Corporate name',
      foundationdate: 'Foundation date',
      socialcontractandbylaws: 'Social contracts and bylaws',
      document: 'Add another document',
      declare: 'I declare that the company: ',
      firstcheckbox: 'Understand the concepts of Anti-Money Laundering (AML), terrorism, and corruption prevention, and it is important to highlight that no entity affiliated with the Company is involved in any of these practices.',
      secondcheckbox: "Has the necessary policies and manuals to implement KYC (Know Your Customer) in accordance with the law, committing to follow Criptech's guidelines.",
      thirdcheckbox: 'Agree to the ',
      termsofuse: 'terms of use and privacy policy.',
      name: 'Full name',
      email: 'Email',
      occupation: 'Occupation',
      wage: 'Monthly income',
      id: 'Id',
      singup: 'Register',
      select: 'Please, select an option:',
      userpf: 'Physical Person',
      userpj: 'Legal Person',
      completeaddress: 'Complete adress',
      phone: 'Phone number',
      birth: 'Birth date',
      code: 'Referral code (optional)',
      check: 'I have read and consent with',
      terms: 'The terms of privacy and use',
      goback: 'Go back',
      submit: 'Continue',
      submitpj: 'I accept the conditions',
      errors: {
        name: 'Please inform your full name!',
        email: 'Please inform your email!',
        occupation: 'Please inform your occupation!',
        wage: 'Please inform your total monthly income!',
        cpf: 'Please inform your id!',
        phone: 'Please inform your phone number',
        birth: 'Please inform your birth date!',
        document: 'Please upload your identity document!',
        selfie: 'Please upload your selfie with your identity document!',
        minor: 'You need to be atleast 18 years old!',
        check: 'Please give your consent!',
        validId: 'Please inform a valid id!',
        validPhone: 'Please inform a valid number',
        address: 'Please inform a valid address!',
        validCnpj: 'Please inform a valid cnpj.',
        validName: 'Please inform a name!',
        validTerms: "It's necessary to agree with all the terms to signup!",
        social: 'Please upload your social contract!',
        foundationdate: 'Please inform your foundation date',
        api: {
          title: 'Failed to register',
          email: 'Email already exists',
          cpf: 'Id already exists',
          phone: 'Phone already exists',
          cnpj: 'CNPJ alredy exists',
        },
      },
      emailPage: {
        title: 'Security code',
        body: 'Please, inform the code sent to your email!',
        send: 'Send again?',
        click: 'Click here!',
        goback: 'Go back',
        submit: 'Continue',
        box: 'Please make sure to fill all the fields!',
        tokenErr: 'Failed to verify token, please inform the code sent to your email!',
      },
      passwordPage: {
        title: 'Set password',
        body: 'Please set your password to start investing',
        pass: 'Password',
        rePass: 'Confirm password',
        validationOne: 'Minimum 8 characters',
        validationTwo: 'At least one number',
        validationThree: 'At least one uppercase letter',
        validationFour: 'At least one special character: !@#$%^&*(),.?":{}|<>]',
        validationEquals: 'Both passwords must match',
        goback: 'Go back',
        submit: 'Finalize',
        errors: {
          pass: 'Please inform your password',
          confirm: 'Please confirm your password',
          val: "The passwords don't match!",
          attend: 'Please make sure your password pass the requirements',
        },
      },
    },
    resetPasswordPage: {
      goback: 'Go back',
      continue: 'Continue',
      firstStep: {
        title: 'Reset password',
        body: 'Inform your email to reset the password',
      },
      secondStep: {
        title: 'Security code',
      },
      errors: {
        err: 'Error',
        email: 'Email not found, please inform a valid email',
        token: 'Failed to validate code, please inform the code sent to your email',
      },
    },
    titles: {
      dashboard: 'Dashboard',
      investment: 'Tokens',
      wallet: 'Wallet',
      profile: 'Profile',
      profilepj: 'Profile',
      rewards: 'Rewards',
      security: 'Security',
      useTerms: 'Use terms',
      inviteAFriend: 'Invite a friend',
      edit: 'Profile',
      publictokens: 'Tokens',
      walletItems: {
        wallet: 'My wallet',
        deposit: 'My deposits',
        withdraw: 'My withdrawals',
        invest: 'My investments',
      },
      logout: 'Logout',
    },
    menuLabel: {
      dashboard: 'Dashboard',
      investment: 'Investment',
      wallet: 'Wallet',
      profile: 'Profile',
      account: 'Change account',
      rewards: 'Rewards',
      security: 'Security',
      useTerms: 'Use terms',
      inviteAFriend: 'Invite a friend',
    },
    investment: {
      balance: 'Balance:',
      tokenFilter: 'All tokens',
      bestSellers: 'Best sellers',
      biggestYield: 'Biggest yield',
      biggestValue: 'Biggest value',
      smallestValue: 'Smallest value',
      loadmore: 'Load more',
      buyTokenPage: {
        investTitle: 'Invest',
        nameField: 'Token name: ',
        defaultValField: 'Price of 1 unit: ',
        selectField: 'Select a amount',
        valField: 'Amount to invest',
        submit: 'Invest',
        valReceive: 'Yield value',
        dateReceive: 'Receive in ',
        about: 'About the token',
        total: 'Total value',
        howItWorks: 'How it works?',
        alert: 'If the funding do not be achieved within the estimated period, the money is automatically refunded',
        description: 'Description',
        info: 'General information',
        captation: 'Captation',
        currentCaptation: 'Current captation',
        remainingTokens: 'Remaining tokens',
        buyers: 'Buyers',
        liquidation: 'Liquidation',
        offeredTokens: 'Offered tokens',
        minimumValue: 'Minimum value',
        issuer: {
          info: 'Issuer informations',
          company: 'Company',
          foundation: 'Foundation:',
          team: 'Our team',
        },
      },
      confirmPurchase: {
        confirmation: 'Investment confirmation',
        confirm: 'Confirm',
        account: 'Account',
        back: 'Back',
      },
      investmentModal: {
        seeToken: 'See token',
        complete: 'Investment completed',
        overview: 'Overview',
        investedValue: 'Invested value',
        toReceive: 'Estimated value to receive',
        receiveDate: 'Receive in 1x on',
        transactionError: 'It was not possible to complete your investment',
        attention: 'Attention',
      },
      noBalanceModal: {
        title: 'Insuficient funds',
        body: "You don't have enough funds in your account, would you like to make a deposit?",
        cancel: 'Cancel',
        Submit: 'Yes, i do',
      },
    },
    myInvestment: {
      balance: 'Balance:',
      tokenFilter: 'All tokens',
      balanceError: "You don't have enought funds",
      buyTokenPage: {
        valInvest: 'Invested value',
        quantity: 'Quantity',
        valReceive: 'Yield value',
        dateReceive: 'Receive in ',
        about: 'About the token',
        total: 'Total value',
      },
    },
    components: {
      select: {
        noOptions: 'No results found',
      },
      tableBody: {
        tokenProposaltokenslist:
          'Unfortunately, you dont have any token in our plataform! Please click the link bellow to make a proposal.',
      },
      tableButton: {
        tokenProposaltokenslist: 'Make proposal',
      },
      footer: {
        title: 'All rights reserverd by ',
        corporate: 'Criptech',
      },
      tableBody: {
        walletdeposit: "You don't have any deposits",
        walletwithdrawList: "You don't have any withdraws",
        walletstatement: "You don't have any investments",
      },
      tableButton: {
        walletdeposit: 'Deposit',
        walletwithdrawList: 'Withdraw',
        walletstatement: 'Invest',
      },
      tokenCard: {
        value: 'Minimum value',
        revenue: 'Monthly revenue',
        quantity: 'Tokens left',
        percentage: 'Compared to the CDI',
      },
      purchaseCard: {
        purchaseOrder: 'Order',
        purchaseQuantity: 'Quantity',
        purchaseAmountInvested: 'Invested amount',
        purchaseAmountReceivable: 'Receivable amount',
        purchaseChargebackAmount: 'Chargeback',
        purchaseAmountReceived: 'Received amount',
        purchaseDate: 'Purchase date',
        purchaseReceivable: 'Receivable',
      },
      statementTable: {
        order: 'Order',
        name: 'Token name',
        date: 'Date bought',
        investVal: 'Invested value',
        quantity: 'Quantity',
        receiveVal: 'Yield value',
        received: 'Received value',
        return: 'Chargeback',
        status: 'Status',
        action: 'Actions',
        filter: {
          filter: 'Filter',
          order: 'Order',
          name: 'Token name',
          date: 'Initial bought date',
          fdate: 'Final bought date',
          quant: 'Quantity',
          invest: 'Invested value',
          toReceive: 'Value to receive',
          received: 'Received value',
          return: 'Returned',
          selectPH: 'Status',
          selectOptions: {
            cancel: 'Canceled',
            captated: 'Captated',
            liquidated: 'Complete',
            incapture: 'In capture',
          },
          err: {
            title: 'Failed to filter statement',
            body: 'Please check your filters or try again latter',
          },
        },
      },
      statusCard: {
        captation: 'In capture',
        complete: 'Completed',
        cancel: 'Canceled',
        captated: 'Capitated',
        ranOut: 'Ran out',
        accepted: 'Approved',
        analysis: 'In analysis'
      },
      depositTable: {
        order: 'Deposit',
        val: 'Deposited amount',
        date: 'Deposit date',
        time: 'Time of deposit',
        form: 'Payment method',
      },
      withdrawTable: {
        order: 'Withdraw',
        val: 'Withdrawn amount',
        date: 'Withdrawn date',
        key: 'Sent to key',
        form: 'Payment method',
      },
    },
    statementPage: {
      title: 'My investments',
      filter: 'Filter',
      blockchain: 'View at blockchain',
    },
    walletPage: {
      deposit: 'I want deposit',
      withdraw: 'I want withdraw',
      emphasisToken: 'Emphasis Token',
      statement: 'Statement',
      currentBalance: 'Current balance',
      investedTokens: 'Invested tokens',
      totalAssets: 'Total assets',
      quantity: 'Quantity',
      myInvestments: 'My investments',
      lastInvestments: 'Last investments',
      errorModal: {
        title: 'Attention',
        inactiveBody: 'Your profile is inactive so you will not be able to invest, get in contact with support.',
        inAnalysisBody: 'Your profile is under analysis, soon you will be able to invest.',
        button: 'Go back',
      },
    },
    profilePage: {
      wallet: 'Wallet',
      data: 'Information',
      address: 'Address',
      walletPlaceholder: 'Inform the wallet address',
      submit: 'Save',
      name: 'Full name',
      email: 'Email',
      phone: 'Phone',
      occupation: 'Occupation',
      income: 'Monthly income',
      cpf: 'ID',
      birth: 'Birth date',
      editSubmit: 'Edit',
      userNotAllowed: 'Your data is under analysis by our team, while we finish, some functions will be blocked.',
    },
    tokenCard: {
      minimumValue: 'Minimum value',
      monthlyRevenue: 'Monthly revenue',
    },
    depositPage: {
      numberHolder: 'Number of the deposit',
      valHolder: 'Amount deposited',
      dateHolder: 'Initial date of deposit',
      fDateHolder: 'Final date of deposit',
      cleanBtn: 'Clean filters',
      title: 'My deposits',
      filter: 'Filter',
      pixMsg: 'Invest in cryptoactives!',
      blocked: 'The deposit will be available in our plataform soon!',
      blockedAlt: 'Depsits blocked',
      firstStep: {
        title: 'Deposit',
        body: 'How much would you like to deposit?',
        valError: 'Cannot deposit 0 BRL!',
        submit: 'Continue',
      },
      secondStep: {
        body: 'Scan the QRCode bellow to make a deposit',
        or: 'or',
        copy: 'Copy paste pix',
        copied: 'Pix string copied!',
      },
      errorModal: {
        title: 'Attention',
        body: "It wasn't possible to finish the deposit.",
        button: 'Go back',
        inactiveBody: 'Your profile is inactive so you will not be able to deposit now, get in contact with support.',
        inAnalysisBody: 'Your profile is under analysis, soon you will be able to deposit.',
      },
    },
    withdrawPage: {
      firstStep: {
        title: 'Withdraw',
        body: 'Please inform the code sent to your email:',
        submit: 'Continue',
        resend: 'Email sent successefuly!',
        errorTitle: 'Attention',
        error: 'Could not validate code, please inform the code sent to your email',
      },
      secondStep: {
        title: 'Withdraw',
        warning: 'To withdraw, your CPF/CNPJ needs to be a PIX key.',
        max: 'Maximum',
        val: 'Amount to withdraw',
        type: 'Type of pix key',
        key: 'Pix key',
        placeholder: 'Select one',
        submit: 'Withdraw',
        typeError: 'Please select a type of key',
        valError: 'Please inform a value to be withdrawn',
        keyError: 'Please inform your pix key',
        emailError: 'Failed to validate email, please inform a valid email',
        cpfError: 'Failed to validade ID, please inform a valid ID',
        phoneError: 'Failed to validade phone number, please inform a valid number',
        error: 'Failed to complete the withdraw',
      },
      finalStep: {
        title: 'Withdrawn',
        body: 'Withdrawn successfuly!',
        goback: 'Go back',
      },
    },
    withdrawList: {
      order: 'Order #',
      date: 'Initial withdraw date',
      fdate: 'Final withdraw date',
      pix: 'Pix key',
      val: 'Withdrawn value',
    },
    securityPage: {
      title: 'Alter password',
      hint: 'Security hints',
      email: 'Email',
      pass: 'Password',
      accordionData: {
        hint1: {
          title: 'Protect your password',
          li1: "Don't use the same password in different plataforms;",
          li2: "In case you don't have any ideas for strong password, please use a password management system;",
          li3: "Don't let your account logged in on public spaces;",
          li4: "Don't share your password.",
        },
        hint2: {
          title: 'Recommendation for Gmail users',
          li1: 'Choose a strong password;',
          li2: 'Add options to recover your account;',
          li3: 'Add google security verification;',
          li4: 'Verify the options to recover accounts.',
        },
        hint3: {
          title: 'Use a secure network',
          li1: "Don't do operations on public networks, be it in our platform or other banking services, your trafic could be intercepted;",
          li2: 'Make sure to access addresses with secure connection.',
        },
        hint4: {
          title: 'Be careful with data saved on the browser',
          li1: 'As practical as it may seem to have your personal information saved, other people can access and take advantage of it;',
          li2: 'Always keep your web browser updated.',
        },
      },
      edit: {
        secondStep: {
          title: 'New password',
          body: 'Insert a new password and start investing again!',
        },
      },
    },
  },
};
