import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import United from '@assets/images/United.png';
import { ptBr } from '@assets';
import { Dropdown } from 'react-bootstrap';

const I18n = () => {
  const { i18n } = useTranslation();
  const [rightSelect, setRightSelect] = useState('pt-BR');
  const [lang, setLang] = useState(ptBr);

  useEffect(() => {
    i18n.changeLanguage('pt-BR');
  }, []);

  function handleChangeLanguage(language) {
    i18n.changeLanguage(language);
  }
  return (
    <div className="search-coundry d-flex align-items-center language-picker">
      <img src={lang} alt="" className="mx-2" style={{ borderRadius: '5rem' }} />
      <Dropdown className="sidebar-dropdown me-2 mt-2">
        <Dropdown.Toggle as="div" className="i-false sidebar-select">
          {rightSelect} <i className="fa-solid fa-angle-down ms-2" />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              handleChangeLanguage('en-US');
              setRightSelect('en-US');
              setLang(United);
            }}
          >
            en-US
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              handleChangeLanguage('pt-BR');
              setRightSelect('pt-BR');
              setLang(ptBr);
            }}
          >
            pt-BR
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default I18n;
