import { createContext, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import swal from 'sweetalert';

import * as tokenService from '../../services/Issuer/tokenService';

export const TokenContext = createContext({});

const TokenProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [allTokens, setAllTokens] = useState([]);
  const [token, setToken] = useState({});

  const { t } = useTranslation();

  const getAllTokens = useCallback(async (params = { issuerId: '', page: 0, pageSize: 12 }) => {
    setLoading(true);

    try {
      const reqParams = {
        page: params.page ? params.page - 1 : 0,
        pageSize: params.pageSize ? params.pageSize : 12,
        ...(params && { ...params }),
      };

      const { data } = await tokenService.getAllTokens(reqParams);

      setAllTokens(data.data);
    } catch (error) {
      const errTitle = t('pages.tokenDetails.apiErr.title');
      const errBody = t('pages.tokenDetails.apiErr.body');
      swal(errTitle, errBody, 'error'); 
    } finally {
      setLoading(false);
    }
  }, []);

  const getOneToken = useCallback(async (tokenId) => {
    setLoading(true);

    try {
      const { data } = await tokenService.getOneToken(tokenId);

      setToken(data);
    } catch (error) {
      const errTitle = t('pages.tokenDetails.apiErr.title');
      const errBody = t('pages.tokenDetails.apiErr.body');
      swal(errTitle, errBody, 'error');
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <TokenContext.Provider value={{ loading, allTokens, getAllTokens, getOneToken, token }}>
      {children}
    </TokenContext.Provider>
  );
};

const useToken = () => {
  return useContext(TokenContext);
};

export { TokenProvider, useToken };
