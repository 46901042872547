import { useState } from 'react';
import { connect } from 'react-redux';

import { I18n } from '@components';
import { bgIssuer } from '@assets';
import { useZoom } from '@utils';

import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import PageHeader from './PageHeader';
import FinalStep from './FinalStep';
import { useEffect } from 'react';

function Register(props) {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState();
  const [code, setCode] = useState();
  const [error, setError] = useState(false);
  const zoom = useZoom();

  useEffect(() => {
    const emailStorage = localStorage.getItem('issuer_email');
    const idStorage = localStorage.getItem('issuerLoginId');
    const tokenStorage = localStorage.getItem('access_token_register_issuer');
    if (emailStorage && idStorage) {
      setStep(2);
    }
    if (tokenStorage) {
      setStep(3);
      setCode(tokenStorage);
    }
  }, []);

  return (
    <div className="page-wraper">
      <div className="browse-job login-style3">
        <div
          className="bg-img-fix overflow-hidden"
          style={{
            background: '#fff url(' + bgIssuer + ')',
            height: '100vh',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right',
            backgroundSize: 'contain',
          }}
        >
          <div className="row gx-0">
            <div className="col-xxl-4 col-xl-5 col-lg-6 col-md-8 col-sm-12 vh-100 bg-white d-flex justify-content-center align-items-center">
              <div className="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside col-12">
                <div
                  id="mCSB_1_container"
                  className="mCSB_container"
                  style={{
                    position: 'relative',
                    top: 25,
                    left: '0',
                    dir: 'ltr',
                  }}
                >
                  <div className={`${zoom > 1 || error ? 'scroll-bar-force' : 'scroll-bar-wrapper'}`}>
                    <div className="login-form style-2">
                      <div className="card-body form-main">
                        <PageHeader props={{ step }} />
                        {step === 1 ? (
                          <FirstStep props={{ setStep, setSendEmail: setEmail, setError }} />
                        ) : step === 2 ? (
                          <SecondStep props={{ email, setCode, code, setStep }} />
                        ) : step === 3 ? (
                          <FinalStep props={{ code, setStep }} />
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <I18n />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(Register);
