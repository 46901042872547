import { stage1 } from '@assets';
import { Translator } from '@components';
import { moneyMask } from '@utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const FirstStep = ({ props: { handleStep } }) => {
  const [deposit, setDeposit] = useState();
  const [value, setValue] = useState();
  const { t } = useTranslation();
  const errorsObj = { obj: '' };
  const [errors, setErrors] = useState(errorsObj);

  const handleDepositVal = (e) => {
    const wage = moneyMask(e.target.value);
    setDeposit(wage);
    const value = wage.replace('R', '').replace('$', '').replaceAll('.', '').replaceAll(',', '.').replaceAll(' ', '');
    setValue(value);
  };

  useEffect(() => {
    const errorObj = { ...errorsObj };
    errorObj.obj = '';
    setErrors(errorObj);
  }, [value]);

  const assembleBody = async () => {
    if (value === NaN || value === 0 || value === undefined) {
      const errorObj = { ...errorsObj };
      errorObj.obj = t('depositPage.firstStep.valError').toString();
      setErrors(errorObj);
    } else {
      const body = {
        value: +value,
        description: 'Pix de depósito criptech',
        status: 'PENDING',
      };
      handleStep(body);
    }
  };

  return (
    <>
      <div className="col-12 d-flex justify-content-center">
        <img src={stage1} className="col-8" />
      </div>
      <div
        className="body col-12 d-flex flex-column justify-content-start align-items-center"
        style={{ margin: '2.5rem 0' }}
      >
        <p>
          <Translator path="depositPage.firstStep.body" />
        </p>
        <div className="col-8">
          <input
            className="col-12"
            value={deposit ? deposit : 'R$ 0,00'}
            style={{ border: 0, textAlign: 'center' }}
            onChange={(e) => handleDepositVal(e)}
          />
          <div className="col-12" style={{ borderBottom: '1px solid #EDEDED' }} />
          {errors.obj && <div className="text-danger fs-12">{errors.obj}</div>}
        </div>
      </div>
      <button className="btn btn-orange col-5" onClick={() => assembleBody()}>
        <Translator path="depositPage.firstStep.submit" />
      </button>
    </>
  );
};

export default FirstStep;
