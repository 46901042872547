import React from 'react';
import loadable from '@loadable/component';
import pMinDelay from 'p-min-delay';
const MarketlineChartRadial = loadable(() => pMinDelay(import('./MarketlineChartRadial'), 1000));

const Graph = ({ props }) => {
  return (
    <>
      {props.showGraph ? (
        <div className="d-flex justify-content-center align-items-center">
          <MarketlineChartRadial percentage={props.porcentage} />
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default Graph;
