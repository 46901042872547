import { createContext, useCallback, useContext, useState } from 'react';
import swal from 'sweetalert';
import { useTranslation } from 'react-i18next';

import * as purchaseService from '../../services/Investor/purchaseService';

export const PurchaseContext = createContext({});

const PurchaseProvider = ({ children }) => {
  const [loadingPurchase, setLoadingPurchase] = useState(false);
  const [complete, setComplete] = useState(false);
  const [purchase, setPurchase] = useState();
  const [totalPurchases, setTotalPurchases] = useState();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [myPurchases, setMyPurchases] = useState();
  const { t } = useTranslation();

  const buyToken = useCallback(async (body) => {
    setLoadingPurchase(true);

    try {
      setShowConfirmationModal(false);
      const purchase = await purchaseService.buyToken(body);

      setPurchase(purchase);
      setComplete(true);
      setShowConfirmationModal(true);
    } catch (error) {
      const errorTitle = t('investment.investmentModal.attention');
      const errorMsg = t('investment.investmentModal.transactionError');
      swal(errorTitle, errorMsg, 'error');
    } finally {
      setLoadingPurchase(false);
    }
  }, []);

  const listPurchases = useCallback(async (page, filters, userId) => {
    setLoadingPurchase(true);
    try {
      const params = {
        page: page ? page - 1 : 0,
        pageSize: 12,
        ...(filters && { ...filters }),
        userId: userId,
      };
      const { data } = await purchaseService.getPurchases(params);
      setTotalPurchases(data.total);
      setMyPurchases(data.data);
    } catch (error) {
      const errorTitle = t('components.statementTable.filter.err.title');
      const errorMsg = t('components.statementTable.filter.err.body');
      swal(errorTitle, errorMsg, 'error');
    } finally {
      setLoadingPurchase(false);
    }
  }, []);

  const listUserPurchases = useCallback(async (userId) => {
    setLoadingPurchase(true);
    try {
      const { data } = await purchaseService.getPurchasesByUser(userId);
      setMyPurchases(data);
    } catch (error) {
      const errorTitle = t('components.statementTable.filter.err.title');
      const errorMsg = t('components.statementTable.filter.err.body');
      swal(errorTitle, errorMsg, 'error');
    } finally {
      setLoadingPurchase(false);
    }
  }, []);

  const listUserLastPurchases = useCallback(async (userId) => {
    setLoadingPurchase(true);
    try {
      const params = {
        page: 0,
        pageSize: 3,
        order: '',
        userId: userId,
      };
      const { data } = await purchaseService.getPurchases(params);
      setMyPurchases(data.data);
    } catch (error) {
      const errorTitle = t('components.statementTable.filter.err.title');
      const errorMsg = t('components.statementTable.filter.err.body');
      swal(errorTitle, errorMsg, 'error');
    } finally {
      setLoadingPurchase(false);
    }
  }, []);

  const closeModal = (navigate, tokenId) => {
    setShowConfirmationModal(false);
    navigate(`/investment/${tokenId}/details`);
  };

  return (
    <PurchaseContext.Provider
      value={{
        loadingPurchase,
        buyToken,
        complete,
        purchase,
        showConfirmationModal,
        closeModal,
        listPurchases,
        listUserPurchases,
        myPurchases,
        totalPurchases,
      }}
    >
      {children}
    </PurchaseContext.Provider>
  );
};

const usePurchase = () => {
  return useContext(PurchaseContext);
};

export { PurchaseProvider, usePurchase };
