import { logoEmissor, proposalSuccess } from '@assets';
import { Translator } from '@components';
import { useIssuer } from '@contexts-issuer';
import { Button, Modal, ModalBody } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export const SuccessModal = ({ show, setShow }) => {
  const { issuerInfo } = useIssuer();
  const navigate = useNavigate();

  const closeModal = () => {
    setShow(false);
  };

  if (issuerInfo) {
    return (
      <Modal show={show} centered className="success-modal" size="lg">
        <ModalBody>
          <img src={logoEmissor} />
          <div className="info">
            <p>
              <Translator path={'pages.tokenProposal.successModal.text'} />
              <span>{issuerInfo.email}</span>
            </p>
            <img src={proposalSuccess} alt="" />

            <Button
              variant="primary"
              size="lg"
              className="success-btn text-center m-0"
              id="btn"
              onClick={() => {
                closeModal();
                navigate('/tokenProposal/list');
              }}
            >
              <Translator path={'pages.tokenProposal.successModal.seeProposal'} />
            </Button>
          </div>
        </ModalBody>
      </Modal>
    );
  }
};
