import React, { useEffect, useState } from 'react';
import { useTokenProposal } from '@contexts-issuer';
import { noPreview } from '@assets';
import { Buffer } from 'buffer';
const Header = ({ props: { info } }) => {
  const [preview, setPreview] = useState();
  const { downloadTokenImage } = useTokenProposal();
  useEffect(() => {
    const downloadImage = async (link) => {
      const data = await downloadTokenImage(link);
      const downloadedImage = Buffer.from(data.data, 'binary').toString('base64');
      setPreview(downloadedImage);
    };

    if (info) downloadImage(info.image);
  }, [info]);

  return (
    <>
      <div className="col-6 image-container">
        <img
          src={preview ? `data:image/jpeg;base64,${preview}` : noPreview}
          alt=""
          style={{
            backgroundImage: `url(${preview ? preview : noPreview})`,
            height: '100%',
            width: '100%',
          }}
        />
      </div>
      <div className="col-6 title-container">
        <p>{info.name}</p>
      </div>
    </>
  );
};

export default Header;
