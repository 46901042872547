import React, { Fragment, useState } from 'react';

import SideBar from './SideBar';
import NavHeader from './NavHeader';
import Header from './Header';
//import RightSideBar from "./RightSideBar";
// import ChatBox from '../ChatBox';

const JobieNav = ({ title, onClick: ClickToAddEvent, onClick2, onClick3, publicRoute }) => {
  const [toggle, setToggle] = useState('');
  const [navToggle, setNavToggle] = useState(false);
  const onClick = (name) => setToggle(toggle === name ? '' : name);

  if (!publicRoute) {
    return (
      <Fragment>
        <NavHeader props={{ toggle: navToggle, setToggle: setNavToggle }} />

        <Header
          onNote={() => onClick('chatbox')}
          onNotification={() => onClick('notification')}
          onProfile={() => onClick('profile')}
          toggle={toggle}
          title={title}
          onBox={() => onClick('box')}
          onClick={() => ClickToAddEvent()}
          publicRoute={false}
        />
        <SideBar publicRoute={false} toggle={navToggle} />
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <NavHeader props={{ toggle: navToggle, setToggle: setNavToggle }} />
        <Header
          onNote={() => onClick('chatbox')}
          onNotification={() => onClick('notification')}
          onProfile={() => onClick('profile')}
          toggle={toggle}
          title={title}
          onBox={() => onClick('box')}
          onClick={() => ClickToAddEvent()}
          publicRoute={true}
        />
        <SideBar publicRoute={true} toggle={navToggle} />
      </Fragment>
    );
  }
};

export default JobieNav;
