import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Translator, SelectInput, InputText } from '@components';
import { moneyMask, setNumberOnly } from '@utils';

const FilterModal = ({ props: { show, setShow, setFilters, filters } }) => {
  const [depositCode, setDepositCode] = useState();
  const [depositValue, setDepositValue] = useState();
  const [tokenName, setTokenName] = useState();
  const [tokenCode, setTokenCode] = useState();
  const [expectedDepositDateInitial, setEDDI] = useState();
  const [expectedDepositDateFinal, setEDDF] = useState();
  const [depositDateInitial, setDepositDateInitial] = useState();
  const [depositDateFinal, setDepositDateFinal] = useState();
  const [selectedOption, setSelectedOption] = useState(null);
  const { t } = useTranslation();

  const onHideModal = () => {
    setShow(false);
  };

  const clearFilters = () => {
    setFilters({});
    setShow(false);
  };

  const handleFilters = () => {
    const filters = {
      depositCode: depositCode ? depositCode : undefined,
      depositValue: depositValue ? depositValue : undefined,
      tokenName: tokenName ? tokenName : undefined,
      tokenCode: tokenCode ? tokenCode : undefined,
      expectedDepositDateFinal: expectedDepositDateFinal
        ? new Date(expectedDepositDateFinal).toISOString().split(/[A-Z]/)[0]
        : undefined,
      expectedDepositDateInitial: expectedDepositDateInitial
        ? new Date(expectedDepositDateInitial).toISOString().split(/[A-Z]/)[0]
        : undefined,
      depositDateFinal: depositDateFinal ? new Date(depositDateFinal).toISOString().split(/[A-Z]/)[0] : undefined,
      depositDateInitial: depositDateInitial ? new Date(depositDateInitial).toISOString().split(/[A-Z]/)[0] : undefined,
      status: selectedOption && selectedOption.value ? selectedOption.value : undefined,
    };
    setFilters(filters);
    setShow(false);
  };

  const selectOption = [
    { value: 'INCAPTURE', label: t('pages.withdrawList.status.incapture') },
    { value: 'AVAILABLE', label: t('pages.withdrawList.status.available') },
    { value: 'ACCOUNT_IN_ANALYSIS', label: t('pages.withdrawList.status.analysing') },
    { value: 'REQUESTED', label: t('pages.withdrawList.status.requested') },
    { value: 'DONE', label: t('pages.withdrawList.status.done') },
  ];

  const selectStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      background: '#FFFFFF',
      lineHeight: 2.4,
      fontSize: '1rem',
      height: '3rem',
      color: 'var(--light-gray) !important',
      borderColor: '#c0c0c0',
      borderRadius: '0.5rem',
      border: '1px solid #C0C0C0',
      boxShadow: '#ff9900',

      '&:hover': {
        borderColor: '#ff9900',
      },
      '&:hover,&:active': {
        boxShadow: 'none',
        background: '#FFFFFF',
        color: '#757575',
      },
    }),

    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#FF9900' : 'white',
      color: 'var(--light-gray) !important',

      '&:hover': {
        borderColor: '#FF9900',
        backgroundColor: '#FF9900',
      },
    }),
    placeholder: (baseStyles, state) => ({
      ...baseStyles,
      color: 'var(--light-gray) !important',
    }),
  };

  const selectData = {
    options: selectOption,
    styles: selectStyles,
    select: selectedOption,
    setSelect: setSelectedOption,
    placeholder: '',
  };

  return (
    <Modal show={show} aria-labelledby="contained-modal-title-vcenter" size="lg" centered onEscapeKeyDown={onHideModal}>
      <Modal.Body
        style={{ margin: 0, padding: '1.25rem 0 ' }}
        className="row d-flex flex-column justify-content-center align-items-center"
      >
        <div className="row" style={{ margin: 0, padding: '0 2.5rem' }}>
          <div
            className="col-12 d-flex justify-content-between align-items-center flex-row "
            style={{ marginBottom: '1.25rem', padding: 0 }}
          >
            <h4 className="col-3 modal-title-deposit">
              <Translator path="pages.withdrawList.filter" />
            </h4>
            <i className="material-icons moda-close-icon cursor-pointer" onClick={onHideModal}>
              close
            </i>
          </div>
        </div>
        <div className="col-12" style={{ borderBottom: '1px solid #EDEDED' }} />
        <div className="row " style={{ padding: '0 2.5rem', margin: 0 }}>
          <div
            className="col-12 d-flex justify-content-center align-items-center "
            style={{ margin: '2.5rem 0 1.25rem 0', padding: 0 }}
          >
            <div className="col-6 no-picker" style={{ paddingRight: '0.625rem' }}>
              <div style={{ marginBottom: '1.25rem' }}>
                <div className="d-flex flex-row text-input-no-star">
                  <p className="text-input-label">
                    <Translator path={'pages.withdrawList.filters.dateIni'} />
                  </p>
                </div>
                <div className="d-flex align-items-center justify-content-end ">
                  <i className="material-icons icon-float-no-margin" style={{ margin: '0 15px 10px 0', zIndex: 1 }}>
                    today
                  </i>
                  <DatePicker
                    className="form-control"
                    selected={
                      expectedDepositDateInitial
                        ? new Date(expectedDepositDateInitial)
                        : filters && filters.expectedDepositDateInitial
                        ? new Date(filters.expectedDepositDateInitial)
                        : ''
                    }
                    dateFormat="dd/MM/yyyy"
                    maxDate={expectedDepositDateFinal}
                    onChange={(date) => setEDDI(date)}
                  />
                </div>
              </div>
              <div>
                <div className="d-flex flex-row text-input-no-star">
                  <p className="text-input-label">
                    <Translator path={'pages.depositList.filters.dateIni'} />
                  </p>
                </div>
                <div className="d-flex align-items-center justify-content-end ">
                  <i className="material-icons icon-float-no-margin" style={{ margin: '0 15px 10px 0', zIndex: 1 }}>
                    today
                  </i>
                  <DatePicker
                    className="form-control"
                    selected={
                      depositDateInitial
                        ? new Date(depositDateInitial)
                        : filters && filters.depositDateInitial
                        ? new Date(filters.depositDateInitial)
                        : ''
                    }
                    dateFormat="dd/MM/yyyy"
                    maxDate={depositDateFinal}
                    onChange={(date) => setDepositDateInitial(date)}
                  />
                </div>
              </div>
            </div>
            <div className="col-6" style={{ paddingLeft: '0.625rem' }}>
              <div style={{ marginBottom: '1.25rem' }}>
                <div className="d-flex flex-row text-input-no-star">
                  <p className="text-input-label">
                    <Translator path={'pages.withdrawList.filters.dateFi'} />
                  </p>
                </div>
                <div className="d-flex align-items-center justify-content-end">
                  <i className="material-icons icon-float-no-margin" style={{ margin: '0 15px 10px 0', zIndex: 1 }}>
                    today
                  </i>
                  <DatePicker
                    className="form-control"
                    selected={
                      expectedDepositDateFinal
                        ? new Date(expectedDepositDateFinal)
                        : filters && filters.expectedDepositDateFinal
                        ? new Date(filters.expectedDepositDateFinal)
                        : ''
                    }
                    dateFormat="dd/MM/yyyy"
                    minDate={expectedDepositDateInitial}
                    onChange={(date) => setEDDF(date)}
                  />
                </div>
              </div>
              <div>
                <div className="d-flex flex-row text-input-no-star">
                  <p className="text-input-label">
                    <Translator path={'pages.depositList.filters.dateFi'} />
                  </p>
                </div>
                <div className="d-flex align-items-center justify-content-end  ">
                  <i className="material-icons icon-float-no-margin" style={{ margin: '0 15px 10px 0', zIndex: 1 }}>
                    today
                  </i>
                  <DatePicker
                    className="form-control"
                    selected={
                      depositDateFinal
                        ? new Date(depositDateFinal)
                        : filters && filters.depositDateFinal
                        ? new Date(filters.depositDateFinal)
                        : ''
                    }
                    dateFormat="dd/MM/yyyy"
                    minDate={depositDateInitial}
                    onChange={(date) => setDepositDateFinal(date)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 d-flex justify-content-center align-items-center" style={{ padding: 0, margin: 0 }}>
            <div className="col-6 no-picker" style={{ paddingRight: '0.625rem' }}>
              <div style={{ marginBottom: '1.25rem' }}>
                <InputText
                  type="text"
                  className="form-control"
                  obrigatory={false}
                  text={'pages.depositList.filters.order'}
                  value={depositCode ? depositCode : filters && filters.depositCode ? filters.depositCode : ''}
                  onChange={setDepositCode}
                />
              </div>
              <InputText
                type="text"
                className="form-control"
                obrigatory={false}
                text={'pages.withdrawList.filters.tokenCode'}
                value={tokenCode ? tokenCode : filters && filters.tokenCode ? filters.tokenCode : ''}
                onChange={setTokenCode}
              />
            </div>
            <div className="col-6" style={{ paddingLeft: '0.625rem' }}>
              <div style={{ marginBottom: '1.25rem' }}>
                <InputText
                  type="text"
                  className="form-control"
                  text={'pages.withdrawList.filters.name'}
                  style={{ marginBottom: '2.5rem' }}
                  obrigatory={false}
                  value={tokenName ? tokenName : filters && filters.tokenName ? filters.tokenName : ''}
                  onChange={setTokenName}
                />
              </div>
              <InputText
                type="text"
                className="form-control"
                text={'pages.withdrawList.filters.amount'}
                obrigatory={false}
                setWithMask={setNumberOnly}
                setState={setDepositValue}
                value={
                  depositValue
                    ? moneyMask(depositValue)
                    : filters && filters.depositValue
                    ? moneyMask(filters.depositValue)
                    : ''
                }
              />
            </div>
          </div>
          <div
            className="col-12 d-flex justify-content-start align-items-center"
            style={{ margin: '1.25rem 0 2.5rem 0', padding: 0 }}
          >
            <div className="col-6" style={{ paddingRight: '0.625rem' }}>
              <div className="d-flex flex-row text-input-no-star">
                <p className="text-input-label">
                  <Translator path={'pages.withdrawList.filters.status'} />
                </p>
              </div>
              <SelectInput props={selectData} />
            </div>
          </div>
        </div>
        <div className="col-12" style={{ borderBottom: '1px solid #EDEDED' }} />
        <div className="row" style={{ padding: '0 2.5rem', margin: 0 }}>
          <div
            className="col-12 d-flex justify-content-end"
            style={{ gap: '1.25rem', marginTop: '1.25rem', padding: 0 }}
          >
            <button className="btn btn-outlined col-4" onClick={() => clearFilters()}>
              <Translator path="pages.withdrawList.filters.cleanBtn" />
            </button>
            <button className="btn btn-orange col-3" onClick={() => handleFilters()}>
              <Translator path="pages.withdrawList.filters.filter" />
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default FilterModal;
