import { Translator, TokenCard, SimpleCard, Profile } from '@components';
import { calIcon, dollarIcon } from '@assets';
import { Card, Modal, Button, Row, Col } from 'react-bootstrap';
import { formatDate } from 'date-utils-2020';

import { CloseIcon } from '@assets';

export const ProposalPreview = ({ show, token, issuer, setState }) => {
  const closeModal = () => {
    setState(false);
  };

  return (
    <Modal
      fullscreen={true}
      show={show}
      style={{ backgroundColor: 'var(--very-light-color)', width: '100vw' }}
      className="modal-modified"
      onEscapeKeyDown={closeModal}
    >
      <Card className="preview-card d-row align-items-center">
        <p className="m-0">
          <Translator path={'pages.tokenProposal.proposalPreview.title'} />
        </p>
        <Button variant="link close-button p-0" onClick={closeModal}>
          <img src={CloseIcon} />
        </Button>
      </Card>
      <Modal.Body>
        <Row className="d-flex justify-content-center" style={{ gap: '1.25rem', maxHeight: '34rem' }}>
          <Col
            className="d-flex flex-column gap p-0"
            style={{ gap: '1.25rem', maxWidth: '36.813rem', maxHeight: '34rem' }}
          >
            <div
              className="col-lg-12 invest-label d-flex align-items-center justify-content-center flex-column"
              style={{ backgroundColor: 'red', height: '6.875rem' }}
            >
              <div
                className="col-lg-12 d-flex flex-row justify-content-center align-items-center mb-0 form-group"
                style={{ textAlign: 'center', alignItems: 'center', padding: '2.5rem' }}
              >
                <p className="mb-0" style={{ whiteSpace: 'nowrap', fontSize: '1.5rem' }}>
                  <Translator path="pages.tokenProposal.proposalPreview.currentBalance" />
                </p>
                <input
                  className="mb-0 form-control show-pass invest-input"
                  style={{ border: 0, fontSize: '1.5rem', textAlign: 'start' }}
                  readOnly
                  value={' R$ 00,00'}
                  type="text"
                />
                <i className="show-pass fa fa-eye-slash" />
              </div>
            </div>
            <div className="token-card-preview-padding" style={{ maxHeight: '25.8785rem', height: '25.875rem' }}>
              <TokenCard props={{ ...token, className: 'token-info' }}isButton={false} />
            </div>
          </Col>
          <Col style={{ maxHeight: '34rem', maxWidth: '26.875rem', padding: 0 }}>
            <div className="row col-xl-12 col-md-6 col-xs-12 padding-control m-0">
              <div
                className="col-lg-12 d-flex flex-column invest-label"
                style={{ padding: '0 2rem 0 2rem', minHeight: '34rem', maxHeight: '34rem' }}
              >
                <p
                  className="mb-0 info-buy-title col-lg-6 "
                  style={{ whiteSpace: 'nowrap', padding: 0, marginTop: '2.5rem' }}
                >
                  <Translator path="pages.tokenProposal.proposalPreview.invest" />
                </p>
                <div className="d-flex" style={{ whiteSpace: 'nowrap', marginBottom: '0.625rem' }}>
                  <p className="info-label fs-5" style={{ fontWeight: '400' }}>
                    <Translator path="pages.tokenProposal.proposalPreview.tokenName" />
                  </p>

                  <p className="info-text" style={{ marginLeft: '0.313rem', fontWeight: '500' }}>
                    {token?.name}
                  </p>
                </div>
                <div className="d-flex" style={{ whiteSpace: 'nowrap' }}>
                  <p className="info-label fs-5">
                    <Translator path="pages.tokenProposal.proposalPreview.unityPrice" />
                  </p>

                  <p className="info-text" style={{ marginLeft: '0.313rem' }}>
                    {token?.unityPrice
                      ? token?.unityPrice?.toLocaleString('pt-BT', { style: 'currency', currency: 'BRL' })
                      : 'R$ 0,00'}
                  </p>
                </div>
                <div
                  className="col-lg-12"
                  style={{
                    borderBottom: '1px solid #c0c0c0',
                    marginTop: '2.5rem',
                    marginBottom: '2.5rem',
                  }}
                />
                <div
                  className="d-flex col-lg-12 justify-content-start align-items-center"
                  style={{ gap: '0.625rem', marginBottom: '1.25rem' }}
                >
                  <p className="info-buy-opt mb-0">
                    <Translator path="pages.tokenProposal.proposalPreview.quantity" />
                  </p>

                  <i className="fa-solid fa-square-minus" />
                  <input
                    className="info-buy-input col-lg-1"
                    style={{ height: '3vh', width: '3vh' }}
                    readOnly
                    value={0}
                  />
                  <i className="fa-solid fa-square-plus" />
                </div>
                <p className="info-buy-opt mb-0">
                  <Translator path="pages.tokenProposal.proposalPreview.valueToInvest" />
                </p>
                <input
                  className="info-buy-input-opacity col-lg-12 col-md-12 col-sm-12 col-xs-12"
                  type="text"
                  style={{ height: '3.375rem', marginTop: '0.625rem' }}
                  value={'R$ 0,00'}
                  readOnly
                />
                <button className="invest-btn btn btn-orange col-6 info-btn" disabled>
                  <Translator path="pages.tokenProposal.proposalPreview.invest" />
                </button>
              </div>
            </div>
          </Col>
          <Col
            className="my-col-3 d-flex flex-column p-0"
            style={{ gap: '1.25rem', maxWidth: '17.125rem', maxHeight: '34rem', flexGrow: 1 }}
          >
            <Row>
              <div
                className="invest-label-padding invest-label-padding-preview invest-label-shadow col-xxl-12 col-xl-5 col-lg-5 col-md-5 col-sm-5 col-xs-5"
                style={{ marginBottom: '0 !important', height: '16.375rem', maxWidth: '17.125rem' }}
              >
                <img src={dollarIcon} className="invest-label-icon mb-2" />
                <p className="info-revenue invest-label-icon-margin ">
                  <Translator path="pages.tokenProposal.proposalPreview.valueToReceive" />
                </p>
                <p className="text-success info-text-big" style={{ whiteSpace: 'nowrap' }}>
                  R$ 0,00
                </p>
              </div>
            </Row>
            <Row>
              <div
                className="invest-label-padding invest-label-padding-preview invest-label-shadow col-xxl-12 col-xl-5 col-lg-5 col-md-5 col-sm-5 col-xs-5"
                style={{ maxWidth: '17.125rem', height: '16.375rem' }}
              >
                <img src={calIcon} className="invest-label-icon mb-2" />
                <p className="info-revenue invest-label-icon-margin ">
                  <Translator path="pages.tokenProposal.proposalPreview.receiveDate" />
                  {token?.paymentParcels}
                </p>
                <p className="info-text info-text-big">
                  {token?.liquidationDate
                    ? formatDate(token?.liquidationDate, 'dd/MM/yyyy')
                    : formatDate(new Date(), 'dd/MM/yyyy')}
                </p>
              </div>
            </Row>
          </Col>
        </Row>
        <Row className="description d-flex justify-content-center" style={{ maxWidth: '86rem', margin: '2.5rem auto' }}>
          <h3 className="investment-title">
            <Translator path="pages.tokenProposal.proposalPreview.about" />
          </h3>
          <div className="about-token card flex-column h-100">
            <div className="card-title mx-0">
              <Translator path={'pages.tokenProposal.proposalPreview.description'} />
            </div>
            <div className="card-stroke" />
            <div className="card-info">
              <p>{token?.description}</p>
            </div>
          </div>
        </Row>
        <Row className="d-flex flex-row justify-content-center">
          <Col style={{ maxWidth: '44rem' }}>
            <div className="general-info card flex-column">
              <div className="card-title mx-0">
                <Translator path="pages.tokenProposal.proposalPreview.info" />
              </div>
              <div className="card-stroke stroke" />
              <div className="card-general-info mb-4">
                <SimpleCard
                  icon={'today'}
                  info={
                    token?.fundingLimitDate
                      ? formatDate(token?.fundingLimitDate, 'dd/MM/yyyy')
                      : formatDate(new Date(), 'dd/MM/yyyy')
                  }
                  title={<Translator path="pages.tokenProposal.proposalPreview.captation" />}
                  infoType="date"
                />
                <SimpleCard
                  icon={'attach_money'}
                  info={token?.soldValue}
                  title={<Translator path="pages.tokenProposal.proposalPreview.currentCaptation" />}
                  infoType="currency"
                />
                <SimpleCard
                  icon={'production_quantity_limits'}
                  info={token.quantity}
                  title={<Translator path="pages.tokenProposal.proposalPreview.remainingQuantity" />}
                  infoType="number"
                />
                <SimpleCard
                  icon={'groups'}
                  info={token?.buyersQuantity}
                  title={<Translator path="pages.tokenProposal.proposalPreview.buyers" />}
                  infoType="number"
                />
                <SimpleCard
                  icon={'date_range'}
                  info={
                    token?.liquidationDate
                      ? formatDate(token?.liquidationDate, 'dd/MM/yyyy')
                      : formatDate(new Date(), 'dd/MM/yyyy')
                  }
                  title={<Translator path="pages.tokenProposal.proposalPreview.liquidation" />}
                  infoType="date"
                />
                <SimpleCard
                  icon={'money'}
                  info={token?.valueToReceive}
                  title={<Translator path="pages.tokenProposal.proposalPreview.totalValue" />}
                  infoType="currency"
                />
                <SimpleCard
                  icon={'token'}
                  info={token?.quantity}
                  title={<Translator path="pages.tokenProposal.proposalPreview.offeredTokens" />}
                  infoType="number"
                />
                <SimpleCard
                  icon={'shopping_cart'}
                  info={token?.unityPrice}
                  title={<Translator path="pages.tokenProposal.proposalPreview.minimumValue" />}
                  infoType="currency"
                />
              </div>
            </div>
          </Col>
          <Col style={{ maxWidth: '43rem', minWidth: '42.5rem' }}>
            <div className="issuer-info card">
              <div className="card-title mx-0">
                <Translator path="pages.tokenProposal.proposalPreview.issuerInfo" />
              </div>
              <div className="card-stroke" style={{ marginTop: '-1.25rem' }} />
              <div className="about-info about-issuer gap-0 flex-column">
                <Profile person={issuer} isIssuer={true} />
              </div>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};
