import React from 'react';
import ErrorGif from '@assets/gifs/error.gif';
import { Translator } from '@components';

const ErrorModal = ({ props: { closeAll, errorType } }) => {
  return (
    <>
      <div className="col-12">
        <h3 className="error-modal-withdraw text-center">
          <Translator path="depositPage.errorModal.title" />
        </h3>
        <p className="error-modal-withdraw text-center">
          {errorType == 'INACTIVE' ? (
            <Translator path="depositPage.errorModal.inactiveBody" />
          ) : errorType === 'IN_ANALYSIS' ? (
            <Translator path="depositPage.errorModal.inAnalysisBody" />
          ) : (
            <Translator path="depositPage.errorModal.body" />
          )}
        </p>
      </div>
      <div className="col-12 d-flex justify-content-center" style={{ marginBottom: '1.5rem' }}>
        <img src={ErrorGif} alt="" className="col-3" />
      </div>
      <button className="btn btn-orange col-4" onClick={() => closeAll()}>
        <Translator path="depositPage.errorModal.button" />
      </button>
    </>
  );
};

export default ErrorModal;
