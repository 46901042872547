import { emptyIssuerTokens } from '@assets';
import { Loading, Table, TokenDetails, Translator } from '@components';
import { useIssuer, useToken } from '@contexts-issuer';
import { formatDate } from 'date-utils-2020';
import { useEffect, useState } from 'react';
import { Dropdown, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import StatusCard from './StatusCard';
import { FiltersModal } from './FiltersModal';
import { COLUMNS } from './TableColumns';

export const TokensList = () => {
  const [tokens, setTokens] = useState([]);
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [tokenToDetail, setTokenToDetail] = useState({});

  const { getAllTokens, allTokens, loading } = useToken();

  const { issuerProfile } = useIssuer();

  const navigate = useNavigate();

  useEffect(() => {
    const issuerId = localStorage.getItem('issuerId');
    getAllTokens({ issuerId });
  }, []);

  useEffect(() => {
    if (allTokens) {
      const tokens = allTokens.map((token) => {
        const actions = (
          <Dropdown style={{ color: 'var(--orange)' }}>
            <Dropdown.Toggle as="div" className="i-false sidebar-select">
              <i className="material-icons cursor-pointer">more_horiz</i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {token.issuerStatus === 'INCAPTURE' ? (
                <Dropdown.Item onClick={() => handleShowDetailsModal(token)}>
                  <Translator path={'pages.tokensList.actions.detail'} />
                </Dropdown.Item>
              ) : token.issuerStatus === 'ALLSOLD' ? (
                <>
                  <Dropdown.Item onClick={() => handleShowDetailsModal(token)}>
                    <Translator path={'pages.tokensList.actions.detail'} />
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => navigate('/wallet/withdrawList')}>
                    <Translator path={'pages.tokensList.actions.withdraw'} />
                  </Dropdown.Item>
                </>
              ) : token.issuerStatus === 'LIQUIDATING' ? (
                <>
                  <Dropdown.Item onClick={() => handleShowDetailsModal(token)}>
                    <Translator path={'pages.tokensList.actions.detail'} />
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => navigate('/wallet/depositList')}>
                    <Translator path={'pages.tokensList.actions.deposit'} />
                  </Dropdown.Item>
                </>
              ) : (
                ''
              )}
            </Dropdown.Menu>
          </Dropdown>
        );

        return {
          ...token,
          formattedOfferedValue:
            token.issuerStatus === 'LIQUIDATING' || token.issuerStatus === 'COMPLETE'
              ? '-'
              : `${token.offeredValue.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}`,
          formattedFundingDate: formatDate(token.fundingLimitDate, 'dd/MM/yyyy'),
          formattedLiquidationDate: formatDate(token.prePaymentDate, 'dd/MM/yyyy'),
          formattedStatus: <StatusCard status={token.issuerStatus} />,
          actions,
        };
      });

      setTokens(tokens);
    }
  }, [allTokens]);

  const handleShowDetailsModal = async (token) => {
    setTokenToDetail(token);
    setShowDetailsModal(true);
  };

  const toogleShowFiltersModal = () => {
    setShowFiltersModal(!showFiltersModal);
  };

  if (loading) {
    return <Loading />;
  }

  if (tokens.length > 0)
    return (
      <div className="page-wrapper tokens-list">
        <div className="d-flex title">
          <p>
            <Translator path={'pages.tokensList.title'} />
          </p>
          {/* // todo: Adicionar filtros
           */}
          <Button variant="primary" size="lg" className="text-center" id="btn" onClick={toogleShowFiltersModal}>
            <Translator path={'pages.tokensList.filtersModal.button'} />
          </Button>
        </div>
        <Table
          props={{
            tableData: tokens,
            tableColumns: COLUMNS,
            emptyImage: emptyIssuerTokens,
          }}
        ></Table>
        {/* // todo: Adicionar filtros
         */}
        <FiltersModal show={showFiltersModal} setState={setShowFiltersModal} />

        <TokenDetails
          show={showDetailsModal}
          tokenOrProposal={tokenToDetail}
          setState={setShowDetailsModal}
          issuer={issuerProfile}
        />
      </div>
    );
};
