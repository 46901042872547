import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CodeValidator, Countdown, Translator } from '@components';

const FirstStep = ({ props: { email, setCode, handleStep } }) => {
  const { t } = useTranslation();
  const successObj = { msg: '' };
  const [resend, setResend] = useState(successObj);

  const reSend = () => {
    const success = { ...successObj };
    success.msg = t('withdrawPage.firstStep.resend').toString();
    setResend(success);
  };

  return (
    <>
      <h3>
        <Translator path="securityPage.title" />
      </h3>
      <p className="step-one" style={{ whiteSpace: 'pre-wrap' }}>
        <Translator path="withdrawPage.firstStep.body" />
      </p>
      <p className="step-one">{email}</p>
      <div className="col-12 d-flex justify-content-center" style={{ gap: '0 11px' }}>
        <CodeValidator props={{ setCode }} />
      </div>
      {resend.msg && (
        <div className="text-success fs-12" style={{ marginTop: '0.313rem' }}>
          {resend.msg}
        </div>
      )}
      <div
        className="col-7 d-flex justify-content-start align-items-start"
        style={{ padding: 0, margin: '0 0 1.5rem 0' }}
      >
        <div className="mt-2" style={{ margin: 0, padding: 0 }}>
          <Countdown />
        </div>
        <span
          className="form-check d-inline-block mt-2"
          style={{ whiteSpace: 'nowrap', padding: '0 0 0 2.5rem', margin: 0 }}
        >
          <label className="label-forgot-password" htmlFor="check1" style={{ margin: 0 }}>
            <Translator path="register.emailPage.send" />
          </label>
          <a href="" className="a-forgot-password">
            <Translator path="register.emailPage.click" />
          </a>
        </span>
      </div>
      <button className=" col-6 btn btn-orange" onClick={handleStep}>
        <Translator path="withdrawPage.firstStep.submit" />
      </button>
    </>
  );
};

export default FirstStep;
