import { Translator } from '@components';
import React from 'react';

const Status = ({ props: { status } }) => {
  const paths = ['/investment', '/publictokens','/publictokenslite'];
  return (
    <div className="row align-items-center justify-content-end " style={{ margin: 0 }}>
      <div
        className="col-md-6 col-sm-6 card-title-text d-flex align-items-center justify-content-center"
        style={{
          borderRadius: '0 1.25rem',
          backgroundColor: `${
            status === 'INCAPTURE'
              ? '#ff9900'
              : status === 'ALLSOLD'
              ? '#475A9D'
              : status === 'CANCELED' || status === 'REJECTED'
              ? '#E61D2B'
              : status === 'COMPLETE' || status === 'LIQUIDATED' || status === 'ACCEPTED'
              ? '#009847'
              : status === 'CAPTATED'
              ? '#117CDF'
              : status === 'IN_ANALYSIS'
              ? '#FF7A00'
              : status === 'ANNULLED'
              ? '#A30914'
              : status === 'PRE_ACCEPTED'
              ? '#005829'
              : status === 'NEGOTIATING'
              ? '#2E291E'
              : ''
          }`,
        }}
      > 
        <p style={{ whiteSpace: 'nowrap', margin: 0 }}>
          {
            status === 'INCAPTURE' ? (
              <Translator path="components.statusCard.captation" />
            ) : status === 'ALLSOLD' ? (
              <Translator path="components.statusCard.ranOut" />
            ) : status === 'ACCEPTED' ? (
              <Translator path="components.statusCard.accepted" />
            ) : status === 'ANNULLED' ? (
              <Translator path="components.statusCard.annuled" />
            ) : status === 'PRE_ACCEPTED' ? (
              <Translator path="components.statusCard.preAccepted" />
            ) : status === 'NEGOTIATING' ? (
              <Translator path="components.statusCard.negotiating" />
            ) : status === 'IN_ANALYSIS' ? (
              <Translator path="components.statusCard.analysis" />
            ) : status === 'CANCELED' ? (
              <Translator path="components.statusCard.cancel" />
            ) : status === 'COMPLETE' || status === 'LIQUIDATED' ? (
              <Translator path="components.statusCard.complete" />
            ) : status === 'CAPTATED' ? (
              <Translator path="components.statusCard.captated" />
            ) : (
              ''
            )
          }
        </p>
      </div>
    </div>
  );
};

export default Status;

// IN_ANALYSIS = 'IN_ANALYSIS',
// NEGOTIATING = 'NEGOTIATING',
// REJECTED = 'REJECTED',
// ACCEPTED = 'ACCEPTED',
// ANNULLED = 'ANNULLED',
// PRE_ACCEPTED = 'PRE_ACCEPTED',
