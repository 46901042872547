import { Button, Modal } from 'react-bootstrap';

import sucessoGif from '@assets/gifs/sucesso.gif';
import { usePurchase, useToken } from '@contexts-investor';
import { Translator } from '@components';
import { formatDate } from 'date-utils-2020';

const InvestmentModal = ({ show, token, quantity, totalValue, hide }) => {
  const { getToken } = useToken();
  const { showConfirmationModal } = usePurchase();

  return (
    <Modal
      show={showConfirmationModal}
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
      centered
      // onHide={getToken(token?.tokenId)}
    >
      <Modal.Body style={{ padding: 0 }}>
        <div className="buy-modal-title text-center">
          <Translator path="investment.investmentModal.complete" />
        </div>
        <div className="buy-modal-gif text-center ">
          <img src={sucessoGif} alt="" />
        </div>
        <div className="buy-modal-info">
          <h4>
            <Translator path="investment.investmentModal.overview" />
          </h4>
          <p>
            <Translator path="investment.investmentModal.investedValue" />
            <span>
              : {(token?.unityPrice * quantity)?.toLocaleString('pt-BT', { style: 'currency', currency: 'BRL' })}
            </span>
          </p>
          <p>
            <Translator path="investment.investmentModal.toReceive" />
            <span id="receive">: {totalValue?.toLocaleString('pt-BT', { style: 'currency', currency: 'BRL' })}</span>
          </p>
          <p>
            <Translator path="investment.investmentModal.receiveDate" />
            <span> {formatDate(token?.paymentDate, 'dd/MM/yyyy')}</span>
          </p>
        </div>
        <div className="buy-modal-actions actions d-flex flex-column justify-content-center">
          <Button variant="primary" size="lg" className="text-center" id="btn" onClick={hide}>
            <Translator path={'investment.investmentModal.seeToken'} />
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default InvestmentModal;
