import React from 'react';
import Body from './Body';
import Header from './Header';
import Status from './Status';

const PropolsalCard = ({ props: { propolsal } }) => {
  return (
    <div className="row col-12 propolsal-card" style={{ backgroundColor: '#ffffff' }}>
      <div className="row col-12 d-flex justify-content-end">
        <div className="col-6 d-flex justify-content-end">
          <Status props={{ status: propolsal.status }} />
        </div>
      </div>
      <div className="row col-12 propolsal-card-header">
        <div className="col-5 d-flex justify-content-center align-items-center">
          <Header props={{ info: propolsal }} />
        </div>
      </div>
      <div className="row col-12 propolsal-card-body">
        <Body props={{ info: propolsal }} />
      </div>
    </div>
  );
};

export default PropolsalCard;
