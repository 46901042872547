export const specialToCode = (value) => {
  return value
    .replaceAll('@', '%40')
    .replaceAll('#', '%23')
    .replaceAll('$', '%24')
    .replaceAll('^', '%5E')
    .replaceAll('&', '%26')
    .replaceAll('*', '%2A')
    .replaceAll('(', '%28')
    .replaceAll(')', '%29')
    .replaceAll('+', '%2B')
    .replaceAll('=', '%3D')
    .replaceAll('{', '%7B')
    .replaceAll('}', '%7D')
    .replaceAll(';', '%3B')
    .replaceAll(':', '%3A')
    .replaceAll('"', '%22')
    .replaceAll('|', '%7C')
    .replaceAll(',', '%2C')
    .replaceAll('<', '%3C')
    .replaceAll('>', '%3E')
    .replaceAll('/', '%2F')
    .replaceAll('?', '%3F')
    .replaceAll('~', '%7E');
};
