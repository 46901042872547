import Translator from '../../../../components/I18n/Translator';

export const COLUMNS = [
  {
    Header: <Translator path={'components.proposalsList.name'} />,
    Footer: <Translator path={'components.proposalsList.name'} />,
    accessor: 'name',
    isSortable: true,
  },
  {
    Header: <Translator path={'components.proposalsList.invoiceValue'} />,
    Footer: <Translator path={'components.proposalsList.invoiceValue'} />,
    accessor: 'formattedInvoiceValue',
    isSortable: true,
  },
  {
    Header: <Translator path={'components.proposalsList.valueToReceive'} />,
    Footer: <Translator path={'components.proposalsList.valueToReceive'} />,
    accessor: 'formattedValueToReceive',
    isSortable: true,
  },
  {
    Header: <Translator path={'components.proposalsList.fundingLimitDate'} />,
    Footer: <Translator path={'components.proposalsList.fundingLimitDate'} />,
    accessor: 'formattedFundingDate',
    isSortable: true,
  },
  {
    Header: <Translator path={'components.proposalsList.liquidationDate'} />,
    Footer: <Translator path={'components.proposalsList.liquidationDate'} />,
    accessor: 'formattedLiquidationDate',
    isSortable: true,
  },
  {
    Header: 'Status',
    Footer: 'Status',
    accessor: 'formattedStatus',
    isSortable: true,
  },
  {
    Header: <Translator path={'components.proposalsList.actions'} />,
    Footer: <Translator path={'components.proposalsList.actions'} />,
    accessor: 'actions',
    isSortable: false,
  },
];
