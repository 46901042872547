import React, { useEffect, useState, Checkbox } from 'react';
import { connect, useDispatch } from 'react-redux';
import { I18n, Translator } from '@components';
import { useTranslation } from 'react-i18next';
import { Wizard, useWizard } from 'react-use-wizard';
import { Form } from 'react-bootstrap';
import swal from 'sweetalert';
import { Link, useNavigate } from 'react-router-dom';
import { logoCriptech } from '@assets';

function Step3({ get, set, onSignUpPJ, loading, ...props }) {
  const { t } = useTranslation();
  const { previousStep } = useWizard();

  function submitUserPJ(e) {
    e.preventDefault();
    if (get.term1 && get.term2 && get.term3) {
      onSignUpPJ(e)
    } else {
      swal('Sign Up', t('register.errors.validTerms'), 'error');
    }
  }

  return (
    <>
      <div className="page-wraper">
        <div className="row gx-0">
          <nav className="nav nav-tabs border-bottom-0 mt-3">
            <div className="tab-content w-100" id="nav-tabContent">
              <div className="tab-pane active show fade">
                <div className="logo-header mt-5">
                  <Link to="/login" className="logo ">
                    <img src={logoCriptech} alt="" className="width-230 mCS_img_loaded " />
                  </Link>
                </div>
                <h3 className="form-title">
                  <Translator path={'register.title'} />
                </h3>
                {props.errorMessage && <div className="">{props.errorMessage}</div>}
                <p style={{ color: '#525252', fontWight: '400', fontSize: '1.25rem' }}>
                  <Translator path={'register.declare'} />
                </p>
                <div>
                  <div className="form-group">
                    <div className="label-form-checkbox-border">
                      {['checkbox'].map((type) => (
                        <div className="label-form-checkbox mt-3">
                          <Form.Check
                            type={type}
                            label={<Translator path="register.firstcheckbox" />}
                            style={{ fontWeight: '400' }}
                            onChange={(e) => set.setTerm1(e.target.checked)}
                            checked={get.term1}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="form-group mt-3">
                    <div className="label-form-checkbox-border">
                      {['checkbox'].map((type) => (
                        <div className="label-form-checkbox mt-3">
                          <Form.Check
                            type={type}
                            label={<Translator path="register.secondcheckbox" />}
                            style={{ fontWeight: '400' }}
                            onChange={(e) => set.setTerm2(e.target.checked)}
                            checked={get.term2}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="form-group mt-3">
                    <div className="label-form-checkbox-border">
                      {['checkbox'].map((type) => (
                        <div className="label-form-checkbox mt-3 terms-of-use">
                          <Form.Check
                            type={type}
                            label={t('register.thirdcheckbox').toString()}
                            style={{ fontWeight: '400' }}
                            onChange={(e) => set.setTerm3(e.target.checked)}
                            checked={get.term3}
                          />
                          <a href={process.env.REACT_APP_USE_TERMS} target="_blank" style={{ marginLeft: '0.3rem', color: '#FF9900', marginTop: '0.15rem' }} rel="noreferrer">
                            <Translator path="register.termsofuse" />
                          </a>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="form-group clearfix text-left mt-5">
                  <button
                    className="btn btn-outlined outline gray"
                    style={{ width: '8rem' }}
                    onClick={() => previousStep()}>
                    <Translator path={'register.goback'} />
                  </button>
                  {loading ? (
                    <button type="submit" className="btn btn-orange float-end disabled btn-nextStep">
                      <Translator path={'loading'} />
                    </button>
                  ) : (
                    <button
                      className="col-6 btn btn-orange float-end"
                      onClick={(e) => submitUserPJ(e)}
                    >
                      <Translator path={'register.submitpj'} />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </nav >
        </div >
      </div >
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(Step3);
