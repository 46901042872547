import * as api from '../api';

export function listTokens(params) {
  const token = localStorage.getItem('token');
  return api.get('v2/token', params, { headers: { Authorization: `Bearer ${token}` } });
}

export function listTokensLite(params) {
  return api.get('token/public', params);
}

export function getToken(tokenId, params) {
  return api.get(`token/${tokenId}`, params);
}

export function listToken(tokenId, params) {
  const token = localStorage.getItem('token');
  return api.get(`token/${tokenId}`, params, { headers: { Authorization: `Bearer ${token}` } });
}

export function getMyTokens(params) {
  return api.get('v2/token/user/purchases', params);
}

export function tokenDetails(tokenId) {
  const token = localStorage.getItem('token');
  return api.get(`token/user/token/${tokenId}`, {}, { headers: { Authorization: `Bearer ${token}` } });
}
