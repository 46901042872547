import React, { useEffect } from 'react';
import { TokenList, Loading} from '@components';
import { useToken } from '@contexts-investor';

const PublicListLite = () => {

  const { tokens, loadingTokens, listTokensLite } = useToken();

  useEffect(() => {
    listTokensLite();
  }, []);

  if (loadingTokens) {
    return <Loading />;
  }

  return (
    <>
      <div id="main-wrapper" className={'show'}>
        <div className="d-flex justify-content-center align-items-center mt-5 pt-4" style={{ minHeight: window.screen.height - 45 }}>
          <div className="container-fluid">
            <div className="page-wrapper">
              <TokenList props={tokens} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PublicListLite;
