import React from 'react';
import { Translator } from '@components';
const LinkCard = ({ props: { image, text, icon } }) => {
  return (
    <>
      <img src={image} style={{ width: '8.125rem', height: '7rem' }} />
      <p>
        <Translator path={`${text}`} />
      </p>
      <i className="material-icons" style={{ color: '#525252', width: '1.5rem', height: '1.875rem' }}>
        {icon}
      </i>
    </>
  );
};

export default LinkCard;
