import React, { useEffect, useState } from 'react';
import { useProfile, useToken } from '@contexts-investor';
import { Translator, TokenList, Loading } from '@components';
import { Dropdown } from 'react-bootstrap';

const Investment = () => {
  const { profile, loadProfile, loadingProfile, balanceControl, showBalance } = useProfile();
  const { tokens, loadingTokens, listTokens, totalToken } = useToken();
  const [filter, setFilter] = useState();
  const [title, setTitle] = useState();
  let [cont, setCont] = useState(12);
  const itemsPerPage = 12;

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    if (userId !== (null && undefined)) loadProfile(userId);
    listTokens(0, { ...filter }, cont);
  }, [localStorage.getItem('userId'), filter, setFilter]);

  const totalPages = Math.ceil(totalToken / itemsPerPage);

  const loadMore = () => {
    cont = cont + 3;
    setCont(cont)
    listTokens(0, { ...filter }, cont);
  }

  const handleItemClick = (order) => {
    const filters = {
      orderBy: order
    };
    setFilter(filters);

    if (order === 'BIGGESTVALUE') {
      setTitle(<Translator path="investment.biggestValue" />)
    } else if (order === 'SMALLESTVALUE') {
      setTitle(<Translator path="investment.smallestValue" />);
    } else if (order === 'BESTSELLERS') {
      setTitle(<Translator path="investment.bestSellers" />);
    } else if (order === 'BIGGESTYIELD') {
      setTitle(<Translator path="investment.biggestYield" />);
    } else {
      setTitle(<Translator path="investment.tokenFilter" />);
    }
  };

  if (loadingProfile || loadingTokens) return <Loading />;

  if (profile)
    return (
      <div className="page-wrapper">
        <div className="row" style={{ marginBottom: '1.25rem', gap: '2.5rem', marginRight: '0.625rem' }}>
          <div
            className="col-xxl-4 col-lg-5 col-md-6 col-sm-12 invest-label d-flex align-items-center"
            style={{ backgroundColor: 'white', marginLeft: '0.938rem', height: '7vh', marginRight: '10px' }}
          >
            <div
              className="d-flex justify-content-start align-items-center mb-0 form-group"
              style={{ textAlign: 'start', alignItems: 'center' }}
            >
              <p className="mb-0 nowrap" style={{ whiteSpace: 'nowrap', fontSize: '1.5rem' }}>
                <Translator path="investment.balance" />
              </p>
              <input
                className="mb-0 form-control show-pass px-2 text-black"
                style={{ border: 0, fontSize: '1.5rem' }}
                readOnly
                value={
                  profile.wallet
                    ? profile.wallet.balance.toLocaleString('pt-BT', { style: 'currency', currency: 'BRL' })
                    : 'R$ 0,00'
                }
                type={showBalance}
              />
              {showBalance === 'text' ? (
                <i
                  className="show-pass fa fa-eye-slash"
                  onClick={() => {
                    balanceControl('password');
                  }}
                />
              ) : (
                <i
                  className="show-pass fa fa-eye"
                  onClick={() => {
                    balanceControl('text');
                  }}
                />
              )}
            </div>
          </div>
          <Dropdown className="col-xxl-2 col-xl-4 col-lg-3 col-md-4 col-sm-12 d-flex justify-content-center token-filter">
            <Dropdown.Toggle style={{ backgroundColor: 'white', height: '7vh' }}>
              <label className='token-filter-text'>
                {title ? title : <Translator path="investment.tokenFilter" />}
              </label>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleItemClick(null)}>
                <Translator path="investment.tokenFilter" />
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleItemClick('BIGGESTVALUE')}>
                <Translator path="investment.biggestValue" />
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleItemClick('SMALLESTVALUE')}>
                <Translator path="investment.smallestValue" />
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleItemClick('BESTSELLERS')}>
                <Translator path="investment.bestSellers" />
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleItemClick('BIGGESTYIELD')}>
                <Translator path="investment.biggestYield" />
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <TokenList props={tokens} />
        {cont < totalToken && totalPages > 1 ? (
          <div className='pagination-div-tokens'>
            <button
              className='col-sm-6 col-md-3 btn btn-outlined-transparent outline gray'
              onClick={loadMore}
            >
              <Translator path="investment.loadmore" />
            </button>
          </div>
        ) : (
          ''
        )
        }
      </div >
    );
};

export default Investment;
