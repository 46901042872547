import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { logoCriptech } from '@assets';
import { NavLink } from 'react-router-dom';
import { I18n, Translator } from '@components';
import { useTranslation } from 'react-i18next';
import { cpfMask, phoneMask } from '@utils';
import { validadeCpf } from '@utils/masks';
import { useWizard } from 'react-use-wizard';


function Step1({ get, set, ...props }) {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  let errorsObj = { name: '', email: '', cpf: '', phone: '', address: '', };

  const [errors, setErrors] = useState(errorsObj);
  const [registerError, setRegisterError] = useState(false);
  const { nextStep } = useWizard();

  const handleCpf = (e) => {
    const cpfFormated = cpfMask(e.target.value);
    set.setCpf(cpfFormated);
  };

  const isPhone = (phone) =>
    /^\(?(\d{2})\)?[- ]?(\d{5})[- ]?(\d{4})$/.test(phone) ||
    /^\(?(\d{2})\)?[- ]?(\d{4})[- ]?(\d{4})$/.test(phone) ||
    /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(phone);

  useEffect(() => {
    const errorObj = { ...errorsObj };
    errorsObj = { name: '', email: '', cpf: '', data: '', phone: '', address: '' };
    setErrors(errorObj);
    setRegisterError(false);
  }, [get.name, get.email, get.cpf, get.phone, get.address]);


  function verifyFields(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };

    if (get.name === '') {
      errorObj.name = t('register.errors.name').toString();
      error = true;
    }
    if (get.email === '') {
      errorObj.email = t('register.errors.email').toString();
      error = true;
    }
    if (get.cpf === '') {
      errorObj.cpf = t('register.errors.cpf').toString();
      error = true;
    }
    if (get.phone === '') {
      errorObj.phone = t('register.errors.phone').toString();
      error = true;
    }
    if (!validadeCpf(get.cpf)) {
      errorObj.cpf = t('register.errors.validId').toString();
      error = true;
    }
    if (!isPhone(get.phone)) {
      errorObj.phone = t('register.errors.validPhone').toString();
      error = true;
    }
    if (get.address === '') {
      errorObj.address = t('register.errors.address').toString();
      error = true;
    }

    setErrors(errorObj);

    if (error) {
      setRegisterError(true);
      return;
    }
    nextStep();
  }

  return (
    <>
      <div className="form-wrapper">
        <nav className="nav nav-tabs border-bottom-0">
          <div className="tab-content w-100" id="nav-tabContent">
            <div className="tab-pane active show fade">
              <div className="logo-header">
                <Link to="/login" className="logo ">
                  <img src={logoCriptech} alt="" className="width-230 mCS_img_loaded " />
                </Link>
              </div>
              <h3 className="form-title">
                <Translator path={'register.title'} />
              </h3>
              <p style={{ color: '#525252', fontWight: '400', fontSize: '1.25rem' }}>
                <Translator path={'register.body'} />
              </p>
              <div className="form-group mt-3">
                <div className="row">
                  <div className="col-sm-12">
                    <input
                      required=""
                      className="form-control"
                      placeholder={t('register.name').toString()}
                      type="text"
                      value={get.name}
                      onChange={(e) => set.setName(e.target.value)}
                    />
                    {errors.name && <div className="text-danger fs-12">{errors.name}</div>}
                  </div>
                </div>
              </div>

              <div className="form-group mt-3">
                <input
                  className="form-control"
                  placeholder={t('register.id').toString()}
                  value={cpfMask(get.cpf)}
                  onChange={(e) => handleCpf(e)}
                />
                {errors.cpf && <div className="text-danger fs-12">{errors.cpf}</div>}
              </div>

              <div className="form-group mt-3">
                <input
                  required=""
                  className="form-control"
                  placeholder={t('register.email').toString()}
                  value={get.email}
                  onChange={(e) => set.setEmail(e.target.value)}
                  type="email"
                />
                {errors.email && <div className="text-danger fs-12">{errors.email}</div>}
              </div>

              <div className="form-group mt-3">
                <input
                  className="form-control"
                  placeholder={t('register.phone').toString()}
                  maxLength={15}
                  value={get.phone}
                  onChange={(e) => set.setPhone(phoneMask(e.target.value))}
                />
                {errors.phone && <div className="text-danger fs-12">{errors.phone}</div>}
              </div>

              <div className="form-group mt-3">
                <textarea
                  required=""
                  className="form-control text-area"
                  placeholder={t('register.completeaddress').toString()}
                  value={get.address}
                  onChange={(e) => set.setAddress(e.target.value)}
                />
                {errors.address && <div className="text-danger fs-12">{errors.address}</div>}
              </div>

              <div className="form-group clearfix text-left mt-4">
                <NavLink to="/registertype" className="btn btn-outlined outline gray" style={{ width: '8rem' }}>
                  <Translator path={'register.goback'} />
                </NavLink>
                <button
                  className="col-6 btn btn-orange float-end"
                  onClick={(e) => verifyFields(e)}
                >
                  <Translator path={'register.submit'} />
                </button>
              </div>
            </div>
          </div>
        </nav>
      </div >
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(Step1);
