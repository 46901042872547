import { useState, useEffect } from 'react';
import { Translator, InputText, SelectInput, Loading } from '@components';
import { useTranslation } from 'react-i18next';
import { editingStyle } from './selectStyles';
import { phoneMask, isEmail, isPhone } from '@utils';
import { useBank } from '@contexts-issuer';

const RegisterForm = ({ props: { setLayout, issuerProfile, bankInfo, banks, handleRequest, isEdit = false } }) => {
  const { t } = useTranslation();
  const { buttonLoading } = useBank();
  const [isEditing, setIsEditing] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedBank, setSelectedBank] = useState(null);
  const [bankCode, setBankCode] = useState();
  const [bankCodeHolder, setBankCodeHolder] = useState();
  const [accountType, setAccountType] = useState();
  const [agency, setAgency] = useState();
  const [agencyDigit, setAgencyDigit] = useState();
  const [accountNumber, setAccountNumber] = useState();
  const [accountNumberDigit, setAccountNumberDigit] = useState();
  const [pixTypeKey, setpixTypeKey] = useState();
  const [pixKey, setPixKey] = useState();
  const [reload, setReload] = useState(false);
  const agencyMaxLength = 6;
  const accountMaxLength = 8;

  let errorsObj = {
    bankCode: '',
    accountType: '',
    agency: '',
    agencyDigit: '',
    accountNumber: '',
    accountNumberDigit: '',
    pixTypeKey: '',
    pixKey: '',
  };
  const [errors, setErrors] = useState(errorsObj);

  const selectOption = [
    { value: 'email', label: t('pages.myBank.pixSelect.email') },
    { value: 'phone', label: t('pages.myBank.pixSelect.phone') },
    { value: 'random', label: t('pages.myBank.pixSelect.random') },
    { value: 'cnpj', label: t('pages.myBank.pixSelect.cnpj') },
  ];
  const selectStyles = editingStyle;

  const getDefaultPixKey = () => {
    switch (true) {
      case bankInfo.pixTypeKey === 'phone':
        return t('pages.myBank.pixSelect.phone');
      case bankInfo.pixTypeKey === 'random':
        return t('pages.myBank.pixSelect.random');
      case bankInfo.pixTypeKey === 'email':
        return t('pages.myBank.pixSelect.email');
      case bankInfo.pixTypeKey === 'cnpj':
        return t('pages.myBank.pixSelect.cnpj');
      default:
        return '';
    }
  };

  const defaultPixSelect = {
    label: pixTypeKey
      ? (pixTypeKey.charAt(0).toUpperCase() + pixTypeKey.slice(1).toLowerCase()).toString()
      : bankInfo && bankInfo.pixTypeKey
      ? getDefaultPixKey()
      : '',
  };

  const pixSelectData = {
    options: selectOption,
    styles: selectStyles,
    select: defaultPixSelect,
    setSelect: setSelectedOption,
    buttonLoading,
  };

  const getDefaultBank = () => {
    const defaultBank = Array.from(banks)
      .map((item) => {
        if (item.code === bankInfo.bankCode)
          return {
            label: `${item.code} - ${item.fullName}`,
            value: item.code,
          };
      })
      .filter((item) => item !== undefined);
    return defaultBank.label;
  };

  const defaultBankSelect = {
    label: bankCodeHolder ? bankCodeHolder : bankInfo && bankInfo.bankCode && banks ? getDefaultBank() : '',
  };

  const bankSelectData = {
    options: banks,
    styles: selectStyles,
    select: defaultBankSelect,
    setSelect: setSelectedBank,
    placeholder: '',
  };

  const accountTypeOptions = [
    { value: 'POUPANÇA', label: t('pages.myBank.typeSelect.poup').toString() },
    { value: 'CORRENTE', label: t('pages.myBank.typeSelect.current').toString() },
  ];

  const getDefaultAccountSelect = () => {
    switch (true) {
      case bankInfo.accountType === 'CORRENTE':
        return t('pages.myBank.typeSelect.current');
      case bankInfo.accountType === 'POUPANÇA':
        return t('pages.myBank.typeSelect.poup');
      default:
        return '';
    }
  };

  const defaultAccountSelect = {
    label: accountType ? accountType.label : bankInfo?.accountType ? getDefaultAccountSelect() : '',
    value: accountType ? accountType.value : bankInfo?.accountType ? bankInfo.accountType : '',
  };

  const typeSelectData = {
    options: accountTypeOptions,
    styles: selectStyles,
    select: defaultAccountSelect,
    setSelect: setAccountType,
    placeholder: '',
  };

  const setNumberOnly = (value, setState) => {
    if (/^\d*$/.test(value)) {
      setState(value);
    }
  };

  const setPixkeyMasks = (value) => {
    if (selectedOption.value === 'phone') {
      setPixKey(phoneMask(value));
    } else if (selectedOption.value === 'random' || selectedOption.value === 'email') {
      setPixKey(value);
    }
  };

  const cancelActions = () => {
    if (bankInfo) {
      setReload(true);
      setSelectedOption('');
      setBankCode('');
      setAccountType('');
      setAgency(bankInfo.agency);
      setAgencyDigit(bankInfo.agencyDigit);
      setAccountNumber(bankInfo.accountNumber);
      setAccountNumberDigit(bankInfo.accountNumberDigit);
      setpixTypeKey(bankInfo.pixTypeKey);
      setPixKey(bankInfo.pixKey);
      setIsEditing(false);
      setTimeout(() => {
        setReload(false);
      }, 200);
    }
  };

  const makeRequest = async () => {
    if (issuerProfile) {
      if (!isEdit) {
        let error = false;
        const errorObj = { ...errorsObj };
        if (!selectedBank || !selectedBank.value) {
          errorObj.bankCode = t('pages.myBank.validators.bankCode').toString();
          error = true;
        }
        if (!accountType) {
          errorObj.accountType = t('pages.myBank.validators.accountType').toString();
          error = true;
        }
        if (!agency) {
          errorObj.agency = t('pages.myBank.validators.agency').toString();
          error = true;
        }
        if (!agencyDigit) {
          errorObj.agencyDigit = t('pages.myBank.validators.agencyDigit').toString();
          error = true;
        }
        if (!accountNumber) {
          errorObj.accountNumber = t('pages.myBank.validators.accountNumber').toString();
          error = true;
        }
        if (!accountNumberDigit) {
          errorObj.accountNumberDigit = t('pages.myBank.validators.accountNumberDigit').toString();
          error = true;
        }
        if (!selectedOption || !selectedOption.value) {
          errorObj.pixTypeKey = t('pages.myBank.validators.pixTypeKey').toString();
          error = true;
        }
        if (
          !(selectedOption && selectedOption.value) ||
          (selectedOption.value === 'email' && !isEmail(pixKey)) ||
          (selectedOption.value === 'phone' && !isPhone(pixKey))
        ) {
          errorObj.pixKey = t('pages.myBank.validators.pixKey').toString();
          error = true;
        }
        if (error) {
          setErrors(errorObj);
          return;
        }
        const body = {
          cnpj: issuerProfile.cnpj,
          bankCode: selectedBank.value.toString(),
          accountType: accountType.value,
          agency,
          agencyDigit,
          accountNumber,
          accountNumberDigit,
          pixTypeKey: selectedOption.value,
          pixKey,
        };
        const { response } = await handleRequest(body);
        if (!response) {
          setLayout(2);
        }
      } else {
        const body = {
          cnpj: issuerProfile.cnpj,
          bankCode: selectedBank && selectedBank.value ? selectedBank.value.toString() : bankInfo.bankCode.toString(),
          accountType: accountType && accountType.value ? accountType.value : bankInfo.accountType,
          agency: agency ? agency : bankInfo.agency,
          agencyDigit: agencyDigit ? agencyDigit : bankInfo.agencyDigit,
          accountNumber: accountNumber ? accountNumber : bankInfo.accountNumber,
          accountNumberDigit: accountNumberDigit ? accountNumberDigit : bankInfo.accountNumberDigit,
          pixTypeKey: selectedOption && selectedOption.value ? selectedOption.value : bankInfo.pixTypeKey,
          pixKey: pixKey ? pixKey : bankInfo.pixKey,
        };
        const { response } = await handleRequest(body);
        if (!response) {
          setLayout(2);
        }
      }
    }
  };
  useEffect(() => {
    const getMyBankCode = () => {
      const data = Array.from(banks).filter((item) => parseInt(bankInfo.bankCode) === item.value);
      setBankCodeHolder(data[0].label);
    };
    if (banks && bankInfo.bankCode) getMyBankCode();
  }, [banks && bankInfo && bankInfo.bankCode]);

  useEffect(() => {
    setPixKey('');
  }, [selectedOption && selectedOption.value]);

  useEffect(() => {
    const errorObj = { ...errorsObj };
    errorsObj = {
      bankCode: '',
      accountType: '',
      agency: '',
      agencyDigit: '',
      accountNumber: '',
      accountNumberDigit: '',
      pixTypeKey: '',
      pixKey: '',
    };
    setErrors(errorObj);
  }, [bankCode, accountType, agency, agencyDigit, accountNumber, accountNumberDigit, pixTypeKey, pixKey]);

  if (reload) return <Loading />;

  return (
    <div className="row col-12" style={{ margin: 0, padding: 0 }}>
      <div className="row col-md-7 col-12 my-bank-content-left my-bank-margin-fix">
        <div className="row col-12">
          <div className="col-10" style={{ marginTop: 0 }}>
            <InputText
              className="issuer-input-disabled input-field-padding"
              text={'pages.myBank.form.cnpj'}
              value={issuerProfile?.cnpj}
              readOnly={true}
            />
          </div>
          <div className="col-10 ">
            <div className="d-flex flex-row text-input-container">
              <p className="text-input-label">
                <Translator path="pages.myBank.form.bank" />
              </p>
              <p>*</p>
            </div>
            {!isEdit || isEditing ? (
              <SelectInput props={{ ...bankSelectData, isDisabled: isEdit ? (isEditing ? false : true) : false }} />
            ) : (
              <input
                className="issuer-input-disabled input-field-padding"
                defaultValue={isEdit && bankInfo?.bankCode ? bankCodeHolder : ''}
                readOnly={true}
              />
            )}
            {errors.bankCode && (
              <div className="col-12 text-danger fs-12" style={{ paddingLeft: 0, margin: 0 }}>
                {errors.bankCode}
              </div>
            )}
          </div>
          <div className="col-10 ">
            <div className="d-flex flex-row text-input-container">
              <p className="text-input-label">
                <Translator path="pages.myBank.form.type" />
              </p>
              <p>*</p>
            </div>
            {!isEdit || isEditing ? (
              <SelectInput props={{ ...typeSelectData, isDisabled: isEdit ? (isEditing ? false : true) : false }} />
            ) : (
              <input
                className="issuer-input-disabled input-field-padding"
                defaultValue={isEdit && bankInfo?.accountType ? defaultAccountSelect.label : ''}
                readOnly={true}
              />
            )}
            {errors.accountType && <div className="col-12 text-danger fs-12">{errors.accountType}</div>}
          </div>
        </div>
        <div className="row col-12">
          <div className="col-10">
            <InputText
              className={`${
                isEdit
                  ? isEditing
                    ? 'form-control form-control-issuer'
                    : 'issuer-input-disabled input-field-padding'
                  : 'form-control form-control-issuer'
              }`}
              readOnly={isEdit ? (isEditing ? false : true) : false}
              setState={setAgency}
              maxLength={agencyMaxLength}
              setWithMask={setNumberOnly}
              defaultValue={isEdit ? bankInfo?.agency : ''}
              text={'pages.myBank.form.agency'}
              value={agency}
            />
          </div>
          <div className="col-sm-2 col-3 my-bank-digit d-flex justify-content-center align-items-center">
            <InputText
              className={`${
                isEdit
                  ? isEditing
                    ? 'form-control form-control-issuer'
                    : 'issuer-input-disabled input-field-padding'
                  : 'form-control form-control-issuer'
              }`}
              readOnly={isEdit ? (isEditing ? false : true) : false}
              setState={setAgencyDigit}
              setWithMask={setNumberOnly}
              defaultValue={isEdit ? bankInfo?.agencyDigit : ''}
              value={agencyDigit ? agencyDigit : ''}
              text={'pages.myBank.form.digit'}
              maxLength={1}
            />
          </div>
          {errors.agency && (
            <div className="col-6 text-danger fs-12" style={{ paddingLeft: 0, margin: 0 }}>
              {errors.agency}
            </div>
          )}
          {errors.agencyDigit && (
            <div className="col-6 text-danger fs-12" style={{ margin: 0, textAlign: 'end' }}>
              {errors.agencyDigit}
            </div>
          )}
          <div className="col-10 ">
            <InputText
              className={`${
                isEdit
                  ? isEditing
                    ? 'form-control form-control-issuer'
                    : 'issuer-input-disabled input-field-padding'
                  : 'form-control form-control-issuer'
              }`}
              readOnly={isEdit ? (isEditing ? false : true) : false}
              setState={setAccountNumber}
              maxLength={accountMaxLength}
              setWithMask={setNumberOnly}
              defaultValue={isEdit ? bankInfo?.accountNumber : ''}
              value={accountNumber ? accountNumber : ''}
              text={'pages.myBank.form.account'}
            />
          </div>
          <div className="col-sm-2 col-3 my-bank-digit d-flex justify-content-center align-items-center">
            <InputText
              className={`${
                isEdit
                  ? isEditing
                    ? 'form-control form-control-issuer'
                    : 'issuer-input-disabled input-field-padding'
                  : 'form-control form-control-issuer'
              }`}
              readOnly={isEdit ? (isEditing ? false : true) : false}
              setState={setAccountNumberDigit}
              setWithMask={setNumberOnly}
              defaultValue={isEdit ? bankInfo?.accountNumberDigit : ''}
              value={accountNumberDigit ? accountNumberDigit : ''}
              text={'pages.myBank.form.digit'}
              maxLength={1}
            />
          </div>
          {errors.accountNumber && (
            <div className="col-6 text-danger fs-12" style={{ paddingLeft: 0, margin: 0 }}>
              {errors.accountNumber}
            </div>
          )}
          {errors.accountNumberDigit && (
            <div className="col-6 text-danger fs-12" style={{ margin: 0, textAlign: 'end' }}>
              {errors.accountNumberDigit}
            </div>
          )}
          <div
            className="col-12 d-flex justify-content-between align-items-center"
            style={{ borderBottom: '1px solid #EDEDED', padding: 0, margin: '2.5rem 0' }}
          />
        </div>
        <div className="row col-12">
          <div className="col-10" style={{ margin: 0 }}>
            <div className="d-flex flex-row text-input-container">
              <p className="text-input-label">
                <Translator path="pages.myBank.form.keyType" />
              </p>
              <p>*</p>
            </div>
            {!isEdit || isEditing ? (
              <SelectInput props={{ ...pixSelectData, isDisabled: isEdit ? (isEditing ? false : true) : false }} />
            ) : (
              <input
                className="issuer-input-disabled input-field-padding"
                defaultValue={isEdit && bankInfo?.pixTypeKey ? defaultPixSelect.label : ''}
                readOnly={true}
              />
            )}
            {errors.pixTypeKey && (
              <div className="col-12 text-danger fs-12" style={{ paddingLeft: 0, margin: 0 }}>
                {errors.pixTypeKey}
              </div>
            )}
          </div>
          <div className="col-10 ">
            <InputText
              className={`${
                isEdit
                  ? isEditing
                    ? 'form-control form-control-issuer'
                    : 'issuer-input-disabled input-field-padding'
                  : 'form-control form-control-issuer'
              }`}
              readOnly={isEdit ? (isEditing ? false : true) : false}
              setState={setPixkeyMasks}
              defaultValue={isEdit ? bankInfo?.pixKey : ''}
              value={pixKey ? pixKey : ''}
              maxLength={selectedOption && selectedOption.value === 'phone' ? 15 : ''}
              text={'pages.myBank.form.key'}
            />
            {errors.pixKey && <div className="col-12 text-danger fs-12">{errors.pixKey}</div>}
          </div>
        </div>
      </div>
      <div className="row col-md-5 col-12 my-bank-content-right justify-content-center align-items-start ">
        <p className="instructions-title">
          <Translator path="pages.myBank.instructions.title" />
        </p>
        <div
          className="row col-12 d-flex justify-content-center align-items-center"
          style={{ margin: '1.25rem 0 0 0' }}
        >
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              border: '1px solid #ff9900',
              borderRadius: '200px',
              padding: '10px 10px 0 0',
              width: '52px',
              height: '52px',
              margin: 0,
              padding: 0,
            }}
          >
            <i className="col-6 col-md-12 material-icons">description</i>
          </div>
          <p className="col-6 col-md-12">
            <Translator path="pages.myBank.instructions.block" />
          </p>
        </div>
        <div className="row col-12 d-flex justify-content-center align-items-center ">
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              border: '1px solid #ff9900',
              borderRadius: '200px',
              padding: '10px 10px 0 0',
              width: '52px',
              height: '52px',
              margin: 0,
              padding: 0,
            }}
          >
            <i className="col-6 col-md-12 material-icons">block</i>
          </div>
          <p className="col-6 col-md-12">
            <Translator path="pages.myBank.instructions.business" />
          </p>
        </div>
        <div className="row col-12 d-flex justify-content-center align-items-center ">
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              border: '1px solid #ff9900',
              borderRadius: '200px',
              padding: '10px 10px 0 0',
              width: '52px',
              height: '52px',
              margin: 0,
              padding: 0,
            }}
          >
            <i className="col-6 col-md-12 material-icons">verified_user</i>
          </div>
          <p className="col-6 col-md-12">
            <Translator path="pages.myBank.instructions.email" />
          </p>
        </div>
      </div>

      <div className="row col-12 my-bank-buttons" style={{ padding: '0 2.5rem', margin: '2.5rem 0 ' }}>
        {!isEdit && !isEditing && bankInfo?.status === 'INCOMPLETE' ? (
          <div className="row col-12" style={{ padding: 0, margin: 0 }}>
            <button
              className={`col-6 col-md-4 btn btn-orange ${buttonLoading ? 'd-flex justify-content-center' : ''}`}
              onClick={() => makeRequest()}
            >
              {buttonLoading ? (
                <div className="loading-spinner" style={{ width: '30px', height: '30px' }} />
              ) : (
                <Translator path="pages.myBank.form.submit" />
              )}
            </button>
          </div>
        ) : isEdit && !isEditing ? (
          <div className="row col-12 d-flex align-items-center" style={{ padding: 0, margin: 0 }}>
            <button
              className="col-6 col-md-4 btn btn-orange btn-resp"
              onClick={() => {
                setIsEditing(true);
              }}
            >
              <Translator path="pages.myBank.form.edit" />
            </button>
            {bankInfo?.status === 'APPROVED' ? (
              <div className="col-12 col-lg-8 d-flex approved-label">
                <div className="col-12 d-flex align-items-center justify-content-evenly">
                  <i className="material-icons d-flex align-items-center">info_outline</i>
                  <p>
                    <Translator path="pages.issuer.alterApproved" />
                  </p>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        ) : isEdit && isEditing ? (
          <>
            {bankInfo?.status === 'IN_ANALYSIS' ||
            bankInfo?.status === 'CHANGES_REQUEST' ||
            bankInfo?.status === 'REJECTED' ? (
              <div className="row col-12 button-res" style={{ padding: 0, margin: 0 }}>
                <button
                  className={`col-6 col-md-4 btn btn-orange ${buttonLoading ? 'd-flex justify-content-center' : ''}`}
                  onClick={() => makeRequest()}
                >
                  {buttonLoading ? (
                    <div className="loading-spinner" style={{ width: '30px', height: '30px' }} />
                  ) : (
                    <Translator path="pages.myBank.form.send" />
                  )}
                </button>
                <button className="col-6 col-md-4 btn btn-outlined" onClick={() => cancelActions()}>
                  <Translator path="pages.myBank.form.cancel" />
                </button>
              </div>
            ) : bankInfo?.status === 'APPROVED' ? (
              <div className="row col-12 button-res " style={{ padding: 0, margin: 0 }}>
                <button
                  className={`col-6 col-md-4 btn btn-orange ${buttonLoading ? 'd-flex justify-content-center' : ''}`}
                  onClick={() => makeRequest()}
                >
                  {buttonLoading ? (
                    <div className="loading-spinner" style={{ width: '30px', height: '30px' }} />
                  ) : (
                    <Translator path="pages.myBank.form.alter" />
                  )}
                </button>
                <button className="col-6 col-md-4 btn btn-outlined" onClick={() => cancelActions()}>
                  <Translator path="pages.myBank.form.cancel" />
                </button>
              </div>
            ) : (
              ''
            )}
          </>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default RegisterForm;
