import { createContext, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import swal from 'sweetalert';
import * as issuerService from '../../services/Issuer/issuerService';

export const IssuerContext = createContext({});

const IssuerProvider = ({ children }) => {
  const { t } = useTranslation();
  const [issuerInfo, setIssuerInfo] = useState();
  const [issuerProfile, setIssuerProfile] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [imageURL, setImageURL] = useState();
  const [imageBuffer, setImageBuffer] = useState();
  const [userImageBuffer, setUserImageBuffer] = useState();
  const [mySocialContract, setMySocialContract] = useState();
  const [socialContractId, setsocialContractId] = useState();
  const [myAdditionalDocs, setMyAdditionalDocs] = useState();
  const [additionalDocsId, setadditionalDocsId] = useState();
  const [downloaded, setDownloaded] = useState();
  const [issuerCompleteProfile, setIssuerCompleteProfile] = useState({});
  const [loadingDocs, setLoadingDocs] = useState('');

  const getIssuerLoginId = useCallback(async (issuerLoginId) => {
    setLoading(true);
    try {
      const { data } = await issuerService.getIssuerLoginProfile(issuerLoginId);
      setIssuerInfo(data);
    } catch (error) {
      const errorTitle = t('profile.error.title').toString();
      const errorMessage = t('profile.error.body').toString();
      swal(errorTitle, errorMessage, 'error');
    } finally {
      setLoading(false);
    }
  }, []);

  const getIssuerProfile = useCallback(async (issuerId) => {
    setLoading(true);
    try {
      const { data } = await issuerService.getIssuerProfile(issuerId);

      setIssuerProfile(data);
    } catch (error) {
      const errorTitle = t('profile.error.title').toString();
      const errorMessage = t('profile.error.body').toString();
      swal(errorTitle, errorMessage, 'error');   
    } finally {
      setLoading(false);
    }
  }, []);

  const getCompleteIssuerProfile = useCallback(async (issuerId) => {
    setLoading(true);
    try {
      const { data } = await issuerService.getCompleteIssuerProfile(issuerId);

      setIssuerCompleteProfile(data);
    } catch (error) {
      const errorTitle = t('profile.error.title').toString();
      const errorMessage = t('profile.error.body').toString();
      swal(errorTitle, errorMessage, 'error');    
    } finally {
      setLoading(false);
    }
  }, []);

  const registerIssuer = useCallback(
    async (issuerId, body) => {
      setLoadingButton(true);
      try {
        await issuerService.finishRegisteringIssuer(issuerId, body);
        const successHeader = t('pages.issuer.success.title');
        const successMessage =
          issuerProfile && issuerProfile.status === 'INCOMPLETE'
            ? t('pages.issuer.success.body')
            : t('pages.issuer.success.editBody');
        swal(successHeader, successMessage, 'success');
        return true;
      } catch (error) {
        const errHeader = t('pages.issuer.apiErr.title');
        const errBody = t('pages.issuer.apiErr.body');
        swal(errHeader, errBody, 'error');
        return false;
      } finally {
        setLoadingButton(false);
      }
    },
    [loadingButton, setLoadingButton],
  );

  const uploadImage = useCallback(async (file) => {
    setImageLoading(true);
    try {
      const { data } = await issuerService.uploadImage(file);
      setImageURL(data);
    } catch (error) {
    } finally {
      setImageLoading(false);
    }
  }, []);

  const downloadImage = useCallback(async (id = '') => {
    setLoading(true);
    try {
      const newId = id.replaceAll('/', '%2F');
      const { data } = await issuerService.downloadImage(newId);
      setImageBuffer(data);
      return data;
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const uploadSocialContract = useCallback(
    async (file) => {
      setLoadingDocs('socialContract');
      setLoading(true);
      try {
        const { data } = await issuerService.uploadSocialContract(file);
        setsocialContractId(data.replaceAll('/', '%2F'));
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
        setLoadingDocs('');
      }
    },
    [setsocialContractId, socialContractId],
  );

  const downloadSocialContract = useCallback(
    async (id) => {
      setLoading(true);
      try {
        const { data } = await issuerService.downloadSocialContract(id);
        setMySocialContract(data);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
    [mySocialContract, setMySocialContract],
  );

  const uploadAdditionalDocs = useCallback(
    async (file) => {
      setLoading(true);
      setLoadingDocs('additionalDocs');
      try {
        const { data } = await issuerService.uploadAdditionalDocs(file);
        setadditionalDocsId(data.replaceAll('/', '%2F'));
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
        setLoadingDocs('');
      }
    },
    [additionalDocsId, setadditionalDocsId],
  );

  const downloadImageIssuer = useCallback(async (id) => {
    setImageLoading(true);
    try {
      const { data } = await issuerService.downloadImage(id);
      return data;
    } catch (error) {
      console.error(error);
    } finally {
      setImageLoading(false);
    }
  }, []);

  const downloadAdditionalDocs = useCallback(async (id) => {
    setLoading(true);
    try {
      const { data } = await issuerService.downloadAdditionalDocs(id);
      setMyAdditionalDocs(data);
      return data;
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const download = useCallback(async (id) => {
    setLoading(true);
    try {
      const { data } = await issuerService.download(id);
      setDownloaded(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const uploadUserImage = useCallback(async (file) => {
    setImageLoading(true);
    try {
      const { data } = await issuerService.uploadIssuerUserImage(file);
      setUserImageBuffer(data);
      return data;
    } catch (error) {
      return false;
    } finally {
      setImageLoading(false);
    }
  }, []);

  const downloadUserImage = useCallback(async (id) => {
    setImageLoading(true);
    try {
      const { data } = await issuerService.downloadIssuerUserImage(id);
      setUserImageBuffer(data);
      return data;
    } catch (error) {
      return false;
    } finally {
      setImageLoading(false);
    }
  }, []);

  const updateIssuerUser = useCallback(async (body) => {
    setLoading(true);
    try {
      const { data } = await issuerService.updateUserInfo(body);
      const successTitle = t('pages.profile.success.title').toString();
      const successMessage = t('pages.profile.success.body').toString();
      swal(successTitle, successMessage, 'success');
      return true;
    } catch (error) {
      return false;
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <IssuerContext.Provider
      value={{
        registerIssuer,
        loading,
        imageLoading,
        uploadImage,
        imageURL,
        getIssuerProfile,
        issuerProfile,
        downloadImage,
        imageBuffer,
        userImageBuffer,
        uploadSocialContract,
        downloadSocialContract,
        mySocialContract,
        socialContractId,
        uploadAdditionalDocs,
        downloadAdditionalDocs,
        myAdditionalDocs,
        additionalDocsId,
        getIssuerLoginId,
        issuerInfo,
        download,
        downloaded,
        getCompleteIssuerProfile,
        issuerCompleteProfile,
        loadingButton,
        uploadUserImage,
        downloadUserImage,
        updateIssuerUser,
        downloadImageIssuer,
        loadingDocs,
      }}
    >
      {children}
    </IssuerContext.Provider>
  );
};

const useIssuer = () => {
  return useContext(IssuerContext);
};

export { IssuerProvider, useIssuer };
