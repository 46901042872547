import React from 'react';
import { Translator } from '@components';

const StatusCard = ({ status }) => {
  return (
    <div
      className="col-12"
      style={{
        borderRadius: '200px',
        backgroundColor: `${
          status === 'INCAPTURE'
            ? '#FF9900'
            : status === 'WAITING'
            ? '#117CDF'
            : status === 'ANALYSING'
            ? '#FF7A00'
            : status === 'COMPLETE'
            ? '#009847'
            : '#E61D2B'
        }`,
        textAlign: 'center',
      }}
    >
      <p className="statement-status-card" style={{ color: 'var(--white)' }}>
        {status === 'INCAPTURE' ? (
          <Translator path="pages.depositList.status.incapture" />
        ) : status === 'WAITING' ? (
          <Translator path="pages.depositList.status.waiting" />
        ) : status === 'ANALYSING' ? (
          <Translator path="pages.depositList.status.analysing" />
        ) : status === 'COMPLETE' ? (
          <Translator path="pages.depositList.status.done" />
        ) : (
          <Translator path="pages.depositList.status.cancel" />
        )}
      </p>
    </div>
  );
};

export default StatusCard;
