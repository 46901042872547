import Translator from '../../../../components/I18n/Translator';

export const COLUMNS = [
  {
    Header: <Translator path={'components.statementTable.order'} />,
    Footer: <Translator path={'components.statementTable.order'} />,
    accessor: 'purchaseCode',
    isSortable: true,
  },
  {
    Header: <Translator path={'components.statementTable.name'} />,
    Footer: <Translator path={'components.statementTable.name'} />,
    accessor: 'token.name',
    isSortable: true,
  },
  {
    Header: <Translator path={'components.statementTable.date'} />,
    Footer: <Translator path={'components.statementTable.date'} />,
    accessor: 'createdAt',
    isSortable: true,
  },
  {
    Header: <Translator path={'components.statementTable.quantity'} />,
    Footer: <Translator path={'components.statementTable.quantity'} />,
    accessor: 'boughtQuantity',
    isSortable: true,
  },
  {
    Header: <Translator path={'components.statementTable.investVal'} />,
    Footer: <Translator path={'components.statementTable.investVal'} />,
    accessor: 'boughtValue',
    isSortable: false,
  },
  {
    Header: <Translator path={'components.statementTable.receiveVal'} />,
    Footer: <Translator path={'components.statementTable.receiveVal'} />,
    accessor: 'receive',
    isSortable: false,
  },
  {
    Header: <Translator path={'components.statementTable.received'} />,
    Footer: <Translator path={'components.statementTable.received'} />,
    accessor: 'received',
    isSortable: false,
  },
  {
    Header: <Translator path={'components.statementTable.return'} />,
    Footer: <Translator path={'components.statementTable.return'} />,
    accessor: 'return',
    isSortable: false,
  },
  {
    Header: <Translator path={'components.statementTable.status'} />,
    Footer: <Translator path={'components.statementTable.status'} />,
    accessor: 'status',
    isSortable: false,
  },
  {
    Header: <Translator path={'components.statementTable.action'} />,
    Footer: <Translator path={'components.statementTable.action'} />,
    accessor: 'action',
    isSortable: false,
  },
];
