import React from 'react';
import { logoEmissor, logoIssuer, stage1, stage2, stage3 } from '@assets';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PageHeader = ({ props: { step } }) => {
  const { i18n } = useTranslation();
  return (
    <>
      <div className=" row gx-0 ">
        <Link to="/login" className="logo ">
          <img
            src={i18n.language === 'pt-BR' ? logoEmissor : logoIssuer}
            alt=""
            className="width-230 mCS_img_loaded "
          />
        </Link>
      </div>
      <div className="row col-12 pd-mg-0">
        <img src={step === 1 ? stage1 : step === 2 ? stage2 : step === 3 ? stage3 : ''} />
      </div>
    </>
  );
};

export default PageHeader;
