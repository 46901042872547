import React from 'react';
import { Modal } from 'react-bootstrap';
import { Translator } from '@components';

const HowtoDepositModal = ({ props: { show, setShow } }) => {
  const onHideModal = () => {
    setShow(false);
  };

  return (
    <Modal show={show} aria-labelledby="contained-modal-title-vcenter" size="lg" centered>
      <Modal.Body
        style={{ margin: 0, padding: '1.25rem 0 ' }}
        className="row d-flex flex-column justify-content-center align-items-center"
      >
        <div className="row" style={{ margin: 0, padding: '0 2.5rem' }}>
          <div
            className="col-12 d-flex justify-content-between align-items-center flex-row "
            style={{ marginBottom: '1.25rem', padding: 0 }}
          >
            <h4 className="col-3 modal-title-deposit">
              <Translator path="pages.depositList.title" />
            </h4>
            <i className="material-icons moda-close-icon cursor-pointer" onClick={onHideModal}>
              close
            </i>
          </div>
        </div>
        <div className="col-12" style={{ borderBottom: '1px solid #EDEDED' }} />
        <div className="row how-it-works-deposit">
          <div className="row col-12">
            <p>
              <Translator path="pages.depositList.worksModal.bodyLabel" />
            </p>
            <p>1°</p>
            <p>
              <span>
                <Translator path="pages.depositList.worksModal.stepOne" />
              </span>
              <span className="complement">
                <Translator path="pages.depositList.worksModal.stepOneComplementOne" />
              </span>
              <span>
                <Translator path="pages.depositList.worksModal.stepOneComplementTwo" />
              </span>
              <span className="complement">
                <Translator path="pages.depositList.worksModal.stepOneComplementThree" />
              </span>
            </p>
            <p>2°</p>
            <p className="d-flex align-items-center">
              <span>
                <Translator path="pages.depositList.worksModal.stepTwo" />
              </span>
              <i className="material-icons statement-action">more_horiz</i>
              <span>
                <Translator path="pages.depositList.worksModal.stepTwoComplementOne" />
              </span>
              <span className="complement">
                <Translator path="pages.depositList.worksModal.stepTwoComplementTwo" />
              </span>
            </p>
            <p>3°</p>
            <p>
              <span>
                <Translator path="pages.depositList.worksModal.stepThree" />
              </span>
              <span className="complement">
                <Translator path="pages.depositList.worksModal.stepThreeComplement" />
              </span>
            </p>
          </div>
        </div>
        <div className="col-12" style={{ borderBottom: '1px solid #EDEDED' }} />
        <div className="row" style={{ padding: '0 2.5rem', margin: 0 }}>
          <div
            className="col-12 d-flex justify-content-end"
            style={{ gap: '1.25rem', marginTop: '1.25rem', padding: 0 }}
          >
            <button className="btn btn-orange col-3" style={{ whiteSpace: 'nowrap' }} onClick={() => onHideModal()}>
              <Translator path="pages.depositList.worksModal.submit" />
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default HowtoDepositModal;
