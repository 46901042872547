import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Translator } from '@components';
import { useWallet, useBank } from '@contexts-issuer';
import PixBody from './PixBody';
import TedBody from './TedBody';

const RequestDeposit = ({ props: { show, setShow, setSuccess, isReview = false, isDone = false } }) => {
  const { getAllBanks } = useBank();
  const { loadingButton, confirmDeposit, depositDetails } = useWallet();
  const [showPayment, setShowPayment] = useState(0);
  const [bankInfo, setBankInfo] = useState();
  const pixLimit = process.env.REACT_APP_PIX_LIMIT;

  const onHideModal = () => {
    setShow(false);
  };

  const handleConfirmDeposit = async () => {
    if (depositDetails) {
      const params = {
        issuerDepositId: depositDetails.issuerDepositId,
        depositType: showPayment === 1 ? 'PIX' : 'TED',
      };
      const success = await confirmDeposit(params);
      if (success) {
        setSuccess(true);
        setShow(false);
      }
    }
  };

  useEffect(() => {
    const getBanks = async () => {
      const data = await getAllBanks();
      const mappedBank = Array.from(data)
        .map((item) => {
          if (item.code === parseInt(depositDetails.adminBankData.bankCode)) {
            return {
              label: `${item.code} - ${item.fullName}`,
            };
          }
        })
        .filter((item) => item !== undefined);
      setBankInfo(mappedBank);
    };
    if (depositDetails) getBanks();
  }, []);

  useEffect(() => {
    const handlePixLimit = () => {
      if (pixLimit < depositDetails.value) setShowPayment(2);
    };

    if (depositDetails) handlePixLimit();
  }, [depositDetails]);

  return (
    <Modal show={show} aria-labelledby="contained-modal-title-vcenter" size="lg" centered onEscapeKeyDown={onHideModal}>
      <Modal.Body
        style={{ margin: 0, padding: '1.25rem 0 ' }}
        className="row d-flex flex-column justify-content-center align-items-center"
      >
        <div className="row" style={{ margin: 0, padding: '0 2.5rem' }}>
          <div
            className="col-12 d-flex justify-content-between align-items-center flex-row "
            style={{ marginBottom: '1.25rem', padding: 0 }}
          >
            <h4 className="col-5 modal-title-deposit">
              {!isDone ? (
                <Translator path={`pages.withdrawList.request.title`} />
              ) : (
                <Translator path={'pages.depositList.titleRequested'} />
              )}
            </h4>
            <i className="material-icons moda-close-icon cursor-pointer" onClick={onHideModal}>
              close
            </i>
          </div>
        </div>

        <div className="col-12 gray-line" style={{ borderBottom: '1px solid #EDEDED' }} />
        <div
          className="row withdraw-header"
          style={{ padding: '0 2.5rem', margin: ` ${showPayment === 0 ? '2.5rem 0 0 0' : '0'} ` }}
        >
          {showPayment === 0 ? (
            <div className="row col-7 request-modal-header" style={{ margin: 0, padding: 0 }}>
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ margin: 0, padding: 0, width: 'fit-content' }}
              >
                <img
                  src={depositDetails?.token?.image}
                  alt=""
                  style={{ width: '80px', height: '80px', borderRadius: '12px' }}
                />
              </div>
              <div className="row col-8 d-flex justify-content-start align-items-center">
                <p
                  className="label-withdraw"
                  style={{ whiteSpace: 'nowrap', margin: 0, padding: 0, width: 'fit-content' }}
                >
                  <Translator path="pages.withdrawList.request.name" />:
                </p>
                <p className="col-5 info" style={{ whiteSpace: 'nowrap', margin: '0 0 0 0.5rem', padding: 0 }}>
                  {depositDetails?.token.name}
                </p>
                <p
                  className="label-withdraw"
                  style={{ whiteSpace: 'nowrap', margin: 0, padding: 0, width: 'fit-content' }}
                >
                  <Translator path="pages.withdrawList.request.issuer" />:
                </p>
                <p className="col-6 info" style={{ whiteSpace: 'nowrap', margin: '0 0 0 0.5rem', padding: 0 }}>
                  {depositDetails?.issuer.name}
                </p>
              </div>
            </div>
          ) : (
            ''
          )}
          <div className="row col-12 d-flex align-items-center request-modal-header-info">
            <p className="col-6 label-withdraw" style={{ whiteSpace: 'nowrap', margin: 0, padding: 0 }}>
              {!isDone ? (
                <Translator path="pages.depositList.amount" />
              ) : (
                <Translator path="pages.depositList.requestedAmount" />
              )}
            </p>
            <p className="col-6 info-value" style={{ whiteSpace: 'nowrap', margin: 0, padding: 0 }}>
              {`R$ ${new Intl.NumberFormat('pt-BR', {
                minimumFractionDigits: 2,
              }).format(parseFloat(depositDetails?.value))}`}
            </p>
            <p className="col-6 label-withdraw" style={{ whiteSpace: 'nowrap', margin: 0, padding: 0 }}>
              {!isDone ? (
                <Translator path="pages.withdrawList.request.date" />
              ) : (
                <Translator path="pages.depositList.dateRequested" />
              )}
            </p>

            <p className="col-6 info" style={{ whiteSpace: 'nowrap', margin: 0, padding: 0 }}>
              {!isDone
                ? new Date(Date.now()).toLocaleDateString('pt-BR')
                : depositDetails && depositDetails.withdrawDate && new Date(depositDetails.withdrawDate)}
            </p>

            <p className="col-6 label-withdraw" style={{ whiteSpace: 'nowrap', margin: 0, padding: 0 }}>
              <Translator path={'pages.depositList.paymentType'} />
            </p>

            <p className="col-6 info" style={{ whiteSpace: 'nowrap', margin: 0, padding: 0 }}>
              {depositDetails?.depositType}
            </p>
          </div>
        </div>

        {showPayment === 1 ? (
          <>
            <div className="row col-12" style={{ padding: ` ${showPayment !== 0 ? '0 2.5rem' : '0'} `, margin: 0 }}>
              <div className="col-12 gray-line" style={{ borderBottom: '1px solid #EDEDED' }} />
            </div>
            <div className="row withdraw-body ">
              <PixBody props={{ info: depositDetails.value }} />
            </div>
          </>
        ) : showPayment === 2 ? (
          <>
            <div className="row col-12" style={{ padding: ` ${showPayment !== 0 ? '0 2.5rem' : '0'} `, margin: 0 }}>
              <div className="col-12 gray-line" style={{ borderBottom: '1px solid #EDEDED' }} />
            </div>
            <div className="row withdraw-body ">
              <TedBody props={{ bankInfo }} />
            </div>
          </>
        ) : isDone || isReview ? (
          <>
            <div className="row col-12" style={{ padding: ` ${showPayment === 0 ? '0 2.5rem' : '0'} `, margin: 0 }}>
              <div className="col-12 gray-line" style={{ borderBottom: '1px solid #EDEDED' }} />
            </div>
            <div className="row withdraw-body ">
              <TedBody props={{ bankInfo }} />
            </div>
          </>
        ) : (
          ''
        )}
        <div
          className="row col-12"
          style={{ padding: ` ${showPayment !== 0 || isReview || isDone ? '0' : '0 2.5rem'} `, margin: 0 }}
        >
          <div className="col-12 gray-line" style={{ borderBottom: '1px solid #EDEDED' }} />
        </div>
        <div className="row deposit-footer">
          <div
            className={`col-12 d-flex footer ${
              showPayment === 0 ? 'justify-content-center align-items-center flex-column' : 'justify-content-end'
            } `}
          >
            {!isDone && !isReview ? (
              <>
                {showPayment === 0 ? (
                  <>
                    <p>
                      <Translator path="pages.depositList.choose" />
                    </p>
                    <div
                      className="row col-12 d-flex justify-content-center"
                      style={{ gap: '0 1.25rem', margin: '0 0 2.5rem 0' }}
                    >
                      <button
                        className={`btn btn-orange col-4 ${depositDetails?.value > pixLimit ? 'disabled' : ''} `}
                        onClick={() => {
                          if (depositDetails?.value <= pixLimit) setShowPayment(1);
                        }}
                      >
                        <Translator path="pages.depositList.pixBtn" />
                      </button>

                      <button className="btn btn-orange col-4" onClick={() => setShowPayment(2)}>
                        <Translator path="pages.depositList.tedBtn" />
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <button
                      className="btn btn-outlined col-4"
                      onClick={() => {
                        if (pixLimit < depositDetails.value) {
                          onHideModal();
                        } else setShowPayment(0);
                      }}
                    >
                      <Translator path={`pages.depositList.${showPayment === 1 ? 'cancel' : 'goback'}`} />
                    </button>
                    <button
                      className={`btn btn-orange col-4 ${
                        loadingButton ? 'd-flex justify-content-center align-items-center disabled' : ''
                      }`}
                      style={{ padding: '0 0.2rem' }}
                      onClick={() => {
                        if (!loadingButton) handleConfirmDeposit();
                      }}
                    >
                      {loadingButton ? (
                        <div className="loading-spinner" style={{ width: '30px', height: '30px' }} />
                      ) : (
                        <Translator path="pages.depositList.finish" />
                      )}
                    </button>
                  </>
                )}
              </>
            ) : (
              ''
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RequestDeposit;
