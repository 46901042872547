import * as api from '../api';

export function get(userId, params) {
  return api.get(`v2/users/wallet/${userId}`, params);
}

export function editUserProfile(userId, body) {
  return api.put(`v2/users/${userId}`, body);
}

export function uploadImage(file) {
  const formData = new FormData();
  formData.append('file', file);
  return api.post('users/image', formData);
}

export function downloadImage(id) {
  return api.get(`users/image/download/${id}`);
}

export function sendToken(params) {
  return api.get(`register/resetPassword/${params}`);
}

export function resetPasswrod(body) {
  return api.put('v2/register/password', body);
}

export function listProfileByUserId(userId) {
  return api.get(`v2/users/wallet/${userId}`)
}

export function listLegalProfiles() {
  return api.get('users/profile/legalUsers')
}