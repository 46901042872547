import { useZoom } from '@utils';
import React, { useEffect, useState, useContext } from 'react';
import Translator from '../../I18n/Translator';
import { useCdi } from '@contexts/CdiContext';

const Details = ({ props }) => {
  
  const zoom = useZoom();
  const { loadCdiPercentage } = useCdi();
  const [percentage, setPercentage] = useState('');


  useEffect(() => {
    const percentageHandler = async () => {
      const value = await loadCdiPercentage();
      setPercentage(value);
    };
    percentageHandler()
  },[]);

  return (
    <>
      <div
        className={`row col-12 d-flex  ${
          zoom > 1 && zoom < 1.25 ? 'flex-column align-items-center' : 'align-items-start justify-content-evenly'
        }`}
        style={{ margin: 0, padding: 0, textAlign: 'center' }}
      >
        {props?.showVal ? (
          <span
            className={`col-12 col-sm-6 col-md-12 col-lg-12 col-xl-6 fs-14 font-w500 d-flex flex-column justify-content-start align-items-center`}
            style={{ margin: 0, padding: 0 }}
          >
            <p className="card-label-text mb-0">
              <Translator path="components.tokenCard.value" />
            </p>
            <div className="d-flex justify-content-center">
              <p className="card-val-text mb-2">
                {props.unityPrice
                  ? props?.unityPrice?.toLocaleString('pt-BT', { style: 'currency', currency: 'BRL' })
                  : 'R$ 00,00'}
              </p>
            </div>
            <p className="card-label-text mb-0" style={{ whiteSpace: 'nowrap', margin: 0 }}>
              <Translator path="components.tokenCard.quantity" />
            </p>
            <div className="d-flex justify-content-center">
              <p className="card-val-text" style={{ margin: 0 }}>
                {props.soldQuantity
                  ? (parseInt(props.offeredQuantity) - parseInt(props.soldQuantity)).toString()
                  : props.offeredQuantity
                  ? props.offeredQuantity
                  : '0'}
              </p>
            </div>
          </span>
        ) : (
          ''
        )}

        {props?.showVal ? (
          <span
            className={`col-12 col-sm-6 col-md-12 col-lg-12 col-xl-6 fs-14 font-w500 d-flex flex-column ${
              props?.monthlyYieldPercentage > 0 || props?.yieldPercentage > 0 ? 'text-success' : 'text-danger'
            }`}
            style={{ margin: 0, padding: 0 }}
          >
            <p className="card-label-text mb-0" style={{ whiteSpace: 'nowrap', margin: 0, textAlign: 'center' }}>
              <Translator path="components.tokenCard.revenue" />
            </p>
            <div className="d-flex justify-content-center">
              <p className="card-porct-text" style={{ margin: 0, textAlign: 'center' }}>
                <i
                  className={`bi pe-2 ${
                    props?.monthlyYieldPercentage > 0 || props?.yieldPercentage > 0
                      ? 'bi-caret-up-fill'
                      : 'bi-caret-down-fill'
                  }`}
                ></i>
                {new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(
                  props?.monthlyYieldPercentage
                    ? parseFloat(props?.monthlyYieldPercentage * 100)
                    : parseFloat(props?.yieldPercentage * 100),
                )}
                %
              </p>
            </div>
            <p className="card-label-text mb-0 mt-3" style={{ whiteSpace: 'nowrap', margin: 0, textAlign: 'center' }}>
              <Translator path="components.tokenCard.percentage" />
            </p>
            <div className="d-flex justify-content-center">
              <p className="card-val-text mb-0" style={{ margin: 0, textAlign: 'center' }}>
                {new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 0 }).format(
                  ((props?.monthlyYieldPercentage
                    ? props?.monthlyYieldPercentage : props?.yieldPercentage ) * 100) / percentage  * 100     
                )}
                %
              </p>
            </div>
          </span>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default Details;
