import React from 'react';
import { useWallet } from '@contexts-issuer';
import { Translator } from '@components';
const TedBody = ({ props: { bankInfo } }) => {
  const { depositDetails } = useWallet();

  return (
    <>
      <h3>
        <Translator path="pages.withdrawList.request.info" />
      </h3>
      <div className="row col-12 d-flex align-items-center info-body">
        <p className="col-6 withdraw-label">
          <Translator path="pages.withdrawList.request.bank" />
        </p>
        <p className="col-6 withdraw-info">{bankInfo ? bankInfo[0].label : ''}</p>
        <p className="col-6 withdraw-label">
          <Translator path="pages.withdrawList.request.accountType" />
        </p>
        <p className="col-6 withdraw-info">{depositDetails?.adminBankData.accountType}</p>
        <p className="col-6 withdraw-label">
          <Translator path="pages.withdrawList.request.agency" />
        </p>
        <p className="col-6 withdraw-info">
          {`${depositDetails?.adminBankData.agency} - ${depositDetails?.adminBankData.agencyDigit}`}
        </p>
        <p className="col-6 withdraw-label">
          <Translator path="pages.withdrawList.request.accountNumber" />
        </p>
        <p className="col-6 withdraw-info">
          {`${depositDetails?.adminBankData.accountNumber} - ${depositDetails?.adminBankData.accountNumberDigit}`}
        </p>
        <p className="col-6 withdraw-label">
          <Translator path="pages.withdrawList.request.keyType" />
        </p>
        <p className="col-6 withdraw-info">{depositDetails?.adminBankData.pixKeyType}</p>
        <p className="col-6 withdraw-label">
          <Translator path="pages.withdrawList.request.pixKey" />
        </p>
        <p className="col-6 withdraw-info">{depositDetails?.adminBankData.pixKey}</p>
      </div>
    </>
  );
};

export default TedBody;
