import React from 'react';
import { Accordion } from 'react-bootstrap';

const AccordionCard = ({ props: { info, index } }) => {
  return (
    <>
      <Accordion className="accordion accordion-primary" defaultActiveKey={`0`} key={`${index}`} alwaysOpen>
        <Accordion.Item className="accordion-item" key={`${index}`} eventKey={`${index}`}>
          <Accordion.Header className="accordion-header rounded-lg">{info.title}</Accordion.Header>
          <Accordion.Collapse eventKey={`${index}`} className="accordion__body">
            <div className="accordion-body" style={{ wordBreak: 'break-word', backgroundColor: 'white' }}>
              {info.text}
            </div>
          </Accordion.Collapse>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

export default AccordionCard;
