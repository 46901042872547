import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Nav from '../../../layouts/nav';
import { Translator, TokenList, Loading, I18n } from '@components';
import { useToken } from '@contexts-investor';

const PublicList = () => {
  const navigate = useNavigate();
  const { tokens, loadingTokens, listTokens } = useToken();

  useEffect(() => {
    listTokens();
  }, []);

  if (loadingTokens) {
    return <Loading />;
  }

  return (
    <>
      <div id="main-wrapper" className={'show'}>
        <Nav publicRoute={true} />
        <div className="content-body" style={{ minHeight: window.screen.height - 45 }}>
          <div className="container-fluid">
            <div className="page-wrapper">
              <div className="row" style={{ marginBottom: '1.25rem' }}>
                <div
                  className="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-12 invest-label d-flex justify-content-between align-items-center"
                  style={{ height: '7vh', marginLeft: '0.938rem' }}
                >
                  <p
                    className="mb-0 nowrap col-6"
                    style={{ whiteSpace: 'nowrap', fontSize: '1.25rem', color: 'var(--darktext)' }}
                  >
                    <Translator path="investment.tokenFilter" />
                  </p>
                  <i className="fa-solid fa-caret-down" style={{ color: 'var(--darktext)' }} />
                </div>
              </div>
              <TokenList props={tokens} />
            </div>
          </div>
        </div>
      </div>
      <I18n />
    </>
  );
};

export default PublicList;
