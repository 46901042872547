import React, { useEffect, useState, useCallback } from 'react';

import { specialToCode, useZoom } from '@utils';
import { connect, useDispatch } from 'react-redux';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { loadingToggleAction, loginAction } from '../../../../store/actions/AuthActions';
import Form from 'react-bootstrap/Form';
import { I18n, Translator } from '@components';
import { useTranslation } from 'react-i18next';
import { loginBg, logoCriptech } from '@assets';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { validateRecaptchaToken } from '../../../../services/AuthService';
import swal from 'sweetalert';

function Login(props) {
  const { } = useZoom;
  const { t } = useTranslation();
  const { pathname, state } = useLocation();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [active, setActive] = useState(false);
  const [showPassword, setShowPassword] = useState('password');
  const [loading, setLoading] = useState(false);
  const [tokenId, setTokenId] = useState(null);
  const [connected, setConnect] = useState(false);
  let errorsObj = { email: '', password: '' };
  const [errors, setErrors] = useState(errorsObj);
  const dispatch = useDispatch();
  const zoom = useZoom();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const startLoading = () => {
    setLoading(!loading);
  };

  function login(e) {
    let error = false;
    const errorObj = { ...errorsObj };
    localStorage.removeItem('jwt-userId');
    if (email === '') {
      errorObj.email = t('login.errors.email').toString();
      error = true;
    }
    if (password === '') {
      errorObj.password = t('login.errors.password').toString();
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      setLoading(false);
      return;
    }
    startLoading();
    if (email && password) {
      const username = specialToCode(email);
      const pass = specialToCode(password);
      const body = `grant_type=password&client_id=${process.env.REACT_APP_CLIENT_ID}&client_secret=${process.env.REACT_APP_CLIENT_SECRET}&username=${username}&password=${pass}`;
      const errObj = {
        title: t('login.errors.title').toString(),
        body: t('login.errors.credentials').toString(),
      };
      dispatch(loadingToggleAction(true));
      if (tokenId !== null) {
        dispatch(loginAction(body, navigate, setLoading, tokenId, errObj, connected));
      } else {
        const mock = { tokenId: 'none' };

        dispatch(loginAction(body, navigate, setLoading, mock, errObj, connected));
      }
    }
  }

  function onLogin(e) {
    e.preventDefault();
    executeRecaptcha("enquiryFormSubmit").then((gReCaptchaToken) => {
      validateRecaptchaToken(gReCaptchaToken).then((response) => {
        if (response?.data?.success) {
          login(e)
        } else {
          throw t('apiErrors.recaptcha').toString();
        }
      }).catch((e) => {
        setLoading(false);
        swal(e, '', 'error');
      })
    })
  }

  useEffect(() => {
    const errorObj = { ...errorsObj };
    errorObj.email = '';
    errorObj.password = '';
    setErrors(errorObj);
  }, [email]);

  useEffect(() => {
    setTokenId(state);
  }, [state]);

  return (
    <div className="page-wraper">
      <div className="browse-job login-style3">
        <div
          className="bg-img-fix overflow-hidden"
          style={{
            background: '#fff url(' + loginBg + ')',
            height: '100vh',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right',
            backgroundSize: 'contain',
          }}
        >
          <div className="row gx-0">
            <div className="login-form-container bg-white d-flex justify-content-center align-items-center">
              <div className="login-form d-flex justify-content-center align-items-center">
                <div className="logo-header">
                  <Link to={'#'} className="logo">
                    <img src={logoCriptech} alt="" className="width-230 mCS_img_loaded" />
                  </Link>
                </div>
                <div className="login-form-box nav nav-tabs border-bottom-0">
                  <div className="tab-content w-100" id="nav-tabContent">
                    <div className="tab-pane fade active show" id="nav-personal">
                      <form className=" dz-form pb-3" onSubmit={onLogin}>
                        <h3 className="form-title m-t0">
                          <Translator path="login.title" />
                        </h3>
                        <div className="dz-separator-outer m-b5">
                          <div className="dz-separator bg-primary style-liner"></div>
                        </div>
                        <p>
                          <Translator path="login.body" />{' '}
                        </p>
                        <div className="form-group mb-3">
                          <input
                            type="email"
                            placeholder={t('login.email').toString()}
                            className="form-control"
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          {errors.email && <div className="text-danger fs-12">{errors.email}</div>}
                        </div>
                        <div className="form-group mb-3" style={{ position: 'relative' }}>
                          {active ? (
                            <i
                              className="show-pass fa fa-eye-slash icon-float"
                              onClick={() => {
                                setActive(false);
                                setShowPassword('password');
                              }}
                            />
                          ) : (
                            <i
                              className="show-pass fa fa-eye icon-float"
                              onClick={() => {
                                setActive(true);
                                setShowPassword('text');
                              }}
                            />
                          )}
                          <input
                            type={showPassword}
                            placeholder={t('login.password').toString()}
                            className="form-control show-pass"
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          {errors.password && <div className="text-danger fs-12">{errors.password}</div>}
                        </div>
                        <div className="form-group text-left mb-5">
                          {loading ? (
                            <button type="submit" className="btn btn-orange dz-xs-flex m-r5 disabled">
                              <Translator path="loading" />
                            </button>
                          ) : (
                            <button type="submit" className="btn btn-orange dz-xs-flex m-r5">
                              <Translator path="login.submit" />
                            </button>
                          )}

                          <span className="form-check-login d-inline-block ms-0">
                            <label className="label-forgot-password" htmlFor="check1">
                              <Translator path="login.forgot" />
                            </label>
                            <NavLink to="/reset" className="a-forgot-password">
                              <Translator path="login.link" />
                            </NavLink>
                          </span>
                          <div>
                            {['checkbox'].map((type) => (
                              <div className="label-keep-connected mt-3">
                                <Form.Check
                                  type={type}
                                  label={<Translator path="login.keep" />}
                                  onChange={(e) => setConnect(e.target.checked)}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="dz-social">
                          <h5 className="form-title fs-20">
                            <Translator path="login.signup" />
                          </h5>
                        </div>
                      </form>
                      <div className="text-center bottom">
                        <NavLink to="/registertype" className="btn btn-orange button-md btn-block">
                          <Translator path={'login.create'} />
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <I18n />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(Login);
