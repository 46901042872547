import React, { useEffect, useState } from 'react';
import { chooseAccount, loginBg, noPreview } from '@assets';
import { useZoom } from '@utils';
import { I18n, Translator, Loading } from '@components';
import { useProfile } from '@contexts-investor';
import { useNavigate } from 'react-router-dom';
import { Buffer } from 'buffer';
import { logout } from '../../../../services/AuthService';

function ChooseProfile(props) {
  const { } = useZoom;
  const { listProfiles, listLegalProfiles, downloadImageInvestor } = useProfile();
  const [legalUser, setLegalUser] = useState('');
  const [user, setUser] = useState('');
  const navigate = useNavigate();
  const [loadingScreen, setLoadingScreen] = useState(true);
  const [preview, setPreview] = useState();
  const [pjPreviews, setPjPreviews] = useState([]);

  const controlLoading = () => {
    setLoadingScreen(!loadingScreen);
  };

  const listProfile = async () => {
    const jwtUserId = localStorage.getItem('jwt-userId');
    const data = await listLegalProfiles()
    setLegalUser(data);

    if (jwtUserId !== null) {
      const data = await listProfiles(jwtUserId)
      setUser(data)
    }
    controlLoading()
  }

  useEffect(() => {
    listProfile();
  }, []);

  useEffect(() => {
    const downloadData = async (imageURL) => {
      const base64Image = await downloadImageInvestor(imageURL.replaceAll('/', '%2F'));
      const downloadedImage = Buffer.from(base64Image.data, 'binary').toString('base64');
      return downloadedImage;
    };

    const image = async () => {
      if (user && user.physicalUser && user.physicalUser.image) {
        const image = await downloadData(user.physicalUser.image);
        setPreview(image);
      }
      if (legalUser?.length > 0) {
        const pjPreviewsPromise = legalUser.map(async (pj) => {
          if (pj && pj.legalUser && pj.legalUser.image) {
            return await downloadData(pj.legalUser.image);
          }
          return null;
        });
        const pjPreviews = await Promise.all(pjPreviewsPromise);
        setPjPreviews(pjPreviews);
      }
    };

    image();
  }, [legalUser, user]);

  if (loadingScreen) {
    return <Loading />;
  }

  const onLoginProfile = async (uid, type) => {
    localStorage.setItem('userId', uid);
    localStorage.setItem('usertype', type);
    navigate('/dashboard')
  }

  return (
    <div className="page-wraper">
      <div className="browse-job login-style3">
        <div
          className="bg-img-fix overflow-hidden"
          style={{
            background: '#fff url(' + loginBg + ')',
            height: '100vh',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right',
            backgroundSize: 'contain',
          }}
        >
          <div className="row gx-0">
            <div className="login-form-container bg-white d-flex justify-content-center align-items-center">
              <div className="col-10">
                <div>
                  <div className="return-login" onClick={() => logout(navigate)}>
                    <i className="bi bi-arrow-left select-arrow" />
                    <p style={{ marginTop: '0.3rem', marginLeft: '-0.5rem' }}><Translator path={'titles.logout'} /> </p>
                  </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                  <img
                    src={chooseAccount}
                    alt="account"
                    style={{ width: '12rem', height: '12rem', marginBottom: '1rem' }}
                  />
                  <h4
                    className='select-option-text'
                    style={{ textAlign: 'center' }}>
                    <Translator path="login.selectaccount" />
                  </h4>
                  <label
                    className='select-option-text'
                    style={{ textAlign: 'center', fontSize: '1rem', margin: '1rem', fontWeight: '400' }}
                  >
                    <Translator path="login.selectaccounttext" />
                  </label>
                  <p
                    className='select-option-text-continue'
                  >
                    <Translator path="login.continueas" />
                  </p>
                  {user ? (
                    <button
                      className="col-12 select-option-profile"
                      style={{ marginBottom: '1rem' }}
                      onClick={() => onLoginProfile(user.userId, 'physical person')}
                    >
                      <div>
                        {preview ? (
                          <img
                            src={preview ? `data:image/jpeg;base64,${preview}` : noPreview}
                            alt="account"
                            style={{ width: '3rem', height: '3rem', borderRadius: '10rem', marginRight: '0.5rem', marginLeft: '1rem' }}
                          />
                        ) : (
                          <label className="firstLetter-logo" style={{ marginLeft: '1rem' }}>
                            <p style={{ color: 'white', marginTop: '0.7rem', }}>
                              {user && user.physicalUser.name[0].toUpperCase()}
                            </p>
                          </label>
                        )}
                        <label className="select-option-person" style={{ marginTop: '0.5rem' }}>
                          {user.physicalUser.name}
                        </label>
                      </div>
                      <i className="bi bi-arrow-right select-arrow" />
                    </button>
                  ) : (
                    ''
                  )}
                  {legalUser ? (
                    legalUser.map((user, i) => (
                      <button key={i}
                        className="col-12 select-option-profile"
                        style={{ marginBottom: '1rem' }}
                        onClick={() => onLoginProfile(user.legalUser.userId, 'legal person')}
                      >
                        <div>
                          {pjPreviews[i] ? (
                            <img
                              src={pjPreviews[i] ? `data:image/jpeg;base64,${pjPreviews[i]}` : noPreview}
                              alt="account"
                              style={{ width: '3rem', height: '3rem', borderRadius: '10rem', marginRight: '0.5rem', marginLeft: '1rem' }}
                            />
                          ) : (
                            <label className="firstLetter-logo" style={{ marginLeft: '1rem' }}>
                              <p style={{ color: 'white', marginTop: '0.7rem' }}>
                                {user && user.legalUser.corporateName[0].toUpperCase()}
                              </p>
                            </label>
                          )}
                          <label className="select-option-person">
                            {user.legalUser.corporateName}
                          </label>
                        </div>
                        <i className="bi bi-arrow-right select-arrow" />
                      </button>
                    ))
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
          <I18n />
        </div>
      </div>
    </div >
  );
}


export default (ChooseProfile);
