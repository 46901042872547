import { loginBg, logoCriptech } from '@assets';
import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { I18n, Translator } from '@components';
import { useTranslation } from 'react-i18next';
import swal from 'sweetalert';
import { singupPassword } from '../../../../store/actions/RegisterActions';

function RegisterPassword(props) {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');

  const [active, setActive] = useState(false);
  const [showPassword, setShowPassword] = useState('password');

  let errorsObj = { password: '', confirm: '', val: '' };
  const [errors, setErrors] = useState(errorsObj);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const startLoading = () => {
    setLoading(!loading);
  };

  const validateCharacteres = (value) => {
    if (value.length >= 8) return true;
    return false;
  };
  const validateNumber = (value) => {
    if (/\d/.test(value)) return true;
    return false;
  };
  const validateString = (value) => {
    if (/[A-Z]/.test(value)) return true;
    return false;
  };
  const validateSpecial = (value) => {
    if (/[`!@#$%^&*()_{};:"|,.<>/?~]/.test(value)) return true;
    return false;
  };
  const validateEquals = (first, second) => {
    if (first === second && first.length >= 8) return true;
    return false;
  };

  async function onSetPassword(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };

    if (password === '') {
      errorObj.password = t('register.passwordPage.errors.pass').toString();
      error = true;
    }

    if (confirm === '') {
      errorObj.confirm = t('register.passwordPage.errors.confirm').toString();
      error = true;
    }

    if (confirm !== password) {
      errorObj.val = t('register.passwordPage.errors.val').toString();
      error = true;
    }

    setErrors(errorObj);

    if (
      validateCharacteres(password) &&
      validateNumber(password) &&
      validateString(password) &&
      validateSpecial(password) &&
      validateEquals(password, confirm)
    ) {
      startLoading();
      const email = localStorage.getItem('useremail');
      const accessToken = localStorage.getItem('access_token_register');
      const body = { password: password, email: email, accessToken };
      dispatch(singupPassword(body, navigate, t, setLoading));
    } else {
      if (error) {
        return;
      }
      swal('Error', t('register.passwordPage.errors.attend').toString(), 'error');
    }
  }

  return (
    <>
      <div className="page-wraper">
        <div className="browse-job login-style3">
          <div
            className="bg-img-fix overflow-hidden"
            style={{
              background: '#fff url(' + loginBg + ')',
              height: '100vh',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'right',
              backgroundSize: 'contain',
            }}
          >
            <div className="row gx-0">
              <div className="login-form-container bg-white d-flex justify-content-center align-items-center" style={{ padding: '50px' }}>
                <div className="login-form d-flex justify-content-center align-items-center">
                  <div className="logo-header row gx-0 ">
                    <Link to="/login" className="logo ">
                      <img src={logoCriptech} alt="" className="width-230 mCS_img_loaded " />
                    </Link>
                  </div>
                  <nav className="nav nav-tabs border-bottom-0">
                    <div className="tab-content w-100" id="nav-tabContent">
                      <div className="tab-pane active show fade">
                        {props.errorMessage && <div className="">{props.errorMessage}</div>}
                        <form className="dz-form py-2 row" onSubmit={onSetPassword}>
                          <h3 className="form-title">
                            <Translator path="register.passwordPage.title" />
                          </h3>
                          <div className="dz-separator-outer m-b5">
                            <div className="dz-separator bg-primary style-liner"></div>
                          </div>
                          <p>
                            <Translator path="register.passwordPage.body" />{' '}
                          </p>
                          <div className="form-group mb-3" style={{ position: 'relative' }}>
                            {active ? (
                              <i
                                className="show-pass fa fa-eye-slash icon-float"
                                onClick={() => {
                                  setActive(false);
                                  setShowPassword('password');
                                }}
                              />
                            ) : (
                              <i
                                className="show-pass fa fa-eye icon-float"
                                onClick={() => {
                                  setActive(true);
                                  setShowPassword('text');
                                }}
                              />
                            )}
                            <input
                              type={showPassword}
                              placeholder={t('register.passwordPage.pass').toString()}
                              className="form-control show-pass"
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            {errors.password && <div className="text-danger fs-12">{errors.password}</div>}
                          </div>
                          <div className="form-group mb-3" style={{ position: 'relative' }}>
                            {active ? (
                              <i
                                className="show-pass fa fa-eye-slash icon-float"
                                onClick={() => {
                                  setActive(false);
                                  setShowPassword('password');
                                }}
                              />
                            ) : (
                              <i
                                className="show-pass fa fa-eye icon-float"
                                onClick={() => {
                                  setActive(true);
                                  setShowPassword('text');
                                }}
                              />
                            )}
                            <input
                              type={showPassword}
                              placeholder={t('register.passwordPage.rePass').toString()}
                              className="form-control show-pass"
                              onChange={(e) => setConfirm(e.target.value)}
                            />
                            {errors.confirm && <div className="text-danger fs-12">{errors.confirm}</div>}
                            {errors.val && <div className="text-danger fs-12">{errors.val}</div>}
                          </div>
                          <div className="form-group d-flex row" style={{ flexDirection: 'column' }}>
                            <div className="col-12">
                              <p
                                className={`${validateCharacteres(password) ? 'label-validation col-8' : 'label-noval col-8'
                                  }`}
                              >
                                <i className="fa-solid fa-check" style={{ marginRight: '0.625rem' }} />
                                <Translator path="register.passwordPage.validationOne" />
                              </p>
                            </div>
                            <div className="col-12">
                              <p
                                className={`${validateNumber(password) ? 'label-validation col-8' : 'label-noval col-8'
                                  }`}
                              >
                                <i className="fa-solid fa-check" style={{ marginRight: '0.625rem' }} />
                                <Translator path="register.passwordPage.validationTwo" />
                              </p>
                            </div>
                            <div className="col-12">
                              <p
                                className={`${validateString(password) ? 'label-validation col-8' : 'label-noval col-8'
                                  }`}
                              >
                                <i className="fa-solid fa-check" style={{ marginRight: '0.625rem' }} />
                                <Translator path="register.passwordPage.validationThree" />
                              </p>
                            </div>
                            <div className="col-12">
                              <p
                                className={`${validateSpecial(password) ? 'label-validation col-8' : 'label-noval col-8'
                                  }`}
                                style={{ whiteSpace: 'nowrap' }}
                              >
                                <i className="fa-solid fa-check" style={{ marginRight: '0.625rem' }} />
                                <Translator path="register.passwordPage.validationFour" />
                              </p>
                            </div>
                            <div className="col-12">
                              <p
                                className={`${validateEquals(password, confirm) ? 'label-validation col-8' : 'label-noval col-8'
                                  }`}
                              >
                                <i className="fa-solid fa-check" style={{ marginRight: '0.625rem' }} />
                                <Translator
                                  path="register.passwordPage.validationEquals"
                                  style={{ marginLeft: '0.625rem' }}
                                />
                              </p>
                            </div>
                          </div>
                          <div className="form-group clearfix text-left">
                            {loading ? (
                              <button type="submit" className="btn btn-orange float-end disabled">
                                <Translator path="loading" />
                              </button>
                            ) : (
                              <button type="submit" className="btn btn-orange float-end">
                                <Translator path="register.passwordPage.submit" />
                              </button>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </nav>
                </div>
                <I18n />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(RegisterPassword);
