import React from 'react';
import { useNavigate } from 'react-router-dom';
import sucessoGif from '@assets/gifs/sucesso.gif';
import { Translator } from '@components';

const FinalStep = () => {
  const navigate = useNavigate();
  return (
    <>
      <h3>
        <Translator path="withdrawPage.finalStep.title" />
      </h3>
      <div className="final-step d-flex flex-column align-items-center">
        <p className="final-step">
          <Translator path="withdrawPage.finalStep.body" />
        </p>
        <div className="buy-modal-gif text-center ">
          <img src={sucessoGif} alt="" />
        </div>
        <button className="btn btn-orange final-step col-6" onClick={() => navigate('/investment')}>
          <Translator path="withdrawPage.finalStep.goback" />
        </button>
      </div>
    </>
  );
};

export default FinalStep;
