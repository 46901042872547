import React, { useEffect, useState } from 'react';
import { PropolsalList, Translator, Loading } from '@components';
import { useTokenProposal, useIssuer, useBank, useWallet } from '@contexts-issuer';
import { linkToDeposit, linkToWithdraw } from '@assets';
import { useNavigate } from 'react-router-dom';
import LinkCard from './LinkCard';
import { useTranslation } from 'react-i18next';

const Wallet = () => {
  const [propolsals, setPropolsals] = useState([]);
  const [bankInfo, setBankInfo] = useState();
  const [bankLabel, setBankLabel] = useState();
  const [balanceInfo, setBalanceInfo] = useState();

  const { t } = useTranslation();
  const { getAllProposals, loading: loadingProposals } = useTokenProposal();
  const { issuerProfile } = useIssuer();
  const { getBankInfo, getAllBanks, loadingBankData, loadingBankInfo } = useBank();
  const { showBalance, balanceController, getIssuerBalance, loading: loadingBalance } = useWallet();

  const navigate = useNavigate();

  useEffect(() => {
    const getLastestPropolsals = async () => {
      const rawData = await getAllProposals({ issuerId: issuerProfile.issuerId });

      const proposalCodes = rawData
        .map((proposal) => proposal.tokenProposalCode)
        .sort()
        .slice(-3);
      const threeLast = [];
      rawData.forEach((proposal) => {
        if (proposalCodes.includes(proposal.tokenProposalCode)) {
          threeLast.push(proposal);
        }
      });
      threeLast.sort((a, b) => (a.tokenProposalCode - b.tokenProposalCode) * -1);
      setPropolsals(threeLast);
    };

    const getBankData = async () => {
      const rawData = await getBankInfo();
      setBankInfo(rawData);
    };

    const getBalanceInfo = async () => {
      const rawData = await getIssuerBalance(issuerProfile.issuerId);
      setBalanceInfo(rawData);
    };

    if (issuerProfile) {
      getLastestPropolsals();
      getBalanceInfo();
    }
    getBankData();
  }, [issuerProfile]);

  useEffect(() => {
    const getBanks = async () => {
      const data = await getAllBanks();
      const mappedBank = Array.from(data)
        .map((item) => {
          if (item.code === parseInt(bankInfo.bankCode)) {
            return {
              label: `${item.code} - ${item.fullName}`,
            };
          }
        })
        .filter((item) => item !== undefined);
      setBankLabel(mappedBank);
    };
    if (bankInfo) getBanks();
  }, [bankInfo && bankInfo.bankCode]);

  if (loadingBalance || loadingBankData || loadingProposals || loadingBankInfo) {
    return <Loading />;
  }

  if (propolsals)
    return (
      <div className="wallet-wrapper row col-12">
        <div className="row col-12 col-lg-7 d-flex main-grid">
          <div className="row col-12 balances">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-6 d-flex align-items-center justify-content-center balance-left">
              <div
                className="col-12 invest-label d-flex align-items-center"
                style={{ backgroundColor: 'white', height: '7vh' }}
              >
                <div
                  className="d-flex justify-content-end align-items-center form-group"
                  style={{ textAlign: 'start', alignItems: 'center' }}
                >
                  <p style={{ margin: 0, whiteSpace: 'nowrap' }}>{t('pages.wallet.return')}</p>
                  <i className="material-icons icon-float-right cursor-pointer" onClick={balanceController}>
                    {showBalance}
                  </i>
                  <input
                    type={showBalance === 'visibility_off' ? 'password' : 'text'}
                    style={{ boxShadow: 'none', border: 'none' }}
                    className="form-control"
                    readOnly
                    value={`R$ ${new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(
                      parseFloat(balanceInfo && balanceInfo.payableAmount),
                    )}`}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-6 d-flex align-items-center justify-content-end balance-right">
              <div
                className="col-12 invest-label d-flex align-items-center"
                style={{ backgroundColor: 'white', marginLeft: '0.938rem', height: '7vh' }}
              >
                <div
                  className="d-flex justify-content-end align-items-center form-group"
                  style={{ textAlign: 'start', alignItems: 'center' }}
                >
                  <p className="mb-0 nowrap" style={{ whiteSpace: 'nowrap', fontSize: '1.5rem' }}>
                    <Translator path="pages.wallet.balance" />
                  </p>
                  <i className="material-icons icon-float-right cursor-pointer" onClick={balanceController}>
                    {showBalance}
                  </i>
                  <input
                    type={showBalance === 'visibility_off' ? 'password' : 'text'}
                    style={{ boxShadow: 'none', border: 'none' }}
                    className="form-control"
                    readOnly
                    value={`R$ ${new Intl.NumberFormat('pt-BR', {
                      minimumFractionDigits: 2,
                    }).format(parseFloat(balanceInfo && balanceInfo.balance))}`}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row col-12 links">
            <div className="row col-12 col-sm-12 col-md-6 col-lg-6 d-flex deposit-link">
              <div
                className="col-12 d-flex flex-column flex-column justify-content-start align-items-center "
                onClick={() => navigate('/wallet/depositList')}
              >
                <LinkCard
                  props={{
                    image: linkToDeposit,
                    text: 'pages.wallet.linkComponent.depositText',
                    icon: 'account_balance_wallet',
                  }}
                />
              </div>
            </div>
            <div className="row col-12 col-sm-12 col-md-6 col-lg-6 d-flex withdraw-link">
              <div
                className="col-12 d-flex flex-column justify-content-start align-items-center "
                onClick={() => navigate('/wallet/withdrawList')}
              >
                <LinkCard
                  props={{
                    image: linkToWithdraw,
                    text: 'pages.wallet.linkComponent.withdrawText',
                    icon: 'account_balance',
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row col-md-5 d-flex bank-grid">
          <div className="bank-grid-background">
            <div className="col-12 bank-grid-header">
              <p>
                <Translator path="pages.wallet.bankComponent.title" />
              </p>
            </div>
            <div className="row col-12 bank-grid-content d-flex justify-content-between">
              <div className="col-5">
                <p className="wallet-bank-label">
                  <Translator path="pages.wallet.bankComponent.bank" />
                </p>
                <p className="wallet-bank-info" style={{ marginBottom: '1.25rem' }}>
                  {bankLabel && bankLabel[0].label}
                </p>
                <p className="wallet-bank-label">
                  <Translator path="pages.wallet.bankComponent.typeA" />
                </p>
                <p className="wallet-bank-info" style={{ marginBottom: '1.25rem' }}>
                  {bankInfo && bankInfo.accountType}
                </p>
                <p className="wallet-bank-label">
                  <Translator path="pages.wallet.bankComponent.agency" />
                </p>
                <p className="wallet-bank-info" style={{ marginBottom: '1.25rem' }}>
                  {bankInfo && bankInfo.agency}
                  {bankInfo && bankInfo.agencyDigit}
                </p>
                <p className="wallet-bank-label">
                  <Translator path="pages.wallet.bankComponent.account" />
                </p>
                <p className="wallet-bank-info">
                  {bankInfo && bankInfo.accountNumber}-{bankInfo && bankInfo.accountNumberDigit}
                </p>
              </div>
              <div className="col-6" style={{ textAlign: 'end' }}>
                <p className="wallet-bank-label">
                  <Translator path="pages.wallet.bankComponent.typeP" />
                </p>
                <p className="wallet-bank-info" style={{ marginBottom: '1.25rem' }}>
                  {bankInfo
                    ? bankInfo.pixTypeKey === 'phone'
                      ? t('pages.myBank.pixSelect.phone')
                      : bankInfo.pixTypeKey === 'cnpj'
                      ? t('pages.myBank.pixSelect.cnpj')
                      : bankInfo.pixTypeKey === 'random'
                      ? t('pages.myBank.pixSelect.random')
                      : bankInfo.pixTypeKey === 'email'
                      ? t('pages.myBank.pixSelect.email')
                      : ''
                    : ''}
                </p>
                <p className="wallet-bank-label">
                  <Translator path="pages.wallet.bankComponent.key" />
                </p>
                <p className="wallet-bank-info">{bankInfo && bankInfo.pixKey}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="row col-12 d-flex justify-content-center align-items-center propolsal-list-grid">
          <div className="row col-12 pl-header">
            <p>
              <Translator path="pages.wallet.propolsal" />
            </p>
          </div>
          <div className="row col-12 proposals" style={{ margin: 0, padding: '0 0.156rem' }}>
            <PropolsalList props={{ propolsals }} />
          </div>
        </div>
      </div>
    );
};

export default Wallet;
