import React, { useEffect, useState } from 'react';
import { stage2 } from '@assets';
import { Translator, Countdown, CodeValidator } from '@components';
import { useRegister } from '@contexts-issuer';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SecondStep = ({ props: { setCode, step, email, handleTokenValidation, sendTokenEmail, setStep } }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { loading } = useRegister();
  const successObj = { msg: '' };
  const [retry, setRetry] = useState(successObj);

  useEffect(() => {
    const sendToken = () => {};
    if (step === 1) sendToken();
  }, [step]);

  useEffect(() => {
    const success = { ...successObj };
    success.msg = '';
    setRetry(success);
  }, [setCode]);

  const resend = () => {
    if (step === 1 && email) {
      sendTokenEmail(email);
    }
    const success = { ...successObj };
    success.msg = t('pages.withdrawList.resend').toString();
    setRetry(success);
  };

  return (
    <>
      <div className="row col-12 reset-header">
        <img src={stage2} />
        <p className="title">
          <Translator path="pages.reset.secondStep.title" />
        </p>
        <p className="body">
          <Translator path="pages.reset.secondStep.body" />
        </p>
        <p className="email">{email ? email : undefined}</p>
      </div>
      <div className="row col-12 d-flex justify-content-start reset-content" style={{ margin: 0, padding: 0 }}>
        <div className="col-12 d-flex justify-content-start" style={{ gap: '0 11px', margin: '2.5rem 0 0 0' }}>
          <CodeValidator props={{ setCode }} />
        </div>

        {retry.msg && (
          <div className="col-3 text-success fs-12 d-flex align-items-center" style={{ marginTop: '0.625rem' }}>
            {retry.msg}
          </div>
        )}
        <div
          className={`row ${retry.msg ? 'col-5' : 'col-8'} d-flex justify-content-end align-items-center`}
          style={{ margin: 0 }}
        >
          <span
            className="form-check d-inline-block d-flex justify-content-end "
            style={{ padding: 0, whiteSpace: 'nowrap' }}
          >
            <label
              className="resend cursor-pointer"
              htmlFor="check1"
              style={{ alignSelf: 'start' }}
              onClick={() => resend()}
            >
              <Translator path="pages.register.secondStep.send" />
            </label>
            <label
              className="resend cursor-pointer"
              style={{ alignSelf: 'end', marginLeft: '0.5rem' }}
              onClick={() => resend()}
            >
              <Translator path="pages.register.secondStep.code" />
            </label>
          </span>
        </div>
        <div className="row col-12 d-flex justify-content-between">
          <button
            className="btn btn-outlined col-3"
            style={{ marginTop: '2.5rem', padding: '1rem 0.625rem' }}
            onClick={() => setStep(0)}
          >
            <Translator path="pages.reset.goback" />
          </button>
          <button
            className={`btn btn-orange col-7 ${loading ? 'd-flex justify-content-center disabled' : ''}`}
            style={{ marginTop: '2.5rem' }}
            onClick={handleTokenValidation}
          >
            {loading ? (
              <div className="loading-spinner" style={{ width: '30px', height: '30px' }} />
            ) : (
              <Translator path="pages.reset.continue" />
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default SecondStep;
