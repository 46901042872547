import { Translator } from '@components';
import { QRCodeSVG } from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDeposit } from '../../../../../../contexts/Investor/DepositContext';

const SecondStep = () => {
  const { loading, currentDeposit } = useDeposit();
  const { t } = useTranslation();

  const copy = async () => {
    let pix = currentDeposit?.pixString;
    const text = pix;
    await navigator.clipboard.writeText(text).then(
      (success) => {
        notifyBottomCenter();
      },
      (error) => {
        console.error(error);
      },
    );
  };

  const notifyBottomCenter = () => {
    toast.success(t('depositPage.secondStep.copied').toString(), {
      position: 'bottom-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <div className="row second-step d-flex flex-column justify-content-center align-items-center ">
      <p style={{ marginBottom: '1rem' }}>
        <Translator path="depositPage.secondStep.body" />
      </p>

      {currentDeposit && <QRCodeSVG value={currentDeposit?.pixString} />}
      <div className="row col-12 align-itens-start justify-content-evenly " style={{ margin: '1.25rem 0 0 0' }}>
        <div className="col-5 " style={{ borderTop: '1px solid #EDEDED', padding: 0, margin: '0.813rem 0 0 0' }} />
        <p className="col-2" style={{ padding: 0, margin: 0, textAlign: 'center' }}>
          <Translator path="depositPage.secondStep.or" />
        </p>
        <div className="col-5" style={{ borderTop: '1px solid #EDEDED', padding: 0, margin: '0.813rem 0 0 0' }} />
      </div>
      <button
        className="btn btn-outlined col-10 d-flex aling-itens-center justify-content-center"
        onClick={() => copy()}
        style={{ margin: '2.188rem 0 0 0' }}
      >
        <i className="material-icons" style={{ margin: '0.313rem 0 0 0' }}>
          content_copy
        </i>
        <p style={{ margin: 0 }}>
          <Translator path="depositPage.secondStep.copy" />
        </p>
      </button>
      <ToastContainer />
    </div>
  );
};

export default SecondStep;
