import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { logoCriptech, loginBg } from '@assets';
import { I18n, Loading } from '@components';
import { useProfile } from '@contexts-investor';

import { useTranslation } from 'react-i18next';
import { specialToCode } from '@utils';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import FinalStep from './FinalStep';

function ResetPassword(props) {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(1);
  const { sendResetToken, validateToken, myId, resetMyId } = useProfile();
  const [code, setCode] = useState();
  const [email, setEmail] = useState();

  const handleStep = async () => {
    if (currentStep === 1) {
      await sendResetToken(specialToCode(email));
    } else if (currentStep === 2) {
      await validateToken(code, setCurrentStep);
      setCurrentStep(3);
    }
  };

  useEffect(() => {
    resetMyId();
    setCurrentStep(1);
  }, []);

  useEffect(() => {
    if (currentStep === 1 && myId) {
      setCurrentStep(2);
    }
  }, [myId]);

  return (
    <div className="page-wraper">
      <div className="browse-job login-style3">
        <div
          className="bg-img-fix overflow-hidden"
          style={{
            background: '#fff url(' + loginBg + ')',
            height: '100vh',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right',
            backgroundSize: 'contain',
          }}
        >
          <div className="row gx-0">
            <div className="col-xxl-4 col-xl-5 col-lg-6 col-md-8 col-sm-12 vh-100 bg-white d-flex justify-content-center align-items-center ">
              <div className="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside col-12">
                <div
                  id="mCSB_1_container"
                  className="mCSB_container"
                  style={{ position: 'relative', top: '0', left: '0', dir: 'ltr' }}
                >
                  <div className="login-form style-2">
                    <div className="card-body row" style={{ margin: 0, padding: '0 2.5rem' }}>
                      <div className="logo-header col-12" style={{ margin: 0 }}>
                        <Link to={'#'} className="logo">
                          <img
                            src={logoCriptech}
                            alt=""
                            className="width-230 mCS_img_loaded"
                            style={{ marginBottom: '2.5rem' }}
                          />
                        </Link>
                      </div>
                      {currentStep === 1 ? (
                        <FirstStep props={{ email, setEmail, handleStep }} />
                      ) : currentStep === 2 ? (
                        <SecondStep props={{ email, setCode, code, handleStep, setStep: setCurrentStep }} />
                      ) : (
                        <FinalStep props={{ code, setCode, setStep: setCurrentStep, userId: myId, email }} />
                      )}
                    </div>
                  </div>
                </div>
                <I18n />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
