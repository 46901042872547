import { createContext, useCallback, useContext, useState } from 'react';
import swal from 'sweetalert';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { saveTokenInLocalStorage, saveUserIdInLocalStorage } from '../../services/AuthService';
import * as registerService from '../../services/Issuer/registerService';
import { loginConfirmedAction } from '../../store/actions/AuthActions';

export const RegisterContext = createContext({});

const RegisterProvider = ({ children }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [resetId, setResetId] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const register = useCallback(
    async (params, setStep) => {
      setLoading(true);
      try {
        const { data } = await registerService.registerIssuer(params);
        localStorage.setItem('issuerLoginId', data.issuerLoginId);
        localStorage.setItem('issuer_email', data.email);
        setStep(2);
        setTimeout(() => {
          localStorage.removeItem('issuerLoginId');
          localStorage.removeItem('issuer_email');
        }, process.env.REACT_APP_REFRESH_TOKEN_TTL);
      } catch (error) {
        if (error.response.data.message) {
          let errors = [];
          for (const message of error.response.data.message) {
            if (message.includes('Email')) {
              errors = [...errors, t('pages.register.firstStep.apiErr.dupEmail').toString()];
            }
            if (message.includes('CPF')) {
              errors = [...errors, t('pages.register.firstStep.apiErr.dupCpf').toString()];
            }
            if (message.includes('CNPJ')) {
              errors = [...errors, t('pages.register.firstStep.apiErr.dupCnpj').toString()];
            }
            if (message.includes('Telefone')) {
              errors = [...errors, t('pages.register.firstStep.apiErr.dupPhone').toString()];
            }
          }
          swal(t('pages.register.firstStep.apiErr.title').toString(), errors.toString().replaceAll(',', '\n'), 'error');
        }
      } finally {
        setLoading(false);
      }
    },
    [loading, t],
  );

  const validateToken = useCallback(
    async (params) => {
      setLoading(true);
      try {
        const { data } = await registerService.validateCode(params);
        localStorage.setItem('access_token_register_issuer', params.token);
        setTimeout(() => {
          localStorage.removeItem('access_token_register_issuer');
        }, process.env.REACT_APP_REFRESH_TOKEN_TTL);
        return true;
      } catch (error) {
        console.log(error);
        const errorTitle = t('pages.register.secondStep.apiErr.title').toString();
        const errorMessage = t('pages.register.secondStep.apiErr.tokenErr');
        swal(errorTitle, errorMessage, 'error');

        return false;
      } finally {
        setLoading(false);
      }
    },
    [loading],
  );

  const setIssuerPassword = useCallback(async (body) => {
    setLoading(true);
    try {
      const { data } = await registerService.setPassword(body);
      const successHeader = t('pages.register.finalStep.success');
      const successMessage = t('pages.register.finalStep.sucessMessage');
      swal(successHeader, successMessage, 'success');
      localStorage.removeItem('access_token_register_issuer');
      localStorage.removeItem('issuer_email');
      localStorage.removeItem('issuerLoginId');
      navigate('/login');
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const resetIssuerPassword = useCallback(async (body) => {
    setLoadingButton(true);
    try {
      const { data } = await registerService.resetPassword(body);
      const successHeader = t('pages.register.finalStep.success');
      const successMessage = t('pages.register.finalStep.sucessMessage');
      swal(successHeader, successMessage, 'success');
      navigate('/profile/edit');
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingButton(false);
    }
  }, []);

  const resetToken = useCallback(async (issuerLoginId) => {
    try {
      registerService.resetToken(issuerLoginId);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const sendTokenEmail = useCallback(async (email) => {
    setLoadingButton(true);
    try {
      const { data } = await registerService.sendTokenEmail(email);
      setResetId(data.issuerLoginId);
      return true;
    } catch (error) {
      const errorHeader = t('pages.reset.err.emailTitle');
      const errorMessage = t('pages.reset.err.emailBody');
      swal(errorHeader, errorMessage, 'error');
      return false;
    } finally {
      setLoadingButton(false);
    }
  }, []);

  const login = useCallback(async (body) => {
    setLoading(true);
    try {
      const { data } = await registerService.login(body);
      saveTokenInLocalStorage(data.access_token);
      saveUserIdInLocalStorage(data.access_token);
      localStorage.setItem('refresh_token', data.refresh_token);
      dispatch(loginConfirmedAction(data));
      navigate('/dashboard');
    } catch (error) {
      const errorTitle = t('pages.login.apiErr.title');
      const errorMesage = t('pages.login.apiErr.login');
      swal(errorTitle, errorMesage, 'error');
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <RegisterContext.Provider
      value={{
        loading,
        register,
        validateToken,
        resetToken,
        setIssuerPassword,
        login,
        loadingButton,
        resetIssuerPassword,
        sendTokenEmail,
        resetId,
      }}
    >
      {children}
    </RegisterContext.Provider>
  );
};

const useRegister = () => {
  return useContext(RegisterContext);
};

export { RegisterProvider, useRegister };
