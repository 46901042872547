import React from 'react';
import { Translator } from '@components';

const AnalysisCard = ({ props: { status } }) => {
  return (
    <div
      className={`col-12 d-flex justify-content-center align-items-center analysis-container ${
        status === 'APPROVED' || status === 'COMPLETE'
          ? 'bg-green'
          : status === 'IN_ANALYSIS'
          ? 'bg-orange'
          : status === 'CHANGES_REQUEST'
          ? 'bg-dark-orange'
          : status === 'REJECTED'
          ? 'bg-red'
          : ''
      }`}
      style={{ borderRadius: '12px' }}
    >
      {status === 'IN_ANALYSIS' ? (
        <>
          <div className="d-flex align-items-center" style={{ border: '1px solid #FFFFFF', borderRadius: '200px' }}>
            <i className="material-icons">priority_high</i>
          </div>
          <p>
            <Translator path="components.analysisCard.analysis" />
          </p>
        </>
      ) : status === 'APPROVED' || status === 'COMPLETE' ? (
        <>
          <div className="d-flex align-items-center" style={{ border: '1px solid #FFFFFF', borderRadius: '200px' }}>
            <i className="material-icons">check</i>
          </div>
          <p>
            <Translator path="components.analysisCard.approved" />
          </p>
        </>
      ) : status === 'CHANGES_REQUEST' ? (
        <>
          <div className="d-flex align-items-center" style={{ border: '1px solid #FFFFFF', borderRadius: '200px' }}>
            <i className="material-icons">swap_horiz</i>
          </div>
          <p>
            <Translator path="components.analysisCard.changes" />
          </p>
        </>
      ) : status === 'REJECTED' ? (
        <>
          <div className="d-flex align-items-center" style={{ border: '1px solid #FFFFFF', borderRadius: '200px' }}>
            <i className="material-icons" style={{ fontSize: '1rem' }}>
              backspace
            </i>
          </div>
          <p>
            <Translator path="components.analysisCard.rejected" />
          </p>
        </>
      ) : (
        ''
      )}
    </div>
  );
};

export default AnalysisCard;
