import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

const CodeValidator = ({ props: { setCode } }) => {
  const [box, setbox] = useState();
  const [box1, setbox1] = useState();
  const [box2, setbox2] = useState();
  const [box3, setbox3] = useState();
  const inputRef = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);
  const [length, setLength] = useState(0);
  const { pathname } = useLocation();
  useEffect(() => {
    setCode(box + box1 + box2 + box3);
  }, [box, box1, box2, box3]);

  useEffect(() => {
    if (!inputRef || !inputRef2 || !inputRef3 || !inputRef4) return;
    const input2 = inputRef2.current;
    const input3 = inputRef3.current;
    const input4 = inputRef4.current;

    if (length === 1) {
      if (document.activeElement === inputRef.current) {
        input2.focus();
      } else if (document.activeElement === inputRef2.current) {
        input3.focus();
      } else if (document.activeElement === inputRef3.current) {
        input4.focus();
      }
      setLength(0);
    }
  }, [length]);

  return (
    <>
      <input
        className="form-control input-square col-3 col-md-6 col-xs-12"
        maxLength={1}
        value={box}
        ref={inputRef}
        onChange={(e) => {
          if (isNaN(e.target.value)) setbox(e.target.value.toUpperCase());
          else setbox(e.target.value);
          if (e.target.value !== (undefined && '' && ' ')) setLength(length + 1);
        }}
      />
      <input
        className="form-control input-square col-3 col-md-6 col-xs-12"
        maxLength={1}
        value={box1}
        ref={inputRef2}
        onChange={(e) => {
          if (isNaN(e.target.value)) setbox1(e.target.value.toUpperCase());
          else setbox1(e.target.value);
          if (e.target.value !== (undefined && '' && ' ')) setLength(length + 1);
        }}
      />
      <input
        className="form-control input-square col-3 col-md-6 col-xs-12"
        maxLength={1}
        value={box2}
        ref={inputRef3}
        onChange={(e) => {
          if (isNaN(e.target.value)) setbox2(e.target.value.toUpperCase());
          else setbox2(e.target.value);
          if (e.target.value !== (undefined && '' && ' ')) setLength(length + 1);
        }}
      />
      <input
        className="form-control input-square col-3 col-md-6 col-xs-12"
        maxLength={1}
        value={box3}
        ref={inputRef4}
        onChange={(e) => {
          if (isNaN(e.target.value)) setbox3(e.target.value.toUpperCase());
          else setbox3(e.target.value);
          if (e.target.value !== (undefined && '' && ' ')) setLength(length + 1);
        }}
      />
    </>
  );
};

export default CodeValidator;
