import Translator from '../../../../components/I18n/Translator';

export const COLUMNS = [
  {
    Header: <Translator path={'pages.tokensList.name'} />,
    Footer: <Translator path={'pages.tokensList.name'} />,
    accessor: 'name',
    isSortable: true,
  },
  {
    Header: <Translator path={'pages.tokensList.offeredValue'} />,
    Footer: <Translator path={'pages.tokensList.offeredValue'} />,
    accessor: 'formattedOfferedValue',
    isSortable: true,
  },
  {
    Header: <Translator path={'pages.tokensList.fundingDate'} />,
    Footer: <Translator path={'pages.tokensList.fundingDate'} />,
    accessor: 'formattedFundingDate',
    isSortable: true,
  },
  {
    Header: <Translator path={'pages.tokensList.liquidationDate'} />,
    Footer: <Translator path={'pages.tokensList.liquidationDate'} />,
    accessor: 'formattedLiquidationDate',
    isSortable: true,
  },
  {
    Header: 'Status',
    Footer: 'Status',
    accessor: 'formattedStatus',
    isSortable: true,
  },
  {
    Header: <Translator path={'pages.tokensList.action'} />,
    Footer: <Translator path={'pages.tokensList.action'} />,
    accessor: 'actions',
    isSortable: true,
  },
];
