export default {
  issuer: {
    pages: {
      register: {
        firstStep: {
          header: 'Register - First step',
          body: 'Please, fill the information bellow',
          cnpj: 'Enterprise id',
          name: 'Full name',
          cpf: 'Personal id',
          email: 'Email',
          phone: 'Phone number',
          goBack: 'Go back',
          resend: 'Token sent successfully!',
          continue: 'Continue',
          validationErr: {
            noCnpj: 'Please inform your enterprise id',
            invalidCnpj: 'Please inform a valid enterprise id',
            noName: 'Please inform your full name',
            noCpf: 'Please inform your id',
            invalidCpf: 'Please inform a valid id',
            noEmail: 'Please inform your email',
            noPhone: 'Please inform your phone number',
            invalidPhone: 'Please inform a valid phone number',
            invalidEmail: 'Please inform a valid email',
          },
          apiErr: {
            title: 'Failed to register',
            dupEmail: 'Email already exists',
            dupCpf: 'Id already exists',
            dupCnpj: 'Enterprise id already exists',
            dupPhone: 'Phone number already exists',
          },
        },
        secondStep: {
          header: 'Register - Second step',
          body: 'Please, inform the code sent to your email',
          send: 'Resend',
          code: 'code?',
          goBack: 'Go back',
          continue: 'Continue',
          apiErr: {
            title: 'Code validation failed',
            tokenErr: 'Please inform the code sent to your email!',
          },
        },
        finalStep: {
          header: 'Register - Third step',
          body: 'Please, set a password',
          password: 'Password',
          confirm: 'Confirm password',
          goback: 'Go back',
          submit: 'Finalize',
          errors: {
            pass: 'Please inform your password',
            confirm: 'Please confirm your password',
            val: "The passwords don't match!",
            attend: 'Please make sure your password pass the requirements',
          },
          validators: {
            lenght: 'Minimum 8 characters',
            num: 'At least one number',
            upper: 'At least one uppercase letter',
            special: 'At least one special character: !@#$%^&*(),.?":{}|<>]',
            equal: 'Both passwords must match',
          },
          validationErr: {
            password: 'Please inform a password',
            confirm: 'Please confirm your password',
          },
          success: 'Success',
          sucessMessage: 'The operation was processed successfuly',
        },
      },
      login: {
        title: 'Welcome, issuer!',
        body: 'Please inform your email and password to login.',
        email: 'Email',
        password: 'Password',
        submit: 'Login',
        alter: 'Change password',
        noAccount: "Don't have an account?",
        register: 'Register',
        loading: 'Loading...',
        validationErr: {
          email: 'Please inform your email',
          password: 'Please inform your password',
          recaptcha: 'Invalid Recaptcha'
        },
        apiErr: {
          title: 'Failed to login',
          login: 'Please check your login credentials',
        },
      },
      issuer: {
        title: 'Register new issuer',
        logo: 'Corporate logo',
        cnpj: 'Corporate id',
        social: 'Social reason',
        selectedSocialContract: 'Social contract uploaded',
        selectedAddtionalDoc: 'Addional doc selected',
        corporateName: 'Corporate name',
        cep: 'CEP',
        corporateEmail: 'Corporate email',
        phone: 'Phone',
        foundingDate: 'Founding date',
        simpleDescription: 'Simple corporate description',
        description: 'Detailed corporate description',
        contract: 'Social contract/Warrant',
        capital: 'Social capital',
        url: 'Website URL',
        area: 'Area',
        docs: 'Additional document',
        docDescription: 'Additional document description',
        register: 'Register',
        edit: 'Edit',
        save: 'Save changes',
        alter: 'Send changes',
        cancel: 'Cancel changes',
        addDoc: 'Add another document',
        removeDoc: 'Remove additional document',
        downloadDoc: 'Download document',
        alterApproved: 'In case of data alteration our team is going to review your registry',
        finishRegister:
          'Almost there! Now the issuer information is being analysed, please wait for the next steps in your email',
        goBack: 'Go back',
        validators: {
          logo: 'Please upload a logo',
          cnpj: 'Please inform a corporation id',
          social: 'Please inform a social reason',
          corporateName: "Please inform your corporation's name",
          cep: 'Please inform your cep',
          corporateEmail: "Please inform your Corporation's email",
          phone: 'Please inform a phone number',
          foundingDate: 'Please inform the founding date',
          simpleDescription: 'Please inform a simple description',
          description: 'Please inform a detailed description',
          contract: 'Please upload a Social contract/Warrant',
          capital: 'Please inform the Social capital',
          url: 'Please inform a Website URL',
          area: 'Please inform a business line',
        },
        isEdit: {
          link: 'No file uploaded',
        },
        err: {
          noLink: 'Please add a document to add another document field',
          noDesc: 'Please add a description',
          noRemove: 'No documents to remove',
          original: 'Unable to remove the document, you can only replace it',
        },
        apiErr: {
          title: 'Failed to register issuer',
          body: 'Please try again latter',
        },
        success: {
          title: 'Success',
          body: 'Issuer registered successfully',
          editBody: 'Issuer data edited successfully',
        },
      },
      myBank: {
        title: 'Bank data',
        form: {
          cnpj: 'Corporation id',
          bank: 'Bank',
          type: 'Account type',
          agency: 'Agency',
          account: 'Account',
          digit: 'Digit',
          keyType: 'Type of key',
          key: 'Pix key',
          submit: 'Register',
          edit: 'Edit',
          send: 'Send changes',
          alter: 'Save changes',
          cancel: 'Cancel changes',
        },
        typeSelect: {
          current: 'checking account',
          poup: 'savings account',
        },
        pixSelect: {
          email: 'Email',
          phone: 'Phone',
          random: 'Random',
          cnpj: 'CNPJ',
        },
        validators: {
          bankCode: 'Por favor selecione um banco brasileiro',
          accountType: 'Por favor informe o tipo da conta',
          agency: 'Por favor insira o número da sua agência',
          agencyDigit: 'Por favor insira o dígito de sua agência',
          accountNumber: 'Por favor insira o número da conta',
          accountNumberDigit: 'Por favor insira o dígito da sua conta',
          pixTypeKey: 'Por favor selecione um tipo de chave pix',
          pixKey: 'Por favor informe uma chave pix',
        },
        err: {
          titleReg: 'Failed to register bank account ',
          titleEdit: 'Failed to edit bank account',
          body: 'Please try again latter!',
        },
        instructions: {
          title: 'Attention to the instructions bellow',
          block: 'Fill in with real data from your corporation to deposit and withdraw with securty.',
          business: "Don't use your personal data, all information must be from your corporation",
          email: 'When registering the information of your corporation, our platform will validate its authenticity',
        },
        finish: {
          body: 'You bank account data is beeing reviewed by our team!',
          end: 'As soon as its approved we will contact you by email',
          goBack: 'Go back',
        },
      },

      loading: 'Loading...',

      tokenProposal: {
        title: 'Token proposal',
        image: 'Token image',
        name: 'Token name (Max: 16 characters)',
        invoiceValue: 'Invoice total value',
        fundingDateLimit: 'Receiving date (Withdraw)',
        liquidationDate: 'Payment date (Deposit)',
        discountPercentage: 'Discount rate',
        valueToReceive: 'Value to receive',
        description: 'Token description',
        additionalDoc: 'Fiscal document/Invoice',
        additionalDocDescription: 'Fiscal document description',
        sendProposal: 'Send proposal',
        edit: 'Edit',
        save: 'Save changes',
        alter: 'Send changes',
        cancel: 'Cancel changes',
        addDoc: 'Add another document',
        preview: 'Preview',
        remove: 'Remove document',
        notApproved: {
          text: 'To submit a proposal, it is necessary to complete the registration of a issuer! Please click in the button bellow and do it right now',
          complete: 'Complete registration',
        },
        missApprove: {
          text: 'To submit a proposal, our team need to approve the informations gathered in register step! We will send you an email when the data get approve. Please, wait.',
        },
        changesRequested: {
          body: 'To submit a proposal, you need to fix your corporation registry. Please, click the button bellow and do it now.',
          button: 'Fix registry',
        },
        rejected: {
          body: 'Unfortunately the the information about your corporation has been rejected. Please, click on the link bellow to register again.',
        },
        errors: {
          noName: 'Please inform the token name.',
          noInvoice: 'Please inform the invoice value.',
          noFundingLimitDate: 'Please inform the receiving date.',
          noLiquidationDate: 'Please inform the payment date.',
          noDiscountPercentage: 'Please inform the discount rate.',
          noDescription: 'Please inform the token description.',
          noImage: 'Please inform the token image.',
          noFiscalDoc: 'Please inform the fiscal document.',
          noFiscalDesc: 'Please inform a description to the fiscal document.',
          noAdditionalDoc: 'Please inform an additional document before adding another one',
          noAdditionalDocDesc: 'Please describe the additional document before adding another one',
          fileTooLargeTitle: 'File too large',
          fileTooLargeBody: 'This file is too large, please insert a smaller one.',
        },
        apiErr: {
          title: 'Failed to submit the token proposal',
          body: 'Please try again later',
          upload: 'Fail to upload the image',
          download: 'Fail to download the image',
          docUpload: 'Fail to upload the document',
          update: 'Fail to update the proposal',
          updateBody: 'Try again later or contact an administrator',
          preApprove: 'Fail to pre-approve your proposal',
          annul: 'Fail to annul your proposal',
        },
        success: {
          title: 'Success',
          body: 'Token proposal submitted successfully',
          uploadBody: 'Token image succesfully updated',
        },
        overviewModal: {
          overview: 'Proposal overview',
          issuer: 'Issuer:',
          invoice: 'Invoice value',
          valueToReceive: 'Value to receive',
          discountPercentage: 'Discount rate',
          fundingDate: 'Receiving date (withdraw)',
          liquidationDate: 'Payment date (deposit)',
          cancel: 'Cancel',
          confirm: 'Confirm',
        },
        successModal: {
          text: 'We are almost there! Now the token informations are being verified, please wait the next steps in your email: ',
          seeProposal: 'See proposal',
        },
        proposalPreview: {
          title: 'You are viewing as an investor',
          currentBalance: 'Current balance',
          minimumValue: 'Minimum value',
          monthlyYield: 'Monthly yield',
          remainingQuantity: 'Remaining tokens',
          invest: 'Invest',
          tokenName: 'Token name:',
          unityPrice: 'Value of 1 token:',
          quantity: 'Select the quantity',
          valueToInvest: 'Value to invest',
          valueToReceive: 'Value to receive',
          receiveDate: 'Receive in 1x',
          description: 'Description',
          about: 'About the token',
          info: 'General infos',
          captation: 'Captation',
          currentCaptation: 'Current captation',
          buyers: 'Buyers',
          liquidation: 'Liquidation',
          totalValue: 'Total value',
          offeredTokens: 'Offered tokens',
          issuerInfo: 'Issuer infos',
          foundationDate: 'Foundation: ',
        },
        tokenCard: {
          value: 'Minimum value',
          revenue: 'Monthly yield',
        },
      },
      withdrawList: {
        title: 'Withdraws',
        filter: 'Filters',
        resend: 'Validation token sent!',
        codeFail: 'Please fill all the camps',
        body: 'Withdraw requested successfuly! Please wait for the next steps in your email:',
        works: 'Doubs about how to withdraw?',
        filters: {
          dateIni: 'Initial date - Foreseen',
          dateFi: 'Final date - Foreseen',
          withdrawDateIni: 'Initial date - withdraw',
          withdrawDateFi: 'Data final - saque',
          code: 'Withdraw code',
          name: 'Token name',
          tokenCode: 'Token code',
          amount: 'Withdraw amount',
          status: 'Status',
          cleanBtn: 'Clean filter',
          filter: 'Filter',
        },
        request: {
          title: 'Request preview',
          titleRequested: 'Withdraw overview',
          requesting: 'Request withdraw',
          name: 'Token name',
          issuer: 'Issuer',
          amount: 'Amount to withdraw',
          date: 'Request date',
          info: 'Receiver information',
          bank: 'Bank',
          accountType: 'Account type',
          agency: 'Agency',
          accountNumber: 'Account',
          keyType: 'Type of pix key',
          pixKey: 'Pix key',
          cancel: 'Cancel',
          continue: 'Continue',
          goBack: 'Go back',
          finish: 'Finish',
          body: 'Please, inform the code sent to your email:',
        },
        tableColumns: {
          withdrawCode: 'Withdraw code',
          name: 'Token name',
          tokenCode: 'Token code',
          val: 'Withdraw amount',
          date: 'Expected date',
          withdrawDate: 'Withdraw date',
          status: 'Status',
          action: 'Actions',
          actionItems: {
            details: 'Token details',
            withdraw: 'Request withdraw',
            account: 'Account details',
            requestD: 'Request details',
          },
        },
        status: {
          available: 'Available',
          incapture: 'incapture',
          analysing: 'Analysing account',
          requested: 'Requested',
          done: 'Done',
          cancel: 'Canceled',
        },
        worksModal: {
          bodyLabel: 'Step by step on how to withdraw',
          stepOne: 'Check at the table if the field ',
          stepOneComplementOne: '"Status"',
          stepOneComplementTwo: ' is',
          stepOneComplementThree: ' "Available"',
          stepTwo: 'Click on the icon "',
          stepTwoComplementOne: '" in the column',
          stepTwoComplementTwo: ' "Actions"',
          stepThree: 'Click ',
          stepThreeComplement: '"Request withdraw"',
          submit: 'Ok, got it!',
        },
      },
      tokensList: {
        title: 'My tokens',
        name: 'Name',
        valueToReceive: 'Value to receive',
        fundingDate: 'Funding date',
        liquidationDate: 'Liquidation date',
        action: 'Actions',
        inCapture: 'In capture',
        allSold: 'Captured',
        liquidating: 'Liquidating',
        complete: 'Complete',
        cancelled: 'Cancelled',

        actions: {
          detail: 'Token details',
          withdraw: 'Request withdrawal',
          deposit: 'Make deposit',
        },

        filtersModal: {
          button: 'Filters',
          fundingInitial: 'Initial date - Capture',
          fundingFinal: 'Final date - Capture',
          liquidationInitial: 'Initial date - Liquidation',
          liquidationFinal: 'Final date - Liquidation',
          name: 'Name',
          valueToReceive: 'Value to receive',
          status: {
            inCapture: 'In capture',
            allSold: 'Captated',
            complete: 'Complete',
            cancelled: 'Cancelled',
            liquidating: 'Liquidating',
          },
          clean: 'Clean filters',
          filter: 'Filter',
        },
      },
      depositList: {
        title: 'Deposit',
        titleRequested: 'Deposit overview',
        body: 'Your deposit is being verified by our team! Now wait for out confirmation to finish the deposit. Keep an eye on your email for status update on your deposit.',
        works: 'Doubts about how it works?',
        choose: 'Choose a payment mean',
        amount: 'Value to deposit',
        requestedAmount: 'Deposited value',
        dateRequested: 'Date of deposit',
        pixBtn: 'Deposit with Pix',
        tedBtn: 'Deposit with TED',
        cancel: 'Cancel',
        goback: 'Go back',
        finish: 'Inform deposit!',
        paymentType: 'Done via',
        pix: {
          msg: 'Invest in cryptoactives!',
          expire: 'Expires in',
          or: 'or',
          copy: 'Copy Paste Pix',
          copied: 'Pix code copied!',
        },
        worksModal: {
          bodyLabel: 'Step by step on how to deposit',
          stepOne: 'Check at the table if the field ',
          stepOneComplementOne: '"Status"',
          stepOneComplementTwo: ' is',
          stepOneComplementThree: ' "Waiting deposit"',
          stepTwo: 'Click on the icon "',
          stepTwoComplementOne: '" in the column',
          stepTwoComplementTwo: ' "Actions"',
          stepThree: 'Click ',
          stepThreeComplement: '"Make deposit"',
          submit: 'Ok, got it!',
        },

        actionItems: {
          ready: 'Deposit',
          detailsPrev: 'Request details',
          details: 'Deposit details',
        },
        status: {
          incapture: 'Incapture',
          waiting: 'Waiting deposit',
          analysing: 'In analysis',
          done: 'Done',
          cancel: 'Canceled',
        },
        tableColumns: {
          order: 'Deposit code',
          name: 'Token name',
          tCode: 'Token code',
          amount: 'Deposit amount',
          pDate: 'Expected date',
          date: 'Deposit date',
          status: 'Status',
          action: 'Actions',
        },
        filter: 'Filter',
        filters: {
          dateIni: 'Initial date - deposit',
          dateFi: 'Final date - deposit',
          order: 'Deposit Code',
        },
      },
      dashboard: {
        firstSteps: 'First steps',
        step1: {
          title: 'Registration data',
          actual: 'Step 1 of 3',
          start: 'Please, click in the button bellow and submit your company data.',
          analysis: 'Your company data are being analysed by our team.',
          rejected: 'Unfortunatelly, your company data have been rejected.',
          change: 'Please, check in your email the requested changes.',
          approved: 'Your company data was approved.',
          startBtn: 'Start',
          analysisBtn: 'In analysis...',
          rejectedBtn: 'Correct registration',
          changeBtn: 'Update registration data',
          approvedBtn: 'Complete',
          status: {
            analysis: 'Data in analysis',
            rejected: 'Rejected data',
            change: 'Changes requested',
            approved: 'Approved data',
          },
        },
        step2: {
          title: 'Token proposal',
          actual: 'Step 2 of 3',
          waiting: 'To start the "Step 2", please, conclude "Step 1" first.',
          start: 'Create your first token proposal in our platform.',
          analysis: 'Your first proposal is been analysed by our team.',
          rejected: 'Unfortunately, your first token proposal was rejected.',
          change: 'Please, check in your email the requested changes.',
          approved: 'Your first token proposal was approved.',
          preAccepted: 'Please, wait while your proposal is being approved.',
          annulled: 'Annulled proposal! Click in the button bellow to send another proposal.',
          waitingBtn: 'Waiting...',
          startBtn: 'Create proposal',
          analysisBtn: 'In analysis...',
          rejectedBtn: 'Correct proposal',
          changeBtn: 'Update proposal',
          approvedBtn: 'Complete',
          preAcceptedBtn: 'See proposal',
          annulledBtn: 'Send another proposal',
          status: {
            analysis: 'Proposal in analysis',
            rejected: 'Rejected proposal',
            change: 'Changes requested',
            approved: 'Approved proposal',
            preAccepted: 'Pre-approved',
            annulled: 'Annulled proposal',
          },
        },
        step3: {
          title: 'Bank data',
          actual: 'Step 3 of 3',
          waiting: 'Please, complete the previous steps.',
          start: 'Please, fill in your company bank account details.',
          analysis: 'Your company bank account details are being analysed by our team.',
          rejected: 'Unfortunately, your bank account details were rejected.',
          change: 'Please, check in your email the requested changes.',
          approved: 'Your bank account details are approved.',
          waitingBtn: 'Waiting...',
          startBtn: 'Submit bank account details',
          analysisBtn: 'In analysis...',
          rejectedBtn: 'Correct your bak account details',
          changeBtn: 'Update your bak account details',
          approvedBtn: 'Complete',
          status: {
            analysis: 'Data in analysis',
            rejected: 'Rejected data',
            change: 'Changes requested',
            approved: 'Approved data',
          },
        },
      },
      wallet: {
        depositBtn: 'Deposit',
        balance: 'Current balance:',
        return: 'Value to return:',
        propolsal: 'Latest propolsals',
        linkComponent: {
          depositText: 'I want to deposit',
          withdrawText: 'I want to withdraw',
        },
        bankComponent: {
          title: 'Bank account',
          bank: 'Bank',
          typeA: 'Account type',
          agency: 'Agency',
          account: 'Account',
          typeP: 'Type of pix key',
          key: 'Pix key',
        },
      },
      profile: {
        title: 'Alter password',
        editTitle: 'Registration data',
        email: 'Email',
        submit: 'Confirm',
        phone: 'Phone',
        name: 'Full name',
        cpf: 'ID',
        edit: 'Edit',
        photo: 'Profile picture',
        goback: 'Go back',
        success: {
          title: 'Success',
          body: 'User updated successfully!',
        },
        edit: 'Edit',
        continue: 'Continue',
        goBack: 'Go back',
        click: 'Click here',
      },
      reset: {
        goback: 'Go back',
        continue: 'Continue',
        finish: 'Finish',
        firstStep: {
          title: 'Reset password - First step',
          body: 'Please, inform your email',
          email: 'Email',
          err: {
            email: 'Inform your email!',
            valid: 'Email invalid',
          },
        },
        secondStep: {
          title: 'Reset password - Second step',
          body: 'Please, inform the code sent to your email: ',
          resend: 'Resend code',
        },
        finalStep: {
          title: 'Reset password - Third step',
          body: 'Please, set a new password',
          pass: 'Password',
          confirm: 'Confirm password',
        },
        err: {
          emailTitle: 'Email not found',
          emailBody: 'Please inform a valid email',
        },
      },
    },
    titles: {
      dashboard: 'Dashboard',
      mycorporation: 'My corporation',
      mybank: 'My corporation',
      tokens: 'Tokens',
      wallet: 'Wallet',
      exit: 'Logout',
      profile: 'My profile',
      tokenProposal: 'Tokens',
    },
    menuLabel: {
      dashboard: 'Dashboard',
      issuer: 'My corporation',
      issuerItems: {
        myData: 'Personal registry',
        myBank: 'Bank registry',
      },
      tokens: 'Tokens',
      tokensItems: {
        newProposal: 'New proposal',
        myProposal: 'My proposal',
        myTokens: 'My tokens',
      },
      wallet: 'Wallet',
      walletItems: {
        whithdrawList: 'Withdraw',
        depositList: 'Deposit',
      },
      profileItems: {
        registry: 'Registration data',
        alter: 'Alter password',
      },
      exit: 'Logout',
      profile: 'Profile',
      logout: 'Exit',
    },
    profile: {
      error: {
        title: 'Failed to get Issuer informaton',
        body: 'Please try again, or contact an admin',
      },
    },
    components: {
      select: {
        noOptions: 'No results found',
      },
      tokenPropolsalCard: {
        captureDate: 'Captation date',
        liquidDate: 'Liquidation date',
        note: 'Invoice value',
        toReceive: 'To receive value',
      },
      tableBody: {
        tokenProposaltokenslist:
          "Unfortunately, you don't have tokens in our plataform! Please, click the link bellow to make a new proposal.",
        walletdepositList:
          "Unfortunately, you don't have any token ready for deposit! Please click the link bellow to make a new token proposal.",
        walletwithdrawList:
          "Unfortunately you don't have any tokens available to withdraw! Please click the link bellow and see the status of your tokens.",
        tokenProposallist:
          'Unfortunately, you did not submitted a proposal until now! Please, click the button bellow to submit a proposal.',
      },
      tableButton: {
        tokenProposaltokenslist: 'Make',
        walletwithdrawList: 'My tokens',
        walletdepositList: 'Make propolsal',
        tokenProposallist: 'Submit proposal',
      },
      footer: {
        title: 'All rights reserverd by ',
        corporate: 'Criptech',
      },
      statusCard: {
        captation: 'In capture',
        complete: 'Completed',
        cancel: 'Canceled',
        captated: 'Capitated',
        ranOut: 'Ran out',
        accepted: 'Approved',
        preAccepted: 'Pre-approved',
        analysis: 'In analysis',
        annuled: 'Annuled',
        negotiating: 'Negotiating',
      },
      tokenCard: {
        value: 'Minimum value',
        revenue: 'Monthly yield',
        quantity: 'Remaining tokens',
        receivable: 'Receivable',
        percentage: 'Compared to the CDI',
      },
      analysisCard: {
        analysis: 'Data in analysis',
        changes: 'Changes requested',
        approved: 'Data approved!',
        rejected: 'Fix your data',
      },
      proposalsList: {
        name: 'Name',
        invoiceValue: 'Invoice total value',
        valueToReceive: 'Value to receive',
        fundingLimitDate: 'Funding date',
        liquidationDate: 'Liquidation date',
        actions: 'Actions',
        status: {
          inAnalysis: 'In analysis',
          negotiating: 'Negotiating',
          approved: 'Approved',
          rejected: 'Rejected',
          preApproved: 'Pre-approved',
          annulled: 'Annulled',
        },
        action: {
          edit: 'Edit proposal',
          view: 'View new proposal',
          detail: 'Token details',
        },
      },
      proposalFormEdit: {
        analysis: 'Data in analysis',
        negotiating: 'In negotiation',
        save: 'Save changes',
        cancel: 'Cancel changes',
        success: 'Updated proposal',
        successBody: 'Proposal successfully updated',
        remove: 'Remove this document',
        noRemove: 'Is not possible to remove',

        confirm: {
          title: 'Are you sure?',
          text: 'Are you sure you want to annul this proposal and do not continue with the approbation process?',
          yes: 'Yes, I want to annul this proposal',
          no: 'No',
          success: 'Your proposal was successfully annulled',
        },
      },
      counterProposal: {
        negotiating: 'In negotiation',
        accept: 'Accept counter-proposal',
        edit: 'Edit counter-proposal',
        annul: 'Annull proposal',
        send: 'Submit new proposal',
        altered: 'This field was updated',

        api: {
          preApprove: 'Proposal successfully accepted',
          preApproveBody:
            'The proposal is pre-approved, after the administrator approval the token will be ready to captation',
          annul: 'Proposal successfully annulled',
          annulBody: 'You just annulled an proposal, submit another.',
        },
      },
    },
  },
};
