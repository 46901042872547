import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

const SelectInput = ({ props: { options, styles, select, setSelect, placeholder, isDisabled } }) => {
  const { t } = useTranslation();
  return (
    <Select
      defaultValue={select}
      onChange={setSelect}
      options={options}
      styles={styles}
      placeholder={placeholder}
      isDisabled={isDisabled ? true : false}
      noOptionsMessage={() => t('components.select.noOptions')}
    />
  );
};

export default SelectInput;
