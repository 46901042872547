import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useProfile } from '@contexts-investor';
import { cpfMask, moneyMask } from '@utils';
import { Translator, Loading } from '@components';
import { noPreview } from '@assets';
import { Buffer } from 'buffer';

import { formatDate } from 'date-utils-2020';

const ProfileEdit = () => {
  const { t } = useTranslation();
  const [userInfo, setUserInfo] = useState();
  const [email, setEmail] = useState();
  const [wage, setWage] = useState();
  const [income, setIncome] = useState();
  const [occupation, setOccupation] = useState();
  const [image, setImage] = useState();
  const [imageURL, setImageURL] = useState();
  const [preview, setPreview] = useState();
  const {
    profile,
    loadingProfile,
    loadProfile,
    editProfile,
    loadingButton,
    uploadImage,
    downloadImageInvestor,
    imageLoading,
  } = useProfile();

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    if (userId !== (null && undefined)) loadProfile(userId);
  }, [localStorage.getItem('userId')]);

  useEffect(() => {
    if (profile) {
      const user = {
        ...profile.physicalUser,
        cpf: cpfMask(profile.physicalUser.cpf),
        monthlyIncome: new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(
          parseFloat(profile.physicalUser.monthlyIncome),
        ),
      };
      setUserInfo(user);
    }
  }, [profile]);

  useEffect(() => {
    const downloadData = async (imageURL) => {
      const base64Image = await downloadImageInvestor(imageURL.replaceAll('/', '%2F'));
      const downloadedImage = Buffer.from(base64Image.data, 'binary').toString('base64');
      setPreview(downloadedImage);
    };

    const imageUploadHandler = async () => {
      const imageURL = await uploadImage(image[0]);
      setImageURL(imageURL);
      downloadData(imageURL);
    };

    if (image) imageUploadHandler();
    else if (userInfo && userInfo.image) downloadData(userInfo.image);
  }, [userInfo && userInfo.image, image]);

  const handleProfession = (e) => {
    console.log(e.target.value);
    if (/^[A-Za-zÀ-ÿ\s^´~`]*$/.test(e.target.value) || e.target.value === '' || e.target.value === ' ') {
      setOccupation(e.target.value);
    }
  };

  const handleWage = (e) => {
    const money = moneyMask(e.target.value);
    setWage(money);
    const value = money.replace('R', '').replace('$', '').replaceAll('.', '').replaceAll(',', '.').replaceAll(' ', '');
    setIncome(value);
  };

  const handleEdit = () => {
    const body = {
      occupation: occupation ? occupation : userInfo.occupation,
      email: email ? email : userInfo.email,
      monthlyIncome: income
        ? +income
        : +userInfo.monthlyIncome.replaceAll('.', '').replaceAll(',', '.').replaceAll(' ', ''),
      image: imageURL ? imageURL : userInfo.image,
    };
    if (profile) editProfile(profile.userId, body);
  };

  if (loadingProfile) return <Loading />;

  if (profile && userInfo)
    return (
      <div className="page-wrapper">
        <div className="row row justify-content-between">
          <div
            className="row col-xl-4 col-md-12 col-sm-12 d-flex align-items-center justify-content-center profile-box"
            style={{ height: '21rem', marginBottom: '2.5rem' }}
          >
            <div className="author-profile">
              <div className="author-media">
                {imageLoading ? (
                  <>
                    <div
                      className="loading-spinner"
                      style={{
                        width: '140px',
                        height: '140px',
                        position: 'absolute',
                      }}
                    />
                    <img src={noPreview} alt="" style={{ width: '140px', height: '140px', margin: '0 !important' }} />
                  </>
                ) : (
                  <>
                    {preview ? (
                      <img
                        src={`data:image/jpeg;base64,${preview} `}
                        alt=""
                        style={{ width: '140px', height: '140px' }}
                      />
                    ) : (
                      <label
                        style={{
                          width: '140px',
                          height: '140px',
                          fontSize: '2.25rem',
                          color: 'white',
                          borderRadius: '5rem',
                          backgroundColor: '#FF9900'
                        }}
                        className="d-flex align-items-center justify-content-center"
                      >
                        {userInfo && userInfo.name[0].toUpperCase()}
                      </label>
                    )}
                    <div
                      className="upload-link"
                      title=""
                      data-toggle="tooltip"
                      data-placement="right"
                      data-original-title="update"
                      style={{ backgroundColor: '#FF9900' }}
                    >
                      <input
                        type="file"
                        className="update-file"
                        onChange={(e) => {
                          setImage(e.target.files);
                        }}
                      />
                      <i className="fa fa-camera" style={{ backgroundColor: '#FF9900' }}></i>
                    </div>
                  </>
                )}
              </div>
              <div className="author-info">
                <h6 className="title">{userInfo.name}</h6>
              </div>
            </div>
          </div>
          <div className="row col-xl-7 col-md-12 col-sm-12 col-xs-12 col-12 profile-box">
            <div
              className="row col-xl-12 col-lg-12 col-sm-12 col-xs-12 d-flex align-items-center justify-content-start cursor-pointer"
              style={{ marginBottom: '10vh', padding: 0, margin: 0 }}
            >
              <div className="row col-12 profile-edit-row-modifier">
                <div className="row col-sm-6" style={{ margin: 0, padding: '0 0.625rem 0 0' }}>
                  <label className="col-12 profile-data-labels">
                    <Translator path="profilePage.name" />
                  </label>
                  <div className="row col-12" style={{ padding: 0, margin: 0 }}>
                    <input className="col-12 profile-data-input-disabled" readOnly value={userInfo.name} />
                  </div>
                  <label className="col-12 profile-data-labels">
                    <Translator path="profilePage.occupation" />
                  </label>
                  <div className="row col-12" style={{ padding: 0, margin: 0 }}>
                    <div className="input-container-profile">
                      <input
                        className="col-12 profile-data-input"
                        defaultValue={userInfo.occupation}
                        value={occupation}
                        onChange={(e) => handleProfession(e)}
                      />
                      <i className="material-icons edit-icon-profile" style={{ color: '#ff9900' }}>
                        edit
                      </i>
                    </div>
                  </div>
                  <label className="col-12 profile-data-labels">
                    <Translator path="profilePage.cpf" />
                  </label>
                  <div className="row col-12" style={{ padding: 0, margin: 0 }}>
                    <input className="col-12 profile-data-input-disabled" readOnly value={userInfo.cpf} />
                  </div>
                </div>
                <div className="row col-sm-6" style={{ margin: 0, padding: '0 0 0 0' }}>
                  <label className="col-12 profile-data-labels">
                    <Translator path="profilePage.email" />
                  </label>
                  <div className="row col-12" style={{ padding: 0, margin: 0 }}>
                    <div className="input-container-profile">
                      <input
                        className=" profile col-12 profile-data-input-disabled"
                        defaultValue={userInfo.email}
                        value={email}
                        readOnly
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <label className="col-12 profile-data-labels">
                    <Translator path="profilePage.income" />
                  </label>
                  <div className="row col-12" style={{ padding: 0, margin: 0 }}>
                    <div className="input-container-profile">
                      <input
                        className="profile col-12 profile-data-input"
                        defaultValue={`R$ ${userInfo.monthlyIncome}`}
                        value={wage}
                        onChange={(e) => handleWage(e)}
                      />
                      <i className="material-icons edit-icon-profile" style={{ color: '#ff9900' }}>
                        edit
                      </i>
                    </div>
                  </div>
                  <label className="col-12 profile-data-labels">
                    <Translator path="profilePage.birth" />
                  </label>
                  <div className="row col-12" style={{ padding: 0, margin: 0 }}>
                    <input
                      className="col-12 profile-data-input-disabled"
                      readOnly
                      value={formatDate(userInfo.birthDate, 'dd/MM/yyyy')}
                    />
                  </div>
                </div>
                {loadingButton ? (
                  <button
                    className="col-md-3 col-lg-3 col-sm-4 col-xxl-3 btn btn-orange d-flex justify-content-center disabled"
                    style={{ marginTop: '2.5rem' }}
                  >
                    <Translator path="loading" />
                  </button>
                ) : (
                  <button
                    className="col-md-3 col-lg-3 col-sm-4 col-xxl-3 btn btn-orange d-flex justify-content-center"
                    style={{ marginTop: '2.5rem' }}
                    onClick={() => handleEdit()}
                  >
                    <Translator path="profilePage.submit" />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default ProfileEdit;
