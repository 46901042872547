import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { Translator } from '@components';

const NoBalanceModal = ({ props: { show, setShow, showDeposit } }) => {
  const navigate = useNavigate();
  const onHideModal = () => {
    setShow(false);
  };

  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
      centered
      style={{ borderRadius: '1.25rem' }}
    >
      <Modal.Body style={{ padding: 20 }} className="row d-flex flex-column justify-content-center align-items-center">
        <div className="row">
          <div
            className="col-12 d-flex justify-content-between align-items-center flex-row "
            style={{ marginBottom: '1.25rem' }}
          >
            <h4 className="col-4 modal-title-deposit">
              <Translator path="investment.noBalanceModal.title" />
            </h4>
            <i className="material-icons moda-close-icon cursor-pointer" onClick={onHideModal}>
              close
            </i>
          </div>
          <div className="col-12" style={{ borderBottom: '1px solid #EDEDED' }} />
          <div
            className="col-12 d-flex justify-content-center align-items-center"
            style={{ gap: '1.25rem', margin: '2.5rem 0' }}
          >
            <p className="fail-modal-text">
              <Translator path="investment.noBalanceModal.body" />
            </p>
          </div>
          <div className="col-12" style={{ borderBottom: '1px solid #EDEDED' }} />
          <div className="col-12 d-flex justify-content-end" style={{ gap: '1.25rem', marginTop: '1.25rem' }}>
            <button className="btn btn-outlined col-3" onClick={() => setShow(false)}>
              <Translator path="investment.noBalanceModal.cancel" />
            </button>
            <button className="btn btn-orange col-3" onClick={showDeposit}>
              <Translator path="investment.noBalanceModal.Submit" />
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default NoBalanceModal;
