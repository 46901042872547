import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import TokenCard from '../TokenCard';

const TokenList = ({ props }) => {
  const [data, setData] = useState();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    setData(props);
  }, [props]);

  const handleRedirect = (item) => {
    if (pathname === '/publictokens' || pathname === '/publictokenslite') {
      navigate('/login', { state: { tokenId: item.tokenId } });
    } else if (pathname.includes('/wallet')) {
      navigate('/investment/' + item.tokenId + '/details');
    } else {
      if (item.status === 'INCAPTURE') navigate('/investment/' + item.tokenId);
    }
  };

  return (
<div className="row">
  {data &&
    data.map((item, index) => {
      return (
        <div
          key={index}
          className={`col-sm-12 col-md-6 col-xxl-4 ${item.status === 'INCAPTURE' ? 'c-pointer' : ''} `}
          style={{ marginBottom: '2.5rem' }}
          onClick={() => {
            handleRedirect(item);
          }}
        >
          <TokenCard props={item} />
        </div>
      );
    })}
</div>
  );
};

export default TokenList;
