import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { I18n } from '@components';
import { logoEmissor, logoIssuer, bgIssuer } from '@assets';
import { useRegister } from '@contexts-issuer';
import { useZoom } from '@utils';

import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import FinalStep from './FinalStep';

const RecoverPassword = () => {
  const { i18n, t } = useTranslation();
  const [step, setStep] = useState(0);
  const [email, setEmail] = useState('');
  const [code, setCode] = useState();
  const { sendTokenEmail, resetId, validateToken } = useRegister();
  const zoom = useZoom();

  const handleEmailRequest = async () => {
    if (email) {
      const response = await sendTokenEmail(email);
      if (response) setStep(1);
    }
  };

  const handleTokenValidation = async () => {
    if (code && resetId) {
      const params = {
        issuerLoginId: resetId,
        token: code,
      };
      const response = await validateToken(params);
      if (response) setStep(2);
    }
  };

  return (
    <>
      <div className="page-wraper">
        <div className="browse-job login-style3">
          <div
            className="bg-img-fix overflow-hidden"
            style={{
              background: '#fff url(' + bgIssuer + ')',
              height: '100vh',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'right',
              backgroundSize: 'contain',
            }}
          >
            <div className="row gx-0">
              <div className="col-xxl-4 col-xl-5 col-lg-6 col-md-8 col-sm-12 vh-100 bg-white d-flex justify-content-center align-items-center">
                <div className="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside col-12">
                  <div
                    id="mCSB_1_container"
                    className="mCSB_container"
                    style={{ position: 'relative', top: '0', left: '0', dir: 'ltr' }}
                  >
                    <div className={`${zoom > 1 ? 'scroll-bar-wrapper' : ''}`}>
                      <div className="login-form style-2">
                        <div
                          className="card-body reset-form"
                          style={{ margin: `${step !== 2 ? '13rem 6.25rem' : '6rem 6.5rem'}` }}
                        >
                          <div className="row gx-0">
                            <Link to="/login" className="logo ">
                              <img
                                src={i18n.language === 'pt-BR' ? logoEmissor : logoIssuer}
                                alt=""
                                className="width-230 mCS_img_loaded hide-logo "
                              />
                            </Link>
                            {step === 0 ? (
                              <FirstStep props={{ setEmail, email, handleRequest: handleEmailRequest }} />
                            ) : step === 1 ? (
                              <SecondStep
                                props={{ setCode, step, email, handleTokenValidation, sendTokenEmail, setStep }}
                              />
                            ) : (
                              <FinalStep props={{ code }} />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <I18n />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecoverPassword;
