import { AnalysisCard, Translator } from '@components';
import { useIssuer } from '@contexts-issuer';
import { useState } from 'react';

import FinishForm from './FinishForm';
import RegisterForm from './RegisterForm';

const MyCorporation = () => {
  const { issuerProfile, registerIssuer } = useIssuer();
  const [layoutControl, setLayoutControl] = useState(0);

  const handleRegister = async (body) => {
    const issuerId = localStorage.getItem('issuerId');
    const redirect = await registerIssuer(issuerId, body);
    if (redirect === true) setLayoutControl(1);
  };

  if (issuerProfile)
    return (
      <div className="row issuer-page-wrapper">
        <div className="row col-12 issuer-container-formating">
          {issuerProfile.status === 'INCOMPLETE' || issuerProfile.status === 'REJECTED' ? (
            <>
              {layoutControl === 0 ? (
                <>
                  <div
                    className="row col-12 d-flex justify-content-between align-items-center"
                    style={{ borderBottom: '1px solid #EDEDED', padding: 0, margin: 0 }}
                  >
                    <p className="col-6 issuer-title" style={{ whiteSpace: 'nowrap', padding: 0 }}>
                      <Translator path="pages.issuer.title" />
                    </p>
                  </div>
                  <RegisterForm props={{ handleRegister }} />
                </>
              ) : (
                <FinishForm props={{ email: issuerProfile?.email, setLayout: setLayoutControl }} />
              )}
            </>
          ) : (
            <>
              {layoutControl === 0 ? (
                <>
                  <div
                    className="row col-12 d-flex  header-flex"
                    style={{ borderBottom: '1px solid #EDEDED', padding: 0, margin: 0 }}
                  >
                    <p
                      className="col-12 col-sm-12 col-md-4 issuer-title margin-control"
                      style={{ whiteSpace: 'nowrap', padding: 0 }}
                    >
                      <Translator path="pages.issuer.title" />
                    </p>
                    <div
                      className="col-9 col-sm-6 col-md-6 col-lg-5 d-flex justify-content-center align-items-center padding-control"
                      style={{ padding: '0 2.5rem 0 0.625rem' }}
                    >
                      <AnalysisCard props={{ status: issuerProfile.status }} />
                    </div>
                  </div>
                  <RegisterForm props={{ handleRegister, isEdit: true }} />
                </>
              ) : (
                <FinishForm props={{ email: issuerProfile?.email, setLayout: setLayoutControl }} />
              )}
            </>
          )}
        </div>
      </div>
    );
};

export default MyCorporation;
