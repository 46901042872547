import 'react-datepicker/dist/react-datepicker.css';

import { InputText, Translator, Loading, PopOver } from '@components';
import { useTokenProposal, useIssuer } from '@contexts-issuer';
import { moneyMask } from '@utils';
import { Buffer } from 'buffer';
import { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { ProposalOverviewModal } from '@pages-issuer';
import { SuccessModal } from '@pages-issuer';
import { ProposalPreview } from '@pages-issuer';
import { useNavigate, useParams } from 'react-router-dom';
import { noPreview } from '@assets';
import swal from 'sweetalert';

const ProposalFormEdit = () => {
  const [choosenImage, setChoosenImage] = useState('');
  const [preview, setPreview] = useState('');

  const [tokenName, setTokenName] = useState('');
  const [invoiceValue, setInvoiceValue] = useState(0);
  const [valueToReceive, setValueToReceive] = useState(0);
  const [fundingLimitDate, setFundingLimitDate] = useState(undefined);
  const [liquidationDate, setLiquidationDate] = useState(undefined);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [description, setDescription] = useState('');
  const [additionalDocs, setAdditionalDocs] = useState([]);
  const [showOverviewModal, setShowOverviewModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [proposalSubmitted, setProposalSubmitted] = useState(false);
  const [body, setBody] = useState({});
  const [temporaryBody, setTemporaryBody] = useState({});

  const [proposalStatus, setProposalStatus] = useState('');
  const [tokenImage, setTokenImage] = useState('');
  const [inputType, setInputType] = useState('text');

  const [isCounterProposal, setIsCounterProposal] = useState(false);
  const [changedFields, setChangedFields] = useState([]);

  const navigate = useNavigate();

  const {
    uploadTokenImage,
    downloadTokenImage,
    imageLink,
    loading,
    loadingImage,
    uploadAdditionalDoc,
    getTokenProposal,
    tokenProposal,
    downloadAdditionalDoc,
    wasProposalUpdated,
    annulProposal,
  } = useTokenProposal();

  const { getIssuerProfile, issuerProfile } = useIssuer();

  let errorsObj = {
    name: '',
    image: '',
    invoiceValue: '',
    valueToReceive: '',
    fundingLimitDate: '',
    liquidationDate: '',
    discountPercentage: '',
    description: '',
    link: '',
    docDescription: '',
    fiscalDoc: '',
    fiscalDocDesc: '',
  };
  const [errors, setErrors] = useState(errorsObj);

  const { t } = useTranslation();
  const { proposalId } = useParams();

  var path = window.location.pathname.split('/');
  var name = path[2];

  useEffect(() => {
    getTokenProposal(proposalId);

    const issuerId = localStorage.getItem('issuerId');
    getIssuerProfile(issuerId);

    if (name === 'counterProposal') {
      setIsCounterProposal(true);
    }
  }, []);

  useEffect(() => {
    if (wasProposalUpdated) navigate('/tokenProposal/list');
  }, [wasProposalUpdated]);

  useEffect(() => {
    if (Object.keys(tokenProposal).length === 0) {
      return;
    }
    resetFormWithData();
  }, [tokenProposal]);

  useEffect(() => {
    if (proposalSubmitted && proposalStatus === '' && showSuccessModal) {
      setShowSuccessModal(true);
    }
  }, [proposalSubmitted]);

  useEffect(() => {
    setShowSuccessModal(false);
  }, []);

  useEffect(() => {
    if (!choosenImage) {
      setPreview('');
      return;
    }

    const upload = async () => {
      await uploadTokenImage(choosenImage);
    };

    upload();
  }, [choosenImage]);

  useEffect(() => {
    const download = async () => {
      const imageBuffer = await downloadTokenImage(tokenImage);
      const newSrc = Buffer.from(imageBuffer).toString('base64');
      setPreview(newSrc);
    };

    if (Object.keys(tokenProposal).length !== 0 && tokenImage != '') {
      download();
    }
    return;
  }, [tokenImage]);

  useEffect(() => {
    setTokenImage(imageLink);
  }, [imageLink]);

  useEffect(() => {
    const errorObj = { ...errorsObj };
    errorsObj = {
      name: '',
      image: '',
      invoiceValue: '',
      valueToReceive: '',
      fundingLimitDate: '',
      liquidationDate: '',
      discountPercentage: '',
      description: '',
      invoiceTooLow: '',
      link: '',
      docDescription: '',
      fiscalDoc: '',
      fiscalDocDesc: '',
    };
    setErrors(errorObj);

    const tempBody = {
      ...tokenProposal,
      issuerId: localStorage.getItem('issuerId'),
      name: tokenProposal.name,
      image: `data:image/jpeg;base64,${preview}`,
      invoiceValue: tokenProposal.invoiceValue,
      valueToReceive: tokenProposal.valueToReceive,
      fundingLimitDate:
        tokenProposal.fundingLimitDate && new Date(tokenProposal.fundingLimitDate).toISOString().split(/[A-Z]/)[0],
      liquidationDate:
        tokenProposal.liquidationDate && new Date(tokenProposal.liquidationDate).toISOString().split(/[A-Z]/)[0],
      discountPercentage,

      description: tokenProposal.description,
      additionalDocs,
    };

    setTemporaryBody(tempBody);
  }, [
    tokenName,
    invoiceValue,
    valueToReceive,
    fundingLimitDate,
    liquidationDate,
    discountPercentage,
    description,
    additionalDocs,
    imageLink,
    preview,
  ]);

  useEffect(() => {
    if (invoiceValue && discountPercentage) {
      setValueToReceiveWithMask(`${+removeMasks(invoiceValue) - +discountPercentage * +removeMasks(invoiceValue)}`);
    }
  }, [invoiceValue, discountPercentage]);

  const submitForm = (e) => {
    e.preventDefault();

    let error = false;
    const errorObj = { ...errorsObj };

    if (tokenName === '') {
      errorObj.name = t('pages.tokenProposal.errors.noName');
      error = true;
    }

    if (preview == '') {
      errorObj.image = t('pages.tokenProposal.errors.noImage');
      error = true;
    }

    if (invoiceValue === 0) {
      errorObj.invoiceValue = t('pages.tokenProposal.errors.noInvoice');
      error = true;
    }

    if (valueToReceive === 0) {
      errorObj.valueToReceive = t('pages.tokenProposal.errors.noValueToReceive');
      error = true;
    }

    if (fundingLimitDate === undefined) {
      errorObj.fundingLimitDate = t('pages.tokenProposal.errors.noFundingLimitDate');
      error = true;
    }

    if (liquidationDate === undefined) {
      errorObj.liquidationDate = t('pages.tokenProposal.errors.noLiquidationDate');
      error = true;
    }

    if (discountPercentage === 0) {
      errorObj.discountPercentage = t('pages.tokenProposal.errors.noDiscountPercentage');
      error = true;
    }

    if (description === '') {
      errorObj.description = t('pages.tokenProposal.errors.noDescription');
      error = true;
    }

    if (additionalDocs[0].link === '') {
      errorObj.fiscalDoc = t('pages.tokenProposal.errors.noFiscalDoc');
      error = true;
    }

    if (additionalDocs[0].description === '') {
      errorObj.fiscalDocDesc = t('pages.tokenProposal.errors.noFiscalDesc');
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }

    const newDocs = additionalDocs.filter((doc) => !doc.id);

    const reqBody = {
      issuerId: localStorage.getItem('issuerId'),
      tokenProposalId: tokenProposal.tokenProposalId,
      name: tokenName,
      image: tokenImage,
      invoiceValue: +removeMasks(invoiceValue),
      discountPercentage: +discountPercentage,
      valueToReceive: +removeMasks(valueToReceive),
      fundingLimitDate: new Date(fundingLimitDate).toISOString().split(/[A-Z]/)[0],
      liquidationDate: new Date(liquidationDate).toISOString().split(/[A-Z]/)[0],
      description: description,
      tokenProposalAdditionalDocs: newDocs,
    };

    setBody(reqBody);

    toggleShowOverviewModal(e);
  };

  const resetFormWithData = () => {
    setTokenName(tokenProposal.name);
    setInvoiceValue(tokenProposal.invoiceValue?.toLocaleString('pt-BT', { style: 'currency', currency: 'BRL' }));
    setDescription(tokenProposal.description);
    setProposalStatus(tokenProposal.status);
    setTokenImage(tokenProposal.image);
    setDiscountPercentage(tokenProposal.discountPercentage);
    setValueToReceive(tokenProposal.valueToReceive?.toLocaleString('pt-BT', { style: 'currency', currency: 'BRL' }));

    const fundingDate = `${tokenProposal.fundingLimitDate} 00:00:00`;
    const liquidateDate = `${tokenProposal.liquidationDate} 00:00:00`;
    setFundingLimitDate(new Date(fundingDate));
    setLiquidationDate(new Date(liquidateDate));

    const previousDocs = tokenProposal?.tokenProposalAdditionalDocs.map((doc) => {
      return { link: doc.link, description: doc.description, id: doc.tokenProposalAdditionalDocId };
    });

    setAdditionalDocs(previousDocs);

    if (tokenProposal.updatedFields) {
      const updatedFields = tokenProposal.updatedFields.split(',');
      setChangedFields(updatedFields);
    }
  };

  const setInvoiceValueWithMask = (value) => {
    setInvoiceValue(moneyMask(value));
  };

  const setValueToReceiveWithMask = (value) => {
    if (+removeMasks(invoiceValue) >= 1)
      setValueToReceive(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value));
    else setValueToReceive('R$ 0,00');
  };

  const addAdditionalDocument = (e) => {
    if (additionalDocs.length !== 0 && additionalDocs[additionalDocs.length - 1].link === '') {
      const errorObj = { ...errorsObj };
      errorObj.link = t('pages.tokenProposal.errors.noAdditionalDoc').toString();
      setErrors(errorObj);
      return;
    } else if (additionalDocs.length !== 0 && additionalDocs[additionalDocs.length - 1].description === '') {
      const errorObj = { ...errorsObj };
      errorObj.docDescription = t('pages.tokenProposal.errors.noAdditionalDocDesc').toString();
      setErrors(errorObj);
      return;
    }

    setAdditionalDocs([...additionalDocs, { link: '', description: '', id: '' }]);
  };

  const handleAddDocLink = async (index, e) => {
    const docLink = await uploadAdditionalDoc(e.target.files[0]);

    if (docLink !== '') {
      additionalDocs[index].link = docLink;
      setAdditionalDocs([...additionalDocs]);
    } else {
      return;
    }
  };

  const handleAddDocDescription = (index, e) => {
    additionalDocs[index].description = e.target.value;
    setAdditionalDocs([...additionalDocs]);
  };

  const handleRemoveDoc = (index) => {
    if (index !== 0) setAdditionalDocs([...additionalDocs.filter((_, i) => index !== i)]);
  };

  const removeMasks = (value) => {
    if (value) {
      const newValue = value
        ?.replace('R', '')
        ?.replace('$', '')
        ?.replaceAll('.', '')
        ?.replaceAll(',', '.')
        ?.replaceAll(' ', '');

      return newValue;
    }
  };

  const toggleShowOverviewModal = (e) => {
    e.preventDefault();
    setShowOverviewModal(!showOverviewModal);
  };

  const handleAdditionalDocsDownload = async (index) => {
    if (Object.keys(tokenProposal).length !== 0) {
      const additionalDoc = await downloadAdditionalDoc(additionalDocs[index].link);
      const buffered = Buffer.from(additionalDoc.data, 'binary');
      const blob = new Blob([buffered], { type: 'application/pdf' });
      const file = new File([blob], `additional document ${index}.pdf`);
      const url = URL.createObjectURL(file);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', `additional document ${index}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }
  };

  const confirmAnnulment = (e) => {
    const title = t('components.proposalFormEdit.confirm.title');
    const text = t('components.proposalFormEdit.confirm.text');
    const yes = t('components.proposalFormEdit.confirm.yes');
    const no = t('components.proposalFormEdit.confirm.no');
    const successMessage = t('components.proposalFormEdit.confirm.success');
    swal({
      title,
      text,
      icon: 'warning',
      buttons: [no, yes],
      dangerMode: true,
    }).then((willAnnul) => {
      if (willAnnul) {
        annulProposal(tokenProposal.tokenProposalId).then(() => {
          swal(successMessage, { icon: 'success' });
        });
      } else {
      }
    });
  };

  if (loading) return <Loading />;

  if (tokenProposal) {
    return (
      <div className="page-wrapper">
        <div className="card card-proposal">
          <div className="card-title card-title-edit me-4">
            <Translator path={'pages.tokenProposal.title'} />

            {proposalStatus === 'IN_ANALYSIS' ? (
              <div className="status-card analysis">
                <i className="material-icons icon-border">priority_high</i>
                <span className="m-0">
                  <Translator path={'components.proposalFormEdit.analysis'} />
                </span>
              </div>
            ) : proposalStatus === 'NEGOTIATING' ? (
              <div className="status-card negotiating">
                <i className="material-icons icon-border">handshake</i>
                <span className="m-0">
                  <Translator path={'components.counterProposal.negotiating'} />
                </span>
              </div>
            ) : (
              ''
            )}
          </div>
          <div className="card-stroke"></div>
          <div className="card-content">
            <div className="form col-12">
              <form onSubmit={submitForm}>
                <div className="token-media image position-relative">
                  {loadingImage ? (
                    <>
                      <div
                        className="loading-spinner"
                        style={{
                          width: '5.625rem',
                          height: '5.625rem',
                          position: 'absolute',
                        }}
                      />
                      <img
                        src={noPreview}
                        alt=""
                        style={{ width: '5.625rem', height: '5.625rem', margin: '0 !important' }}
                      />
                    </>
                  ) : (
                    <>
                      <img
                        src={preview ? `data:image/jpeg;base64,${preview}` : noPreview}
                        alt=""
                        style={{ width: '5.625rem', height: '5.625rem' }}
                      />

                      <div
                        className="upload-button"
                        title=""
                        data-toggle="tooltip"
                        data-placement="right"
                        data-original-title="update"
                        style={{ backgroundColor: '#FF9900' }}
                      >
                        <input
                          type="file"
                          className="update-file"
                          style={{ backgroundColor: '#FF9900' }}
                          onChange={(e) => setChoosenImage(e.target.files[0])}
                          disabled={isCounterProposal ? true : false}
                        />
                        <i className="fa fa-camera"></i>
                      </div>
                    </>
                  )}
                  <span
                    className="mx-4"
                    style={{
                      fontWeight: '500',
                      fontSize: '1rem',
                      color: '#525252',
                      margin: '0 0 5px 0',
                      padding: 0,
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <Translator path={'pages.tokenProposal.image'} />
                    <span className="obrigatory">*</span>
                  </span>
                  {errors.image && <div className="text-danger fs-12">{errors.image}</div>}
                </div>
                <div className="row col-12">
                  <div className="col-lg-6 col-md-12">
                    <InputText
                      className={`${isCounterProposal ? 'token-input-disabled' : ''}`}
                      text={'pages.tokenProposal.name'}
                      setState={setTokenName}
                      value={tokenName}
                      readOnly={isCounterProposal ? true : false}
                      maxLength={'16'}
                    />
                    {errors.name && <div className="text-danger fs-12">{errors.name}</div>}
                    {isCounterProposal && changedFields.includes('name') ? (
                      <div className="altered-text">
                        <Translator path={'components.counterProposal.altered'} />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <InputText
                      text={'pages.tokenProposal.invoiceValue'}
                      setState={setInvoiceValueWithMask}
                      value={invoiceValue && !invoiceValue.includes('NaN') ? invoiceValue : 0}
                      className={`${isCounterProposal ? 'token-input-disabled' : ''}`}
                      readOnly={isCounterProposal ? true : false}
                    />
                    {errors.invoiceValue && <div className="text-danger fs-12">{errors.invoiceValue}</div>}
                    {isCounterProposal && changedFields.includes('invoiceValue') ? (
                      <div className="altered-text">
                        <Translator path={'components.counterProposal.altered'} />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div className="row col-12">
                  <div className="col-lg-6 col-md-12">
                    <div className="d-flex flex-row text-input-container">
                      <p className="text-input-label">
                        <Translator path={'pages.tokenProposal.fundingDateLimit'} />
                      </p>
                      <p>*</p>
                    </div>
                    <DatePicker
                      selected={fundingLimitDate}
                      onChange={(date) => setFundingLimitDate(date)}
                      dateFormat="dd/MM/yyyy"
                      className={`date-picker col-12 d-flex align-items-start form-control ${
                        isCounterProposal ? 'token-input-disabled' : ''
                      }`}
                      readOnly={isCounterProposal ? true : false}
                      startDate={new Date()}
                      minDate={new Date()}
                    />
                    {errors.fundingLimitDate && <div className="text-danger fs-12">{errors.fundingLimitDate}</div>}
                    {isCounterProposal && changedFields.includes('fundingLimitDate') ? (
                      <div className="altered-text">
                        <Translator path={'components.counterProposal.altered'} />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="d-flex flex-row text-input-container">
                      <p className="text-input-label">
                        <Translator path={'pages.tokenProposal.liquidationDate'} />
                      </p>
                      <p>*</p>
                    </div>
                    <DatePicker
                      selected={liquidationDate}
                      onChange={(date) => setLiquidationDate(date)}
                      dateFormat="dd/MM/yyyy"
                      className={`date-picker col-12 d-flex align-items-start form-control ${
                        isCounterProposal ? 'token-input-disabled' : ''
                      }`}
                      readOnly={isCounterProposal ? true : false}
                      startDate={fundingLimitDate}
                      minDate={fundingLimitDate}
                    />

                    {errors.liquidationDate && <div className="text-danger fs-12">{errors.liquidationDate}</div>}
                    {isCounterProposal && changedFields.includes('liquidationDate') ? (
                      <div className="altered-text">
                        <Translator path={'components.counterProposal.altered'} />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div className="row col-12">
                  <div className="col-lg-6 col-md-12">
                    <div className="col-12 d-flex flex-column justify-content-start">
                      <div className="d-flex flex-row text-input-container">
                        <p className="text-input-label">
                          <Translator path={'pages.tokenProposal.discountPercentage'} />
                        </p>
                        <p>*</p>
                      </div>
                      <div className="position-relative">
                        <Form.Select
                          className={`form-control ${isCounterProposal ? 'token-input-disabled' : ''}`}
                          onChange={(e) => setDiscountPercentage(e.target.value)}
                          value={discountPercentage}
                          disabled={isCounterProposal}
                        >
                          <option value=""></option>
                          <option value="0.03">3%</option>
                          <option value="0.035">3.5%</option>
                          <option value="0.04">4%</option>
                        </Form.Select>
                        <i className="material-icons position-absolute select-discount">expand_more</i>
                      </div>
                      {errors.discountPercentage && (
                        <div className="text-danger fs-12">{errors.discountPercentage}</div>
                      )}
                      {isCounterProposal && changedFields.includes('discountPercentage') ? (
                        <div className="altered-text">
                          <Translator path={'components.counterProposal.altered'} />
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <InputText
                      className="token-input-disabled"
                      text={'pages.tokenProposal.valueToReceive'}
                      readOnly={true}
                      value={valueToReceive && !valueToReceive.includes('NaN') ? valueToReceive : 0}
                    />
                  </div>
                </div>
                <div className="row col-12"></div>
                <div className="row col-12 p-0">
                  <div className="row col-12 p-0">
                    <div className="col-12 d-flex flex-column justify-content-start p-0 padding-left">
                      <div className="d-flex flex-row text-input-container">
                        <p className="text-input-label">
                          <Translator path={'pages.tokenProposal.description'} />
                        </p>
                        <p>*</p>
                      </div>
                      <textarea
                        type="textarea"
                        className={`form-control col-12 d-flex align-items-start ${
                          isCounterProposal ? 'token-input-disabled' : ''
                        }`}
                        onChange={(e) => setDescription(e.target.value)}
                        style={{ height: '9.5vh' }}
                        value={description}
                        readOnly={isCounterProposal ? true : false}
                      />
                      {errors.description && <div className="text-danger fs-12">{errors.description}</div>}
                      {isCounterProposal && changedFields.includes('description') ? (
                        <div className="altered-text">
                          <Translator path={'components.counterProposal.altered'} />
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12 additional-docs">
                  {additionalDocs.map((doc, index) => (
                    <div key={index} className="row col-lg-12 justify-content-center">
                      <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                        <div className="position-relative d-flex flex-column justify-content-start">
                          <div className="d-flex flex-row text-input-container">
                            <p className="text-input-label" style={{ wordWrap: 'normal' }}>
                              <Translator path="pages.tokenProposal.additionalDoc" />
                              {<span> ({index + 1})</span>}
                              {index === 0 ? <span className="obrigatory">*</span> : ''}
                            </p>
                            <p></p>
                          </div>
                          <input
                            name="link"
                            type={inputType}
                            placeholder={`${doc.link ? doc.link.split('/')[1] : ''}`}
                            className={`col-10 d-flex align-items-start form-control hide-label ${
                              isCounterProposal ? 'token-input-disabled' : ''
                            }`}
                            onClick={isCounterProposal ? () => {} : () => setInputType('file')}
                            style={{ overflow: 'hidden', paddingRight: '25px' }}
                            onChange={(e) => handleAddDocLink(index, e)}
                            readOnly={isCounterProposal ? true : false}
                          />
                          <i
                            className="material-icons icon-float-right hide-icon-issuer"
                            onClick={() => handleAdditionalDocsDownload(index)}
                          >
                            attach_file
                          </i>
                          {errors.fiscalDoc !== '' ? <div className="text-danger fs-12">{errors.fiscalDoc}</div> : ''}
                        </div>
                      </div>
                      <div className="col-xl-7 col-lg-11 col-md-10 col-sm-10 col-xs-8">
                        <div className="d-flex flex-column justify-content-start" style={{ padding: 0 }}>
                          <div className="d-flex flex-row text-input-container">
                            <p className="text-input-label">
                              <Translator path="pages.tokenProposal.additionalDocDescription" />
                              {<span> ({index + 1})</span>}
                              {index === 0 ? <span className="obrigatory">*</span> : ''}
                            </p>
                            <p></p>
                          </div>
                          <input
                            key={index}
                            name="description"
                            value={additionalDocs[index].description}
                            className={`col-10 d-flex align-items-start form-control ${
                              isCounterProposal ? 'token-input-disabled' : ''
                            }`}
                            onChange={(e) => handleAddDocDescription(index, e)}
                            readOnly={isCounterProposal ? true : false}
                          />
                          {errors.fiscalDocDesc !== '' ? (
                            <div className="text-danger fs-12">{errors.fiscalDoc}</div>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                      <div className="col-xl-1 col-lg-1 col-md-2 col-sm-2 col-xs-2 d-flex">
                        <div className="d-flex align-items-end">
                          <div
                            className={`d-flex justify-content-center align-items-center redPopOver-div ${
                              isCounterProposal ? 'disable' : 'enable'
                            }`}
                            style={{
                              borderRadius: '8px',
                              border: '1px solid #E61D2B',
                              padding: '8px 10px 10px 7px',
                              cursor: 'pointer',
                            }}
                            onClick={() => handleRemoveDoc(index)}
                          >
                            <PopOver
                              children={
                                <i
                                  className={`material-icons delete delete-document-${
                                    isCounterProposal ? 'disable' : 'enable'
                                  }`}
                                >
                                  delete
                                </i>
                              }
                              placement="top"
                              id={`redPopOver ${isCounterProposal ? 'disable' : 'enable'}`}
                              text={
                                index < tokenProposal.tokenProposalAdditionalDocs.length
                                  ? t('components.proposalFormEdit.noRemove').toString()
                                  : t('components.proposalFormEdit.remove').toString()
                              }
                            ></PopOver>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="add-doc">
                    {isCounterProposal ? (
                      ''
                    ) : (
                      <p onClick={(e) => addAdditionalDocument(e)}>
                        <i className="material-icons">add</i>
                        <Translator path={'pages.tokenProposal.addDoc'} />
                      </p>
                    )}
                  </div>
                  <div className="row col-12" style={{ padding: 0, margin: '0 0 0.5rem 0' }}>
                    <div className="col-sm-6 paddingLeft-control-issuer">
                      {errors.link && <div className="text-danger fs-12">{errors.link}</div>}
                    </div>
                    <div className="col-sm-6 paddingRight-control-issuer">
                      {errors.docDescription && <div className="text-danger fs-12">{errors.docDescription}</div>}
                    </div>
                  </div>
                </div>

                <div className="actions actions-proposal-edit col-lg-12">
                  <Button
                    variant="primary"
                    size="lg"
                    className="text-center col-xl-6 col-md-2 text-nowrap w-auto"
                    id="btn"
                    type="submit"
                  >
                    {isCounterProposal ? (
                      <Translator path={'components.counterProposal.accept'} />
                    ) : proposalStatus === 'NEGOTIATING' ? (
                      <Translator path={'components.counterProposal.send'} />
                    ) : (
                      <Translator path={'components.proposalFormEdit.save'} />
                    )}
                  </Button>
                  <Button
                    className="text-nowrap w-auto"
                    variant="outline-primary"
                    size="lg"
                    id="btn-outlined"
                    onClick={
                      isCounterProposal
                        ? () => {
                            setIsCounterProposal(false);
                            navigate(`/tokenProposal/edit/${tokenProposal.tokenProposalId}`);
                          }
                        : tokenProposal.status === 'NEGOTIATING'
                        ? () => {
                            setIsCounterProposal(true);
                            resetFormWithData();
                            navigate(`/tokenProposal/counterProposal/${tokenProposal.tokenProposalId}`);
                          }
                        : () => navigate('/tokenProposal/list')
                    }
                  >
                    {isCounterProposal ? (
                      <Translator path={'components.counterProposal.edit'} />
                    ) : (
                      <Translator path={'components.proposalFormEdit.cancel'} />
                    )}
                  </Button>
                  {isCounterProposal ? (
                    <div className="preview col-xl-6 col-md-4" onClick={(e) => confirmAnnulment(e)}>
                      <Translator path={'components.counterProposal.annul'} />
                    </div>
                  ) : (
                    <div
                      className="preview col-xl-6 col-md-4"
                      onClick={() => {
                        setShowPreviewModal(true);
                      }}
                    >
                      <i className="material-icons">visibility</i>
                      <Translator path="pages.tokenProposal.preview" />
                    </div>
                  )}
                </div>
              </form>
              <ProposalOverviewModal
                show={showOverviewModal}
                body={body}
                issuer={issuerProfile}
                imageLink={preview}
                setShow={setShowOverviewModal}
                setSubmitted={setProposalSubmitted}
                isEdit={!isCounterProposal}
                isCounterProposal={isCounterProposal}
              />

              <SuccessModal show={showSuccessModal} />
              <ProposalPreview
                show={showPreviewModal}
                token={temporaryBody}
                issuer={issuerProfile}
                setState={setShowPreviewModal}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default ProposalFormEdit;
