import { createContext, useCallback, useContext, useState } from 'react';
import * as tokenProposalService from '../../services/Issuer/tokenProposalService';
import { useTranslation } from 'react-i18next';
import swal from 'sweetalert';

export const TokenProposalContext = createContext({});

const TokenProposalProvider = ({ children }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [imageLink, setImageLink] = useState('');
  const [imageBuffer, setImageBuffer] = useState('');
  const [createdTokenProposal, setCreatedTokenProposal] = useState({});
  const [allProposals, setAllProposals] = useState([]);
  const [docLink, setDocLink] = useState('');
  const [tokenProposal, setTokenProposal] = useState({});
  const [additionalFileBuffer, setAdditionalFileBuffer] = useState();
  const [wasProposalUpdated, setWasProposalUpdated] = useState(false);
  const [loadingDocs, setLoadingDocs] = useState('');

  const createTokenProposal = useCallback(async (body) => {
    setLoading(true);

    try {
      const { data } = await tokenProposalService.createTokenProposal(body);

      setCreatedTokenProposal(data);
    } catch (error) {
      const errTitle = t('pages.tokenProposal.apiErr.title');
      const errBody = t('pages.tokenProposal.apiErr.body');

      swal(errTitle, errBody, 'error');
    } finally {
      setLoading(false);
    }
  }, []);

  const getAllProposals = useCallback(async (params = { issuerId: '' }) => {
    setLoading(true);

    try {
      const { data } = await tokenProposalService.getAllProposals(params);

      setAllProposals(data.proposals);
      return data.proposals;
    } catch (error) {
      const errTitle = t('pages.tokenProposal.apiErr.title');
      const errBody = t('pages.tokenProposal.apiErr.body');
      swal(errTitle, errBody, 'error');
    } finally {
      setLoading(false);
    }
  }, []);

  const getTokenProposal = useCallback(async (proposalId) => {
    setLoading(true);

    try {
      const { data } = await tokenProposalService.getTokenProposal(proposalId);

      setTokenProposal(data);
    } catch (error) {
      const errTitle = t('pages.tokenProposal.apiErr.title');
      const errBody = t('pages.tokenProposal.apiErr.body');
      swal(errTitle, errBody, 'error');
    } finally {
      setLoading(false);
    }
  }, []);

  const uploadTokenImage = useCallback(
    async (file) => {
      setLoadingImage(true);

      try {
        const { data } = await tokenProposalService.uploadTokenImage(file);

        setImageLink(data);

        // const successHeader = t('pages.tokenProposal.success.title');
        // const successMessage = t('pages.tokenProposal.success.uploadBody');
        // swal(successHeader, successMessage, 'success');
      } catch (error) {
        const errTitle = t('pages.tokenProposal.apiErr.upload');
        const errBody = t('pages.tokenProposal.apiErr.body');
        swal(errTitle, errBody, 'error');
      } finally {
        setLoadingImage(false);
      }
    },
    [setImageLink],
  );

  const downloadTokenImage = useCallback(async (id = '') => {
    setLoadingImage(true);

    try {
      const newId = id.replaceAll('/', '%2F');

      const { data } = await tokenProposalService.downloadTokenImage(newId);

      setImageBuffer(data);
      return data;
    } catch (error) {
      const errTitle = t('pages.tokenProposal.apiErr.download');
      const errBody = t('pages.tokenProposal.apiErr.body');
      swal(errTitle, errBody, 'error');
    } finally {
      setLoadingImage(false);
    }
  }, []);

  const uploadAdditionalDoc = useCallback(async (file) => {
    try {
      setLoadingDocs('additionalDocs');
      setDocLink('');
      const { data } = await tokenProposalService.uploadAdditionalDoc(file);

      return data;
    } catch (error) {
      const errTitle = t('pages.tokenProposal.apiErr.docUpload');
      const errBody = t('pages.tokenProposal.apiErr.body');
      swal(errTitle, errBody, 'error');
    } finally {
      setLoadingDocs('');
    }
  }, []);

  const downloadAdditionalDoc = useCallback(async (link) => {
    try {
      const { data } = await tokenProposalService.downloadAdditionalDoc(link);

      setAdditionalFileBuffer(data);
      return data;
    } catch (error) {
      const errTitle = t('pages.tokenProposal.apiErr.docUpload');
      const errBody = t('pages.tokenProposal.apiErr.body');
      swal(errTitle, errBody, 'error');
    }
  }, []);

  const updateTokenProposal = useCallback(async (proposalId, body) => {
    setLoading(true);
    try {
      const { data } = await tokenProposalService.updateTokenProposal(proposalId, body);

      if (data.status === 200) {
        const successHeader = t('components.proposalFormEdit.success');
        const successMessage = t('components.proposalFormEdit.successBody');
        swal(successHeader, successMessage, 'success');
        setWasProposalUpdated(true);
      }
    } catch (error) {
      const errTitle = t('pages.tokenProposal.apiErr.upload');
      const errBody = t('pages.tokenProposal.apiErr.body');
      swal(errTitle, errBody, 'error');
    } finally {
      setLoading(false);
    }
  }, []);

  const preApproveProposal = useCallback(async (proposalId) => {
    setLoading(true);

    try {
      const { data } = await tokenProposalService.preApproveProposal(proposalId);

      if (data.status === 200) {
        const successHeader = t('components.counterProposal.api.preApprove');
        const successMessage = t('components.counterProposal.api.preApproveBody');
        swal(successHeader, successMessage, 'success');
        setWasProposalUpdated(true);
      }
    } catch (error) {
      const errTitle = t('pages.tokenProposal.apiErr.preApprove');
      const errBody = t('pages.tokenProposal.apiErr.body');
      swal(errTitle, errBody, 'error');
    } finally {
      setLoading(false);
    }
  }, []);

  const annulProposal = useCallback(async (proposalId) => {
    setLoading(true);

    try {
      const { data } = await tokenProposalService.annulProposal(proposalId);

      if (data.status === 200) {
        const successHeader = t('components.counterProposal.api.annul');
        const successMessage = t('components.counterProposal.api.annulBody');
        swal(successHeader, successMessage, 'success');
        setWasProposalUpdated(true);
      }
    } catch (error) {
      const errTitle = t('pages.tokenProposal.apiErr.annul');
      const errBody = t('pages.tokenProposal.apiErr.body');
      swal(errTitle, errBody, 'error');
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <TokenProposalContext.Provider
      value={{
        loading,
        imageLink,
        uploadTokenImage,
        downloadTokenImage,
        imageBuffer,
        createTokenProposal,
        createdTokenProposal,
        imageLink,
        getAllProposals,
        allProposals,
        loadingImage,
        uploadAdditionalDoc,
        docLink,
        getTokenProposal,
        tokenProposal,
        updateTokenProposal,
        downloadAdditionalDoc,
        additionalFileBuffer,
        wasProposalUpdated,
        setWasProposalUpdated,
        preApproveProposal,
        annulProposal,
        loadingDocs,
      }}
    >
      {children}
    </TokenProposalContext.Provider>
  );
};

const useTokenProposal = () => {
  return useContext(TokenProposalContext);
};

export { TokenProposalProvider, useTokenProposal };
