import React from 'react';
import { logoEmissor, logoIssuer } from '@assets';
import { useTranslation } from 'react-i18next';
import { pendingStatus } from '@assets';
import { Translator } from '@components';

const FinishForm = ({ props: { email, setLayout } }) => {
  const { i18n, t } = useTranslation();

  return (
    <>
      <div className="row col-12">
        <div className="col-12 d-flex flex-column align-items-center justify-content-center">
          <img
            src={i18n.language === 'pt-BR' ? logoEmissor : logoIssuer}
            alt=""
            className="width-230 mCS_img_loaded hide-logo "
            style={{ marginTop: '2.5rem' }}
          />
          <div className="col-12 pending-status" style={{ padding: '2.5rem 2.5rem 0 2.5rem ' }}>
            <p style={{ margin: 0 }}>
              <Translator path="pages.issuer.finishRegister" />
            </p>
            <p style={{ alignSelf: 'flex-start', margin: 0 }}>{email}</p>
          </div>
          <img src={pendingStatus} style={{ marginTop: '2.5rem' }} />
          <button
            className="col-3 btn btn-orange"
            style={{ margin: '2.5rem 0 3.75rem 0' }}
            onClick={(e) => setLayout(0)}
          >
            <Translator path="pages.issuer.goBack" />
          </button>
        </div>
      </div>
    </>
  );
};

export default FinishForm;
