import React, { useState, useEffect } from 'react';
import { Translator, InputText } from '@components';
import { stage1 } from '@assets';
import { isEmail } from '@utils';
import { useRegister } from '@contexts-issuer';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const FirstStep = ({ props: { setEmail, email, handleRequest } }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { loadingButton } = useRegister();
  const errorObj = { msg: '' };
  const [errors, setErrors] = useState(errorObj);

  const makeRequest = () => {
    if (!email) {
      const errors = { ...errorObj };
      errors.msg = t('pages.reset.firstStep.err.email').toString();
      setErrors(errors);
    } else if (!isEmail(email)) {
      const errors = { ...errorObj };
      errors.msg = t('pages.reset.firstStep.err.valid').toString();
      setErrors(errors);
    } else handleRequest();
  };

  useEffect(() => {
    const errors = { ...errorObj };
    errors.msg = '';
    setErrors(errorObj);
  }, [email]);

  return (
    <>
      <div className="row col-12 reset-header">
        <img src={stage1} />
        <p className="title">
          <Translator path="pages.reset.firstStep.title" />
        </p>
        <p className="body">
          <Translator path="pages.reset.firstStep.body" />
        </p>
      </div>
      <div className="row col-12 d-flex justify-content-between reset-content">
        <InputText text="pages.reset.firstStep.email" type="email" setState={setEmail} value={email} />
        {errors.msg && (
          <div className="text-danger fs-12" style={{ marginTop: '0.313rem' }}>
            {errors.msg}
          </div>
        )}
        <button
          className="btn btn-outlined col-3"
          style={{ marginTop: '2.5rem', padding: '1rem 0.625rem' }}
          onClick={() => navigate('/login')}
        >
          <Translator path="pages.reset.goback" />
        </button>
        <button
          className={`btn btn-orange col-7 ${loadingButton ? 'd-flex justify-content-center disabled' : ''}`}
          style={{ marginTop: '2.5rem' }}
          onClick={makeRequest}
        >
          {loadingButton ? (
            <div className="loading-spinner" style={{ width: '30px', height: '30px' }} />
          ) : (
            <Translator path="pages.reset.continue" />
          )}
        </button>
      </div>
    </>
  );
};

export default FirstStep;
