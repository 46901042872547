import { useState } from 'react';
import { Translator, Countdown, CodeValidator } from '@components';
import { stage1 } from '@assets';
import { useWithdraw } from '@contexts-investor';
import { useTranslation } from 'react-i18next';

const FirstStep = ({ props: { email, handleStep } }) => {
  const { sendEmailToken } = useWithdraw();
  const { t } = useTranslation();
  const successObj = { msg: '' };
  const [resend, setResend] = useState(successObj);
  const [code, setCode] = useState();

  const reSend = () => {
    const success = { ...successObj };
    success.msg = t('withdrawPage.firstStep.resend').toString();
    setResend(success);
    sendEmailToken();
  };

  return (
    <>
      <h3>
        <Translator path="withdrawPage.firstStep.title" />
      </h3>
      <img className="col-8" src={stage1} />
      <p className="step-one" style={{ whiteSpace: 'pre-wrap' }}>
        <Translator path="withdrawPage.firstStep.body" />
      </p>
      <p className="step-one col-12" style={{ overflow: 'clip' }}>
        {email}
      </p>
      <div className="col-12 d-flex justify-content-center" style={{ gap: '0 11px' }}>
        <CodeValidator props={{ setCode }} />
      </div>
      {resend.msg && (
        <div className="text-success fs-12" style={{ marginTop: '0.313rem' }}>
          {resend.msg}
        </div>
      )}
      <div
        className="col-7 d-flex justify-content-start align-items-start"
        style={{ padding: 0, margin: '0 0 1.5rem 0' }}
      >
        <div className="mt-2" style={{ margin: 0, padding: 0 }}>
          <Countdown />
        </div>
        <span
          className="form-check d-inline-block mt-2"
          style={{ whiteSpace: 'nowrap', padding: '0 0 0 2.5rem', margin: 0 }}
        >
          <label className="label-forgot-password" htmlFor="check1" style={{ margin: 0 }}>
            <Translator path="register.emailPage.send" />
          </label>
          <label className="a-forgot-password" onClick={() => reSend()}>
            <Translator path="register.emailPage.click" />
          </label>
        </span>
      </div>
      <button
        className=" col-6 btn btn-orange"
        onClick={() => {
          handleStep(code);
        }}
      >
        <Translator path="withdrawPage.firstStep.submit" />
      </button>
    </>
  );
};

export default FirstStep;
