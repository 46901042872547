/// Menu
import React, { useContext, useEffect, useReducer, useState } from 'react';

import Collapse from 'react-bootstrap/Collapse';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { login, register } from '@assets';

/// Link
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';

import { Translator } from '@components';
import { ThemeContext } from '@contexts/ThemeContext';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { MenuList } from './Menu';

import { logout } from '../../../services/AuthService';
import { NavMenuToggle } from './NavHeader';

const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

const initialState = {
  active: <Translator path={'menuLabel.issuerItems.myData'} />,
  activeSubmenu: '',
};

const SideBar = ({ publicRoute, toggle }) => {
  const { iconHover, sidebarposition, headerposition, sidebarLayout } = useContext(ThemeContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [state, setState] = useReducer(reducer, initialState);
  const [activeSubmenu, setActiveSubmenu] = useState('');
  const [menuBar, setMenuBar] = useState('');
  const [hideOnScroll, setHideOnScroll] = useState(true);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll],
  );

  useEffect(() => {
    setMenuBar(Array.from(MenuList))
  }, []);

  useEffect(() => {
    const setActiveSubMenu = () => {
      Array.from(MenuList).forEach((data) => {
        if (data.content)
          Array.from(data.content).forEach((item) => {
            if (item.to === pathname.replace('/', '')) setActiveSubmenu(item.title);
          });
      });
    };
    if (MenuList) setActiveSubMenu();
  }, [pathname]);

  const handleMenuActive = (status) => {
    setState({ active: status });

    if (state.active === status) {
      setState({ active: '' });
    }
  };

  function isMobileScreen() {
    return window.innerWidth <= 767;
  }

  const handleMenuClose = (title) => {
    if (process.env.REACT_APP_BUILD_TARGET === 'investor') {
      if (isMobileScreen()) {
        if (title !== 'menuLabel.wallet' && title !== 'menuLabel.account') {
          NavMenuToggle();
        }
      }
    }
  };

  const handleSubmenuActive = (status) => {
    setState({ activeSubmenu: status });
    if (state.activeSubmenu === status) {
      setState({ activeSubmenu: '' });
    }
  };

  let path = window.location.pathname;
  path = path.split('/');
  path = path[path.length - 1];

  if (!publicRoute) {
    return (
      <div
        className={`deznav border-right ${iconHover} ${sidebarposition.value === 'fixed' && sidebarLayout.value === 'horizontal' && headerposition.value === 'static'
          ? hideOnScroll > 120
            ? 'fixed'
            : ''
          : ''
          }`}
      >
        <PerfectScrollbar className="deznav-scroll">
          <ul className="metismenu" id="menu">
            {MenuList.map((data, index) => {
              if (data?.id === 'profile') {
                data.to = localStorage.getItem('usertype') === 'legal person' ? 'profilepj' : 'profile'
              }
              let menuClass = data.classsChange;
              if (menuClass === 'menu-title') {

                return (
                  <li className={menuClass} key={index}>
                    {data.menuLabel}
                  </li>
                );
              } else if (menuClass === 'logout') {
                return (
                  <li className={` ${state.active === data.title ? 'mm-active' : ''}`} key={index}>
                    <NavLink
                      to=""
                      className={` ${state.active === data.title ? 'mm-active' : ''}`}
                      onClick={() => {
                        logout(navigate);
                      }}
                    >
                      {data.iconStyle}
                      <span className="nav-text">{data.menuLabel}</span>
                    </NavLink>
                  </li>
                );
              } else {
                return (
                  <li onClick={() => handleMenuClose(data.menuLabel.props.path)}
                    className={` ${data.to === pathname.replace('/', '') ||
                      (data.content &&
                        data.content.length > 0 &&
                        data.content
                          .map((content) => {
                            if (content.to.replaceAll('/', '') === pathname.replaceAll('/', '')) return pathname;
                            return false;
                          })
                          .includes(pathname))
                      ? 'mm-active'
                      : ''
                      }`}
                    key={index}
                  >
                    {data.content && data.content.length > 0 ? (
                      <>
                        <Link
                          to={'#'}
                          className="has-arrow"
                          onClick={() => {
                            handleMenuActive(data.title);
                          }}
                        >
                          {data.iconStyle}
                          <span className="nav-text">{data.menuLabel}</span>
                        </Link>
                        <Collapse in={state.active === data.title ? true : false}>
                          <ul className={`${menuClass === 'mm-collapse' ? 'mm-show' : ''}`}>
                            {data.content &&
                              data.content.length > 0 &&
                              data.content.map((data, index) => {
                                return (
                                  <li
                                    onClick={() => handleMenuClose(data.title)}
                                    key={index}
                                    className={`${state.activeSubmenu === data.title ? 'mm-active' : ''}`}
                                  >
                                    {data.content && data.content.length > 0 ? (
                                      <>
                                        <NavLink
                                          to={data.to}
                                          className={data.hasMenu ? 'has-arrow' : ''}
                                          onClick={() => {
                                            handleSubmenuActive(data.title);
                                          }}
                                        >
                                          {data.title}
                                        </NavLink>
                                        <Collapse in={state.activeSubmenu === data.title ? true : false}>
                                          <ul className={`${menuClass === 'mm-collapse' ? 'mm-show' : ''}`}>
                                            {data.content.length > 0 &&
                                              data.content.map((data, index) => {
                                                return (
                                                  <>
                                                    <li key={index}>
                                                      <Link
                                                        className={`${path === data.to ? 'mm-active' : ''}`}
                                                        to={data.to}
                                                      >
                                                        {data.title}
                                                      </Link>
                                                    </li>
                                                  </>
                                                );
                                              })}
                                          </ul>
                                        </Collapse>
                                      </>
                                    ) : (
                                      <Link
                                        style={{
                                          textDecoration: `${data.to.replaceAll('/', '') === pathname.replaceAll('/', '') &&
                                            activeSubmenu === data.title
                                            ? 'underline'
                                            : ''
                                            }`,
                                        }}
                                        to={data.to}
                                        onClick={() => setActiveSubmenu(data.title)}
                                      >
                                        {data.title}
                                      </Link>
                                    )}
                                  </li>
                                );
                              })}
                          </ul>
                        </Collapse>
                      </>
                    ) : (
                      <NavLink
                        to={data.to}
                        className={` ${state.active === data.title ? 'mm-active' : ''}`}
                        onClick={() => {
                          handleMenuActive(data.title);
                          if (data.onClick === true) {
                            window.open(process.env.REACT_APP_USE_TERMS, '_blank', 'noreferrer');
                          }
                        }}
                      >
                        {data.iconStyle}
                        <span className="nav-text">{data.menuLabel}</span>
                      </NavLink>
                    )}
                  </li>
                );
              }
            })}
          </ul>
        </PerfectScrollbar>
      </div>
    );
  } else {
    return (
      <div
        className={`deznav  border-right ${iconHover} ${sidebarposition.value === 'fixed' && sidebarLayout.value === 'horizontal' && headerposition.value === 'static'
          ? hideOnScroll > 120
            ? 'fixed'
            : ''
          : ''
          }`}
      >
        <PerfectScrollbar className="deznav-scroll">
          <ul className={`metismenu menu-modifier-pl ${toggle === false ? '' : 'pd-0'}`} id="menu">
            {toggle === false ? (
              <>
                <button
                  className="btn btn-orange clear-btn "
                  style={{ fontSize: '1.25rem' }}
                  onClick={() => navigate('/register')}
                >
                  <Translator path="register.header" />
                </button>
                <button className="btn btn-opac clear-btn" onClick={() => navigate('/login')}>
                  <Translator path="login.header" />
                </button>
              </>
            ) : (
              <>
                <button
                  className="btn btn-orange clear-btn d-flex justify-content-center "
                  style={{ fontSize: '1.25rem' }}
                  onClick={() => navigate('/register')}
                >
                  <img src={register} alt="" />
                </button>
                <button
                  className="btn btn-opac btn-outlined clear-btn d-flex justify-content-center"
                  onClick={() => navigate('/login')}
                >
                  <img src={login} alt="" style={{ color: 'white' }} />
                </button>
              </>
            )}
          </ul>
        </PerfectScrollbar>
      </div>
    );
  }
};

export default SideBar;
