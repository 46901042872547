import { calIcon, dollarIcon, tokenTutorial, tokenTutorialSmall } from '@assets';
import { Loading, Profile, SimpleCard, TokenCard, Translator } from '@components';
import { useProfile, useToken } from '@contexts-investor';
import { formatDate } from 'date-utils-2020';
import { useEffect, useState } from 'react';
import ReactCardFlip from 'react-card-flip';
import { useParams } from 'react-router-dom';

import DepositModal from '../Wallet/DepositModal';
import ConfirmInvestment from './ConfirmInvestment';
import ErrorModal from './ErrorModal';
import NoBalanceModal from './NoBalanceModal';

const InvestmentInfo = () => {
  const { token, loadingToken, getToken } = useToken();
  const { profile, loadProfile } = useProfile();
  const { tokenId } = useParams();

  const [active, setActive] = useState(false);
  const [showBalance, setShowBalance] = useState('password');
  const [quantity, setQuantity] = useState(0);
  const [totalValue, setTotalValue] = useState(0);
  const [investmentLabel, setInvestmentLabel] = useState(0);
  const [isFlipped, setIsFlipped] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showDepositModal, setShowDepositModal] = useState(false);
  const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [profileStatus, setprofileStatus] = useState('');

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    if (userId !== (null && undefined)) loadProfile(userId);
    getToken(tokenId);
  }, [localStorage.getItem('userId')]);

  useEffect(() => {
    if (profile) {
      setprofileStatus(profile.status);
    }
  }, [profile]);

  useEffect(() => {
    if (token) {
      setInvestmentLabel(quantity * token.unityPrice);
      setTotalValue(quantity * parseFloat(token.unityPrice) + parseFloat(token.yieldValue) * quantity);
    }
  }, [quantity]);

  const showDeposit = () => {
    setShowModal(false);
    setShowDepositModal(true);
  };

  const handleFlip = (e) => {
    e.preventDefault();

    if (profile) {
      if (profileStatus === 'ACTIVE') {
        if (profile.wallet.balance >= quantity * token?.unityPrice) {
          setIsFlipped(!isFlipped);
        } else {
          setShowModal(true);
        }
      } else {
        setShowErrorModal(true);
      }
    }
  };

  if (loadingToken) {
    return <Loading />;
  }

  if (profile && token)
    return (
      <div className="page-wrapper">
        <div className="row d-flex " style={{ padding: 0, margin: 0 }}>
          <div
            className="row d-flex flex-column justify-content-between col-xxl-5 col-xl-6 col-lg-12 col-md-12 col-xs-12"
            style={{ padding: 0, margin: 0, height: '100% !important' }}
          >
            <div
              className="col-lg-12 invest-label d-flex align-items-center flex-column"
              style={{ backgroundColor: 'white', height: '10vh', marginBottom: '3%' }}
            >
              <div
                className="col-lg-12 d-flex flex-row justify-content-center align-items-center mb-0 form-group"
                style={{ textAlign: 'center', alignItems: 'center', height: '100%' }}
              >
                <p className="mb-0" style={{ whiteSpace: 'nowrap', fontSize: '1.5rem', width: '70%' }}>
                  <Translator path="walletPage.currentBalance" />
                </p>
                <input
                  className="mb-0 form-control show-pass invest-input"
                  style={{ border: 0, fontSize: '1.5rem', textAlign: 'start' }}
                  readOnly
                  value={
                    profile?.wallet
                      ? profile?.wallet.balance.toLocaleString('pt-BT', { style: 'currency', currency: 'BRL' })
                      : 'R$ 0,00'
                  }
                  type={showBalance}
                />
                {active ? (
                  <i
                    className="show-pass fa fa-eye-slash"
                    onClick={() => {
                      setActive(false);
                      setShowBalance('password');
                    }}
                  />
                ) : (
                  <i
                    className="show-pass fa fa-eye"
                    onClick={() => {
                      setActive(true);
                      setShowBalance('text');
                    }}
                  />
                )}
              </div>
            </div>
            <div className="col-lg-12" style={{ padding: 0 }}>
              <TokenCard props={token} isButton={false} />
            </div>
          </div>
          <div className="row col-xxl-4 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 padding-control" style={{ margin: 0 }}>
            <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
              <div
                className="col-lg-12 d-flex flex-column invest-label invest-label-tokens"
                style={{ padding: '0 2rem 0 2rem', height: '100%' }}
              >
                <p
                  className="mb-0 info-buy-title col-lg-6 "
                  style={{ whiteSpace: 'nowrap', padding: 0, marginTop: '2.5rem' }}
                >
                  <Translator path="investment.buyTokenPage.investTitle" />
                </p>
                <div className="d-flex" style={{ whiteSpace: 'nowrap', marginBottom: '0.625rem' }}>
                  <p className="info-label fs-5" style={{ fontWeight: '400' }}>
                    <Translator path="investment.buyTokenPage.nameField" />
                  </p>

                  <p className="info-text" style={{ marginLeft: '0.313rem', fontWeight: '500' }}>
                    {token?.name}
                  </p>
                </div>
                <div className="d-flex" style={{ whiteSpace: 'nowrap' }}>
                  <p className="info-label fs-5">
                    <Translator path="investment.buyTokenPage.defaultValField" />
                  </p>

                  <p className="info-text" style={{ marginLeft: '0.313rem' }}>
                    {token?.unityPrice.toLocaleString('pt-BT', { style: 'currency', currency: 'BRL' })}
                  </p>
                </div>
                <div
                  className="col-lg-12"
                  style={{
                    borderBottom: '1px solid #c0c0c0',
                    marginTop: '2.5rem',
                    marginBottom: '2.5rem',
                  }}
                />
                <div
                  className={`d-flex col-lg-12 ${windowSize[0] > 767 ? 'flex-row ' : 'flex-column'
                    } justify-content-start  align-items-center`}
                  style={{ gap: '0.625rem', marginBottom: '1.25rem' }}
                >
                  <p className="info-buy-opt mb-0" style={{ whiteSpace: 'nowrap' }}>
                    <Translator path="investment.buyTokenPage.selectField" />
                  </p>
                  <div className="d-flex align-items-center" style={{ gap: '' }}>
                    <i
                      className={`fa-solid fa-square-minus ${quantity <= 0 ? 'disabled-icon' : ''}`}
                      onClick={() => {
                        if (quantity > 0) {
                          setQuantity(parseInt(quantity - 1));
                        }
                      }}
                    />
                    <input
                      className="info-buy-input col-lg-1 mx-1"
                      style={{ width: '5vh', height: '1.5rem' }}
                      onChange={(e) => {
                        if (+e.target.value <= token.offeredQuantity - token.soldQuantity) {
                          setQuantity(+e.target.value);
                        } else {
                          setQuantity(0);
                        }
                      }}
                      value={quantity}
                    />
                    <i
                      className={`fa-solid fa-square-plus ${quantity < token.offeredQuantity - token.soldQuantity ? '' : 'disabled-icon'
                        }`}
                      onClick={() => {
                        if (quantity < token.offeredQuantity - token.soldQuantity) {
                          setQuantity(parseInt(quantity + 1));
                        }
                      }}
                    />
                  </div>
                </div>
                <p className="info-buy-opt mb-0">
                  <Translator path="investment.buyTokenPage.valField" />
                </p>
                <input
                  className="info-buy-input-opacity col-lg-12 col-md-12 col-sm-12 col-xs-12 col-10"
                  type="text"
                  style={{ height: '3.375rem', marginTop: '0.625rem' }}
                  value={
                    'R$ ' +
                    new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(parseFloat(investmentLabel))
                  }
                  readOnly
                />
                <button
                  className="invest-btn btn btn-orange col-sm-6 info-btn"
                  disabled={quantity <= 0}
                  onClick={handleFlip}
                >
                  <Translator path="investment.buyTokenPage.submit" />
                </button>
              </div>
              <ConfirmInvestment
                flip={handleFlip}
                tokenId={tokenId}
                quantity={quantity}
                token={token}
                totalValue={totalValue}
              />
            </ReactCardFlip>
          </div>
          <div
            className="row col-xxl-3 col-xl-12 col-md-12 col-sm-12 col-xs-12 col-12 padding-resp d-flex justify-content-between investment-values"
            style={{ padding: 0, margin: 0 }}
          >
            <div
              className="col-xxl-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 padding-right d-flex align-items-start investment-values-value"
              style={{ padding: 0, margin: 0 }}
            >
              <div
                className="invest-label-padding invest-label-shadow "
                style={{ padding: '1.25rem 2.5rem', margin: 0, height: '100%', width: '100%' }}
              >
                <img src={dollarIcon} className="invest-label-icon mb-2" alt="" />
                <p className="info-revenue invest-label-icon-margin ">
                  <Translator path="investment.buyTokenPage.valReceive" />
                </p>
                <p className="text-success info-text-big" style={{ whiteSpace: 'nowrap' }}>
                  {totalValue != 0
                    ? totalValue.toLocaleString('pt-BT', { style: 'currency', currency: 'BRL' })
                    : 'R$ 0,00'}
                </p>
              </div>
            </div>

            <div className="col-xxl-12 col-xl-6 col-lg-5 col-md-6 col-sm-12 col-xs-12 d-flex align-items-end investment-values-date p-0">
              <div
                className=" invest-label-padding invest-label-shadow"
                style={{ padding: '1.25rem 2.5rem', margin: 0, height: '100%', width: '100%' }}
              >
                <img src={calIcon} className="invest-label-icon mb-2" alt="" />
                <p className="info-revenue invest-label-icon-margin ">
                  <Translator path="investment.buyTokenPage.dateReceive" />
                  {token?.paymentParcels}x
                </p>
                <p className="info-text info-text-big">{formatDate(token?.paymentDate, 'dd/MM/yyyy')}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row tutorial-title" style={{ margin: '2.5rem 0 0 0' }}>
          <h3 className="col-12 investment-title">
            <Translator path="investment.buyTokenPage.howItWorks" />
          </h3>
          <div
            className="col-12 tutorial-gif card  text-center d-flex justify-content-center"
            style={{ borderRadius: '1.25rem' }}
          >
            <img
              src={windowSize[0] > 1000 ? tokenTutorial : tokenTutorialSmall}
              style={{ borderRadius: '1.25rem' }}
              alt="Gif ilustrativo de como um token é ofertado."
            />
          </div>
          <div className="col-12 tutorial-attention" style={{ minHeight: '2.75rem' }}>
            <p className="d-flex align-items-center m-0">
              <i className="material-icons px-1">info_outline</i>
              <Translator path="investment.buyTokenPage.alert" />
            </p>
          </div>
        </div>
        <div className="row about" style={{ margin: 0, padding: 0 }}>
          <div className="col-12" style={{ margin: '0 0 1.25rem 0', padding: 0 }}>
            <h3 className="investment-title">
              <Translator path="investment.buyTokenPage.about" />
            </h3>

            <div className="grid-items-container flex-column" style={{ padding: '0 !important' }}>
              <div className="card-title">
                <Translator path={'investment.buyTokenPage.description'} />
              </div>
              <div className="about-stroke" />
              <div className="about-info">
                <p>{token.description}</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-lg-6" style={{ padding: '0 0.625rem 0 0' }}>
            <div className="grid-items-container flex-column">
              <div className="card-title">
                <Translator path="investment.buyTokenPage.info" />
              </div>
              <div className="about-stroke" />
              <div className="row investment-info-table" style={{ margin: 0, padding: 0 }}>
                <SimpleCard
                  icon={'today'}
                  info={token?.fundingLimitDate}
                  title={<Translator path="investment.buyTokenPage.captation" />}
                  infoType="date"
                />
                <SimpleCard
                  icon={'attach_money'}
                  info={token?.soldValue}
                  title={<Translator path="investment.buyTokenPage.currentCaptation" />}
                  infoType="currency"
                />
                <SimpleCard
                  icon={'production_quantity_limits'}
                  info={token?.offeredQuantity - token?.soldQuantity}
                  title={<Translator path="investment.buyTokenPage.remainingTokens" />}
                  infoType="number"
                />
                <SimpleCard
                  icon={'groups'}
                  info={token?.buyersQuantity}
                  title={<Translator path="investment.buyTokenPage.buyers" />}
                  infoType="number"
                />
                <SimpleCard
                  icon={'date_range'}
                  info={token?.paymentDate}
                  title={<Translator path="investment.buyTokenPage.liquidation" />}
                  infoType="date"
                />
                <SimpleCard
                  icon={'money'}
                  info={token?.offeredValue}
                  title={<Translator path="investment.buyTokenPage.total" />}
                  infoType="currency"
                />
                <SimpleCard
                  icon={'token'}
                  info={token?.offeredQuantity}
                  title={<Translator path="investment.buyTokenPage.offeredTokens" />}
                  infoType="number"
                />
                <SimpleCard
                  icon={'shopping_cart'}
                  info={token?.unityPrice}
                  title={<Translator path="investment.buyTokenPage.minimumValue" />}
                  infoType="currency"
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-lg-6 investment-issuer-info">
            <div className="grid-items-container flex-column">
              <div className="card-title">
                <Translator path="investment.buyTokenPage.issuer.info" />
              </div>
              <div className="about-stroke" />
              <div className="about-info about-issuer gap-0 flex-column">
                <Profile person={token.issuer} />
              </div>
            </div>
          </div>

          {token.issuer?.collaborators.length > 0 ? (
            <div className="col-12 team card flex-column">
              <div className="card-title">
                <Translator path="investment.buyTokenPage.issuer.team" />
              </div>
              <div className="about-stroke" />
              <div className="about-info about-issuer gap-0 flex-column">
                {token.issuer?.collaborators.map((collaborator, index, collaborators) => {
                  return (
                    <>
                      <Profile person={collaborator} key={collaborator.collaboratorId} />
                      {collaborators[index + 1] ? <div className="about-stroke about-stroke-modified" /> : ''}
                    </>
                  );
                })}
              </div>
            </div>
          ) : (
            ''
          )}
        </div>

        <NoBalanceModal props={{ show: showModal, setShow: setShowModal, showDeposit: showDeposit }} />
        <DepositModal props={{ show: showDepositModal, setShow: setShowDepositModal }} />
        <ErrorModal props={{ show: showErrorModal, setShow: setShowErrorModal, status: profileStatus }} />
      </div>
    );
};

export default InvestmentInfo;
