import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { loadingToggleAction } from '../../../../store/actions/AuthActions';
// image

import { loginBg, logoCriptech } from '@assets';
import { CodeValidator, Countdown, I18n, Translator } from '@components';
import { useTranslation } from 'react-i18next';
import { verifyEmail } from '../../../../store/actions/RegisterActions';

function RegisterEmail(props) {
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState('');
  const { t } = useTranslation();

  let errorsObj = { code: '' };
  const [errors, setErrors] = useState(errorsObj);
  const email = localStorage.getItem('email');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  const startLoading = () => {
    setLoading(!loading);
  };

  async function onConfirmEmail(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    setErrors(errorObj);

    if (code === '') {
      errorObj.code = t('register.emailPage.box').toString();
      error = true;
    }
    if (error) {
      return;
    }
    startLoading();

    const params = { email: localStorage.getItem('useremail'), token: code };
    dispatch(loadingToggleAction(true));
    dispatch(verifyEmail(params, navigate, t, setLoading));
  }

  useEffect(() => {
    const email = localStorage.getItem('useremail');
    const id = localStorage.getItem('id');
    const token = localStorage.getItem('access_token_register');
    if (token && email && id) {
      navigate('register/password');
    }
  }, []);

  return (
    <>
      <div className="page-wraper">
        <div className="browse-job login-style3">
          <div
            className="bg-img-fix overflow-hidden"
            style={{
              background: '#fff url(' + loginBg + ')',
              height: '100vh',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'right',
              backgroundSize: 'contain',
            }}
          >
            <div className="row gx-0">
              <div className="login-form-container bg-white d-flex justify-content-center align-items-center">
                <div className="login-form d-flex justify-content-center align-items-center">
                  <div className="logo-header row gx-0 ">
                    <Link to="/login" className="logo ">
                      <img src={logoCriptech} alt="" className="width-230 mCS_img_loaded " />
                    </Link>
                  </div>
                  <nav className="nav nav-tabs border-bottom-0">
                    <div className="tab-content w-100" id="nav-tabContent">
                      <div className="tab-pane active show fade d-flex justify-content-center align-items-center">
                        {props.errorMessage && <div className="">{props.errorMessage}</div>}
                        <form
                          className="dz-form py-2 row d-flex justify-content-center align-items-center"
                          onSubmit={onConfirmEmail}
                          style={{ margin: 0, padding: 0, textAlign: 'center' }}
                        >
                          <h3 className="form-title" style={{ marginLeft: 0, padding: 0 }}>
                            <Translator path="register.emailPage.title" />
                          </h3>
                          <div className="dz-separator-outer m-b5">
                            <div className="dz-separator bg-primary style-liner"></div>
                          </div>
                          <p className="step-two-body" style={{ marginLeft: 0, padding: 0 }}>
                            <Translator path="register.emailPage.body" />
                          </p>
                          {email ? (
                            <div className="email-props " style={{ margin: '0 0 1.25rem 0', padding: 0 }}>
                              {email}
                            </div>
                          ) : (
                            localStorage.getItem('email') && (
                              <div className="email-props" style={{ margin: '0 0 1.25rem 0', padding: 0 }}>
                                {localStorage.getItem('email')}
                              </div>
                            )
                          )}
                          <div className="form-group row d-flex justify-content-center align-items-center">
                            <div
                              className="col-7 d-flex justify-content-center align-items-center "
                              style={{
                                padding: 0,
                                gap: '0 0.688rem',
                                textAlign: 'center',
                              }}
                            >
                              <CodeValidator props={{ setCode }} />
                              {errors.code && <div className="text-danger fs-12 mt-1">{errors.code}</div>}
                            </div>
                          </div>
                          {errors.box && <div className="text-danger fs-12 mt-1">{errors.box}</div>}
                          <div
                            className="col-7 d-flex justify-content-center align-items-start"
                            style={{ padding: 0, margin: '0 0 1.5rem 0' }}
                          >
                            <div className="mt-2" style={{ margin: 0, padding: 0 }}>
                              <Countdown />
                            </div>
                            <span
                              className="form-check d-inline-block mt-2"
                              style={{ whiteSpace: 'nowrap', padding: '0 0 0 2.5rem', margin: 0 }}
                            >
                              <label className="label-forgot-password" htmlFor="check1" style={{ margin: 0 }}>
                                <Translator path="register.emailPage.send" />
                              </label>
                              <a href="" className="a-forgot-password">
                                <Translator path="register.emailPage.click" />
                              </a>
                            </span>
                          </div>
                          <div
                            className="row col-12 d-flex flex-row justify-content-center form-group"
                            style={{ margin: 0, padding: 0 }}
                          >
                            {loading ? (
                              <button type="submit" className="col-6 btn btn-orange disabled">
                                <Translator path="loading" />
                              </button>
                            ) : (
                              <button type="submit" className="col-6 btn btn-orange ">
                                <Translator path="register.emailPage.submit" />
                              </button>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </nav>
                </div>
                <I18n />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(RegisterEmail);
