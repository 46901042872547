import React, { useContext } from 'react';

/// React router dom
import { Routes, Route, Outlet, useNavigate, Navigate } from 'react-router-dom';
import { isAuthenticated } from '../../../store/selectors/AuthSelectors';

/// Css
import '../../css/index.css';
import '../../css/chart.css';
import '../../css/step.css';

/// Layout
import Nav from '../../layouts/nav';
// import Footer from './layouts/Footer';
//import Main from './layouts/Main';
import { connect } from 'react-redux';

import ScrollToTop from '../../layouts/ScrollToTop';
/// Dashboard
import { Footer } from '@components';
import { ThemeContext } from '@contexts/ThemeContext';

import {
  Dashboard,
  Investment,
  InvestmentInfo,
  MyTokenDetails,
  Profile,
  ProfileEdit,
  Wallet,
  Statement,
  Deposit,
  Withdraw,
  WithdrawList,
  Security,
  SecurityEdit,
  ChooseProfile,
  ProfilePJ,
  ProfileEditPJ

} from '@pages-investor';



const Markup = (props) => {
  const navigate = useNavigate();
  const allroutes = [
    { url: '/login/account', Component: ChooseProfile },
    { url: '/dashboard', Component: Dashboard },
    { url: '/investment', Component: Investment },
    { url: '/investment/:tokenId', Component: InvestmentInfo },
    { url: '/investment/:tokenId/details', Component: MyTokenDetails },
    { url: '/profile', Component: Profile },
    { url: '/profile/edit', Component: ProfileEdit },
    { url: '/profilepj', Component: ProfilePJ },
    { url: '/profile/editpj', Component: ProfileEditPJ },
    { url: '/wallet', Component: Wallet },
    { url: '/wallet/statement', Component: Statement },
    { url: '/wallet/deposit', Component: Deposit },
    { url: '/wallet/withdraw', Component: Withdraw },
    { url: '/wallet/withdrawList', Component: WithdrawList },
    { url: '/security', Component: Security },
    { url: '/security/edit', Component: SecurityEdit },
  ];

  //let path = window.location.pathname;
  //path = path.split("/");
  //path = path[path.length - 1];

  //let pagePath = path.split("-").includes("page");
  //const { menuToggle } = useContext(ThemeContext);
  const token = localStorage.getItem('token');
  const userId = localStorage.getItem('userId');
  const path = window.location.pathname;
  const noAuthPaths = ['/publictokens', '/publictokenslite', '/register', '/registertype', '/registerpj', '/register/concluded', '/register/email', '/register/password', '/reset', '/login'];

  return (
    <>
      <Routes>
        <Route element={!path.includes('/login/account') ? <MainLayout /> : ''}>
          {allroutes.map(({ Component, url }, i) => {
            return <Route key={i} exact path={url} element={<Component />} />;
          })}
          <Route
            path="*"
            element={
              token ? (
                path.includes('/dashboard') || path.includes('/investment') ? (
                  ''
                ) : (
                  userId ? <Navigate to="/dashboard" /> : <Navigate to="/login/account" />
                )
              ) : !noAuthPaths.includes(path) ? (
                <Navigate to="/login" />
              ) : (
                ''
              )
            }
          />
        </Route>
      </Routes>
      <ScrollToTop />
    </>
  );
};

function MainLayout() {
  const { menuToggle } = useContext(ThemeContext);
  return (
    <div style={{ width: '100%', height: '100%', position: 'absolute', }}>
      <div id="main-wrapper" className={`show ${menuToggle ? 'menu-toggle' : ''}`}>
        <Nav />
        <div className="content-body" style={{ minHeight: window.screen.height - 45 }}>
          <div className="container-fluid">
            <>
              <Outlet />
            </>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default connect(mapStateToProps)(Markup);
