import React from 'react';
import { Translator } from '@components';

const StatusCard = ({ status }) => {
  return (
    <div
      className="col-12"
      style={{
        borderRadius: '200px',
        backgroundColor: `${
          status === 'IN_ANALYSIS'
            ? '#FF9900'
            : status === 'NEGOTIATING'
            ? '#2E291E'
            : status === 'REJECTED'
            ? '#E61D2B'
            : status === 'ACCEPTED'
            ? '#009847'
            : status === 'PRE_ACCEPTED'
            ? '#005829'
            : status === 'ANNULLED'
            ? '#A30914'
            : ''
        }`,
        textAlign: 'center',
      }}
    >
      <p className="statement-status-card" style={{ color: 'var(--white)' }}>
        {status === 'IN_ANALYSIS' ? (
          <Translator path="components.proposalsList.status.inAnalysis" />
        ) : status === 'NEGOTIATING' ? (
          <Translator path="components.proposalsList.status.negotiating" />
        ) : status === 'REJECTED' ? (
          <Translator path="components.proposalsList.status.rejected" />
        ) : status === 'ACCEPTED' ? (
          <Translator path="components.proposalsList.status.approved" />
        ) : status === 'PRE_ACCEPTED' ? (
          <Translator path="components.proposalsList.status.preApproved" />
        ) : status === 'ANNULLED' ? (
          <Translator path="components.proposalsList.status.annulled" />
        ) : (
          ''
        )}
      </p>
    </div>
  );
};

export default StatusCard;
