import { calIcon, cardLogo, dollarIcon, investIcon } from '@assets';
import { Loading, TokenCard, Translator } from '@components';
import { useProfile, useToken } from '@contexts-investor';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { formatDate } from 'date-utils-2020';

const MyTokenDetails = () => {
  const { tokenDetail, getTokenDetails, loadingTokens } = useToken();
  const { profile, loadProfile, balanceControl, showBalance } = useProfile();
  const { tokenId } = useParams();
  const [investValue, setInvestValue] = useState();
  const [receiveValue, setReceiveValue] = useState();
  const [quantitySold, setQuantitySold] = useState();

  useEffect(() => {
    getTokenDetails(tokenId);
  }, [tokenId]);

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    if (userId !== (null && undefined)) loadProfile(userId);
  }, [localStorage.getItem('userId')]);

  useEffect(() => {
    if (tokenDetail && tokenDetail.purchases) {
      let investTotal = 0,
        quantity = 0;
      for (const purchase of tokenDetail.purchases) {
        investTotal += parseInt(purchase.boughtValue);
        quantity += parseInt(purchase.boughtQuantity);
      }
      setInvestValue(investTotal);
      setQuantitySold(quantity);
      const total = quantity * (parseInt(tokenDetail.unityPrice) + parseInt(tokenDetail.yieldValue));
      setReceiveValue(total);
    }
  }, [tokenDetail]);

  if (loadingTokens) {
    return <Loading />;
  }

  if (profile && tokenDetail && investValue && receiveValue)
    return (
      <>
        <div className="page-wrapper" style={{ marginTop: '3rem' }}>
          <div className="row row-modifier">
            <div
              className="row col-xxl-5 col-xl-6 col-md-12 col-xs-12 flex-column"
              style={{ gap: '1.25rem', padding: 0, justifyContent: 'space-between' }}
            >
              <div
                className="col-lg-12 invest-label d-flex align-items-center flex-column"
                style={{ backgroundColor: 'white', height: '10vh' }}
              >
                <div
                  className="col-lg-12 d-flex flex-row justify-content-center align-items-center mb-0 form-group"
                  style={{ textAlign: 'center', alignItems: 'center', height: '100%' }}
                >
                  <p className="mb-0" style={{ whiteSpace: 'nowrap', fontSize: '1.5rem', width: '70%' }}>
                    <Translator path="myInvestment.balance" />
                  </p>
                  <input
                    className="mb-0 form-control show-pass invest-input"
                    style={{ border: 0, fontSize: '1.5rem', textAlign: 'start' }}
                    readOnly
                    value={
                      profile.wallet
                        ? profile.wallet.balance.toLocaleString('pt-BT', { style: 'currency', currency: 'BRL' })
                        : 'R$ 0,00'
                    }
                    type={showBalance}
                  />
                  {showBalance === 'text' ? (
                    <i
                      className="show-pass fa fa-eye-slash"
                      onClick={() => {
                        balanceControl('password');
                      }}
                    />
                  ) : (
                    <i
                      className="show-pass fa fa-eye"
                      onClick={() => {
                        balanceControl('text');
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="col-12" style={{ padding: 0 }}>
                <TokenCard props={tokenDetail} />
              </div>
            </div>
            <div
              className="row col-xxl-3 col-xl-6 col-md-12 col-sm-12 col-xs-12 padding-control justify-content-center"
              style={{ gap: '1.25rem' }}
            >
              <div
                className="invest-label-padding invest-label-shadow col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-5 col-xs-5"
                style={{ padding: '2.5rem' }}
              >
                <img src={investIcon} className="invest-label-icon" />
                <p className="info-revenue invest-label-icon-margin ">
                  <Translator path="myInvestment.buyTokenPage.valInvest" />
                </p>
                <p className="text-success info-text-big" style={{ whiteSpace: 'nowrap' }}>
                  {'R$ ' + new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(parseFloat(investValue))}
                </p>
              </div>

              <div
                className="invest-label-padding invest-label-shadow col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-5 col-xs-5"
                style={{ padding: '2.5rem' }}
              >
                <img src={cardLogo} className="invest-label-icon" />
                <p className="info-revenue invest-label-icon-margin ">
                  <Translator path="myInvestment.buyTokenPage.quantity" />
                </p>
                <p className="info-text info-text-big">{quantitySold}</p>
              </div>
            </div>
            <div
              className="row col-xxl-3 col-xl-12 col-md-12 col-sm-12 col-xs-12 padding-control justify-content-center"
              style={{ gap: '1.25rem', }}
            >
              <div
                className="invest-label-padding invest-label-shadow col-xxl-12 col-xl-5 col-lg-5 col-md-12 col-sm-5 col-xs-5"
                style={{ padding: '2.5rem' }}
              >
                <img src={dollarIcon} className="invest-label-icon" />
                <p className="info-revenue invest-label-icon-margin ">
                  <Translator path="myInvestment.buyTokenPage.valReceive" />
                </p>
                <p className="text-success info-text-big" style={{ whiteSpace: 'nowrap' }}>
                  {'R$ ' +
                    new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(parseFloat(receiveValue))}
                </p>
              </div>

              <div
                className="invest-label-padding invest-label-shadow col-xxl-12 col-xl-5 col-lg-5 col-md-12 col-sm-5 col-xs-5"
                style={{ padding: '2.5rem' }}
              >
                <img src={calIcon} className="invest-label-icon" />
                <p className="info-revenue invest-label-icon-margin ">
                  <Translator path="myInvestment.buyTokenPage.dateReceive" /> {tokenDetail.paymentParcels}x
                </p>
                <p className="info-text info-text-big">{formatDate(tokenDetail.paymentDate, 'dd/MM/yyyy')}</p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default MyTokenDetails;
