import Translator from '../../../../components/I18n/Translator';

export const COLUMNS = [
  {
    Header: <Translator path={'components.withdrawTable.order'} />,
    Footer: <Translator path={'components.withdrawTable.order'} />,
    accessor: 'withdrawCode',
    isSortable: true,
  },
  {
    Header: <Translator path={'components.withdrawTable.val'} />,
    Footer: <Translator path={'components.withdrawTable.val'} />,
    accessor: 'formatedValue',
    isSortable: true,
  },
  {
    Header: <Translator path={'components.withdrawTable.date'} />,
    Footer: <Translator path={'components.withdrawTable.date'} />,
    accessor: 'day',
    isSortable: true,
  },
  {
    Header: <Translator path={'components.withdrawTable.key'} />,
    Footer: <Translator path={'components.withdrawTable.key'} />,
    accessor: 'key',
    isSortable: true,
  },
  {
    Header: <Translator path={'components.withdrawTable.form'} />,
    Footer: <Translator path={'components.withdrawTable.form'} />,
    accessor: 'form',
    isSortable: false,
  },
];
