import { Translator } from '@components';
import { formatDate } from 'date-utils-2020';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import Status from '../TokenCard/Status';

const PurchaseCard = ({ props, title = '', showStatus = true }) => {
  return (
    <Swiper className="swiper overflow-hiden" style={{ width: '100%', cursor: 'pointer' }}>
      <SwiperSlide>
        <div
          className="card card-box card-hover"
          style={{
            marginBottom: 0,
            height: '100%',
            boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15)',
          }}
        >
          {showStatus && <Status props={{ status: props.status }} />}

          <div className="my-token-card-box d-flex justify-content-center align-items-center">
            <div className="my-token-card-box-left">
              <div className="my-token-image-box d-flex align-items-center">
                <img src={props.token.image} alt="my token" />
                <div className="my-token-name">
                  <p style={{ color: 'black' }}>{props.token.name}</p>
                  <p>
                    <Translator path={'purchaseCard.purchaseReceivable'} />
                  </p>
                </div>
              </div>

              <p>
                <Translator path={'purchaseCard.purchaseOrder'} />:
              </p>
              <p style={{ color: 'black' }}>{props.purchaseCode}</p>

              <p>
                <Translator path={'purchaseCard.purchaseQuantity'} />:
              </p>
              <p style={{ color: 'black' }}>{props.boughtQuantity}</p>

              <p>
                <Translator path={'purchaseCard.purchaseInvestedAmount'} />:
              </p>
              <p style={{ color: 'black' }}>
                {parseFloat(props.boughtValue).toLocaleString('pt-BT', { style: 'currency', currency: 'BRL' })}
              </p>

              <p>
                {props.status === 'CANCELED' ? (
                  <Translator path="purchaseCard.purchaseChargebackAmount" />
                ) : props.status === 'LIQUIDATED' ? (
                  <Translator path="purchaseCard.purchaseReceivedAmount" />
                ) : (
                  <Translator path={'purchaseCard.purchaseAmountReceivable'} />
                )}
                :
              </p>
              <p style={{ color: 'black' }}>
                {(
                  props.boughtQuantity * parseFloat(props.token.unityPrice) +
                  parseFloat(props.token.yieldValue) * props.boughtQuantity
                ).toLocaleString('pt-BT', { style: 'currency', currency: 'BRL' })}
              </p>
            </div>

            <div className="my-token-card-box-right">
              <div>
                <p>
                  <Translator path={'purchaseCard.purchaseDate'} />:
                </p>
                <p style={{ color: 'black' }}>{`${formatDate(props.createdAt, 'dd/MM/yyyy')}`}</p>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

export default PurchaseCard;
