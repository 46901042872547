import { Translator, Loading } from '@components';
import { useTokenProposal } from '@contexts-issuer';
import { formatDate } from 'date-utils-2020';
import { Button, Modal, ModalBody, ModalFooter, ModalTitle } from 'react-bootstrap';
import { useEffect } from 'react';

export const ProposalOverviewModal = ({
  show,
  body,
  issuer,
  imageLink,
  setShow,
  setSubmitted,
  isEdit,
  isCounterProposal = false,
}) => {
  const { createTokenProposal, createdTokenProposal, updateTokenProposal, loading, preApproveProposal } =
    useTokenProposal();

  useEffect(() => {
    console.log(body);
  }, []);

  useEffect(() => {
    if (Object.keys(createdTokenProposal).length !== 0) {
      setSubmitted(true);
    }
  }, [createdTokenProposal]);

  const submitProposal = (e) => {
    e.preventDefault();
    const submit = async () => {
      await createTokenProposal(body);
      setShow(false);
    };

    const edit = async () => {
      await updateTokenProposal(body.tokenProposalId, body);

      setShow(false);
      isCounterProposal && setSubmitted(true);
    };

    const preApprove = async () => {
      await preApproveProposal(body.tokenProposalId);

      setShow(false);
    };

    if (isEdit) {
      edit();
    } else if (isCounterProposal) {
      preApprove();
    } else {
      submit().catch(() => setSubmitted(false));
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (body && issuer) {
    return (
      <>
        <Modal show={show} centered className="modal" size="lg">
          <ModalTitle className="modal-title">
            <p className="m-0">
              <Translator path={'pages.tokenProposal.overviewModal.overview'} />
            </p>
          </ModalTitle>
          <ModalBody>
            <div className="issuer-info">
              <img
                src={
                  body.image
                    ? `data:image/jpeg;base64,${imageLink}`
                    : 'https://static.significados.com.br/foto/da4f-cor-cinza-significados.jpg'
                }
                style={{ width: '90px', height: '90px' }}
              />
              <div className="issuer-info-text">
                <p>{body.name}</p>
                <p>
                  <Translator path={'pages.tokenProposal.overviewModal.issuer'} /> {issuer.name}
                </p>
              </div>
            </div>
            <div className="proposal-info">
              <div className="proposal-info-text">
                <Translator path={'pages.tokenProposal.overviewModal.invoice'} />
                <span>{body?.invoiceValue?.toLocaleString('pt-BT', { style: 'currency', currency: 'BRL' })}</span>
              </div>
              <div className="proposal-info-text">
                <Translator path={'pages.tokenProposal.overviewModal.valueToReceive'} />
                <span id="green-text">
                  {body?.valueToReceive?.toLocaleString('pt-BT', { style: 'currency', currency: 'BRL' })}
                </span>
              </div>
              <div className="proposal-info-text">
                <Translator path={'pages.tokenProposal.overviewModal.discountPercentage'} />
                <span>{`${(+body.discountPercentage * 100).toFixed(1)}%`}</span>
              </div>
              <div className="proposal-info-text">
                <Translator path={'pages.tokenProposal.overviewModal.fundingDate'} />
                <span>{formatDate(body.fundingLimitDate, 'dd/MM/yyyy')}</span>
              </div>
              <div className="proposal-info-text">
                <Translator path={'pages.tokenProposal.overviewModal.liquidationDate'} />
                <span>{formatDate(body.liquidationDate, 'dd/MM/yyyy')}</span>
              </div>
            </div>
          </ModalBody>
          <ModalFooter className="actions proposal-actions">
            <Button
              variant="outline-primary"
              size="lg"
              id="btn-outlined"
              className="m-0"
              onClick={() => setShow(false)}
            >
              <Translator path={'pages.tokenProposal.overviewModal.cancel'} />
            </Button>
            <Button
              variant="primary"
              size="lg"
              className="text-center m-0"
              id="btn"
              type="submit"
              onClick={submitProposal}
            >
              <Translator path={'pages.tokenProposal.overviewModal.confirm'} />
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
};
