import { noPreview } from '@assets';
import { Translator } from '@components';
import { useIssuer } from '@contexts-issuer';
import { Buffer } from 'buffer';
import { formatDate } from 'date-utils-2020';
import { useEffect, useState } from 'react';
import { useProfile } from '../../../contexts/Investor/ProfileContext';


const Profile = ({ person, isIssuer = false }) => {
  const { downloadImageIssuer } = useIssuer();
  const { downloadImageInvestor } = useProfile();
  const [preview, setPreview] = useState();

  useEffect(() => {
    const downloadData = async (imageURL) => {
      let base64Image;
        if(process.env.REACT_APP_BUILD_TARGET === 'issuer'){
          base64Image = await downloadImageIssuer(imageURL.replaceAll('/', '%2F'));
        }else if(process.env.REACT_APP_BUILD_TARGET === 'investor'){
          base64Image = await downloadImageInvestor(imageURL.replaceAll('/', '%2F'));
        }
        const downloadedImage = Buffer.from(base64Image.data, 'binary').toString('base64');
        setPreview(downloadedImage);
    };
    if (person && person.image) downloadData(person.image);
  }, [person && person.image]);

  if (person) {
    return (
      <>
        <div className="person-profile d-flex flex-row align-items-center">
          <div className="person-image">
            {person?.image && preview !== null ? (
              <img src={preview ? `data:image/jpeg;base64,${preview}` : noPreview} alt="" />
            ) : (
              <div className="row align-items-center justify-content-center ">
                <div className="col-6 my-header-letter">
                  <p style={{ margin: 0, color: 'white' }}>{person && person?.corporateName[0].toUpperCase()}</p>
                </div>
              </div>
            )}
          </div>
          <div className="person-name d-flex flex-column">
            {person?.foundationDate ? (
              <p className="m-0">
                {isIssuer ? '' : <Translator path="investment.buyTokenPage.issuer.company" />}

                {` ${person?.corporateName}`}
              </p>
            ) : (
              <p className="m-0">{person?.corporateName}</p>
            )}
            {person?.foundationDate ? (
              <p className="m-0">
                {isIssuer ? (
                  <Translator path="pages.tokenProposal.proposalPreview.foundationDate" />
                ) : (
                  <Translator path="investment.buyTokenPage.issuer.foundation" />
                )}
                {` ${formatDate(person?.foundationDate, 'dd/MM/yyyy')}`}
              </p>
            ) : (
              <p className="m-0">{person?.simpleDescription}</p>
            )}
          </div>
        </div>
        <div className="person-description">
          <p>{person?.detailedDescription}</p>
          {person?.website ? (
            <p className="m-0">
              Site:{' '}
              <a
                href={
                  person?.website
                    ? person.website.includes('https://')
                      ? person.website
                      : `https://${person.website}`
                    : ''
                }
                target="_blank"
                className="person-link"
                rel="noreferrer"
              >
                {person?.website}
              </a>
            </p>
          ) : (
            ''
          )}
        </div>
      </>
    );
  }
};

export default Profile;
