import { useState, useEffect } from 'react';

export function useZoom() {
  const [zoom, setZoom] = useState(window.devicePixelRatio);

  useEffect(() => {
    const handleResize = () => {
      setZoom(window.devicePixelRatio);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return zoom;
}
