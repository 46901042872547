import { noPreview } from '@assets';
import { InputText, Translator } from '@components';
import { useIssuer } from '@contexts-issuer';
import { cpfMask, phoneMask } from '@utils';
import { Buffer } from 'buffer';
import React, { useEffect, useState } from 'react';

const Profile = () => {
  const { issuerInfo, imageLoading, uploadUserImage, getIssuerLoginId, downloadUserImage, updateIssuerUser, loading } =
    useIssuer();
  const [isEditing, setIsEditing] = useState(false);
  const [preview, setPreview] = useState();
  const [uploadPath, setUploadPath] = useState();
  const [image, setImage] = useState();
  const [phone, setPhone] = useState();
  const [name, setName] = useState();

  const setPhonewithMask = (value) => {
    setPhone(phoneMask(value));
  };

  const makeRequest = async () => {
    const body = {
      name: name ? name : issuerInfo.name ? issuerInfo.name : undefined,
      issuerLoginId: issuerInfo.issuerLoginId,
      issuerId: issuerInfo.issuerId,
      image: uploadPath ? uploadPath : issuerInfo.image ? issuerInfo.image : undefined,
      phone: phone ? phone : issuerInfo.phone ? issuerInfo.phone : undefined,
    };

    const response = await updateIssuerUser(body);
    if (response) {
      setIsEditing(false);
      getIssuerLoginId(issuerInfo.issuerLoginId);
    }
  };

  useEffect(() => {
    const uploadImage = async () => {
      const data = await uploadUserImage(image);
      setUploadPath(data);
    };

    if (image) uploadImage();
  }, [image, setImage]);

  useEffect(() => {
    const downloadedImage = async () => {
      const id = uploadPath ? uploadPath : issuerInfo && issuerInfo.image ? issuerInfo.image : '';
      const rawData = await downloadUserImage(id.replaceAll('/', '%2F'));
      const image = Buffer.from(rawData.data, 'binary').toString('base64');
      setPreview(image);
    };

    if ((issuerInfo && issuerInfo.image) || uploadPath) downloadedImage();
  }, [issuerInfo && issuerInfo.image, uploadPath]);

  return (
    <div className="d-flex justify-content-center">
      <div className="col-10 issuer-profile-wrapper">
        <div className="row col-12 d-flex justify-content-start align-items-center profile-header">
          <p className="col-6 header-label">
            <Translator path="pages.profile.editTitle" />
          </p>
        </div>
        <div className="row col-12 my-profile-body">
          <div className={`author-profile d-flex align-items-center flex-control-issuer`}>
            <div className="author-media" style={{ margin: 0 }}>
              {imageLoading ? (
                <>
                  <div className="loading-spinner" style={{ width: '90px', height: '90px', position: 'absolute' }} />
                  <img src={noPreview} alt="" style={{ width: '90px', height: '90px', margin: '0 !important' }} />
                </>
              ) : (
                <>
                  {preview ? (
                    <img
                      src={preview ? `data:image/jpeg;base64,${preview}` : noPreview}
                      alt=""
                      style={{ width: '90px', height: '90px' }}
                    />
                  ) : (
                    <label
                      style={{
                        width: '90px',
                        height: '90px',
                        fontSize: '2.25rem',
                        color: 'white',
                        borderRadius: '5rem',
                        backgroundColor: '#FF9900'
                      }}
                      className="d-flex align-items-center justify-content-center"
                    >
                      {issuerInfo && issuerInfo.name[0].toUpperCase()}
                    </label>
                  )}

                  {isEditing ? (
                    <div
                      className="upload-link"
                      title=""
                      data-toggle="tooltip"
                      data-placement="right"
                      data-original-title="update"
                      style={{ backgroundColor: '#FF9900' }}
                    >
                      <input
                        type="file"
                        className="update-file"
                        style={{ backgroundColor: '#FF9900' }}
                        onChange={(e) => setImage(e.target.files[0])}
                        readOnly={isEditing ? false : true}
                      />
                      <i className="fa fa-camera"></i>
                    </div>
                  ) : (
                    ''
                  )}
                </>
              )}
            </div>
            <div className="d-flex flex-row text-input-no-star">
              <p className="corporate-logo-text" style={{ marginLeft: '1rem' }}>
                <Translator path="pages.profile.photo" />
              </p>
            </div>
          </div>
          <div className="row col-12 flex-column my-profile-form">
            <div className="col-xl-6 col-lg-12 col-md-10 col-sm-12 d-flex flex-column" style={{ gap: '1.25rem 0' }}>
              <InputText
                text="pages.profile.name"
                className={`${isEditing ? 'form-control' : 'issuer-input-disabled'}`}
                setState={setName}
                value={name ? name : issuerInfo && issuerInfo.name ? issuerInfo.name : ''}
                readOnly={isEditing ? false : true}
              />
              <InputText
                text="pages.profile.cpf"
                className="issuer-input-disabled"
                defaultValue={issuerInfo && issuerInfo.cpf ? cpfMask(issuerInfo.cpf) : ''}
                readOnly={true}
              />

              <InputText
                text="pages.profile.email"
                className="issuer-input-disabled"
                defaultValue={issuerInfo && issuerInfo.email}
                readOnly={true}
              />
              <InputText
                text="pages.profile.phone"
                className={`${isEditing ? 'form-control' : 'issuer-input-disabled'}`}
                readOnly={isEditing ? false : true}
                setState={setPhonewithMask}
                maxLength={15}
                value={phone ? phone : issuerInfo && issuerInfo.phone ? phoneMask(issuerInfo.phone) : ''}
              />

              <div
                className="row col-12 d-flex flex-row justify-content-between"
                style={{ margin: '1.25rem 0 0 0', padding: 0 }}
              >
                {isEditing === false ? (
                  <button className="col-7 btn btn-orange " onClick={() => setIsEditing(true)}>
                    <Translator path="pages.profile.edit" />
                  </button>
                ) : (
                  <>
                    <button
                      className="col-4 btn btn-outlined "
                      style={{ padding: '1rem 0.625rem' }}
                      onClick={() => setIsEditing(false)}
                    >
                      <Translator path="pages.profile.goback" />
                    </button>

                    <button
                      className={`col-7 btn btn-orange ${loading ? 'd-flex justify-content-center disabled' : ''}`}
                      style={{ padding: '1rem 0.625rem' }}
                      onClick={() => makeRequest()}
                    >
                      {loading ? (
                        <div className="loading-spinner" style={{ width: '30px', height: '30px' }} />
                      ) : (
                        <Translator path="pages.profile.submit" />
                      )}
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
