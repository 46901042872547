import { Modal, ModalBody, ModalTitle, Button, Form } from 'react-bootstrap';
import { useState } from 'react';
import { Translator, InputText } from '@components';
import DatePicker from 'react-datepicker';
import { moneyMask } from '@utils';

import { CloseIcon } from '@assets';

export const FiltersModal = ({ show, setState }) => {
  const [fundingInitialDate, setFundingInitialDate] = useState();
  const [fundingFinalDate, setFundingFinalDate] = useState();
  const [liquidationInitialDate, setLiquidationInitialDate] = useState();
  const [liquidationFinalDate, setLiquidationFinalDate] = useState();
  const [name, setName] = useState();
  const [valueToReceive, setValueToReceive] = useState(0);
  const [status, setStatus] = useState('');

  const setValueToReceiveWithMask = (value) => {
    setValueToReceive(moneyMask(value));
  };

  const closeModal = () => {
    setState(false);
  };

  const cleanFilters = () => {
    setFundingInitialDate(undefined);
    setFundingFinalDate(undefined);
    setLiquidationInitialDate(undefined);
    setLiquidationFinalDate(undefined);
    setName(undefined);
    setValueToReceive(0);
    setStatus('');
  };

  const submitForm = (e) => {
    e.preventDefault();
  };

  return (
    <Modal
      show={show}
      onShow={cleanFilters}
      onEscapeKeyDown={closeModal}
      centered={true}
      className="filters-modal"
      size="lg"
    >
      <ModalTitle className="d-flex flex-row justify-content-between align-items-baseline">
        <p>
          <Translator path={'pages.tokensList.filtersModal.button'} />
        </p>
        <Button variant="link close-button close-filter-button p-0" onClick={closeModal}>
          <img src={CloseIcon} />
        </Button>
      </ModalTitle>
      <ModalBody className="modal-body">
        <form
          onSubmit={(e) => {
            submitForm(e);
          }}
        >
          <div className="col-12">
            <div className="row">
              <div className="col-6">
                <div className="d-flex flex-row input-title">
                  <p className="text-input-label">
                    <Translator path={'pages.tokensList.filtersModal.fundingInitial'} />
                  </p>
                </div>
                <div className="date position-relative">
                  <DatePicker
                    selected={fundingInitialDate}
                    onChange={(date) => setFundingInitialDate(date)}
                    dateFormat="dd/MM/yyyy"
                    className="date-picker col-12 d-flex align-items-start form-control"
                  />
                  <i className="material-icons position-absolute">today</i>
                </div>
              </div>
              <div className="col-6">
                <div className="d-flex flex-row input-title">
                  <p className="text-input-label">
                    <Translator path={'pages.tokensList.filtersModal.fundingFinal'} />
                  </p>
                </div>
                <div className="date position-relative">
                  <DatePicker
                    selected={fundingFinalDate}
                    onChange={(date) => setFundingFinalDate(date)}
                    dateFormat="dd/MM/yyyy"
                    className="date-picker col-12 d-flex align-items-start form-control"
                    startDate={fundingInitialDate}
                    minDate={fundingInitialDate}
                  />
                  <i className="material-icons position-absolute">today</i>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="d-flex flex-row input-title">
                  <p className="text-input-label">
                    <Translator path={'pages.tokensList.filtersModal.liquidationInitial'} />
                  </p>
                </div>
                <div className="date position-relative">
                  <DatePicker
                    selected={liquidationInitialDate}
                    onChange={(date) => setLiquidationInitialDate(date)}
                    dateFormat="dd/MM/yyyy"
                    className="date-picker col-12 d-flex align-items-start form-control"
                  />
                  <i className="material-icons position-absolute">today</i>
                </div>
              </div>
              <div className="col-6">
                <div className="d-flex flex-row input-title">
                  <p className="text-input-label">
                    <Translator path={'pages.tokensList.filtersModal.liquidationFinal'} />
                  </p>
                </div>
                <div className="date position-relative">
                  <DatePicker
                    selected={liquidationFinalDate}
                    onChange={(date) => setLiquidationFinalDate(date)}
                    dateFormat="dd/MM/yyyy"
                    className="date-picker col-12 d-flex align-items-start form-control"
                    startDate={liquidationInitialDate}
                    minDate={liquidationInitialDate}
                  />
                  <i className="material-icons position-absolute">today</i>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6 input-title">
                <InputText
                  text={'pages.tokensList.filtersModal.name'}
                  setState={setName}
                  value={name}
                  obrigatory={false}
                />
              </div>
              <div className="col-6 input-title">
                <InputText
                  text={'pages.tokensList.filtersModal.valueToReceive'}
                  setState={setValueToReceiveWithMask}
                  value={valueToReceive}
                  obrigatory={false}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="d-flex flex-row input-title">
                  <p className="text-input-label">Status</p>
                </div>
                <Form.Select className="form-control" onChange={(e) => setStatus(e.target.value)} value={status}>
                  <option value=""></option>
                  <option value="INCAPTURE">
                    <Translator path={'pages.tokensList.filtersModal.status.inCapture'} />
                  </option>
                  <option value="ALLSOLD">
                    <Translator path={'pages.tokensList.filtersModal.status.allSold'} />
                  </option>
                  <option value="LIQUIDATING">
                    <Translator path={'pages.tokensList.filtersModal.status.liquidating'} />
                  </option>
                  <option value="COMPLETE">
                    <Translator path={'pages.tokensList.filtersModal.status.complete'} />
                  </option>
                  <option value="CANCELLED">
                    <Translator path={'pages.tokensList.filtersModal.status.cancelled'} />
                  </option>
                </Form.Select>
              </div>
            </div>
            <div className="row actions actions-filters">
              <div className="col-4"></div>
              <div className="col-4 m-0 p-0">
                <Button
                  variant="outline-primary"
                  size="lg"
                  className="text-center text-modified m-0"
                  id="btn-outlined"
                  onClick={cleanFilters}
                >
                  <Translator path={'pages.tokensList.filtersModal.clean'} />
                </Button>
              </div>
              <div className="col-4 m-0 p-0" style={{ marginLeft: '1.25rem !important' }}>
                <Button
                  variant="primary"
                  size="lg"
                  className="text-center text-modified w-100 m-0"
                  id="btn"
                  type="submit"
                >
                  <Translator path={'pages.tokensList.filtersModal.filter'} />
                </Button>
              </div>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};
