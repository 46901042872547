import React from 'react';
import { concludedSingup, loginBg } from '@assets';
import { useZoom } from '@utils';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { I18n, Translator } from '@components';

function RegisterConcluded(props) {
  const { } = useZoom;

  return (
    <div className="page-wraper">
      <div className="browse-job login-style3">
        <div
          className="bg-img-fix overflow-hidden"
          style={{
            background: '#fff url(' + loginBg + ')',
            height: '100vh',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right',
            backgroundSize: 'contain',
          }}
        >
          <div className="row gx-0">
            <div className="login-form-container bg-white d-flex justify-content-center align-items-center">
              <div className="login-form d-flex justify-content-center align-items-center">
                <div className="login-form-box nav nav-tabs border-bottom-0">
                  <div className="tab-content w-100" id="nav-tabContent">
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                      <img
                        src={concludedSingup}
                        alt="concluded"
                        style={{ width: '12rem', height: '12rem', marginBottom: '1rem' }}
                      />
                      <h4
                        className='select-option-text'
                        style={{ textAlign: 'center' }}>
                        <Translator path={'login.concluded'} />
                      </h4>
                      <label
                        className='select-option-text'
                        style={{ textAlign: 'center', fontSize: '1rem', margin: '1rem', fontWeight: '400' }}
                      >
                        <Translator path={'login.concludedtext'} />
                      </label>
                      <div className="text-center bottom mt-1">
                        <NavLink to="/login" className="btn btn-orange" style={{ width: '17rem' }}>
                          <Translator path={'login.login'} />
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <I18n />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


export default (RegisterConcluded);
