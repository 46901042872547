import * as api from '../api';

export function sentEmail(uid) {
  return api.post(`v2/withdrawToken/${uid}`);
}

export function validadeWithdrawToken(params) {
  return api.get('v2/withdrawToken/validateToken', params);
}

export function newWithdraw(body) {
  return api.post('v2/withdraw', body);
}

export function getWithdraw(params) {
  return api.get('withdraw/', params);
}
