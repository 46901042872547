export const editingStyle = {
  valueContainer: (provided) => ({
    ...provided,
    maxHeight: '3rem',
    padding: 0,
  }),
  singleValue: (provided) => ({
    ...provided,
    padding: '0 0 0 1.2rem',
    margin: 0,
  }),
  input: (provided) => ({
    ...provided,
    maxHeight: '3rem',
    margin: 0,
    padding: '0.813rem 0 0.813rem 1.2rem',
  }),

  control: (baseStyles, state) => ({
    ...baseStyles,
    padding: 0,
    background: '#FFFFFF',
    lineHeight: 2.4,
    fontSize: '1rem',
    height: '3rem',
    color: 'var(--light-gray) !important',
    borderColor: '#c0c0c0',
    borderRadius: '0.5rem',
    border: '1px solid #C0C0C0',
    boxShadow: '#ff9900',

    '&:hover': {
      borderColor: '#ff9900',
    },
    '&:hover,&:active': {
      boxShadow: 'none',
      background: '#FFFFFF',
      color: '#757575',
    },
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#FF9900' : 'white',
    color: 'var(--light-gray) !important',

    '&:hover': {
      borderColor: '#FF9900',
      backgroundColor: '#FF9900',
    },
  }),
  placeholder: (baseStyles, state) => ({
    ...baseStyles,
    color: 'var(--light-gray) !important',
  }),
};
