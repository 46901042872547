import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSortBy, useTable } from 'react-table';
import Translator from '../I18n/Translator';

const Table = ({ props: { tableData, tableColumns, emptyImage } }) => {
  const columns = useMemo(() => tableColumns, []);
  const data = useMemo(() => tableData, [tableData]);
  const tableInstance = useTable({ columns, data }, useSortBy);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    //footerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  return (
    <div className="row" style={{ margin: 0, padding: 0 }}>
      <div className="card col-12" style={{ margin: 0, padding: 0 }}>
        <div className="card-body col-12" style={{ paddingLeft: 0, paddingRight: 0 }}>
          <div className="table-responsive col-12">
            <div className="dataTables_wrapper">
              <table {...getTableProps()} className="table dataTable display">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                          {column.render('Header')}
                          <span className="ml-1">
                            {column.isSortable ? (
                              column.isSorted ? (
                                column.isSortedDesc ? (
                                  <i className="fa fa-arrow-down ms-2 fs-14" style={{ opacity: '0.7' }} />
                                ) : (
                                  <i className="fa fa-arrow-up ms-2 fs-14" style={{ opacity: '0.7' }} />
                                )
                              ) : (
                                <i className="fa fa-sort ms-2 fs-14" style={{ opacity: '0.3' }} />
                              )
                            ) : (
                              ''
                            )}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                {tableData.length > 0 ? (
                  <tbody {...getTableBodyProps()} style={{ textAlign: 'center' }}>
                    {rows.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return <td {...cell.getCellProps()}> {cell.render('Cell')} </td>;
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                ) : (
                  ''
                )}
              </table>
              {tableData.length > 0 ? (
                ''
              ) : (
                <div className="d-flex flex-column justify-content-center align-items-center tableImgFormat">
                  <img src={emptyImage} style={{ width: '13rem', height: '13rem', borderRadius: '12px' }} />
                  <h4 className="tableText">
                    <Translator path={`components.tableBody.${window.location.pathname.replaceAll('/', '')}`} />
                  </h4>
                  <button
                    className="btn btn-orange"
                    onClick={() => {
                      if (pathname === '/wallet/deposit') {
                        navigate('/wallet');
                      } else if (pathname === '/wallet/statement') {
                        navigate('/investment');
                      } else if (pathname === '/wallet/withdrawList') {
                        navigate('/wallet/withdraw');
                      } else if (pathname === '/wallet/depositList' && window.location.hostname.includes('emissor')) {
                        navigate('/tokenProposal');
                      } else if (pathname === '/tokenProposal/list' || pathname === '/tokenProposal/tokens/list') {
                        navigate('/tokenProposal');
                      }
                    }}
                  >
                    <Translator path={`components.tableButton.${window.location.pathname.replaceAll('/', '')}`} />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
