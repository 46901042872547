import { useEffect, useState } from 'react';

const Countdown = () => {
  const [hours, setHours] = useState(59);
  const [counter, setCounter] = useState(59);

  useEffect(() => {
    if (counter === 0) {
      setHours(hours - 1);
      setCounter(59);
    }

    const timer =
      counter > 0 &&
      setInterval(() => {
        setCounter(counter - 1);
      }, 1000);
    return () => clearInterval(timer);
  }, [counter]);

  return <div className="countdown-text">{`${hours}:${counter}`}</div>;
};

export default Countdown;
