import { useState, useEffect } from 'react';
import { Translator, AnalysisCard, Loading } from '@components';
import RegisterForm from './RegisterForm';
import FinishForm from './FinishForm';
import { useBank, useIssuer } from '@contexts-issuer';

const MyBank = () => {
  const [layoutController, setLayoutController] = useState(1);
  const [bankInfo, setBankInfo] = useState();
  const [banks, setBanks] = useState();
  const { issuerProfile } = useIssuer();
  const { getBankInfo, getAllBanks, registerBankInfo, editBankInfo, loadingBankInfo, loadingAllBanks } = useBank();

  useEffect(() => {
    const returnBankData = async () => {
      const data = await getBankInfo();
      setBankInfo(data);
    };

    const getBanks = async () => {
      const data = await getAllBanks();
      const mappedBanks = Array.from(data)
        .map((item) => {
          if (item.code !== null)
            return {
              label: `${item.code} - ${item.fullName}`,
              value: item.code,
            };
        })
        .filter((item) => item !== undefined);
      setBanks(mappedBanks);
    };
    returnBankData();
    getBanks();
  }, []);

  if (loadingBankInfo || loadingAllBanks) {
    return <Loading />;
  }

  if (bankInfo)
    return (
      <div className="row issuer-page-wrapper" id="mybank">
        <div className="row col-12 issuer-page-header d-flex justify-content-between align-items-center" id="mybank">
          <p className="col-6 page-title">
            <Translator path="pages.myBank.title" />
          </p>
          {bankInfo?.status !== 'INCOMPLETE' && layoutController === 1 ? (
            <div className="col-9 col-md-6 col-lg-5 analysis-card ">
              <AnalysisCard props={{ status: bankInfo?.status }} />
            </div>
          ) : (
            ''
          )}
        </div>
        {bankInfo?.status === 'INCOMPLETE' ? (
          <>
            {layoutController === 1 ? (
              <>
                <RegisterForm
                  props={{
                    setLayout: setLayoutController,
                    issuerProfile,
                    bankInfo,
                    banks,
                    handleRequest: registerBankInfo,
                  }}
                />
              </>
            ) : (
              <FinishForm props={{ email: issuerProfile?.email, setLayout: setLayoutController }} />
            )}
          </>
        ) : (
          <>
            {layoutController === 1 ? (
              <>
                <RegisterForm
                  props={{
                    setLayout: setLayoutController,
                    issuerProfile,
                    bankInfo,
                    banks,
                    handleRequest: editBankInfo,
                    isEdit: true,
                  }}
                />
              </>
            ) : (
              <FinishForm props={{ email: issuerProfile?.email, setLayout: setLayoutController }} />
            )}
          </>
        )}
      </div>
    );
};

export default MyBank;
