import { createContext, useCallback, useContext, useState } from 'react';
import swal from 'sweetalert';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as bankService from '../../services/Issuer/bankService';

export const BankContext = createContext({});

const BankProvider = ({ children }) => {
  const { t } = useTranslation();
  const [loadingBankInfo, setLoadingBankInfo] = useState(false);
  const [loadingAllBanks, setLoadingAllBanks] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const navigate = useNavigate();

  const getBankInfo = useCallback(async () => {
    setLoadingBankInfo(true);
    try {
      const { data } = await bankService.getBankAccountInfo();
      return { ...data };
    } catch (error) {
      return { status: 'INCOMPLETE' };
    } finally {
      setLoadingBankInfo(false);
    }
  }, []);

  const registerBankInfo = useCallback(async (body) => {
    setButtonLoading(true);
    try {
      return await bankService.registerBankData(body);
    } catch (error) {
      const title = t('pages.myBank.err.titleReg');
      const body = t('pages.myBank.err.body');
      swal(title, body, 'error');  
    } finally {
      setButtonLoading(false);
    }
  });

  const editBankInfo = useCallback(async (body) => {
    setButtonLoading(true);
    try {
      return await bankService.editBankData(body);
    } catch (error) {
      const title = t('pages.myBank.err.titleEdit');
      const body = t('pages.myBank.err.body');
      swal(title, body, 'error');
    } finally {
      setButtonLoading(false);
    }
  });

  const getAllBanks = useCallback(async () => {
    setLoadingAllBanks(true);
    try {
      const { data } = await bankService.getAllBanks();
      return data;
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingAllBanks(false);
    }
  });

  return (
    <BankContext.Provider
      value={{
        getBankInfo,
        loadingBankInfo,
        loadingAllBanks,
        buttonLoading,
        getAllBanks,
        registerBankInfo,
        editBankInfo,
      }}
    >
      {children}
    </BankContext.Provider>
  );
};

const useBank = () => {
  return useContext(BankContext);
};

export { BankProvider, useBank };
